import React, { useState } from "react";
import Filter from "./filter.js";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./rentnft.scss";
import ExploreItem from "../../components/layouts/explore-04/ExploreItem";
import todayPickData from "../../assets/fake-data/data-today-pick";

const Rentnft = () => {
  const [showBtn, setShowBtn] = useState(false);

  return (
    <>
      <Header />
      <section className="rentnft">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 m-auto">
              <div className="filter-items">
                <div className="left-filter-icon">
                  <button className="filter-btn pl-0" onClick={() => setShowBtn(!showBtn)}>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      className="change-my-color"
                    >
                      <path
                        d="M4 9.33301H28"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M8 16H24"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M13.334 22.667H18.6673"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                    </svg>
                  </button>
                  <button
                    className="filter-left-btn-mbl d-none"
                    data-toggle="modal"
                    data-target="#staticBackdrop"
                  >
                    {" "}
                    Filters
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="change-my-color"
                    >
                      <path
                        d="M3 7H21"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 11.5H18"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M8 16.5H16"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="right-filter-drop">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondary dropdown-toggle "
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Low to High
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <a className="dropdown-item" href="#">
                        Price: Low to High
                      </a>
                      <a className="dropdown-item" href="#">
                        Price: High to Low
                      </a>
                      <a className="dropdown-item" href="#">
                        Auction ending soon
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bdr"></div>

              <div className="row">
                <div className={showBtn ? "col-xl-3 col-lg-3 col-12" : 'none_filters'}>
                  <Filter showBtn={showBtn} />
                </div>
                <div className={showBtn ? "col-xl-9 col-lg-9 col-12" : "col-xl-12 col-12"}>
                  <div className="exploreheadings">
                    <h4 className="mainex">Updated 3m ago</h4>
                    <h4 className="mainex">45,279,069 items</h4>
                  </div>
                  <button className="filter-delete">
                    Buy Now
                    <svg
                      width="11"
                      height="12"
                      viewBox="0 0 11 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="change-my-color"
                    >
                      <path
                        d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                        fill="white"
                      />
                      <path
                        d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                        fill="url(#paint0_linear_1297_38187)"
                      />
                      <path
                        d="M1 1.5249L9.68233 10.4752"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                        fill="white"
                      />
                      <path
                        d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                        fill="url(#paint1_linear_1297_38187)"
                      />
                      <path
                        d="M9.68233 1.5249L1 10.4752"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1297_38187"
                          x1="5.34117"
                          y1="1.5249"
                          x2="5.34117"
                          y2="10.4752"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#0090B4" />
                          <stop offset="1" stop-color="#103F91" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_1297_38187"
                          x1="5.34117"
                          y1="1.5249"
                          x2="5.34117"
                          y2="10.4752"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#0090B4" />
                          <stop offset="1" stop-color="#103F91" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </button>
                  <button className="filter-delete">
                    Art
                    <svg
                      width="11"
                      height="12"
                      viewBox="0 0 11 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="change-my-color"
                    >
                      <path
                        d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                        fill="white"
                      />
                      <path
                        d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                        fill="url(#paint0_linear_1297_38187)"
                      />
                      <path
                        d="M1 1.5249L9.68233 10.4752"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <path
                        d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                        fill="white"
                      />
                      <path
                        d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                        fill="url(#paint1_linear_1297_38187)"
                      />
                      <path
                        d="M9.68233 1.5249L1 10.4752"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1297_38187"
                          x1="5.34117"
                          y1="1.5249"
                          x2="5.34117"
                          y2="10.4752"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#0090B4" />
                          <stop offset="1" stop-color="#103F91" />
                        </linearGradient>
                        <linearGradient
                          id="paint1_linear_1297_38187"
                          x1="5.34117"
                          y1="1.5249"
                          x2="5.34117"
                          y2="10.4752"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#0090B4" />
                          <stop offset="1" stop-color="#103F91" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </button>
                  <ExploreItem showBtn={showBtn} data={todayPickData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Rentnft;
