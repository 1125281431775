// import { useCallback, useState, useEffect } from "react";
// import useWeb3 from "../useWeb3";
// import environment from "../../utils/Environment";
// import { getWethContract } from "../../utils/contractHelpers";
// import { useWeb3React } from "@web3-react/core";

// export const BalOf = () => {
// const web3 = useWeb3();
// const tokenAddress = environment.weth;
// const context = useWeb3React();
// const { chainId } = context;
// const [marketAddress, setMarketAddress] = useState(null);

// useEffect(() => {
//   if (chainId === 97) {
//     setMarketAddress(environment.Bsc.weth);
//   } else if (chainId === 4) {
//     setMarketAddress(environment.Eth.weth);
//   } else if (chainId === 43114) {
//     setMarketAddress(environment.Eth.weth);
//   } else if (chainId === 80001) {
//     setMarketAddress(environment.Polygon.weth);
//   }
// }, [chainId])

//   const BalanceOFF = useCallback(async (account, market) => {
//     // console.log("asasasas2", account, market)
//     // // console.log("asasasas", market, account)
//     const contract = getWethContract(market, web3);
//     const approved = await contract.methods.balanceOf(account).call();
//     // console.log("asasasas3", approved)
//     return approved;
//   }, []);

//   return { BalanceOFF: BalanceOFF };

// };

// export default BalOf;

import { useCallback, useState, useEffect } from "react";
import useWeb3 from "../useWeb3";
import { getWethContract } from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";
import { useWeb3React } from "@web3-react/core";

export const BalOf = () => {
    const web3 = useWeb3();
    const context = useWeb3React();
    const { chainId } = context;
    const [marketAddress, setMarketAddress] = useState(null);
    const [contractAddress, setContractAddress] = useState(null);
    //   useEffect(() => {
    //     if (chainId === 97) {
    //       setMarketAddress(Environment.Bsc.MarketContract);
    //       setContractAddress(Environment.Bsc.weth);
    //     } else if (chainId === 4) {
    //       setMarketAddress(Environment.Eth.MarketContract);
    //       setContractAddress(Environment.Eth.weth);
    //     } else if (chainId === 43114) {
    //       setMarketAddress(Environment.Eth.MarketContract);
    //       setContractAddress(Environment.Eth.weth);
    //     } else if (chainId === 80001) {
    //       setMarketAddress(Environment.Polygon.MarketContract);
    //       setContractAddress(Environment.Polygon.weth);
    //     }
    //   }, [chainId])

    useEffect(() => {
        setMarketAddress(Environment.marketPlaceContract);
        setContractAddress(Environment.weth);
    }, [])

    const BalanceOFF = useCallback(async (account) => {
        if (marketAddress) {
            try {
                const contract = getWethContract(contractAddress, web3);
                const approved = await contract.methods.balanceOf(account).call();
                return approved;
            } catch (e) {
                // console.log("AAAA", e)
            }
        }
    }, [marketAddress]);

    return { BalanceOFF: BalanceOFF };
};

export default BalOf;