import React from 'react';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import heroSliderData from '../assets/fake-data/data-slider';
import Slider from '../components/slider/Slider';
import liveAuctionData from '../assets/fake-data/data-live-auction';
import LiveAuction from '../components/layouts/LiveAuction';
import TopSeller from '../components/layouts/TopSeller';
import topSellerData from '../assets/fake-data/data-top-seller'
import TodayPicks from '../components/layouts/TodayPicks';
import todayPickData from '../assets/fake-data/data-today-pick';
import PopularCollection from '../components/layouts/PopularCollection';
import popularCollectionData from '../assets/fake-data/data-popular-collection';
import Create from '../components/layouts/Create';
import SliderStyle4 from '../components/slider/SliderStyle4';
import Nftswap from '../components/layouts/Nftswap';
import Competition from '../components/layouts/Competition';
import todayPickData2 from '../assets/fake-data/data-today-pick2';
import popularCollectionData2 from '../assets/fake-data/data-popular-collection2';
import RentNftSlider from '../components/layouts/RentNftSlider';
import NftSwapSlider from '../components/layouts/NftSwapSlider';
import TrendingNow from '../components/layouts/TrendingNow';
import Feature from './UniqueFeature/Feature';

const Home01 = () => {

    return (
        <div className='home-1'>
            <Header />
            <Slider />
            {/* <Create /> */}
            <Feature />
            <PopularCollection />
            {/* <Competition data={popularCollectionData2} /> */}
            {/* <NftSwapSlider data={todayPickData2} /> */}
            <TrendingNow />
            {/* <RentNftSlider data={todayPickData} /> */}
            {/* <TodayPicks data={todayPickData} /> */}
            {/* <Nftswap data={todayPickData2} /> */}
            {/* <SliderStyle4></SliderStyle4> */}
            <LiveAuction />
            <TopSeller />
            <Footer />
        </div>
    );
}

export default Home01;
