import React, { useEffect, useState } from "react";
import "./collectionsfirst.scss";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Api_URL } from "../../utils/ApiURL";
import axios from "axios";
import Loader from "../../hooks/loader"
import CollectionCard from "../CollectionCardNew/CollectionCard";


const Collectionsfirst = () => {

  const [allCollection, getAllCollections] = useState([]);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    callAPI()
  }, []);

  const callAPI = async () => {
    setLoader(true)
    try {

      const res = await axios.post(`${Api_URL}/collection/getTrendingCollections`,
        {
          chainId: '97'
        }
      );
      getAllCollections(res?.data?.result || []);
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  }

  const callAPITop = async () => {
    setLoader(true)
    try {

      const res = await axios.get(`${Api_URL}/collection/getTopCollection`,
        {
          chainId: '97'
        }
      );
      getAllCollections(res?.data?.data);
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  }

  const getCollectionByCat = async (CatName) => {
    setLoader(true)
    try {
      const res = await axios.post(`${Api_URL}/collection/getCollectionsByCategory`,
        {
          category: CatName
        }
      );
      getAllCollections(res?.data?.data)
      setLoader(false)

    } catch (error) {
      setLoader(false)

    }
  }

  return (
    <>
      <Header />
      {loader && <Loader />}
      <section className="main-Collectionsfirst">
        <div className="container1920">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 m-auto">
              <ul className="nav nav-tabs nav-justified" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#trending"
                    role="tab"
                    aria-controls="trending"
                    aria-selected="true"
                    onClick={() => callAPI()}
                  >
                    Trending
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#top"
                    role="tab"
                    aria-controls="top"
                    aria-selected="false"
                    onClick={() => callAPITop()}
                  >
                    Top
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#art"
                    role="tab"
                    aria-controls="art"
                    aria-selected="false"
                    onClick={() => getCollectionByCat(['Art'])}
                  >
                    Art
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link "
                    data-toggle="tab"
                    href="#Collectibles"
                    role="tab"
                    aria-controls="Collectibles"
                    aria-selected="false"
                    onClick={() => getCollectionByCat(['Collectibles'])}
                  >
                    Collectibles
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#domain"
                    role="tab"
                    aria-controls="domain"
                    aria-selected="true"
                    onClick={() => getCollectionByCat(['Domain Names'])}
                  >
                    Domain Names
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link "
                    data-toggle="tab"
                    href="#Music"
                    role="tab"
                    aria-controls="Music"
                    aria-selected="false"
                    onClick={() => getCollectionByCat(['Music'])}
                  >
                    Music
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#Photography"
                    role="tab"
                    aria-controls=" Photography"
                    aria-selected="false"
                    onClick={() => getCollectionByCat(['Photography'])}
                  >
                    Photography
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#Sports"
                    role="tab"
                    aria-controls="Sports"
                    aria-selected="false"
                    onClick={() => getCollectionByCat(['Sports'])}
                  >
                    Sports
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#Trading"
                    role="tab"
                    aria-controls="Trading"
                    aria-selected="false"
                    onClick={() => getCollectionByCat(['Trading Cards'])}
                  >
                    Trading Cards
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#Virtual"
                    role="tab"
                    aria-controls="Virtual"
                    aria-selected="false"
                    onClick={() => getCollectionByCat(['Virtual Worlds'])}
                  >
                    Virtual Worlds
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-3">
                <div
                  className="tab-pane active"
                  id="trending"
                  role="tabpanel"
                  aria-labelledby="trending-tab"
                >
                  <CollectionCard data={allCollection} />
                  {/* <ExploreCollections data={allCollection} /> */}
                </div>
                <div
                  className="tab-pane"
                  id="top"
                  role="tabpanel"
                  aria-labelledby="top-tab"
                >
                  {/* <ExploreItem data={todayPickData} /> */}
                  {/* <ExploreCollections data={allCollection} /> */}
                  <CollectionCard data={allCollection} />
                </div>
                <div
                  className="tab-pane"
                  id="art"
                  role="tabpanel"
                  aria-labelledby="art-tab"
                >
                  {/* <ExploreCollections data={allCollection} /> */}
                  <CollectionCard data={allCollection}  />
                </div>
                <div
                  className="tab-pane"
                  id="Collectibles"
                  role="tabpanel"
                  aria-labelledby="   Collectibles-tab"
                >
                  {/* <ExploreCollections data={allCollection} /> */}
                  <CollectionCard data={allCollection}  />
                </div>
                <div
                  className="tab-pane"
                  id="domain"
                  role="tabpanel"
                  aria-labelledby="domain-tab"
                >
                  {/* <ExploreCollections data={allCollection} /> */}
                  <CollectionCard data={allCollection}  />
                </div>
                <div
                  className="tab-pane"
                  id="Music"
                  role="tabpanel"
                  aria-labelledby="Music-tab"
                >
                  {/* <ExploreCollections data={allCollection} /> */}
                  <CollectionCard data={allCollection}  />
                </div>
                <div
                  className="tab-pane"
                  id="Photography"
                  role="tabpanel"
                  aria-labelledby=" Photography-tab"
                >
                  {/* <ExploreCollections data={allCollection} /> */}
                  <CollectionCard data={allCollection}  />
                </div>
                <div
                  className="tab-pane followerstaabb"
                  id="Sports"
                  role="tabpanel"
                  aria-labelledby="Sports-tab"
                >
                  {/* <ExploreCollections data={allCollection} /> */}
                  <CollectionCard data={allCollection}  />
                </div>
                <div
                  className="tab-pane"
                  id="Trading"
                  role="tabpanel"
                  aria-labelledby="Trading-tab"
                >
                  {/* <ExploreCollections data={allCollection} /> */}
                  <CollectionCard data={allCollection}  />
                </div>
                <div
                  className="tab-pane"
                  id="Virtual"
                  role="tabpanel"
                  aria-labelledby="Virtual-tab"
                >
                  {/* <ExploreCollections data={allCollection} /> */}
                  <CollectionCard data={allCollection}  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Collectionsfirst;
