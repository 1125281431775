import React from "react";
import "./compettion.scss";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Comp from "./component";

const Compettion = () => {
  return (
    <>
      <Header />
      <section className="main-Compettion">
        <div className="bg-img-author">
          <img src="\comptn.png" alt="img" className="img-fluid" />
        </div>
        <div className="container-fluid  ">
          <div className="row">
            <div className="col-sm-12 m-auto ">
              <div className="main_prodiv ">
                <div className="">
                  <img src="\comptnncocla.png" alt="img" className='img-fluid profileavtt' />
                </div>
                <div className="icondiv">
                  <ul className="list-inline">
                    <li className="list-inline-item iconbtnnnn"><a href=""><img src="\fst.svg" alt="img" className='' /></a></li>
                    <li className="list-inline-item iconbtnnnn"><a href=""><img src="\sseenndd.svg" alt="img" className='' /></a></li>
                    <li className="list-inline-item iconbtnnnn"><a href=""><img src="\thirdds.svg" alt="img" className='' /></a></li>
                    <li className="list-inline-item iconbtnnnn"><a href=""><img src="\forrh.svg" alt="img" className='' /></a></li>
                    <li className="list-inline-item iconbtnnnn"><a href=""><img src="\fifth.svg" alt="img" className='' /></a></li>
                    <li className="list-inline-item iconbtnnnn"><a href=""><img src="\sixx.svg" alt="img" className='' /></a></li>
                  </ul>

                </div>
              </div>
              <div className="avatar__row">
                <h3 className="jekkee">Coca-Cola</h3>
                <button className='editprofilebtnmm'>Submit</button>
                <p className='ttteexxxtt'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do <br></br>eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut <br></br>enim ad minim veniam, quis nostrud exercitation ullamco laboris<br></br> nisi ut.
                </p>
              </div>
            </div>
          </div>
          <Comp />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Compettion;
