import './App.css';
import { Routes, Route } from 'react-router-dom';
import routes from './pages/index'
import {Translator, Translate} from 'react-auto-translate';
import useEagerConnect from './hooks/useEagerConnect';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {

    // const {login } = useEagerConnect
    // useEagerConnect();
    return (
        <>
        <ToastContainer style={{fontSize: '14px', zIndex: "9999999999999999"}} theme="dark" position="top-right" />
        <Routes >
            {
                routes.map((data, index) => (
                    <Route onUpdate={() => window.scrollTo(0, 0)} exact={true} path={data.path} element={data.component} key={index} />
                ))
            }
        </Routes>
        </>
    );
}

export default App;
