import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {BrowserRouter} from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import { Web3ReactProvider } from "@web3-react/core";
import { getLibrary } from "./utils/web3React";
import { store } from "./redux-toolkit/store";
import { Provider } from "react-redux";

ReactDOM.render(
  <BrowserRouter >
  <Provider store={store}>
    <ScrollToTop />
    <Web3ReactProvider getLibrary={getLibrary}>
    <App />
    </Web3ReactProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
