import React from "react";
import "./cofilter.scss";
import Accordion from "react-bootstrap/Accordion";

const Cofilter = ({
    GetContract,
    contractId,
    onsale,
    onauction,
    setOnsale,
    setOnauction,
    setSprice,
    setLprice,
    sprice,
    lprice,
    GetFilterContract,
    search,
    setSearch,
    activities
}) => {

    const StartPrice = (val) => {
        let amount = parseFloat(val);
        console.log('gooooooooooooooooo', amount, sprice, lprice, amount > 0 && sprice < lprice)
        if (amount > 0 && sprice < lprice) {
            setSprice(parseFloat(amount))
        }
    }

    const EndPrice = (val) => {
        let amount = parseFloat(val);
        if (amount > sprice) {
            setLprice(parseFloat(amount));
        }
    }

    return (
        <>
            <div className="container1920">
                <div className="row">
                    <div className="col-12 p-0">
                        <section id="Cofilter" className="filter pb-0 border-bottom-0">
                            {/* <header className="header">
                                <h3>Filters</h3>
                                <a onClick={()=>GetContract(contractId)}>Reset</a>
                            </header> */}
                            <div className="main">
                                <Accordion defaultActiveKey={["0"]} alwaysOpen>
                                    <Accordion.Item eventKey="0" className="border-bottom-0">
                                        <Accordion.Header>Status</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="option-field px-4">
                                                <div className="form-check inplabel d-flex align-items-center justify-content-between w-100 border-none">
                                                    <p>Buy Now</p>
                                                    <label className="checkbox-button">
                                                        <input
                                                            className="checkbox-button__input"
                                                            type="checkbox"
                                                            value=""
                                                            checked={onsale}
                                                            onChange={() => setOnsale(!onsale)}
                                                            id="defaultCheck1"
                                                        />
                                                        <span className="checkbox-button__control"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="option-field px-4">
                                                <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                    <p>On Auction</p>
                                                    <label className="checkbox-button">
                                                        <input
                                                            className="checkbox-button__input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck2"
                                                            checked={onauction}
                                                            onChange={() => setOnauction(!onauction)}
                                                        />
                                                        <span className="checkbox-button__control"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            {/* <div className="option-field px-4">
                                                <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                    <p>For Rent</p>
                                                    <label className="checkbox-button">
                                                        <input
                                                            className="checkbox-button__input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                        />
                                                        <span className="checkbox-button__control"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="option-field px-4">
                                                <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                    <p>Swap</p>
                                                    <label className="checkbox-button">
                                                        <input
                                                            className="checkbox-button__input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                        />
                                                        <span className="checkbox-button__control"></span>
                                                    </label>
                                                </div>
                                            </div> */}
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    {/* <Accordion.Item eventKey="1">
                                        <Accordion.Header>Price</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="price">
                                                <div className="set-arrow">
                                                    <img
                                                        src="\assets\arrow-down.png"
                                                        alt="img"
                                                        className="img-fluid arrow-down-set"
                                                    />
                                                    <select disabled className="custom-select">
                                                        <option value="1">USD</option>
                                                    </select>
                                                </div>
                                                <input
                                                    onChange={(e) => StartPrice(e.target.value)}
                                                    value={sprice}
                                                    type="number"
                                                    placeholder=""
                                                />
                                                <p>to</p>
                                                <input
                                                    onChange={(e) => EndPrice(e.target.value)}
                                                    value={lprice}
                                                    type="number"
                                                    placeholder=""
                                                />
                                            </div>
                                            <div className="apply-btn">
                                                {" "}
                                                <button
                                                    onClick={() => GetFilterContract()}
                                                    className="apply-btn-btn"
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item> */}

                                    {/* <Accordion.Item eventKey="3">
                                        <Accordion.Header>Collections</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="search">
                                                <i className="fa fa-search"></i>
                                                <input type="text" className="form-control" placeholder="Filter" />

                                            </div>

                                            <div className="option-field">
                                                <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                    <p>Bored Ape Kenne...</p>
                                                    <label className="checkbox-button">
                                                        <input
                                                            className="checkbox-button__input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                        />
                                                        <span className="checkbox-button__control"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item> */}
                                    {/* <Accordion.Item eventKey="4">
                                        <Accordion.Header>Chains</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="option-field">
                                                <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                    <p className="align-item-center"><img src="\assets\ethereum-eth 1.svg" alt="img" className="img-fluid" />  Ethereum</p>
                                                    <label className="checkbox-button">
                                                        <input
                                                            className="checkbox-button__input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                        />
                                                        <span className="checkbox-button__control"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="option-field">
                                                <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                    <p><img src="\assets\OneArt-_x2022_-Desktop-_x2022_-Light.svg" alt="img" className="img-fluid" /> BSC</p>
                                                    <label className="checkbox-button">
                                                        <input
                                                            className="checkbox-button__input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                        />
                                                        <span className="checkbox-button__control"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="option-field">
                                                <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                    <p> <img src="\assets\polygon-matic-seeklogo.com 1.svg" alt="img" className="img-fluid" />  Polygon</p>
                                                    <label className="checkbox-button">
                                                        <input
                                                            className="checkbox-button__input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                        />
                                                        <span className="checkbox-button__control"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className="option-field">
                                                <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                    <p className="text-align-center"> <img src="\assets\solana.svg" alt="img" className="img-fluid" /> Solana</p>
                                                    <label className="checkbox-button">
                                                        <input
                                                            className="checkbox-button__input"
                                                            type="checkbox"
                                                            value=""
                                                            id="defaultCheck1"
                                                        />
                                                        <span className="checkbox-button__control"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item> */}


                                </Accordion>
                            </div>
                        </section>

                        <section className="filter-mbl ">
                            <div
                                className="modal fade"
                                id="staticBackdrop"
                                data-backdrop="static"
                                data-keyboard="false"
                                tabindex="-1"
                                aria-labelledby="staticBackdropLabel"
                                aria-hidden="true"
                            >
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-body">
                                            <header className="header">
                                                <h3>Filters</h3>
                                                <button
                                                    type="button"
                                                    className="close"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                >
                                                    <span aria-hidden="true">
                                                        <img
                                                            src="\bluemoon-nft\close.png"
                                                            alt="img"
                                                            className="img-fluid"
                                                        />
                                                    </span>
                                                </button>
                                            </header>
                                            <div className="main">
                                                <Accordion defaultActiveKey={["0"]} alwaysOpen>
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header>Status</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="option-field">
                                                                <p>Buy Now</p>
                                                                {/* <Form.Check

                                                                /> */}
                                                                <div className="content">
                                                                    <label className="checkBox">
                                                                        <input id="ch2" type="checkbox" />
                                                                        <div className="transition"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="option-field">
                                                                <p>On Auction</p>
                                                                {/* <Form.Check

                                                                /> */}
                                                                <div className="content">
                                                                    <label className="checkBox">
                                                                        <input id="ch2" type="checkbox" />
                                                                        <div className="transition"></div>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>Price</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="price">
                                                                <div className="set-arrow">

                                                                    <select disabled className="custom-select">

                                                                        <option value="1">
                                                                            BNB
                                                                        </option>

                                                                    </select>
                                                                </div>
                                                                <input
                                                                    type="number"
                                                                    min={0}

                                                                    placeholder=""
                                                                />
                                                                <p>to</p>
                                                                <input
                                                                    type="number"
                                                                    min={0}

                                                                    placeholder=""
                                                                />
                                                            </div>
                                                            <div className="apply-btn">
                                                                {/* <Link to="/timeauction"> */}{" "}
                                                                <button

                                                                    className="apply-btn-btn"
                                                                >
                                                                    Apply
                                                                </button>
                                                                {/* </Link> */}
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>

                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header>Collection</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="option-field">
                                                                <p>Bored Ape Kenne...</p>
                                                                {/* <Form.Check /> */}
                                                                <div className="content">
                                                                    <label className="checkBox">
                                                                        <input id="ch2" type="checkbox" />
                                                                        <div className="transition"></div>
                                                                    </label>
                                                                </div>
                                                            </div>

                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="4">
                                                        <Accordion.Header>Chains</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="option-field">
                                                                <p className="align-item-center"><img src="\assets\ethereum-eth 1.svg" alt="img" className="img-fluid" />  Ethereum</p>
                                                                {/* <Form.Check /> */}
                                                                <div className="content">
                                                                    <label className="checkBox">
                                                                        <input id="ch2" type="checkbox" />
                                                                        <div className="transition"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="option-field">
                                                                <p><img src="\assets\OneArt-_x2022_-Desktop-_x2022_-Light.svg" alt="img" className="img-fluid" /> BSC</p>
                                                                {/* <Form.Check /> */}
                                                                <div className="content">
                                                                    <label className="checkBox">
                                                                        <input id="ch2" type="checkbox" />
                                                                        <div className="transition"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="option-field">
                                                                <p> <img src="\assets\polygon-matic-seeklogo.com 1.svg" alt="img" className="img-fluid" />  Polygon</p>
                                                                {/* <Form.Check /> */}
                                                                <div className="content">
                                                                    <label className="checkBox">
                                                                        <input id="ch2" type="checkbox" />
                                                                        <div className="transition"></div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="option-field">
                                                                <p className="text-align-center"> <img src="\assets\solana.svg" alt="img" className="img-fluid" /> Solana</p>
                                                                {/* <Form.Check /> */}
                                                                <div className="content">
                                                                    <label className="checkBox">
                                                                        <input id="ch2" type="checkbox" />
                                                                        <div className="transition"></div>
                                                                    </label>
                                                                </div>
                                                            </div>



                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Cofilter;
