import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  profileData: {}
};

export const counterSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    profile: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.profileData = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { profile } = counterSlice.actions;

export default counterSlice.reducer;
