import React from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./createitem.scss";
import { Link, useNavigate } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";


const Createitem = () => {
  const { account } = useWeb3React();
  let navigate = useNavigate();
  return (
    <>
      <Header />
      <section className="createitem">
        <div className="container-fluid p-0">
          <div className="row">
            <div className="col-sm-6 mx-auto">
              <h1 className="headingone">Create Item</h1>
              <p className="headingtwo">Choose “Single” if you want your collectible to be one of a kind or “Multiple” if you want to <br /> sell one collectible multiple times</p>
            </div>
          </div>
          <div className="row cards">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="cardone ml-auto">
                <img className="card-img-top imgone" src="\asssests\cardone.svg" alt="Card image cap" />
                <div onClick={() => account ? navigate('/createnft') : toast.info('Connect Your Wallet')}>
                  <div className="card-body">
                    <a className="btn-1" href="#">Create Single</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <div className="cardtwo mr-auto">
                <img className="card-img-top imgone" src="\asssests\cardtwo.svg" alt="Card image cap" />
                <div onClick={() => account ? navigate('/createcollection') : toast.info('Connect Your Wallet')} >
                  <div className="card-body">
                    <a className="btn-2" href="#">Create Collection</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Createitem;