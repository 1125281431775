import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import "./swapnft.scss"
import { useWeb3React } from "@web3-react/core";
import { Api_URL } from '../../utils/ApiURL'
import { toast } from 'react-toastify'
import axios from 'axios'
import { useNavigate } from "react-router-dom";
import useWeb3 from "../../hooks/useWeb3";
import { soliditySha3 } from "web3-utils";
import Environment from '../../utils/Environment'
import { getErc20TokenContract } from '../../utils/contractHelpers'
import Loader from '../../hooks/loader'

const Swapnft = () => {
    const { account } = useWeb3React();
    const [loader, setLoader] = useState(false);
    const [token, setToken] = useState(null);
    const [onsale, setOnsale] = useState([]);
    let navigate = useNavigate();
    const [ids, setIds] = useState()
    const [ownedCollection, setOwnedCollection] = useState([]);
    const [myNftArr, setMyNftArr] = useState([])
    const [othersNftArr, setOthersNftArr] = useState([])
    const [allNfts, setAllNfts] = useState([])
    const [minted, setMinted] = useState(true)
    const [nftId, setNftId] = useState('')
    const [searched, setSearched] = useState({ value: '', array: [] });
    const [searched2, setSearched2] = useState({ value: '', array: [] });
    console.log('searchation', searched2)
    const [meta, setMeta] = useState({
        price: "0",
        startTime: new Date().getTime(),
        endTime: null,
        method: "fixed"
    })
    const context = useWeb3React();
    const web3 = useWeb3()
    const { chainId } = context;
    // console.log('this is sthe ', myNftArr, othersNftArr)
    const getOwnedNft = async () => {
        setOnsale([])
        var axios = require("axios");
        setLoader(true);
        var data = JSON.stringify({
            walletAddress: account,
            chainId: chainId?.toString()
        });
        var config = {
            method: "post",
            url: `${Api_URL}/token/getOwnedTokensOfUserAndDetails`,
            headers: {
                Authorization: "Bearer " + token,
                "Content-Type": "application/json"
            },
            data: data
        };
        axios(config)
            .then(function (response) {
                setOwnedCollection(response?.data?.tokens);
                let dumArr = response?.data?.data?.filter((i) => {
                    return i?.metaData && i?.onSale;
                })
                setOnsale(response?.data?.data);
                setLoader(false);
            })
            .catch(function (error) {
                setLoader(false);
                // console.log("getApi error", error);
            });
    };

    const getAllNft = () => {
        setAllNfts([])
        try {
            setLoader(true);
            var data = JSON.stringify({
                walletAddress: ids,
                chainId: chainId?.toString()
            });
            var config = {
                method: "post",
                url: `${Api_URL}/token/getOwnedTokensOfUserAndDetails`,
                headers: {
                    Authorization: "Bearer " + token,
                    "Content-Type": "application/json"
                },
                data: data
            };

            axios(config).then(function (res) {
                if (res) {
                    console.log('ttttttt', nftId)
                    let filArray = res?.data?.data?.filter((item) => item?.ownerAddress?.toLowerCase() !== account?.toLowerCase())
                    setAllNfts(filArray)
                    const foundItem = filArray.find(obj => obj._id === nftId);
                    setOthersNftArr((pre) => [...pre, foundItem])
                    setMinted(foundItem?.isMinted)
                    console.log('tttttttttt', foundItem)
                }
                setLoader(false);
            }).catch((err) => {
                setLoader(false);
            })
        } catch (error) {
            setLoader(false);

        }
    }


    const myNftAddFunc = async (e) => {
        let dumArr = myNftArr;
        let data = dumArr.filter((i) => {
            return (
                i._id === e._id
            )

        })
        if (data?.length === 0) {
            setMyNftArr((pre) => [...pre, e])
        } else {
            let data2 = dumArr.filter((i) => {
                return (
                    i._id !== e._id
                )
            })
            setMyNftArr(data2)
            // toast.info("Nft already selected")
        }
    }
    const myNftAddFuncAll = async (e) => {
        let dumArr = othersNftArr;
        let data = dumArr.filter((i) => {
            return (
                i._id === e._id
            )

        })
        if (data?.length === 0) {
            setOthersNftArr((pre) => [...pre, e])
        } else {
            let data2 = dumArr.filter((i) => {
                return (
                    i._id !== e._id
                )
            })
            setOthersNftArr(data2)
        }
    }
    const myNftRemoveFunc = async (e) => {
        let dumArr = myNftArr;
        let data = dumArr.filter((i) => {
            return (
                i._id !== e._id
            )
        })
        if (data) {
            setMyNftArr(data)
        }

        if (data?.length === 0) {
            getOwnedNft();
            window.$('#exampleModalClose').modal('hide')
        }
    }
    const myNftRemoveFuncAll = async (e) => {
        let dumArr = othersNftArr;
        let data = dumArr.filter((i) => {
            return (
                i._id !== e._id
            )
        })
        if (data) {
            setOthersNftArr(data)
        }

        if (data?.length === 0) {
            // getAllNft();
            window.$('#exampleModalCloseAll').modal('hide')
        }
    }
    // console.log('all data console', othersNftArr?.map((item) => item?.tokenID))
    // const makeAnOffer = async () => {}
    // const makeAnOffer = async () => {
    //     let signature = false;
    //     let appStatus = false;
    //     let tok = localStorage.getItem("accessToken");
    //     setLoader(true)
    //     for (let i = 0; i < myNftArr?.length; i++) {

    //         const contract = getErc20TokenContract(myNftArr[i]?.contractAddress, web3);
    //         if (myNftArr[i]?.isMinted) {
    //             // tokenIds.push(myNftArr[i].tokenID)
    //             let res = await contract.methods.isApprovedForAll(account, Environment?.swap).call()
    //             // console.log('all data console', res)
    //             if (!res) {
    //                 try {
    //                     let res2 = await contract.methods.setApprovalForAll(Environment?.swap, true).send({ from: account })
    //                     appStatus = false
    //                 } catch (error) {
    //                     appStatus = true
    //                 }
    //             }
    //         }
    //     }
    //     var tokenIds = [];
    //     var ReqtokenIds = []
    //     for (let item of myNftArr) {
    //         if (item.isMinted) {
    //             tokenIds.push(item.tokenID)
    //         }
    //     }
    //     for (let item of othersNftArr) {
    //         if (item.isMinted) {
    //             ReqtokenIds.push(item.tokenID)
    //         }
    //     }
    //     // console.log('firstSign', tokenIds?.length === 0 ? JSON.stringify(['1']) : tokenIds, ReqtokenIds?.length === 0 ? ['1'] : ReqtokenIds)
    //     if (appStatus === false) {
    //         let soliditySha3Expected = null;
    //         let dumArr = null;
    //         let dumArr2 = null;
    //         if (tokenIds?.length === 0) {
    //             dumArr = ['']
    //         } else {
    //             dumArr = tokenIds;
    //         }
    //         if (ReqtokenIds?.length === 0) {
    //             dumArr2 = ['']
    //         } else {
    //             dumArr2 = tokenIds;
    //         }
    //         // console.log("send offer")
    //         // console.log("contract", Environment?.swap)
    //         // console.log("seller", '0x009c4E059C8c79B9228337b622D0b2Db6159f7bB')
    //         // console.log("account", account?.toLowerCase())
    //         // console.log("start time", meta.startTime)
    //         if (true) {
    //             soliditySha3Expected = soliditySha3(
    //                 Environment?.swap,
    //                 othersNftArr[0]?.ownerAddress?.toLowerCase(),// othersNftArr[0]?.ownerAddress,
    //                 account?.toLowerCase(),
    //                 meta.startTime,
    //             );
    //         }
    //         // else if (chainId === 5) {
    //         //     soliditySha3Expected = soliditySha3(
    //         //         Environment?.swap,
    //         //         account?.toLowerCase(), 
    //         //         othersNftArr[0]?.ownerAddress,
    //         //         meta.startTime,
    //         //         account?.toLowerCase(), //ref address in future will change
    //         //         0,
    //         //         0
    //         //     );
    //         // } else if (chainId === 80001) {
    //         //     soliditySha3Expected = soliditySha3(
    //         //         Environment?.swap,
    //         //         account?.toLowerCase(),
    //         //         othersNftArr[0]?.ownerAddress,
    //         //         meta.startTime,
    //         //         account?.toLowerCase(), //ref address in future will change
    //         //         0,
    //         //         0
    //         //     );
    //         // } else if (chainId === 43114) {
    //         //     soliditySha3Expected = soliditySha3(
    //         //         Environment?.swap,
    //         //         account?.toLowerCase(),
    //         //         othersNftArr[0]?.ownerAddress,
    //         //         meta.startTime,
    //         //         account?.toLowerCase(), //ref address in future will change
    //         //         0,
    //         //         0
    //         //     );
    //         // }
    //         await web3.eth.personal.sign(soliditySha3Expected, account).then((res) => {
    //             signature = res;
    //             if (signature) {
    //                 // console.log('signature', signature)
    //                 setLoader(true);
    //                 try {
    //                     var data = JSON.stringify({
    //                         'bidderAddress': account,
    //                         'sellerAddress': othersNftArr[0]?.ownerAddress,
    //                         'contractAddress': myNftArr?.map((item) => item?.contractAddress),
    //                         'time': meta.startTime,
    //                         'requestedContractAddress': othersNftArr?.map((item) => item?.contractAddress),
    //                         'offerTokenID': myNftArr?.map((item) => item?.tokenID),
    //                         'requestedTokenID': othersNftArr?.map((item) => item?.tokenID),
    //                         'offerAmount': myNftArr?.map((item) => 0),
    //                         'requestedAmounts': othersNftArr?.map((item) => 0),
    //                         'chainId': 97,
    //                         'signature': signature
    //                     });

    //                     var config = {
    //                         method: 'post',
    //                         url: `${Api_URL}/swapOffer/addSwapOffer`,
    //                         headers: {
    //                             'Authorization': `Bearer ${tok}`,
    //                             'Content-Type': 'application/json'
    //                         },
    //                         data: data
    //                     };

    //                     axios(config)
    //                         .then(function (response) {
    //                             // GetNft();
    //                             toast.success("Swap offer created")
    //                             navigate('/myprofile')
    //                             // if (meta?.method === "fixed") {
    //                             //   toast.success("Added on Sale")
    //                             // } else {
    //                             //   toast.success("Added on Auction")
    //                             // }
    //                             setLoader(false)
    //                         })
    //                         .catch(function (error) {
    //                             setLoader(false);
    //                             toast.error("Something went wrong")
    //                             // console.log(error);
    //                         });
    //                 } catch (err) {
    //                     toast.error(err.message);
    //                     setLoader(false);
    //                 }
    //             } else {
    //                 setLoader(false);
    //             }
    //         }).catch((err) => {
    //             // console.log("ddddd", err)
    //             setLoader(false);
    //             throw err;
    //         });
    //     }
    //     setLoader(false)
    //     if (chainId) {
    //         let data = {
    //             bidderAddress: account,
    //             sellerAddress: othersNftArr[0]?.ownerAddress,
    //             contractAddress: myNftArr[0]?.ownerAddress,
    //             requestedContractAddress: othersNftArr[0]?.contractAddress,
    //             offerTokenID: myNftArr[0]?.tokenID,
    //             requestedTokenID: othersNftArr[0]?.tokenID,
    //             offerAmount: 0,
    //             requestedAmounts: 0,
    //             chainId: 97,
    //             signature: "0x6513ddc0e65a8b"
    //         }
    //     }
    // }
    const makeAnOffer = async () => {
        let signature = false;
        let appStatus = false;
        let tok = localStorage.getItem("accessToken");
        setLoader(true);

        try {
            for (let i = 0; i < myNftArr?.length; i++) {
                const contract = getErc20TokenContract(myNftArr[i]?.contractAddress, web3);

                if (myNftArr[i]?.isMinted) {
                    let res = await contract.methods.isApprovedForAll(account, Environment?.swap).call();

                    if (!res) {
                        try {
                            var gasFunPrice;
                            web3.eth.getGasPrice().then((result) => {
                                var result2 = parseInt(result) + 5000000000
                                // // console.log("gasfun", typeof result2, result2)
                                gasFunPrice = result2.toString()
                            })
                            let gas = await contract.methods.setApprovalForAll(Environment?.swap, true).estimateGas({
                                from: account
                            });
                            await contract.methods.setApprovalForAll(Environment?.swap, true).send({
                                gas: gas,
                                gasPrice: gasFunPrice, from: account
                            });
                            appStatus = false;
                        } catch (error) {
                            appStatus = true;
                            toast.error("Failed to set approval for all. Please try again.");
                        }
                    }
                }
            }

            let tokenIds = myNftArr.filter(item => item.isMinted).map(item => item.tokenID);
            let ReqtokenIds = othersNftArr.filter(item => item.isMinted).map(item => item.tokenID);

            if (appStatus === false) {
                let soliditySha3Expected = null;

                if (true) {
                    soliditySha3Expected = soliditySha3(
                        Environment?.swap,
                        othersNftArr[0]?.ownerAddress?.toLowerCase(),
                        account?.toLowerCase(),
                        meta.startTime
                    );
                }

                await web3.eth.personal.sign(soliditySha3Expected, account).then((res) => {
                    signature = res;

                    if (signature) {
                        setLoader(true);

                        try {
                            var data = JSON.stringify({
                                'bidderAddress': account,
                                'sellerAddress': othersNftArr[0]?.ownerAddress,
                                'contractAddress': myNftArr?.map((item) => item?.contractAddress),
                                'time': meta.startTime,
                                'requestedContractAddress': othersNftArr?.map((item) => item?.contractAddress),
                                'offerTokenID': myNftArr?.map((item) => item?.tokenID),
                                'requestedTokenID': othersNftArr?.map((item) => item?.tokenID),
                                'offerAmount': myNftArr?.map((item) => 0),
                                'requestedAmounts': othersNftArr?.map((item) => 0),
                                'chainId': 97,
                                'signature': signature
                            });

                            var config = {
                                method: 'post',
                                url: `${Api_URL}/swapOffer/addSwapOffer`,
                                headers: {
                                    'Authorization': `Bearer ${tok}`,
                                    'Content-Type': 'application/json'
                                },
                                data: data
                            };

                            axios(config)
                                .then(function (response) {
                                    toast.success("Swap offer created");
                                    navigate(-1);
                                    setLoader(false);
                                })
                                .catch(function (error) {
                                    toast.error("Failed to create swap offer. Please try again.");
                                    setLoader(false);
                                });
                        } catch (err) {
                            toast.error(err.message);
                            setLoader(false);
                        }
                    } else {
                        toast.error("Signature is not valid.");
                        setLoader(false);
                    }
                }).catch((err) => {
                    toast.error("Error occurred while signing. Please try again.");
                    setLoader(false);
                    throw err;
                });
            }
        } catch (error) {
            toast.error("An unexpected error occurred. Please try again.");
            setLoader(false);
            console.error(error);
        } finally {
            setLoader(false);
        }
    };

    useEffect(() => {
        if (account && chainId && token) {
            getOwnedNft();
            getAllNft()
        }
    }, [account, chainId, token]);
    useEffect(() => {
        var val = window.location.href;
        val = new URL(val);
        var id = val.searchParams.get("id");
        setIds(id.split('|')[0])
        setNftId(id.split('|')[1])
        console.log('eeeeeeee', id)
    }, [])
    useEffect(() => {
        if (account) {
            setToken(localStorage.getItem("accessToken"));
        }
    }, [account]);
    useEffect(() => {
        if (othersNftArr?.length === 0 && myNftArr?.length === 0) {
            setMinted(true)
        }
    }, [othersNftArr, myNftArr]);
    function searchNftFunc(e) {
        const inputValue = e.target.value.toLowerCase();
        console.log('first search', onsale);

        let filteredArr = onsale?.filter((item) => item?.nftName?.toLowerCase().includes(inputValue));
        setSearched({ value: inputValue, array: filteredArr })
        console.log('first search filteredArr', inputValue, filteredArr);
    }
    function searchNftFunc2(e) {
        const inputValue = e.target.value.toLowerCase();
        console.log('first search2', allNfts);

        let filteredArr = allNfts?.filter((item) => item?.nftName?.toLowerCase().includes(inputValue));
        setSearched2({ value: inputValue, array: filteredArr })
        console.log('first search2 filteredArr', inputValue, filteredArr);
    }
    return (
        <>
            {loader && <Loader />}
            <Header />
            <section className='main-swapnft'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='main-heading'>
                                <h1 className=''>Creating NFT Swap</h1>
                                <p className=''>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor<br></br> incididunt ut labore</p>
                            </div>
                        </div>
                    </div>
                    <div className="text-center text-primary  mb-5">
                        {(myNftArr?.length > 0 || othersNftArr?.length > 0) && minted && <h3>ON CHAIN  <hr className='w-25' /></h3>}
                        {(myNftArr?.length > 0 || othersNftArr?.length > 0) && !minted && <h3>OFF CHAIN  <hr className='w-25' /></h3>}
                    </div>
                    {(myNftArr?.length > 0 || othersNftArr?.length > 0) ||
                        <div className='row'>
                            <div className='col-lg-12 d-flex justify-content-center '>
                                <ul className="nav nav-pills mb-5 " id="pills-tab" role="tablist">
                                    <li className="nav-item">
                                        <a onClick={() => setMinted(true)} className="nav-link py-3 px-4 active font-weight-bold fs-16" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">On Chain</a>
                                    </li>
                                    <li className="nav-item">
                                        <a onClick={() => setMinted(false)} className="nav-link py-3 fs-16 px-4 font-weight-bold " id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Off Chain</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    }
                    <div className='row'>
                        <div className='col-lg-5 col-md-5 col-12 d-flex justify-content-md-end'>
                            {myNftArr?.length > 0 ?
                                <div className='main-data'>
                                    <div className='main-imgcard'>
                                        <div className='main-imagescard1'>
                                            <div className="row">
                                                {(myNftArr?.length > 3 ? myNftArr?.slice(0, 3) : myNftArr)?.map((item) => {
                                                    return (
                                                        <div className={(myNftArr?.length === 1) ? `col-12` : `col-6`}>
                                                            <img src={item?.image} className='innerImg' alt="" />
                                                        </div>
                                                    )
                                                })}
                                                {myNftArr?.length > 3 ?
                                                    <div className="col-6 viewAll">
                                                        <h2>+ {myNftArr?.length - 3}</h2>
                                                        <p data-toggle="modal" data-target="#exampleModalClose">View All</p>
                                                    </div>
                                                    :

                                                    <div className="col-6 viewAll d-flex align-items-center justify-content-between">
                                                        <img data-toggle="modal" data-target="#exampleModalAll" src='\asssests\Group.svg' className='img-fluid cPointer' />

                                                        <p onClick={() => window.$('#exampleModalClose').modal('show')}>View</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {/* <div className='main-imagescard1'>
                                     <img data-toggle="modal" data-target="#exampleModal" src='\asssests\Group.svg' className='img-fluid' />
                                     <h5 className='main-paragraph'>Add a NFT you want <br />in exchange</h5>
                                 </div> */}
                                    </div>
                                </div>
                                :
                                <div className='main-data'>
                                    <div className='main-imgcard'>
                                        <div className='main-imagescard1'>
                                            <img data-toggle="modal" data-target="#exampleModal" src='\asssests\Group.svg' className='img-fluid cPointer' />
                                            <h5 className='main-paragraph'>Add a NFT you want <br />to exchange</h5>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* <div className='main-imgcard'>
                                <div className='main-imagescard'>
                                    <img src='\asssests\Frame4.svg' className='img-fluid' />
                                    <div className='left-content'>
                                        <h5 className='left-heading'>"Hamlet Contemplates ...</h5>
                                        <button className='left-btn'>BSC</button>
                                    </div>
                                    <div className='main-leftcontent'>
                                        <div className='leftcontent-img'>
                                            <div className='img11'>
                                                <img src='\asssests\Rectangle742.svg' className='img-fluid' />
                                            </div>
                                            <div className='leftcontent-heading'>
                                                <p className=''>Creator</p>
                                                <h5 className=''>SalvadorDali</h5>
                                            </div>
                                        </div>
                                        <div className='right-content'>
                                            <p className=''>Current</p>
                                            <h5 className=''>4.89 eTH</h5>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className='col-lg-2 col-md-2 col-12 text-center m-auto'>
                            <div className='swapimg'>
                                <img src='\asssests\swap.svg' className='img-fluid' />
                            </div>

                        </div>
                        <div className='col-lg-5 col-md-5 col-12 '>
                            {othersNftArr?.length > 0 ?
                                <div className='main-data'>
                                    <div className='main-imgcard'>
                                        <div className='main-imagescard1'>
                                            <div className="row">
                                                {(othersNftArr?.length > 3 ? othersNftArr?.slice(0, 3) : othersNftArr)?.map((item) => {
                                                    return (
                                                        <div className={(othersNftArr?.length === 1) ? `col-12` : `col-6`}>
                                                            <img src={item?.image} className='innerImg' alt="" />
                                                        </div>
                                                    )
                                                })}
                                                {othersNftArr?.length > 3 ?
                                                    <div className="col-6 viewAll">
                                                        <h2>+ {othersNftArr?.length - 3}</h2>
                                                        <p data-toggle="modal" data-target="#exampleModalCloseAll">View All</p>
                                                    </div>
                                                    :
                                                    <div className="col-6 viewAll d-flex align-items-center justify-content-between">
                                                        <img data-toggle="modal" data-target="#exampleModalAll" src='\asssests\Group.svg' className='img-fluid cPointer' />

                                                        <p onClick={() => window.$('#exampleModalCloseAll').modal('show')}>View</p>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        {/* <div className='main-imagescard1'>
                                     <img data-toggle="modal" data-target="#exampleModal" src='\asssests\Group.svg' className='img-fluid' />
                                     <h5 className='main-paragraph'>Add a NFT you want <br />in exchange</h5>
                                 </div> */}
                                    </div>
                                </div>
                                :
                                <div className='main-data'>
                                    <div className='main-imgcard'>
                                        <div className='main-imagescard1'>
                                            <img data-toggle="modal" data-target="#exampleModalAll" src='\asssests\Group.svg' className='img-fluid cPointer' />
                                            <h5 className='main-paragraph'>Add a NFT you want <br />in exchange</h5>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className='button-btn'>
                            <button onClick={makeAnOffer} disabled={myNftArr?.length < 1 || othersNftArr?.length < 1} className={(myNftArr?.length > 0 && othersNftArr?.length > 0) ? ' button1 buttonVisible' : 'button1 cNPointer'}>Make an Offer</button>
                            <button disabled={myNftArr?.length < 1 && othersNftArr?.length < 1} className={(myNftArr?.length > 0 || othersNftArr?.length > 0) ? ' button2 buttonVisible' : 'button2 cNPointer'} onClick={() => { setMyNftArr([]); setOthersNftArr([]); setMinted(true) }}>Cancel</button>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Select NFT</h5>
                                <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <img src='\bluemoon-nft\create\close.png' className='img-fluid' />
                                </a>
                            </div>
                            <div className="modal-body">
                                {onsale?.filter((item) => item?.isMinted === minted).length > 6 && (
                                    <div className='d-flex justify-content-center flex-column align-items-center'>
                                        <h4 className=' text-center pb-3' htmlFor="Search">Search</h4>
                                        <input
                                            type="text"
                                            className='w-sm-50 w-50 text-center'
                                            onChange={searchNftFunc}
                                            placeholder="Search Nft By Name..."
                                        />
                                    </div>
                                )}
                                {/* {onsale?.length < 1 && <h2 className='text-center mt-5'>Nft not Found!</h2>} */}
                                {/* <div className='row'>
                                    {onsale?.map((item) => {
                                        return (
                                            (item?.isMinted === minted) &&
                                            <div className='col-xl-4 col-12'>
                                                <div className="ccaarrdd">
                                                    <div className="form-check">
                                                        <input onClick={() => myNftAddFunc(item)} className="form-check-input" type="checkbox" name={item?.nftName} id="exampleRadios1" value="option1" />
                                                    </div>
                                                    <img
                                                        src={item?.image}
                                                        alt="img"
                                                        className="img-fluid w-100"
                                                    />
                                                    <div className="inerfst">
                                                        <h6 className="crdhngg ">{item?.nftName}</h6>
                                                        <Link to="explore" className="cardbtn">
                                                            {item?.chain}
                                                        </Link>
                                                    </div>
                                                    <div className="fitcont">
                                                        <ul className="list-inline ">
                                                            <div className="mmaaaiin d-flex">
                                                                <div className="maaiinnoonnee">
                                                                    <li className="list-inline-item">
                                                                        <img
                                                                            src={item?.creators[0]?.picture}
                                                                            className="img-fluid sfgsdfsdsdgsdg "
                                                                            alt=""
                                                                        />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <div className="history-heads">
                                                                            <h3 className="hhiigghh"> Creator </h3>
                                                                            <p className="hours"><a href={"/user?id=" + item?.creators[0]?.walletAddress}>{item?.creators[0]?.userFullName ? item?.creators[0]?.userFullName : item?.creators[0]?.walletAddress?.slice(0, 12)}</a>{" "}</p>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                                <div className="mainncccxxxx">
                                                                    <li className="list-inline-item">
                                                                        <div className="history-heads">
                                                                            <h3 className="hhiigghh"> Highest Bid</h3>
                                                                            <p className="hoursss">{item?.metaData?.price || 0} eTH</p>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                        )

                                    })}

                                </div> */}
                                <div className='row'>
                                    {(searched?.value ? searched?.array : onsale)?.filter((item) => item?.isMinted === minted).length > 0 ? (
                                        (searched?.value ? searched?.array : onsale)
                                            .filter((item) => item?.isMinted === minted && !myNftArr?.includes(item))
                                            .map((item) => (
                                                <div className='col-xl-4 col-12'>
                                                    <div className="ccaarrdd">
                                                        <div className="form-check">
                                                            <input onClick={() => myNftAddFunc(item)} checked={false} className="form-check-input" type="checkbox" name={item?.nftName} id="exampleRadios1" value="option1" />
                                                        </div>
                                                        <img
                                                            src={item?.image}
                                                            alt="img"
                                                            className="img-fluid w-100 nfts-img"
                                                        />
                                                        <div className="inerfst">
                                                            <h6 className="crdhngg ">{item?.nftName}</h6>
                                                            <Link to="explore" className="cardbtn">
                                                                {item?.chain}
                                                            </Link>
                                                        </div>
                                                        <div className="fitcont">
                                                            <ul className="list-inline ">
                                                                <div className="mmaaaiin d-flex">
                                                                    <div className="maaiinnoonnee">
                                                                        <li className="list-inline-item">
                                                                            <img
                                                                                src={item?.creators[0]?.picture}
                                                                                className=" sfgsdfsdsdgsdg "
                                                                                alt=""
                                                                            />
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <div className="history-heads">
                                                                                <h3 className="hhiigghh"> Creator </h3>
                                                                                <p className="hours">
                                                                                    <a href={"/user?id=" + item?.creators[0]?.walletAddress}>
                                                                                        {item?.creators[0]?.userFullName ? item?.creators[0]?.userFullName : item?.creators[0]?.walletAddress?.slice(0, 12)}
                                                                                    </a>{" "}
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                    <div className="mainncccxxxx">
                                                                        <li className="list-inline-item">
                                                                            <div className="history-heads">
                                                                                <h3 className="hhiigghh"> Highest Bid</h3>
                                                                                <p className="hoursss">{item?.metaData?.price || 0} BNB</p>
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                    ) : (
                                        <h2 className='mt-5 py-5 text-center w-100'>No items found.</h2>
                                    )}
                                </div>

                                <div className='footer'>
                                    <div className='modal-button'>
                                        <div className='button1' onClick={() => window.$('#exampleModal').modal('hide')}>Confirm</div>
                                        {/* <button disabled={myNftArr?.length < 1} className={(myNftArr?.length > 0) ? ' button2 button3' : 'button2 button3 cNPointer'}  onClick={() => { getOwnedNft(); setMyNftArr([]) }}>CLear All</button> */}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModalClose" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">UnSelect NFT {myNftArr?.length}</h5>
                                <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <img src='\bluemoon-nft\create\close.png' className='img-fluid' />
                                </a>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    {myNftArr?.map((item) => {
                                        return (
                                            <div className='col-xl-4 col-12'>
                                                <div className="ccaarrdd">
                                                    <div className="form-check">
                                                        {/* <input onClick={() => { setMyNftArr((pre) => [...pre, item]) }} className="form-check-input" type="checkbox" name={item?.nftName} id="exampleRadios1" value="option1" /> */}
                                                        <a className='' onClick={() => myNftRemoveFunc(item)}><img src='\asssests\Frame1120.svg' className='img-fluid' /></a>
                                                    </div>
                                                    <img
                                                        src={item?.image}
                                                        alt="img"
                                                        className="img-fluid w-100"
                                                    />
                                                    <div className="inerfst">
                                                        <h6 className="crdhngg ">{item?.nftName}</h6>
                                                        <Link to="explore" className="cardbtn">
                                                            {item?.chain}
                                                        </Link>
                                                    </div>
                                                    <div className="fitcont">
                                                        <ul className="list-inline ">
                                                            <div className="mmaaaiin d-flex">
                                                                <div className="maaiinnoonnee">
                                                                    <li className="list-inline-item">
                                                                        <img
                                                                            src={item?.creators[0]?.picture}
                                                                            className=" sfgsdfsdsdgsdg "
                                                                            alt=""
                                                                        />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <div className="history-heads">
                                                                            <h3 className="hhiigghh"> Creator </h3>
                                                                            <p className="hours"><a href={"/user?id=" + item?.creators[0]?.walletAddress}>{item?.creators[0]?.userFullName ? item?.creators[0]?.userFullName : item?.creators[0]?.walletAddress?.slice(0, 12)}</a>{" "}</p>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                                <div className="mainncccxxxx">
                                                                    <li className="list-inline-item">
                                                                        {/* <div className="history-heads">
                                                                            <h3 className="hhiigghh"> Highest Bid</h3>
                                                                            <p className="hoursss">4.89 eTH</p>
                                                                        </div> */}
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })}

                                </div>
                                <div className='footer'>
                                    <div className='modal-button'>
                                        <div className='button1' onClick={() => window.$('#exampleModalClose').modal('hide')}>Confirm</div>
                                        <div className='button2' onClick={() => { window.$('#exampleModalClose').modal('hide'); setMyNftArr([]) }}>Clear All</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModalAll" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Select NFT</h5>
                                <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <img src='\bluemoon-nft\create\close.png' className='img-fluid' />
                                </a>
                            </div>
                            <div className="modal-body">
                                {allNfts?.filter((item) => item?.isMinted === minted).length > 6 && (
                                    <div className='d-flex justify-content-center flex-column align-items-center'>
                                        <h4 className=' text-center pb-3' htmlFor="Search">Search</h4>
                                        <input
                                            type="text"
                                            className='w-sm-50 w-50 text-center'
                                            onChange={searchNftFunc2}
                                            placeholder="Search Nft By Name..."
                                        />
                                    </div>
                                )}
                                {/* {allNfts?.length < 1 && <h2 className='text-center mt-5'>Nft not Found!</h2>} */}
                                <div className='row'>
                                    {(searched2?.value ? searched2?.array : allNfts)?.filter((item) => item?.isMinted === minted).length > 0 ? (
                                        (searched2?.value ? searched2?.array : allNfts)
                                            .filter((item) => item?.isMinted === minted && !othersNftArr?.includes(item))
                                            .map((item) => (

                                                <div className='col-xl-4 col-12'>
                                                    <div className="ccaarrdd">
                                                        <div className="form-check">
                                                            <input onClick={() => myNftAddFuncAll(item)} className="form-check-input" checked={false} type="checkbox" name={item?.nftName} id={`exampleRadios${item?.nftName}`} value="option1" />
                                                        </div>
                                                        <img
                                                            src={item?.image}
                                                            alt="img"
                                                            className="img-fluid w-100 nfts-img"
                                                        />
                                                        <div className="inerfst">
                                                            <h6 className="crdhngg ">{item?.nftName}</h6>
                                                            <Link to="explore" className="cardbtn">
                                                                {item?.chain}
                                                            </Link>
                                                        </div>
                                                        <div className="fitcont">
                                                            <ul className="list-inline ">
                                                                <div className="mmaaaiin d-flex">
                                                                    <div className="maaiinnoonnee">
                                                                        <li className="list-inline-item">
                                                                            <img
                                                                                src={item?.creators[0]?.picture}
                                                                                className=" sfgsdfsdsdgsdg "
                                                                                alt=""
                                                                            />
                                                                        </li>
                                                                        <li className="list-inline-item">
                                                                            <div className="history-heads">
                                                                                <h3 className="hhiigghh"> Creator </h3>
                                                                                <p className="hours">
                                                                                    <a href={"/user?id=" + item?.creators[0]?.walletAddress}>
                                                                                        {item?.creators[0]?.userFullName ? item?.creators[0]?.userFullName : item?.creators[0]?.walletAddress?.slice(0, 12)}
                                                                                    </a>{" "}
                                                                                </p>
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                    <div className="mainncccxxxx">
                                                                        <li className="list-inline-item">
                                                                            <div className="history-heads">
                                                                                <h3 className="hhiigghh"> Highest Bid</h3>
                                                                                <p className="hoursss">{item?.metaData?.price || 0} BNB</p>
                                                                            </div>
                                                                        </li>
                                                                    </div>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                    ) : (
                                        <h2 className='mt-5 py-5 text-center w-100'>No items found.</h2>
                                    )}
                                </div>
                                {/* <div className='row'>
                                    {allNfts?.map((item) => {
                                        return (

                                            (item?.isMinted === minted) &&
                                            <div className='col-xl-4 col-12'>
                                                <div className="ccaarrdd">
                                                    <div className="form-check">
                                                        <input onClick={() => myNftAddFuncAll(item)} className="form-check-input" type="checkbox" name={item?.nftName} id="exampleRadios1" value="option1" />
                                                     </div>
                                                    <img
                                                        src={item?.image}
                                                        alt="img"
                                                        className="img-fluid w-100"
                                                    />
                                                    <div className="inerfst">
                                                        <h6 className="crdhngg ">{item?.nftName}</h6>
                                                        <Link to="explore" className="cardbtn">
                                                            {item?.chain}
                                                        </Link>
                                                    </div>
                                                    <div className="fitcont">
                                                        <ul className="list-inline ">
                                                            <div className="mmaaaiin d-flex">
                                                                <div className="maaiinnoonnee">
                                                                    <li className="list-inline-item">
                                                                        <img
                                                                            src={item?.creators[0]?.picture}
                                                                            className="img-fluid sfgsdfsdsdgsdg "
                                                                            alt=""
                                                                        />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <div className="history-heads">
                                                                            <h3 className="hhiigghh"> Creator </h3>
                                                                            <p className="hours"><a href={"/user?id=" + item?.creators[0]?.walletAddress}>{item?.creators[0]?.userFullName ? item?.creators[0]?.userFullName : item?.creators[0]?.walletAddress?.slice(0, 12)}</a>{" "}</p>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                                <div className="mainncccxxxx">
                                                                    <li className="list-inline-item">
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })}

                                </div> */}
                                <div className='footer'>
                                    <div className='modal-button'>
                                        <div className='button1' onClick={() => window.$('#exampleModalAll').modal('hide')}>Confirm</div>
                                        {/* <button disabled={othersNftArr?.length < 1} className={(othersNftArr?.length > 0) ? ' button2 button3' : 'button2 button3 cNPointer'} onClick={() => { window.$('#exampleModalCloseAll').modal('hide'); setOthersNftArr([]) }}>CLear All</button> */}
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampleModalCloseAll" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">UnSelect NFT {myNftArr?.length}</h5>
                                <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <img src='\bluemoon-nft\create\close.png' className='img-fluid' />
                                </a>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    {othersNftArr?.map((item) => {
                                        return (
                                            <div className='col-xl-4 col-12'>
                                                <div className="ccaarrdd">
                                                    <div className="form-check">
                                                        {/* <input onClick={() => { setMyNftArr((pre) => [...pre, item]) }} className="form-check-input" type="checkbox" name={item?.nftName} id="exampleRadios1" value="option1" /> */}
                                                        <a className='' onClick={() => myNftRemoveFuncAll(item)}><img src='\asssests\Frame1120.svg' className='img-fluid' /></a>
                                                    </div>
                                                    <img
                                                        src={item?.image}
                                                        alt="img"
                                                        className="img-fluid w-100"
                                                    />
                                                    <div className="inerfst">
                                                        <h6 className="crdhngg ">{item?.nftName}</h6>
                                                        <Link to="explore" className="cardbtn">
                                                            {item?.chain}
                                                        </Link>
                                                    </div>
                                                    <div className="fitcont">
                                                        <ul className="list-inline ">
                                                            <div className="mmaaaiin d-flex">
                                                                <div className="maaiinnoonnee">
                                                                    <li className="list-inline-item">
                                                                        <img
                                                                            src={item?.creators[0]?.picture}
                                                                            className=" sfgsdfsdsdgsdg "
                                                                            alt=""
                                                                        />
                                                                    </li>
                                                                    <li className="list-inline-item">
                                                                        <div className="history-heads">
                                                                            <h3 className="hhiigghh"> Creator </h3>
                                                                            <p className="hours"><a href={"/user?id=" + item?.creators[0]?.walletAddress}>{item?.creators[0]?.userFullName ? item?.creators[0]?.userFullName : item?.creators[0]?.walletAddress?.slice(0, 12)}</a>{" "}</p>
                                                                        </div>
                                                                    </li>
                                                                </div>
                                                                <div className="mainncccxxxx">
                                                                    <li className="list-inline-item">
                                                                        {/* <div className="history-heads">
                                                                            <h3 className="hhiigghh"> Highest Bid</h3>
                                                                            <p className="hoursss">4.89 eTH</p>
                                                                        </div> */}
                                                                    </li>
                                                                </div>
                                                            </div>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        )

                                    })}

                                </div>
                                <div className='footer'>
                                    <div className='modal-button'>
                                        <div className='button1' onClick={() => window.$('#exampleModalCloseAll').modal('hide')}>Confirm</div>
                                        <div className='button2' onClick={() => { window.$('#exampleModalCloseAll').modal('hide'); setOthersNftArr([]) }}>Clear All</div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Swapnft