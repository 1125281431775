import React, { useEffect, useState } from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';
import "./editprofile.scss";
import dummyImg from "../../assets/dummyImg.png";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { Api_URL } from "../../utils/ApiURL";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Loader from '../../hooks/loader';

const Editprofile = () => {
  const navigate = useNavigate();
  const [profilePicture, setProfilePicture] = useState(null);
  const [coverPicture, setCoverPicture] = useState();
  const { account } = useWeb3React();
  const [token, setToken] = useState();
  const [username, setUsername] = useState();
  const [bio, setBio] = useState();
  const [email, setEmail] = useState("");
  const [wallet, setWallet] = useState();
  const [twitterUsername, settwitterUsername] = useState();
  const [instagramHandle, setinstagramHandle] = useState('');
  const [yoursite, setyourSite] = useState();
  const [reddit, setReddit] = useState();
  const [discord, setDiscord] = useState();
  const [medium, setMedium] = useState();
  const [telegram, setTelegram] = useState();
  const [data, setData] = useState();
  const [loader, setLoader] = useState(false);
  // console.log("🚀 ~ file: Editprofile.js ~ line 36 ~ Editprofile ~ loader", loader)

  const [changeUrl, setChnageUrl] = useState();
  // console.log("🚀 ~ file: Editprofile.js ~ line 39 ~ Editprofile ~ changeUrl", changeUrl)

  //profile
  const setProfilePic = (evt) => {
    let image = evt.target.files[0];
    if (image.size > 4999328) {
      toast.error("Max file size 5mb!")
    } else {
      setProfilePicture(evt.target.files[0]);
    }
  };

  // setChnageUrl(profilePicture)


  const setBannerPic = (evt) => {
    let image = evt.target.files[0];
    if (image.size > 4999328) {
      toast.error("Max file size 5mb!")
    } else {
      setCoverPicture(evt.target.files[0]);
    }
  };
  useEffect(() => {
    if (account) {
      setToken(localStorage.getItem("accessToken"));
    }
  }, [account]);

  const editUsername = (e) => {
    setUsername(e.target.value);
  };

  const editBio = (e) => {
    setBio(e.target.value);
  };

  // const changeSocialLinks = (e) => {
  //   const { name, value } = e.target;
  //   setInputs((inputs) => ({ ...inputs, [name]: value }));
  // };

  // const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  // console.log(error, "error email")
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const editEmail = event => {
    if (!isValidEmail(event.target.value)) {
      setError('Email is invalid');
    } else {
      setError(null);
    }
    setEmail(event.target.value);
  };


  const editWallet = (e) => {
    setWallet(e.target.value);
  };

  const edittwitterUsername = (e) => {
    settwitterUsername(e.target.value);
  };
  const editinstagram = (e) => {
    setinstagramHandle(e.target.value);
  };
  const edityourSite = (e) => {
    setyourSite(e.target.value);
  };
  const editReddit = (e) => {
    setReddit(e.target.value);
  };
  const editDiscord = (e) => {
    setDiscord(e.target.value);
  };
  const editMedium = (e) => {
    setMedium(e.target.value);
  };

  const editTelegram = (e) => {
    setTelegram(e.target.value);
  };




  // Get User Data 



  const getUser = async () => {
    const data = { walletAddress: account }
    await axios
      .post(`${Api_URL}/user/getUser`, data, {
        headers: {
          Authorization: "Bearer " + token
        }
      })
      .then((res) => {
        setData(res.data);
        setUsername(res?.data?.resultData?.userFullName !== 'undefined' && res?.data?.resultData?.userFullName);
        setBio(res?.data?.resultData?.bio !== 'undefined' && res?.data?.resultData?.bio);
        setEmail(res?.data?.resultData?.userEmail !== 'undefined' && res?.data?.resultData?.userEmail);
        setTelegram(res?.data?.resultData?.telegramLink !== 'undefined' && res?.data?.resultData?.telegramLink);
        setDiscord(res?.data?.resultData?.discordLink !== 'undefined' && res?.data?.resultData?.discordLink);
        setMedium(res?.data?.resultData?.meduimLink !== 'undefined' && res?.data?.resultData?.meduimLink);
        setReddit(res?.data?.resultData?.redditLink !== 'undefined' && res?.data?.resultData?.redditLink);
        setyourSite(res?.data?.resultData?.websiteLink !== 'undefined' && res?.data?.resultData?.websiteLink);
        setinstagramHandle(res?.data?.resultData?.instagramLink !== 'undefined' && res?.data?.resultData?.instagramLink);
        settwitterUsername(res?.data?.resultData?.twitterUserName !== 'undefined' && res?.data?.resultData?.twitterUserName);
        setProfilePicture(res?.data?.resultData?.picture);
        setCoverPicture(res?.data?.resultData?.coverPicture);

      })
      .catch((err) => {
        toast.error(err?.response?.data.msg, {
          position: "top-right",
          autoClose: 2000
        });
      })
    // };
  }





  // Update User Data

  const [checkState, setCheckState] = useState(false);
  const editPro = async (e) => {
    e.preventDefault();

    if (profilePicture == null && username == null && yoursite == null) {
      setCheckState(true);
    }
    else {
      e.preventDefault();
      var axios = require("axios");
      var data = new FormData();
      data.append("picture", profilePicture);
      data.append("coverPicture", coverPicture);
      data.append("userFullName", username);
      data.append("bio", bio);
      data.append("twitterUserName", twitterUsername);
      data.append("instagramLink", instagramHandle);
      data.append("telegramLink", telegram);
      data.append("websiteLink", yoursite);
      data.append("discordLink", discord);
      data.append("meduimLink", medium);
      data.append("userEmail", email);
      data.append("redditLink", reddit);
      setLoader(true);
      var config = {
        method: "post",
        url: `${Api_URL}/user/editUser`,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: data
      };

      axios(config)
        .then((response) => {
          setLoader(false)
          toast.success("Profile Edit Successfully");
          navigate('/myprofile');
        })
        .catch((error) => {
          setLoader(false)
          toast.error(error)
        });
    }



  };


  useEffect(() => {
    if (token) {
      getUser()
    }
  }, [token]);




  // Validation




  const SignupSchema = Yup.object().shape({
    // firstName: Yup.string()
    // .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    // .required('Invalid email'),
    // lastName: Yup.string()
    // .min(2, 'Too Short!')
    // .max(50, 'Too Long!')
    // .required('Invalid email'),
    // email: Yup.string().email('Invalid email'),
    email: Yup.string()
      .email('Invalid email address')
      .required('Invalid email!')
  });



  return (
    <>
      {loader &&
        <Loader />
      }
      <ToastContainer />
      <Header />
      <Formik
        initialValues={{
          email: ''
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 1000);
        }}
      >
        {({ errors, touched }) => (
          <Form onSubmit={(e) => editPro(e)}>
            <section className="editprofile">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-xl-10 col-12 m-auto ">
                    <div className="heading-edit mb-5">
                      <h2>Edit Your Profile</h2>
                      <p className=''>
                        You can set preferred display name and manage other personal
                        settings
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-12 order-2">
                        <div className="input-field">
                          <h6>Displaycgn Name</h6>
                          <input
                            type="text"
                            placeholder="Enter your display name"
                            value={username}
                            onChange={editUsername}
                            className={checkState ? "border-danger___email" : ""}
                          />
                        </div>
                        <div className="input-field">
                          <h6>Bio</h6>
                          <textarea
                            placeholder="Tell about yourself in a few words"
                            value={bio}
                            onChange={editBio}
                          />
                        </div>
                        <div className="input-field">
                          <h6>Twitter username</h6>

                          <input
                            type="text"
                            placeholder="Enter your name in Twitter"
                            className="specific"
                            onChange={edittwitterUsername}
                            value={twitterUsername}
                          />
                          {/* <button className="connect">Connect</button> */}
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-12 ">
                            <div className="input-field">
                              <h6>Instagram Handle</h6>
                              <input
                                type="text"
                                placeholder="Enter your Instagram Handle"
                                onChange={editinstagram}
                                value={instagramHandle || ''}
                              />
                            </div>
                            <div className="input-field">
                              <h6>Reddit</h6>
                              <input
                                type="text"
                                placeholder="Enter your Reddit Handle"
                                onChange={editReddit}
                                value={reddit}
                              />
                            </div>
                            <div className="input-field">
                              <h6>Medium</h6>
                              <input
                                type="text"
                                placeholder="Enter your Medium Handle"
                                onChange={editMedium}
                                value={medium}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-12 ">
                            <div className="input-field">
                              <h6>Your Site</h6>
                              <input
                                type="text"
                                placeholder="yoursite.io"
                                value={yoursite}
                                onChange={edityourSite}
                                className={checkState ? "border-danger___email" : ""}
                              />
                            </div>
                            <div className="input-field">
                              <h6>Discord</h6>
                              <input
                                type="text"
                                placeholder="yoursite.io"
                                value={discord}
                                onChange={editDiscord}
                              />
                            </div>
                            <div className="input-field">
                              <h6>Telegram</h6>
                              <input
                                type="text"
                                placeholder="yoursite.io"
                                value={telegram}
                                onChange={editTelegram}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="input-field">
                          <h6>Email</h6>
                          {/* <input
                            type="text"
                            placeholder="Enter your email address"
                            value={email}
                            onChange={editEmail}
                          /> */}
                          <Field
                            name="email"
                            type="email"
                            placeholder="Enter valid email address"
                            value={email}
                            onChange={editEmail}
                            className={checkState ? "border-danger___email" : ""}
                          />
                          <p className='text-danger'>
                            {error && <h5 style={{ color: 'red' }}>{error}</h5>}
                          </p>
                        </div>
                        <div className="input-field">
                          <h6>Wallet Address</h6>
                          <input
                            type="text"
                            placeholder={account}
                            onChange={editWallet}
                            value={wallet}
                            readOnly
                          />
                          {/* <button className="connect">Copy</button> */}
                        </div>
                        <div className="bottom-btn">
                          <button type='submit' className='w-100'>Update Profile</button>
                        </div>
                      </div>
                      <div className="col-xl-6 col-12 order">
                        <div className="top">
                          <h4>Profile Picture</h4>
                          <div className="profile">
                            <div className={checkState ? "profile-img border-danger___email" : "profile-img"}>
                              {profilePicture?.slice(0, 5) === "https" ?
                                <img
                                  src={profilePicture}
                                  alt="img"
                                  className="img-fluid"
                                />
                                :
                                <img
                                  src={profilePicture ? URL?.createObjectURL(profilePicture) : dummyImg}
                                  alt="img"
                                  className="img-fluid"
                                />
                              }
                            </div>
                            <div className="profile-data">
                              <p>
                                We recommend an image of at least <br /> 300x300.PNG, JPG, GIF, WEBP & Gifs
                                work too. Max 5mb.
                              </p>
                              <label for="upload">Upload Profile</label>
                              <input
                                id="upload"
                                type="file"
                                onChange={(e) => setProfilePic(e)}
                                className="d-none"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="bottom">
                          <h4>Cover Photo</h4>
                          <div className="cover-img Cover________Photo">
                            {coverPicture?.slice(0, 5) === "https" ?
                              <img
                                src={coverPicture}
                                alt="img"
                                className="img-fluid"
                              />
                              :
                              <img
                                src={coverPicture ? URL?.createObjectURL(coverPicture) : dummyImg}
                                alt="img"
                                className="img-fluid"
                              />
                            }
                          </div>
                          {/* <button className='upload'>Upload Cover</button> */}
                          <p className='pt-4'>
                            We recommend an image of at least 600x600.PNG, JPG, GIF, WEBP, Max 5mb.
                          </p>
                          <label for="coverImg">Upload Cover</label>
                          <input
                            id="coverImg"
                            type="file"
                            accept='image/*'
                            // onChange={(e)=>setProfilePic(e)}
                            onChange={setBannerPic}
                            className="d-none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </Form>
        )}
      </Formik>
      <Footer />
    </>
  );
};

export default Editprofile;
