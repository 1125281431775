import React, { useState } from "react";
import { Navbar } from 'react-bootstrap'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import "./competitionform.scss";
import Category from "./Listarray";

const Competitionform = () => {
  const [category, setcategory] = useState("Ethereum");
  const [img, setimg] = useState(
    "https://res.cloudinary.com/hamza1234/image/upload/v1653662264/drop-in_wkkhmu.png"
  );


  const categoriess = (a, b) => {
    setcategory(a);
    setimg(b);
  };
  return (
    <>
      <Header />
      <section className='competitionform'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className="mainheadings">
                <h1>Edit Your Profile</h1>
                <p>You can set preferred display name and manage other personal settings</p>
              </div>
              </div>
              </div>
              <div className='row direction'>
            <div className='col-sm-12 col-lg-8'>
              <div className="upload">
                <h6>Upload file</h6>
                <div className="piciploddiv">
                  <p className="inerpicpara m-0">
                    PNG, JPG, GIF, WEBP or MP4. Max 200mb.
                  </p>
                  <label for="upload" className="upload-btn">Upload file</label>
                  <input id="upload" type="file" className="d-none" />
                </div>
              </div>
              <div className="inputss">
                <div>
                  <p>Title</p>
                  <input type="text" className='forminput' placeholder='Item Name' />
                </div>
                <div>
                  <p>Description</p>
                  <textarea className='forminput' placeholder='e.g. “This is very limited item”'></textarea>
                </div>
                <div className="input-field">
                  <h6>Collection</h6>

                  <div className="button-list">
                    <div className="dropdown buttons-list-all">
                      <button
                        className="button-listing"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="left d-flex justify-content-center align-items-center">
                          <img src={img} alt="img" className="img-fluid " />
                          &nbsp;&nbsp;
                          <p className="m-0">{category}</p>
                        </div>
                        <img
                          src="bluemoon-nft\bbarrow.png"
                          alt="img"
                          className="img-fluid"
                        />
                      </button>

                      <div
                        className="dropdown-menu gfghfhgfv"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {Category.map((elem) => {
                          return (
                            <a
                              className="dropdown-item"
                              onClick={() => categoriess(elem.item, elem.url)}
                            >
                              <img
                                src={elem?.url}
                                alt="img"
                                className="img-fluid"
                              />
                              &nbsp;&nbsp;
                              {elem?.item}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="formbtn">
                <button>Create</button>
              </div>
            </div>
            <div className='col-sm-12 col-lg-4'>
              <div className='card'>
                <img src='\create\cardimage.png' className='cardimg' alt='img' />
                <div className='forfl'>
                  <h6 className='cardhead'>"Hamlet Contemplates ...</h6>
                  <span className='cardbtn'>BSC</span>
                </div>
                <div className='forfl'>
                  <div className='creator'>
                    <img src='\create\Rectangle.svg' alt='img' className='creatorimg' />
                    <div>
                      <p className='creatorhead'>Creator</p>
                      <p className='creatorpara'>SalvadorDali</p>
                    </div>
                  </div>
                  <div className='fcol'>
                    <p className='high'>Highest Bid</p>
                    <h6 className='highhead'>4.89 eTH</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Competitionform