import React, { useState } from 'react'
import Countdown from "react-countdown";
import "./liveauctioncard.scss"

const LiveAuctionCard = ({ data }) => {
    const [leng, setLeng] = useState(6);

    // const ShowMore = () => {
    //     let val = leng + 3;
    //     if (val > data.length) {
    //         setLeng(data.length);
    //     } else {
    //         setLeng(val);
    //     }
    // }

    return (
        <>
            <section className='liveauctioncard'>
                <div className="container p-0">
                    <div className="trend-cards">
                        {data?.slice(0, leng)?.map((item, index) => {
                            return (
                                <div key={index} className="card-inner">
                                    <div className="img-main">
                                        <a href={'/item-details?id=' + item?._id}><img src={item?.image} alt="img" className='img-fluid main-imggg' /></a>
                                        <div className="timerrr">
                                            <img src="\assets\fire.svg" alt="img" className='img-fluid' />
                                            <Countdown date={new Date(item?.metaData?.endTime)}>
                                                <span>You are good to go!</span>
                                            </Countdown>
                                        </div>
                                    </div>
                                    <div className="mid-content">
                                        {item?.chainId === "97" && <span className='bsc'>BSC</span>}
                                        {item?.chainId === "5" && <span className='bsc'>Eth</span>}
                                        {item?.chainId === "80001" && <span className='bsc'>Matic</span>}
                                        <h6 className='title'><a href={'/item-details?id=' + item?._id}>{item?.nftName}</a></h6>
                                    </div>
                                    <div className="bottom-content">
                                        <div className="profile">
                                            <img src={item?.creators[0]?.picture} alt="img" className='img-fluid' />
                                        </div>
                                        <div className="text">
                                            <h6><span>Created by</span> <a href={"/user?id=" + item?.creators[0]?.walletAddress}>{item?.creators[0]?.userFullName ? item?.creators[0]?.userFullName : item?.walletAddress}</a></h6>
                                            {/* <h5>Art Collection</h5> */}
                                        </div>
                                    </div>
                                    <div className="price">
                                        <span>Price</span>
                                        <h6>{item?.metaData?.price} eTH</h6>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <button onClick={() => ShowMore()} className='seemore'>{leng.length === data.leng ? "No more to show" : "See More"}</button> */}
                </div>
            </section>
        </>
    )
}

export default LiveAuctionCard