import React, { useState, Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import CardModal from "../CardModal";
import { useWeb3React } from "@web3-react/core";
import dotsImg from "../../../assets/images/svg/dots.svg";
import transNFT from "../../../assets/images/svg/transNFT.svg";
import transIcon from "../../../assets/images/svg/transIcon.svg";
import "../../../pages/MyProfile/myprofile.scss";
import whiteTransBtn from "../../../assets/images/svg/white-trans-btn.svg";
import crossBtn from "../../../assets/images/svg/crossBtn.svg";
import { soliditySha3 } from "web3-utils";
import { useNavigate } from "react-router-dom";
import Loader from "../../../hooks/loader";
import useWeb3 from "../../../hooks/useWeb3";
import { Api_URL } from "../../../utils/ApiURL";
import axios from "axios";
import { toast } from "react-toastify";
import RentNft from "../../../hooks/dataSenders/rentNft";
const ExploreItem = (props) => {
  const { account } = useWeb3React();
  const [modalShow, setModalShow] = useState(false);
  const [show, setShow] = useState(8);
  const [FilToks, setFilToks] = useState([]);
  const [bulkTrans, setBulkTrans] = useState([]);
  const [rentItem, setRentItem] = useState(null);
  const [rend, setRend] = useState(false);
  const [duration, setDuration] = useState(0);
  const [duration2, setDuration2] = useState(0)
  const [rentPrice, setRentPrice] = useState(0);
  const [collateral, setCollateral] = useState(0);
  const context = useWeb3React();
  const { rentNfts } = RentNft();
  const { chainId } = context;
  const web3 = useWeb3()
  console.log('lksadfjlas', duration)
  let navigate = useNavigate();

  useEffect(() => {
    RemoveItems();
  }, [])

  const ToShow = () => {
    let sho = show + 4;
    if (sho > FilToks.length) {
      setShow(FilToks.length);
    } else {
      setShow(sho);
    }
  };

  useEffect(() => {
    if (props.owned && FilToks?.length > 0) {
      if (FilToks[0]?.tokens?.length > 0) {
        let dumArr = [];
        for (let i of FilToks) {
          dumArr.push(i.tokens[0]);
        }
        setFilToks(dumArr);
      }
    }
  }, [props.owned, FilToks])
 
  useEffect(() => {
    if (props?.recentAddTokens?.length > 0) {
      let dumArr = [];
      for (let i of props?.recentAddTokens) {
        if (i?.tokens?.length > 0) {
          dumArr.push(i);
        } else if (!i?.tokens) {
          dumArr.push(i);
        }
      }
      setFilToks(dumArr);
    } else {
      setFilToks([]);
    }
  }, [props?.recentAddTokens]);

  const AddRemoveItem = (item) => {
    let dumArr = bulkTrans;
    let dumObj = dumArr.find((i) => {
      return i._id === item._id;
    })
    if (dumObj) {
      dumArr = dumArr.filter((io) => {
        return io._id !== item._id;
      })
    } else {
      dumArr.push(item);
    }
    setBulkTrans(dumArr);
    localStorage.setItem("bulkTrans", JSON.stringify(dumArr));
    setRend(!rend);
  }

  const CheckItem = (item) => {
    let dumObj = bulkTrans.find((i) => i._id === item._id);
    if (dumObj) {
      return true;
    } else {
      return false;
    }
  }

  const RemoveItems = () => {
    localStorage.setItem("bulkTrans", JSON.stringify([]));
    setBulkTrans([]);
  }

  const TransferNft = (item) => {
    // console.log("!!!!!", item)
    localStorage.setItem("bulkTrans", JSON.stringify([item]));
    navigate('/transferitems')
  }

  const UpdateDuration = (e) => {
    // Check if the input is empty
    if (e === "") {
      setDuration2(""); // Set duration to an empty string
      return;
    }

    let val = parseInt(e);

    if (val > 0 && val <= parseInt(duration)) {
      setDuration2(val.toFixed(0));
    } else if (val <= 0) {
      toast.info(`Value can't be less than 1`);
    } else {
      if (!duration) {
        toast.info("Rent duration has expired");
        return;
      } else {
        toast.info(`Only available for ${duration} day`);
      }
    }
  };



  const UpdateRent = (e) => {
    let val = parseFloat(e);
    if (e > 0) {
      setRentPrice(val.toFixed(2));
    } else {
      setRentPrice(0)
    }
  }

  const UpdateCollateral = (e) => {
    let val = parseFloat(e);
    if (e > 0) {
      setCollateral(val.toFixed(2));
    } else {
      setCollateral(0)
    }
  }
  const AddRentOfferTime = async (duration) => {
    const rentStart = new Date(rentItem?.rentOrder[0]?.rentStart);
    const rentEnd = new Date(rentItem?.rentOrder[0]?.rentEnd);
    const needDays = duration;

    const today = new Date(); // Get the current date

    // Calculate the difference in milliseconds between rentStart and today
    const diffStart = today.getTime() - rentStart.getTime();
    const daysFromStart = Math.ceil(diffStart / (1000 * 60 * 60 * 24));

    // Calculate the difference in milliseconds between rentEnd and today
    const diffEnd = rentEnd.getTime() - today.getTime();
    const daysToEnd = Math.ceil(diffEnd / (1000 * 60 * 60 * 24));

    const isAvailable = daysFromStart >= 0 && daysToEnd >= needDays;
    let response = { daysToEnd, isAvailable }
    return response

  }
  function getEpochTimeAfterDays(days) {
    // const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    // const millisecondsPerMinute = 300 * 1000; // Number of milliseconds in a 6 minute | adding to take time for trasaction

    const millisecondsPerDay = 5 * 60 * 1000;
    const millisecondsPerMinute = 30 * 1000; // Number of milliseconds in a 6 minute | adding to take time for trasaction

    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + days * millisecondsPerDay + millisecondsPerMinute);
    const epochTime = futureDate.getTime() / 1000;
    return epochTime;
  }
  // console.log('jjjjjjjjjjjjjjjjjjjjjjjjjjjj', rentItem)
  const AddRentOffer = async () => {
    if (duration2 > 0 && account) {
      if (duration2 > 0) {
        props.setLoader(true);
        window.$("#offermodal").modal("hide");
        let token = localStorage.getItem("accessToken");
        let soliditySha3Expected = null;
        let signature = false;
        if (chainId === 97) {
          soliditySha3Expected = soliditySha3(
            // Environment?.marketPlaceContract,
            account?.toLowerCase(),
            // web3.utils.toWei(meta?.price.toString()),
            // meta.startTime
          );
        } else if (chainId === 5) {
          soliditySha3Expected = soliditySha3(
            // Environment?.marketPlaceContract,
            account?.toLowerCase(),
            // web3.utils.toWei(meta?.price.toString()),
            // meta.startTime
          );
        } else if (chainId === 80001) {
          soliditySha3Expected = soliditySha3(
            // Environment?.marketPlaceContract,
            account?.toLowerCase(),
            // web3.utils.toWei(meta?.price.toString()),
            // meta.startTime
          );
        } else if (chainId === 43114) {
          soliditySha3Expected = soliditySha3(
            // Environment?.marketPlaceContract,
            account?.toLowerCase(),
            // web3.utils.toWei(meta?.price.toString()),
            // meta.startTime
          );
        }
        await web3.eth.personal.sign(soliditySha3Expected, account).then(async (res) => {
          signature = res;
          if (signature) {
            try {
              console.log('errror of the rent',
                ' in function');
              // const epochTimeExpiryTime = getEpochTimeAfterDays(duration);
              let rentPrice = parseInt(duration2) * parseFloat(rentItem?.rentOrder[0]?.rentPricePerDay)
              const res = await rentNfts(rentPrice, rentItem?.contractAddress, rentItem?.tokenID, duration2, account)

              var data = JSON.stringify({
                "contractAddress": rentItem?.contractAddress,
                // "renterAddress": account?.toLowerCase(),
                // "ownerAddress": rentItem?.ownerAddress?.toLowerCase(),
                "signature": signature,
                "tokenID": rentItem?.tokenID,
                "rentDuration": duration2,
                "totalRentPrice": rentItem?.rentOrder[0]?.rentPricePerDay * duration,
                // "collateral": rentItem?.rentOrder[0]?.collateral
              });
              var config = {
                method: "post",
                url: `${Api_URL}/rentOrder/acceptRentOrder`,
                headers: {
                  Authorization: "Bearer " + token,
                  "Content-Type": "application/json"
                },
                data: data
              };
              axios(config)
                .then(function (response) {
                  toast.success("Borrow Successfull!")
                  props.setLoader(false);
                  props?.callAPI()
                })
                .catch(function (error) {
                  props.setLoader(false);
                  // console.log("getApi error", error);
                });
            } catch (error) {
              console.log('errror of the rent', error);
              props.setLoader(false)
              toast.error('Insufficient Funds')
            }

          } else {
            toast.error('Trasaction Rejected')
            console.log('out of league')
            props.setLoader(false);
          }
        }).catch((err) => {
          toast.error('Trasaction Rejected')
          props.setLoader(false);
          throw err;
        });
      } else {
        toast.info(`Not Avaialbe For Rent`)
      }
    } else {
      toast.info(account ? 'Fill all fields' : 'Connect Your Wallet')
    }
  }
  function getRemainingDays(createdAt, noOfDays) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const currentDate = new Date();
    const futureDate = new Date(createdAt);
    futureDate.setDate(futureDate.getDate() + noOfDays);

    if (futureDate <= currentDate || noOfDays < 1) {
      return 0; // Return 0 for past dates or when noOfDays is less than 1
    } else {
      const remainingTime = futureDate.getTime() - currentDate.getTime();
      const remainingDays = Math.ceil(remainingTime / millisecondsPerDay);
      return remainingDays;
    }
  }

  function getAvailabilityRange(createdAt, noOfDays) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const currentDate = new Date();

    // Parse the createdAt date if it's not in ISO 8601 format
    const createdAtDate = new Date(createdAt);

    const futureDate = new Date(createdAtDate.getTime());
    futureDate.setDate(futureDate.getDate() + noOfDays);

    if (futureDate <= currentDate || noOfDays < 1) {
      return "Not Available";
    } else {
      const startDate = new Date(createdAtDate.getTime());
      const formattedStartDate = startDate.toISOString().slice(0, 10);
      const formattedEndDate = futureDate.toISOString().slice(0, 10);

      return `Available From ${formattedStartDate} To ${formattedEndDate}`;
    }
  }
  const availabilityRange = (rentItem?.rentOrder && rentItem?.rentOrder[0]?.createdAt && rentItem?.rentOrder[0]?.noOfDays) ? getAvailabilityRange(rentItem?.rentOrder[0]?.createdAt, rentItem?.rentOrder[0]?.noOfDays) : ''
  const functionTime = function (createdAt, noOfDays) {
    // Check if createdAt and noOfDays are present and valid
    if (!createdAt || isNaN(parseInt(noOfDays))) {
      // Return false if any of the required properties is missing or invalid
      return false;
    }

    // Convert createdAt to a Date object
    const createdDate = new Date(createdAt);

    // Check if createdDate is a valid date
    if (isNaN(createdDate.getTime())) {
      // Return false if createdAt is not a valid date
      return false;
    }

    // Calculate the end date by adding noOfDays to createdDate
    const endDate = new Date(createdDate);
    endDate.setDate(createdDate.getDate() + parseInt(noOfDays));

    // Get the current date
    const currentDate = new Date();

    // Check if the current date is within the range of createdDate and endDate
    const isAvailable = currentDate >= createdDate && currentDate <= endDate;

    return isAvailable;
  }
  useEffect(() => {
    if (rentItem) {
      const availabeDays = (rentItem?.rentOrder && rentItem?.rentOrder[0]?.createdAt && rentItem?.rentOrder[0]?.noOfDays) ? getRemainingDays(rentItem?.rentOrder[0]?.createdAt, rentItem?.rentOrder[0]?.noOfDays) : ''
      setDuration(availabeDays)
    }

  }, [rentItem])

  return (
    <>
      <Fragment>
        <div className="explore pb-0">
          {FilToks?.length > 0 ? (
            <div className={props?.showBtn ? "zfdkjndf box-epxlore" : "box-epxlore"}>
              {console.log('FilToks', FilToks)}
              {FilToks?.slice(0, props?.forRent ? 5000000 : show)?.map((item, index) => {
                const rentOrder = item?.rentOrder && item.rentOrder[0];

                // Check if rentOrder and its properties are present
                const createdAt = rentOrder?.createdAt;
                const noOfDays = parseInt(rentOrder?.noOfDays);

                // Check if props.forRent is present (assuming props is an object)
                const forRent = props?.forRent;

                // Use the ternary operator to determine availability
                let available = forRent ? (createdAt && noOfDays ? functionTime(createdAt, noOfDays) : false) : true;
                return (
                  <>
                    {console.log('itemmmmmmmm', item)}
                    {available &&
                      (item?.tokens?.length > 0 ? (
                        <div
                          className={
                            props?.showBtn
                              ? `sc-card-product explode style2 mg-bt dhdghdf ${item?.feature ? "comingsoon" : ""
                              } `
                              : "explode style2 mg-bt sc-card-product sc-card-product4item"
                          }
                          key={index}
                        >
                          <div className="card-media">
                            <Link to={"/item-details?id=" + item?.tokens[0]?._id}>
                              <img src={item?.tokens[0]?.image} alt="Axies" />
                            </Link>
                            <div className="button-place-bid">
                              {account?.toLowerCase() ==
                                item?.tokens[0]?.ownerAddress ? (
                                <Link to={"/item-details?id=" + item?.tokens[0]._id}>
                                  <button className="sc-button style-place-bid style bag fl-button pri-3">
                                    <span>View</span>
                                  </button>
                                </Link>
                              ) : (
                                <Link to={"/item-details?id=" + item?.tokens[0]._id}>
                                  <button className="sc-button style-place-bid style bag fl-button pri-3">
                                    <span>
                                      {item?.tokens[0]?.metaData?.method ===
                                        "auction" && item?.tokens[0]?.onSale
                                        ? "Place a Bid"
                                        : "Buy Now"}
                                    </span>
                                  </button>
                                </Link>
                              )}
                            </div>
                            {/* {props.owned ? (
                        <>
                          <div className="dropdown owned-new-trans-btn">
                            <button
                              className="btn dot-btn"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <img src={dotsImg} alt="dotsImg" />
                            </button>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <button>
                                {" "}
                                <img src={transNFT} alt="transNFT" /> Transfer
                                NFT
                              </button>
                              <div
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <button>
                                  {" "}
                                  <img src={transNFT} alt="transNFT" /> Transfer
                                  NFT
                                </button>
                              </div>
                            </div>
                            <img src={transIcon} alt="transIcon" />
                          </>
                        ) : null}
                        {props.owned !== true ? (
                          <Link
                            to={"/item-details?id=" + item?.tokens[0]._id}
                            className="wishlist-button heart"
                          >
                            <span className="number-like">
                              {item?.tokens[0].likedBy?.length}
                            </span>
                          </Link>
                        ) : null} */}
                            <div className="coming-soon">
                              {item?.tokens[0].feature}
                            </div>
                          </div>
                          <div className="card-title">
                            <h5>
                              <Link to={"/item-details?id=" + item?.tokens[0]._id}>
                                {item?.tokens[0].nftName}
                              </Link>
                            </h5>
                            <div className="tags">{item?.tokens[0].chain}</div>
                          </div>
                          <div className="meta-info">
                            <div className="author">
                              <a
                                href={"/user?id=" + item?.creators[0]?.walletAddress}
                              >
                                <div className="avatar cursor-pointer avatar__rounded sc-author-box p-0">
                                  <div className="author-avatar m-0 w-100 h-100">
                                    <img
                                      src={item?.creators[0]?.picture}
                                      alt="Axies"
                                      className="verified___badge"
                                    />
                                    {item?.verified ? (
                                      <div className="badge">
                                        <i className="ripple"></i>
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </a>
                              <div className="info">
                                <span>Creator</span>
                                <h6>
                                  {" "}
                                  <a
                                    href={
                                      "/user?id=" + item?.creators[0]?.walletAddress
                                    }
                                  >
                                  {item?.creators[0]?.userFullName
                                    ? item?.creators[0]?.userFullName
                                      : item?.creators[0]?.walletAddress?.slice(
                                        0,
                                        12
                                      )}
                                  </a>{" "}
                                </h6>
                              </div>
                            </div>
                          {console.log('goood', item)}
                          {item?.lastPrice ? (
                            <div className="price">
                              <span>Last Price</span>
                              <h5> {parseFloat(item?.lastPrice)?.toFixed(4)}</h5>
                            </div>
                          ) : <div className="price">
                            <span>Price</span>
                              <h5> {parseFloat(item?.price)?.toFixed(4)}</h5>
                          </div>}
                          </div>
                        </div>
                      ) : (
                        <>
                          {!item?.tokens && (
                            <div
                              className={
                                props?.showBtn
                                  ? `sc-card-product explode style2 mg-bt dhdghdf ${item?.feature ? "comingsoon" : ""
                                  } `
                                  : "explode style2 mg-bt sc-card-product sc-card-product4item"
                              }
                              key={index}
                            >
                              <div className="card-media position-relative">
                                <Link to={"/item-details?id=" + item?._id}>
                                  <img src={item?.image} alt="Axies" />
                                </Link>
                                <div className="button-place-bid">
                                  {account?.toLowerCase() == item?.ownerAddress ? (
                                    <Link to={"/item-details?id=" + item?._id}>
                                      <button className="sc-button style-place-bid style bag fl-button pri-3">
                                        <span>View</span>
                                      </button>
                                    </Link>
                                  ) : (
                                    <div className="d-flex flex-column align-items-center">
                                      {props?.forRent || <Link to={"/item-details?id=" + item?._id}>
                                        <button className="sc-button style-place-bid style bag fl-button pri-3">
                                          <span>
                                            {item?.metaData?.method === "auction" &&
                                              item?.onSale
                                              ? "Place a Bid"
                                              : "Buy Now"}
                                          </span>
                                        </button>
                                      </Link>}
                                      {props?.forRent &&
                                        <button onClick={() => setRentItem(item)} data-toggle="modal"
                                          data-target="#offermodal" className="sc-button style-place-bid style bag fl-button pri-3 mt-3  w-50">
                                          <span>
                                            Rent
                                          </span>
                                        </button>
                                      }
                                    </div>
                                  )}
                                </div>
                                {props.owned ? (
                                  <>
                                    <div className="dropdown owned-new-trans-btn">
                                      <button
                                        className="btn dot-btn"
                                        type="button"
                                        id="dropdownMenuButton"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                      >
                                        <img src={dotsImg} alt="dotsImg" />
                                      </button>
                                      <div
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton"
                                      >
                                        <button onClick={() => TransferNft(item)}>
                                          {" "}
                                          <img src={transNFT} alt="transNFT" />{" "}
                                          Transfer NFT
                                        </button>
                                      </div>
                                      <div className="transIcon-wrapper">
                                        <div className="form-group">
                                          <input
                                            type="checkbox"
                                            checked={CheckItem(item)}
                                            onChange={() => AddRemoveItem(item)}
                                            id={"methodone" + index}
                                            style={{ display: "none" }}
                                            name="gender"
                                          />
                                          <label for={"methodone" + index}></label>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : null}
                                {props.owned !== true ? (
                                  <Link
                                    to={"/item-details?id=" + item?._id}
                                    className="wishlist-button heart"
                                  >
                                    <span className="number-like">
                                      {item?.likedBy?.length}
                                    </span>
                                  </Link>
                                ) : null}
                                <div className="coming-soon">{item?.feature}</div>
                              </div>
                              <div className="card-title">
                                <h5>
                                  <Link to={"/item-details?id=" + item?._id}>
                                    {item?.nftName}
                                  </Link>
                                </h5>
                                <div className="tags">{item?.chain}</div>
                              </div>
                              <div className="meta-info">
                                <div className="author">
                                  <a
                                    href={
                                      "/user?id=" + item?.creators[0]?.walletAddress
                                    }
                                  >
                                    <div className="avatar cursor-pointer avatar__rounded sc-author-box p-0">
                                      <div className="author-avatar m-0 w-100 h-100">
                                        {console.log('cccccccc', item)}
                                        <img
                                          src={props?.recentAddTokens[index]?.creators[0]?.picture}
                                          alt="Axies"
                                          className="verified___badge"
                                        />
                                        {item?.verified ? (
                                          <div className="badge">
                                            <i className="ripple"></i>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </a>
                                  <div className="info">
                                    {console.log('sdfasldfjlasdfjlas', index, props, props?.recentAddTokens[index]?.creators[0]?.userFullName)}
                                    <span>Creator</span>
                                    <h6>
                                      {" "}
                                      <a
                                        href={
                                          "/user?id=" +
                                          item?.creators[0]?.walletAddress
                                        }
                                      >
                                        {props?.recentAddTokens[index]?.creators[0]?.userFullName
                                          ? props?.recentAddTokens[index]?.creators[0]?.userFullName
                                          : (item?.creators[0]?.walletAddress || item?.creatorAddress)?.slice(
                                            0,
                                            12
                                          )}
                                      </a>{" "}
                                    </h6>
                                  </div>
                                </div>
                                {console.log('goood', item, item?.creators[0]?.userFullName)}
                                {item?.lastPrice ? (
                                  <div className="price">
                                    <span>Last Price</span>
                                    <h5> {parseFloat(item?.lastPrice)?.toFixed(4)}</h5>
                                  </div>
                                ) : <div className="price">
                                  <span>Sale Price</span>
                                    <h5> {parseFloat(item?.salePrice)?.toFixed(4)}</h5>
                                </div>}
                              </div>
                            </div>
                          )}

                        </>
                      ))
                    }
                  </>
                )
              })}

            </div>
          ) : (
            <div className="no_item_display">
              <img src="\assets\no-display.png" alt="img" className="img-fluid" />
              <h5>No Items To Display</h5>
            </div>
          )}
          {props.forRent || (FilToks.length > 8 ? (
            <>
             { show === FilToks?.length ||
              <div className="col-md-12 wrap-inner load-more text-center">
                <button
                  onClick={() => ToShow()}
                  className="sc-button loadmore fl-button pri-3"
                >
                  <span>
                    {show === FilToks?.length
                      ? "NO MORE TO SHOW"
                      : "LOAD MORE"}
                  </span>
                </button>
              </div>}
            </>
          ) : (
            ""
          ))}
        </div>
        <CardModal show={modalShow} onHide={() => setModalShow(false)} />
      </Fragment>
      {props?.owned && bulkTrans?.length > 0 ? (
        <div className="Transfer-nfts-buttons-wrapper">
          <Link to={"/transferitems"}>
            <button className="tans-dark-btn">
              {" "}
              <img
                src={whiteTransBtn}
                alt="whiteTransBtn"
              />{" "}
              Transfer
            </button>
          </Link>
          <button onClick={() => RemoveItems()} className="tans-light-btn">
            {" "}
            <img src={crossBtn} alt="crossBtn" /> Cancel
          </button>
        </div>
      ) : null}

      {/* //lent modal */}
      <div className="modal-offer">
        <div
          className="modal fade offeereed1 p-0"
          id="offermodal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content gcyctycytc">
              <div className="modal-header unknown-header">
                <h5 className="modal-title uk111u" id="exampleModalLabel">
                  Make an Offer
                </h5>
                <button
                  type="button"
                  className="unknown-image"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="\asssests\layer122.png"
                    alt="img"
                    className="img-fluid"
                  />
                </button>
              </div>

              <div className="modal-body unknown-body">
                <div>
                  <label htmlFor="efkehakf" className="form-label unknown-label">
                    Rent Duration <p className="mb-3">{availabilityRange}</p>
                  </label>
                  <div className="input-group efkehakf">
                    <input
                      type="number"
                      value={duration2}
                      onChange={(e) => UpdateDuration(e.target.value)}

                      className="form-control unknown-input"
                      placeholder="Enter Rent Duration"
                      aria-describedby="button-addon2"
                    />
                  </div>
                </div>
                {/* <div>


                                  <label htmlFor="efkehakf" className="form-label unknown-label">
                                    Daily Rent Price ({rentItem?.chainId == "97" && "WBNB"}{rentItem?.chainId == "5" && "WETH"}{rentItem?.chainId == "80001" && "Matic"})
                                  </label>
                                  <div className="input-group efkehakf">
                                    <input
                                      type="number"
                                      value={rentItem?.rentOrder && rentItem.rentOrder[0]?.rentPricePerDay ? rentItem.rentOrder[0].rentPricePerDay : ''}
                                      onChange={(e) => UpdateRent(e.target.value)}
                                      min="0"
                                      step={0.01}
                                      className="form-control unknown-input"
                                      placeholder="Enter Daily Rent Price"
                                      aria-describedby="button-addon2"
                                      readOnly
                                    />
                                  </div>
                                </div>
                                <div>
                                  <label htmlFor="efkehakf" className="form-label unknown-label">
                                    Collateral ({rentItem?.chainId == "97" && "WBNB"}{rentItem?.chainId == "5" && "WETH"}{rentItem?.chainId == "80001" && "Matic"})
                                  </label>
                                  <div className="input-group efkehakf">
                                    <input
                                      type="number"
                                      value={rentItem?.rentOrder && rentItem.rentOrder[0]?.collateral ? rentItem.rentOrder[0].collateral : ''}
                                      onChange={(e) => UpdateCollateral(e.target.value)}
                                      min="0"
                                      step={0.01}
                                      className="form-control unknown-input"
                                      placeholder="Collateral"
                                      aria-describedby="button-addon2"
                                      readOnly
                                    />
                                  </div>
                                </div> */}

                <div className="main-outer-div">
                  <div className="sec-inner-div">
                    <h5 className="daily-rent">
                      Daily Rent
                    </h5>
                    <h3 className="ethh">
                      {rentItem?.rentOrder[0]?.rentPricePerDay} BNB
                    </h3>
                  </div>
                  <hr className="modalcente-line"></hr>
                  <div className="sec-inner-div">
                    <h5 className="daily-rent">
                      Total amount
                    </h5>
                    <h3 className="ethh">
                      {parseFloat(rentItem?.rentOrder[0]?.rentPricePerDay * duration2)?.toFixed(4)} BNB
                    </h3>
                  </div>
                </div>
                <div className="worry22">
                  <button disabled={duration < 1 || duration2 < 1 || props.loader} onClick={() => AddRentOffer()} className="btn21btn61" >
                    Rent Now
                  </button>
                  <a href="" data-dismiss="modal"
                    aria-label="Close" className="btn21btn61">
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default ExploreItem;
