import { create } from "ipfs-http-client";
const projectId = '2E6wz7GkHGNj2VCFy7gyPnayjGj'
const projectSecret = '1063222031887d57279e615d89af9006'
const auth = 'Basic ' + Buffer.from(projectId + ':' + projectSecret).toString('base64')
const client = create({
    host: 'ipfs.infura.io',
    port: 5001,
    protocol: 'https',
    headers: {
        authorization: auth
    }
})

export const IpfsStorage = async (file) => {
  try {
    const addImage = await client.add(file)
    const imageUrl = `https://gateway.ipfs.io/ipfs/${addImage.path}`;
    return imageUrl;
  } catch (error) {
    // console.log('Error uploading file: ', error)
  }
}

export const AddFolder = async (file) => {
  try {
    const addImage = await client.addAll(file);
    const imageUrl = `ipfs://${addImage.path}`;
    return imageUrl;
  } catch (error) {
    // console.log('Error uploading file: ', error)
  }
}

const ipfs = create({
  host: 'ipfs.infura.io',
  port: 5001,
  protocol: 'https'
});

export function addFolder(
  folderPath
) {
  const files = folderPath;

  ipfs.add(files, { pin: true })
      .then(result => {
          // console.log(result)
          const rootItem = `/ipfs/${result.cid}`;
          // console.log("rootItem",rootItem)
      })
      .catch(error => {
          console.error(error)
      });
}
