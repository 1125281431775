import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./NFTComparison.scss";
import itemImg from "../../assets/images-item.jpg";
import crossIcon from "../../assets/crossIcon.svg";
import addIcon from "../../assets/add-icon.svg";
import externalLink from "../../assets/external-link.svg";
import modalCrossBtnIcon from "../../assets/modal-cross-btn-icon.svg";
import Loader from "../../hooks/loader";
import { Api_URL } from "../../utils/ApiURL";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { toast } from "react-toastify";

const NFTComparison = () => {
  const [loader, setLoader] = useState(false);
  const [item, setItem] = useState([]);
  const [collection, setCollection] = useState([]);
  const [userAccount, setUserAccount] = useState([]);
  const [search, setSearch] = useState("");
  const [id, setId] = useState(null);
  const [comNfts, setComNfts] = useState([])
  console.log('lasjfdl', comNfts)
  const GetNft = (id) => {
    window.$('#exampleModal').modal('hide')
    if (id !== null) {
      setId(id);
      setLoader(true);
      var qs = require("qs");
      var data = qs.stringify({
        _id: id,
      });
      var config = {
        method: "post",
        url: `${Api_URL}/token/getTokenById`,
        headers: {
          // 'Authorization': `Bearer ${auth}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          setLoader(false);
          setSearch('')
          // setNft(response.data.token);
          // setUser(response.data.owner);
          // setOrder(response.data.order);
          // setCollection(response.data?.collection);
          // setOwner(response.data.creator);
          if (comNfts?.length === 0) {
            setComNfts((pre) => [...pre, response?.data])
          } else {
            let res = comNfts?.filter((item, id) => {
              return item?.token?._id === response?.data?.token?._id
            })
            if (res?.length > 0) {
              toast.info('NFT already selected')
            } else {
              setComNfts((pre) => [...pre, response?.data])
            }
          }

          // let token = response.data.token._id;
          // let dumArr = response.data.token.likedBy;
          // let Find = dumArr.find((e) => {
          //   return e.address === account?.toLowerCase()?.toString();
          // })
          // if (Find) {
          //   setLiked(true);
          // }
        })
        .catch(function (error) {
          setSearch('')
          setLoader(false);
          console.log(error);
        });
    }
  }
  const handleSearch = async (e) => {
    var url = e.target.value;
    var PREFIX = ".";
    for (var i = 0; i < url.length; i++) {
      if (url.startsWith(PREFIX)) {
        url = url.slice(PREFIX.length);
      }
    }
    
    if (url === "") {
      setSearch("");
      setCollection([]);
      setItem([]);
      // setitemlength(2)
      // setitemlength2(2)
      // setitemlength3(2)
      setUserAccount([]);
      // setToggle(false)
      setLoader(false);
    } else {
      setSearch(url);
      // setToggle(true)
      // setLoader(true);
      axios
        .post(`${Api_URL}/collection/search`, { search: url.toLowerCase() })
        .then((res) => {
          // console.log("response we get search", res);
          var data2 = res.data.data;
          setCollection(data2[0].collection);
          setItem(data2[1].token);
          setUserAccount(data2[2].user);
          setLoader(false);
        })
        .catch((err) => {
          return err;
        });
    }
  };
  const removeItem = (id) => {
    let newData = comNfts.filter((item) => {
      return item?.token?._id !== id
    })
    setComNfts(newData)
  }
  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("id");
    if (id) {
      GetNft(id)
    }

  }, [])

  return (
    <>
      {loader && <Loader />}
      <Header />
      <section>
        <div className="nft-comparison-main-wraper">
          <div className="nft-comparison-header">
            <h2>NFT Comparison</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum
              obcaecat.
            </p>
          </div>
          <div className="nft-comparison-body nft-comparison-add-cards-wrapper">
            <div className="row m-0 w-100">
              <div className="col-3 empty-column-space"></div>
              {comNfts?.slice(0, 4).map((item) => {
                return (
                  <div className="col-xl-2 col-md-2 col-sm-3 pr-0 col-6 column-width-on-mobile">
                    <div className="nft-comparison-add-cards-item">
                      {comNfts?.length > 1 &&
                        <img
                          src={crossIcon}
                          alt="crossIcon"
                          className="crossIconImg"
                          onClick={() => removeItem(item?.token?._id)}
                        />
                      }
                      <img src={item?.token?.image} alt="itemImg" className="card-item-img" />
                    </div>
                  </div>
                )
              })}
              {/* <div className="col-xl-2 col-md-2 col-sm-3 col-6 pr-0 column-width-on-mobile">
                <div className="nft-comparison-add-cards-item">
                  <img
                    src={crossIcon}
                    alt="crossIcon"
                    className="crossIconImg"
                  />
                  <img src={itemImg} alt="itemImg" className="card-item-img" />
                </div>
              </div>
              <div className="col-xl-2 col-md-2 col-sm-3 pr-0 column-width-on-mobile last-view-nft-item">
                <div className="nft-comparison-add-cards-item">
                  <img
                    src={crossIcon}
                    alt="crossIcon"
                    className="crossIconImg"
                  />
                  <img src={itemImg} alt="itemImg" className="card-item-img" />
                </div>
              </div> */}
              {comNfts?.length > 3 ||
                <div className="col-xl-2 col-md-3 col-sm-3 column-width-on-mobile">
                  <div
                    className="nft-comparison-add-cards-item add-item-card"
                    type="button"
                    //   class="btn btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModal"
                    onClick={()=>setSearch('')}
                  >
                    <img src={addIcon} alt="addIcon" />
                    <p>Add another NFT</p>
                  </div>
                </div>
              }
            </div>
          </div>
          <div className="nft-comarison-table-wrapper">
            <div className="row m-0 nft-comarison-table-row tbl-name-row">
              <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                <p>Name</p>
              </div>
              {comNfts?.map((item) => {
                return (
                  <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                    <p>{item?.token?.nftName}</p>
                  </div>
                )
              })}
              <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-empty-column"></div>
            </div>
            <div className="row m-0 nft-comarison-table-row tbl-name-row">
              <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                <p>Likes</p>
              </div>
              {comNfts?.map((item) => {
                return (
                  <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                    <p>{item?.token?.likedBy?.length}</p>
                  </div>
                )
              })}
              <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-empty-column"></div>
            </div>
            <div className="row m-0 nft-comarison-table-row tbl-name-row">
              <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                <p>Views</p>
              </div>
              {comNfts?.map((item) => {
                return (
                  <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                    <p>{item?.token?.views}</p>
                  </div>
                )
              })}
              <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-empty-column"></div>
            </div>
            <div className="row m-0 nft-comarison-table-row tbl-collection-row">
              <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                <p>Collection</p>
              </div>
              {comNfts?.map((item) => {
                return (
                  <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                    <p className="externalLink">{item?.collection?.name}</p>
                  </div>
                )
              })}
            </div>
            <div className="row m-0 nft-comarison-table-row tbl-owner-row">
              <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                <p>Owner</p>
              </div>
              {comNfts?.map((item) => {
                return (
                  <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                    <p>{item?.owner?.userFullName}</p>
                  </div>
                )
              })}
            </div>
            <div className="row m-0 nft-comarison-table-row tbl-price-row">
              <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                <p>Price</p>
              </div>
              {comNfts?.map((item) => {
                return (
                  <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                    <p>{item?.token?.salePrice}</p>
                  </div>
                )
              })}
            </div>
            <div className="row m-0 nft-comarison-table-row tbl-hightest-bid-row">
              <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                <p>Highest Bid</p>
              </div>
              {comNfts?.map((item) => {
                return (
                  <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                    <p>{item?.token?.metaData?.price || 0}</p>
                  </div>
                )
              })}
            </div>
            <div className="row m-0 nft-comarison-table-row tbl-contract-address-row">
              <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                <p>Contract Address</p>
              </div>
              {comNfts?.map((item) => {
                return (
                  <div className="col-xl-2 text-center col-md-2 col-sm-3 nft-comarison-table-column">
                    <a target="blank" href={`https://testnet.bscscan.com/address/${item?.token?.contractAddress}`} className="externalLink">
                      <span>{item?.token?.contractAddress?.slice(0, 3)}...{item?.token?.contractAddress?.slice(39, 45)}</span>{" "}
                      <img
                        src={externalLink}
                        alt="externalLink"
                        className="externalLinkImg"
                      />{" "}
                    </a>
                  </div>
                )
              })}
              <div className="col-2 nft-comarison-table-empty-column"></div>
            </div>
            <div className="row m-0 nft-comarison-table-row tbl-token-id-row">
              <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                <p>Token Standard</p>
              </div>
              {comNfts?.map((item) => {
                return (
                  <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                    <p>{item?.collection?.tokenStandard}</p>
                  </div>
                )
              })}
            </div>
            <div className="row m-0 nft-comarison-table-row tbl-token-standard-row">
              <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                <p>Chain</p>
              </div>
              {comNfts?.map((item) => {
                return (
                  <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                    <p>{item?.token?.chain}</p>
                  </div>
                )
              })}
            </div>
            <div className="row m-0 nft-comarison-table-row tbl-chain-row">
              <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                <p>Creator Earnings</p>
              </div>
              {comNfts?.map((item) => {
                return (
                  <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                    <p>{item?.collection?.percentageFee || 0} %</p>
                  </div>
                )
              })}

            </div>
            {/* <div className="row m-0 nft-comarison-table-row tb-creator-earning-row">
                    <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                        <p>Format</p>
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                        <p>Name</p>
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-3 pl-5 nft-comarison-table-column">
                        <p>Name</p>
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-3 pl-5 nft-comarison-table-column up-nft-comarison-table-empty-column">
                        <p>Name</p>
                      </div>
                      <div className="col-2 nft-comarison-table-empty-column"></div>
                    </div>
                    <div className="row m-0 nft-comarison-table-row tbl-format-row">
                      <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                        <p>Resolution</p>
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                        <p>Name</p>
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-3 pl-5 nft-comarison-table-column">
                        <p>Name</p>
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-3 pl-5 nft-comarison-table-column up-nft-comarison-table-empty-column">
                        <p>Name</p>
                      </div>
                      <div className="col-2 nft-comarison-table-empty-column"></div>
                    </div>
                    <div className="row m-0 nft-comarison-table-row tbl-format-row">
                      <div className="col-xl-3 col-md-3 col-sm-3 nft-comarison-table-column">
                        <p>Resolution</p>
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-3 nft-comarison-table-column">
                        <p>Name</p>
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-3 pl-5 nft-comarison-table-column">
                        <p>Name</p>
                      </div>
                      <div className="col-xl-2 col-md-2 col-sm-3 pl-5 nft-comarison-table-column up-nft-comarison-table-empty-column">
                        <p>Name</p>
                      </div>
                      <div className="col-2 nft-comarison-table-empty-column"></div>
                    </div> */}
          </div>
        </div>
      </section >
      <Footer />

      {/* <!-- Modal --> */}
      <div
        class="modal fade modal-dialog-centered comparisopn-nft-modal-list"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Add another NFT
              </h5>
              <button
                type="button"
                className="close modal-close-btn"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src={modalCrossBtnIcon} alt="modalCrossBtnIcon" />
                {/* <span aria-hidden="true">&times;</span> */}
              </button>
            </div>
            <div class="modal-body">
              <div className="search-bar-comp-nft"></div>
              <div className="dropdown comparison-nft-list-wrapper">
                {console.log('first search', search)}
                <input
                  type="search"
                  placeholder="Search Bluemoon"
                  // className="cttyvtycvt"
                  className="cttyvtycvt  dropdown-toggle"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  autocomplete="off"
                  onChange={(e) =>
                    handleSearch(e) }
                  value={search}
                />
                {
                  search != "" ? <span className="clear-icon" onClick={() => setSearch("")}> <img src="\assets\close-icon.svg" alt="img" className="img-fluid" /> </span> : ""
                }

                {/* {collection || userAccount || item ? */}

                <div
                  className="dropdown-menu searchbar-menu earchbar-menu-fk border-0 bg-transparent"
                  aria-labelledby="dropdownMenuButton"
                  style={{ background: 'transparent!important', boxShadow: '0 0 !important' }}
                >
                  <div className="parent-main">
                    <div className="main">
                      <h5 className="main-heading">Items</h5>
                      <a className="dropdown-item">
                        {item?.length >= 1 ? (
                          <>
                            {item?.map((data) => (
                              // <a href={"/item-details?id=" + data?._id}>
                              <div onClick={() => GetNft(data?._id)} className="parent">
                                <div className="left">
                                  <img
                                    src={data?.image}
                                    alt="img"
                                    className="img-fluid mr-3"
                                    style={{
                                      borderRadius: "100%",
                                      height: "40px",
                                      width: "40px",
                                    }}
                                  />
                                </div>
                                <div className="right">
                                  <h6>{data?.nftName}</h6>
                                  {/* <p>azukkiglobal#3</p> */}
                                </div>
                              </div>
                              // </a>
                            ))}
                          </>
                        ) : (
                          <p>No item found</p>
                        )}
                      </a>
                    </div>
                  </div>
                  {/* <div className="input-field">
            <input type="text" placeholder="Press Enter to search all items" />
          </div> */}
                </div>
                {/* <div className="comparisopn-nft-list-modal-item">
                  <div className="comparisopn-nft-list-item-modal-left">
                    <img src={itemImg} alt="itemImg" />
                    <div className="comparisopn-nft-list-content">
                      <p>name</p>
                      <h6>BAYC #2342</h6>
                    </div>
                  </div>
                  <div className="comparisopn-nft-list-content">
                    <p>Collection</p>
                    <h6>Bored Ape Yacht Club</h6>
                  </div>
                </div>
                <div className="comparisopn-nft-list-modal-item">
                  <div className="comparisopn-nft-list-item-modal-left">
                    <img src={itemImg} alt="itemImg" />
                    <div className="comparisopn-nft-list-content">
                      <p>name</p>
                      <h6>BAYC #2342</h6>
                    </div>
                  </div>
                  <div className="comparisopn-nft-list-content">
                    <p>Collection</p>
                    <h6>Bored Ape Yacht Club</h6>
                  </div>
                </div>
                <div className="comparisopn-nft-list-modal-item">
                  <div className="comparisopn-nft-list-item-modal-left">
                    <img src={itemImg} alt="itemImg" />
                    <div className="comparisopn-nft-list-content">
                      <p>name</p>
                      <h6>BAYC #2342</h6>
                    </div>
                  </div>
                  <div className="comparisopn-nft-list-content">
                    <p>Collection</p>
                    <h6>Bored Ape Yacht Club</h6>
                  </div>
                </div>
                <div className="comparisopn-nft-list-modal-item">
                  <div className="comparisopn-nft-list-item-modal-left">
                    <img src={itemImg} alt="itemImg" />
                    <div className="comparisopn-nft-list-content">
                      <p>name</p>
                      <h6>BAYC #2342</h6>
                    </div>
                  </div>
                  <div className="comparisopn-nft-list-content">
                    <p>Collection</p>
                    <h6>Bored Ape Yacht Club</h6>
                  </div>
                </div>
                <div className="comparisopn-nft-list-modal-item">
                  <div className="comparisopn-nft-list-item-modal-left">
                    <img src={itemImg} alt="itemImg" />
                    <div className="comparisopn-nft-list-content">
                      <p>name</p>
                      <h6>BAYC #2342</h6>
                    </div>
                  </div>
                  <div className="comparisopn-nft-list-content">
                    <p>Collection</p>
                    <h6>Bored Ape Yacht Club</h6>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NFTComparison;
