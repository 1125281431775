import React, {useState} from 'react'
import Footer from '../../components/footer/Footer'
import Header from '../../components/header/Header'
import "./placebid.scss"
import { Link } from 'react-router-dom';
import Countdown from "react-countdown";
import { Tab, Tabs, TabList, TabPanel  } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import liveAuctionData from '../../assets/fake-data/data-live-auction';
import LiveAuction from '../../components/layouts/LiveAuction';
import img1 from '../../assets/images/avatar/avt-3.jpg'
import img2 from '../../assets/images/avatar/avt-11.jpg'
import img3 from '../../assets/images/avatar/avt-1.jpg'
import img4 from '../../assets/images/avatar/avt-5.jpg'
import img5 from '../../assets/images/avatar/avt-7.jpg'
import img6 from '../../assets/images/avatar/avt-8.jpg'
import img7 from '../../assets/images/avatar/avt-2.jpg'
import imgdetail1 from '../../assets/images/box-item/images-item-details.jpg'
const Placebid = () => {
    const [dataHistory] = useState(
        [
            {
                img: img1,
                name:"Mason Woodward",
                time: "8 hours ago",
                price: "4.89 ETH",
                priceChange: "$12.246"
            },
            {
                img: img2,
                name:"Mason Woodward",
                time: "at 06/10/2021, 3:20 AM",
                price: "4.89 ETH",
                priceChange: "$12.246"
            },
            {
                img: img3,
                name:"Mason Woodward",
                time: "8 hours ago",
                price: "4.89 ETH",
                priceChange: "$12.246"
            },
            {
                img: img4,
                name:"Mason Woodward",
                time: "8 hours ago",
                price: "4.89 ETH",
                priceChange: "$12.246"
            },
            {
                img: img5,
                name:"Mason Woodward",
                time: "8 hours ago",
                price: "4.89 ETH",
                priceChange: "$12.246"
            },
            {
                img: img6,
                name:"Mason Woodward",
                time: "8 hours ago",
                price: "4.89 ETH",
                priceChange: "$12.246"
            }
        ]
    )
    return (
        <>
        <div className='item-details'>
            <Header />
            <div className="tf-section tf-item-details">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-xl-6 col-md-12">
                            <div className="content-left">
                                <div className="media">
                                    <img src={imgdetail1} alt="Axies" />
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-md-12">
                            <div className="content-right">
                                <div className="sc-item-details">
                                    <h2 className="style2">“The Fantasy Flower illustration ” </h2>
                                    <div className="meta-item">
                                        <div className="left">
                                            <span className="viewed eye">225</span>
                                            <span to="/login" className="liked heart wishlist-button mg-l-8"><span className="number-like">100</span></span>
                                        </div>
                                        <div className="right">
                                            <Link to="#" className="share"></Link>
                                            <Link to="#" className="option"></Link>
                                        </div>
                                    </div>
                                    <div className="client-infor sc-card-product">
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={img6} alt="Axies" />
                                                </div>
                                                <div className="info">
                                                    <span>Owned By</span>
                                                    <h6> <Link to="/author-02">Ralph Garraway</Link> </h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="meta-info">
                                            <div className="author">
                                                <div className="avatar">
                                                    <img src={img7} alt="Axies" />
                                                </div>
                                                <div className="info">
                                                    <span>Create By</span>
                                                    <h6> <Link to="/author-02">Freddie Carpenter</Link> </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Habitant sollicitudin faucibus cursus lectus pulvinar dolor non ultrices eget.
                                        Facilisi lobortisal morbi fringilla urna amet sed ipsum vitae ipsum malesuada.
                                        Habitant sollicitudin faucibus cursus lectus pulvinar dolor non ultrices eget.
                                        Facilisi lobortisal morbi fringilla urna amet sed ipsum</p>
                                    <div className="meta-item-details style2">
                                        <div className="item meta-price">
                                            <span className="heading">Current Bid</span>
                                            <div className="price">
                                                <div className="price-box">
                                                    <h5> 4.89 ETH</h5>
                                                    <span>= $12.246</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item count-down">
                                            <span className="heading style-2">Countdown</span>
                                            <Countdown date={Date.now() + 500000000}>
                                                <span>You are good to go!</span>
                                            </Countdown>
                                        </div>
                                    </div>
                                    <a href="#" className="sc-button loadmore style bag fl-button pri-3" data-toggle="modal" data-target="#exampleModal-place-bid"><span>Place a bid</span></a>
                                    <div className="flat-tabs themesflat-tabs">
                                    <Tabs>
                                        <TabList>
                                        <Tab>Bid History</Tab>
                                        <Tab>Details</Tab>
                                        <Tab>History</Tab>
                                        </TabList>
                                        <TabPanel>
                                            <ul className="bid-history-list">
                                            {
                                                dataHistory.map((item, index) => (
                                                    <li key={index} item={item}>
                                                        <div className="content">
                                                            <div className="client">
                                                                <div className="sc-author-box style-2">
                                                                    <div className="author-avatar">
                                                                        <Link to="#">
                                                                            <img src={item.img} alt="Axies" className="avatar" />
                                                                        </Link>
                                                                        <div className="badge"></div>
                                                                    </div>
                                                                    <div className="author-infor">
                                                                        <div className="name">
                                                                            <h6><Link to="/author-02">{item.name} </Link></h6> <span> place a bid</span>
                                                                        </div>
                                                                        <span className="time">{item.time}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="price">
                                                                <h5>{item.price}</h5>
                                                                <span>= {item.priceChange}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                            </ul>
                                        </TabPanel>
                                        <TabPanel>
                                            <ul className="bid-history-list">
                                                    <li>
                                                        <div className="content">
                                                            <div className="client">
                                                                <div className="sc-author-box style-2">
                                                                    <div className="author-avatar">
                                                                        <Link to="#">
                                                                            <img src={img1} alt="Axies" className="avatar" />
                                                                        </Link>
                                                                        <div className="badge"></div>
                                                                    </div>
                                                                    <div className="author-infor">
                                                                        <div className="name">
                                                                            <h6> <Link to="/author-02">Mason Woodward </Link></h6> <span> place a bid</span>
                                                                        </div>
                                                                        <span className="time">8 hours ago</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                            </ul>
                                        </TabPanel>
                                        <TabPanel>
                                        <ul className="bid-history-list">
                                            {
                                                dataHistory.map((item, index) => (
                                                    <li key={index} item={item}>
                                                        <div className="content">
                                                            <div className="client">
                                                                <div className="sc-author-box style-2">
                                                                    <div className="author-avatar">
                                                                        <Link to="#">
                                                                            <img src={item.img} alt="Axies" className="avatar" />
                                                                        </Link>
                                                                        <div className="badge"></div>
                                                                    </div>
                                                                    <div className="author-infor">
                                                                        <div className="name">
                                                                            <h6><Link to="/author-02">{item.name} </Link></h6> <span> was listed</span>
                                                                        </div>
                                                                        <span className="time">{item.time}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            }
                                            </ul>
                                        </TabPanel>
                                    </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section className='placeabid-modals'>
            <div className="modal fade" id="exampleModal-place-bid" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Place a bid</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <img src='\asssests\layer1.svg' className='img-fluid' />
                                </button>
                            </div>
                            <div className="modal-body">
                                <p className='para-modal'>You are about to place a bid for<span className='span3'>#03 The <br/> Destroyer</span>  from <span className='span3'>Monsters</span>  collection.</p>
                                <h6>Your bid</h6>
                                <div className="input-fieldcard">
                                    <div className='rightcontentmodal'>
                                        <h6>Enter yor bid</h6>
                                        <h6 className='ethhead'>ETH</h6>
                                    </div>
                                </div>
                                <div className='balance mt-5'>
                                    <p className='bhead'>Your balance</p>
                                    <p className='bhead1'>3.20 ETH</p>
                                </div>
                                <div className='balance'>
                                    <p className='bhead'>Service fee</p>
                                    <p className='bhead1'>0.025 ETH</p>
                                </div>
                                <div className='brdr'></div>
                                <div className='balance'>
                                    <p className='bhead'>You will pay</p>
                                    <p className='bhead1'>0.00 ETH</p>
                                </div>
                                <div className='modbutton'>
                                    <button className='btn9' data-toggle="modal" data-target="#exampleModal-place-bid-loading">Place a Bid</button>
                                    <button className='btn10' data-toggle="modal" data-target="#exampleModalloadingmodal" >Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='loading-modal'>
                    <div className="modal fade" id="exampleModal-place-bid-loading" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Place a bid</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <img src='\asssests\layer1.svg' className='img-fluid' />
                                    </button>
                                </div>
                                <div className="modal-body loadermodal-p">
                                    <div className='modal-img text-center'>
                                        <img src='\asssests\Ellipse 2.png' className='img-fluid' />
                                    </div>
                                    <p className='whitemodal text-center'>loading...</p>
                                    <p className='grayemodal text-center'>Lorem ipsum dolor sit amet, consectetur.</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='loading-success'>
                    <div className="modal fade" id="exampleModalloadingmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLabel">Place a bid</h5>
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <img src='\asssests\layer1.svg' className='img-fluid' />
                                    </button>
                                </div>
                                <div className="modal-body loadermodal-p">
                                    <div className='modal-img text-center'>
                                        <img src='\bluemoon-nft\create\Frame9313.svg' className='img-fluid' />
                                    </div>
                                    <p className='whitemodal text-center mt-4'>Your Bid has been successfully submitted</p>
                                    <div className='successbutton'>
                                    <button className='successbutton'>Okay</button>
                                </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <LiveAuction data={liveAuctionData} />
            <Footer />
        </div>
        </>
    )
}

export default Placebid