import { useCallback, useEffect, useState } from "react";
import useWeb3 from "../useWeb3";
import { getFactContract } from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";
import { useWeb3React } from "@web3-react/core";

const GetApproved = () => {
    const web3 = useWeb3();
    const [contractAddress, setContractAddress] = useState(null);
    const context = useWeb3React();
    const { chainId } = context;

    useEffect(() => {
        if (chainId === 97) {
            setContractAddress(Environment.marketPlaceContract);
        } else if (chainId === 4) {
            setContractAddress(Environment.marketPlaceContract);
        } else if (chainId === 43114) {
            setContractAddress(Environment.marketPlaceContract);
        } else if (chainId === 80001) {
            setContractAddress(Environment.marketPlaceContract);
        }
    }, [chainId])

    const ApproveMe = useCallback(
        async (account, address) => {
            if (contractAddress) {
                try {
                    // console.log("asasasas", account, address, contractAddress)
                    const contract = getFactContract(address, web3);
                    const gas = await contract.methods
                        .setApprovalForAll(contractAddress, true)
                        .estimateGas({ from: account });
                    let gasPrice = await web3.eth.getGasPrice();
                    const details = await contract.methods
                        .setApprovalForAll(contractAddress, true)
                        .send({
                            from: account,
                            gas,
                            gasPrice: gasPrice,
                        })
                    // console.log('saldfkj', details)
                    return details;
                } catch (error) {
                    // console.log(error)
                    throw (error)
                }
            }
        },
        [web3, contractAddress]
    );
    return { ApproveMe: ApproveMe };
};

export default GetApproved;