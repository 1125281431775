import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Link, useParams } from "react-router-dom";
import Filter from "../Filter/Filter";
import ExploreItem from "../../components/layouts/explore-04/ExploreItem";
import CollectionCard from "../CollectionCardNew/CollectionCard";
import dummyProfile from "../../assets/dummyProfile.png";
import dummyImg from "../../assets/dummyImg.png";
import { useNavigate } from "react-router-dom";
import { Api_URL } from "../../utils/ApiURL";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { soliditySha3 } from "web3-utils";
import { toast } from "react-toastify";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Loader from "../../hooks/loader";
import Activity01 from "../Activity01";
import Cofilter from "./Cofilter";
import { getErc20TokenContract, getSwapContract } from '../../utils/contractHelpers'
import useWeb3 from "../../hooks/useWeb3";
import Environment from "../../utils/Environment";
import "./myprofile.scss";
import whiteTransBtn from "../../assets/images/svg/white-trans-btn.svg";
import clickDosts from "../../assets/click-dosts.svg";
import createNftCollIcon from "../../assets/createNftColl-icon.svg";
import importNftColl from "../../assets/import-nft-coll.svg";
import { Dropdown, Modal } from "react-bootstrap";
import AmountApprove from "../../hooks/dataSenders/approveRenting";
import RemoveRentNft from "../../hooks/dataSenders/removeRent"

const Myprofille = () => {


  const [show3, setShow3] = useState(false);
const [tabState, setTabState] = useState('onSale')
console.log('taaaab', tabState)
  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);
  let navigate = useNavigate();
  const { account } = useWeb3React();
  const [data, setData] = useState(null);
  const [token, setToken] = useState();
  const [copied, setCopied] = useState(false);
  const [loader, setLoader] = useState(false);
  const [collectionByCreatorAddress, setCollectionByCreatorAddress] =
    useState(null);
  const [onsale, setOnsale] = useState([]);
  console.log('firsdsaft', onsale)
  const [followers, getFollowers] = useState();
  const [createdCollection, setCreatedCollection] = useState([]);
  const context = useWeb3React();
  const { chainId } = context;
  const web3 = useWeb3()
  const [ownedCollection, setOwnedCollection] = useState([]);
  const [following, getFollowing] = useState();
  const [offerRecieved, setOfferRecieved] = useState();
  const [offers, setOffers] = useState();
  const [ethUsd, setEthUsd] = useState(null);
  const [offerSent, setOfferSent] = useState();
  const [offerSent2, setOfferSent2] = useState();
  const [id, setId] = useState(null);
  const [address, setAddress] = useState();
  const [follow, setFollow] = useState(false);
  const [showBtn, setShowBtn] = useState(false);
  const [justOwned, setJustOnwnd] = useState(false);
  const [timeFilts, setTimeFilts] = useState("Recently added");
  const [onsale2, setOnsale2] = useState(false);
  const [onauction, setOnauction] = useState(false);
  const [sprice, setSprice] = useState("0");
  const [lprice, setLprice] = useState("10000000");
  const [search, setSearch] = useState("");
  const [swapOffers, setSwapOffers] = useState()
  const [swapSent, setSwapSent] = useState()
  const [lendStatus, setLendStatus] = useState('lent')
  const { UserApprove } = AmountApprove();
  const { rentNfts } = RemoveRentNft()
  const [lentBorrowedData, setLentBorrowedData] = useState([])
  const { subPath } = useParams();
  console.log('subPath', subPath, tabState)
  const [meta, setMeta] = useState({
    price: "0",
    startTime: new Date().getTime(),
    endTime: null,
    method: "fixed"
  })
  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("id");
    if (id !== null) {
      setId(id);
      setAddress(id);
    }
  }, []);

  useEffect(() => {
    if (account && token) {
      getFollowingDetails();
      getFollowersDetails();
    }
  }, [account, token]);

  useEffect(() => {
    axios
      .get("https://min-api.cryptocompare.com/data/price?fsym=BNB&tsyms=USD")
      .then((res) => {
        setEthUsd(res.data);
      });
  }, [offerRecieved]);

  const getOfferSent = async () => {
    let tok = localStorage.getItem("accessTok");
    setLoader(true);

    var data = JSON.stringify({
      bidderAddress: account.toLowerCase(),
    });
    var config = {
      method: "post",
      url: `${Api_URL}/bid/getBidsByBidder`,
      headers: {
        Authorization: "Bearer " + tok,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let dumArr = [];
        for (let i of response?.data?.bid) {
          if (i.bids?.length > 0) {
            dumArr.push(i);
          }
        }
        setOfferSent(dumArr);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  };

  const getFollowingDetails = async () => {
    setLoader(true);
    await axios
      .post(
        `${Api_URL}/user/getFollowingAndDetails`,
        {
          walletAddress: account,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      )
      .then((res) => {
        let data2 = res?.data?.data?.detailsOfFollowing;
        getFollowing(data2);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        // console.log("getApi error", err);
      });
  };

  const getFollowersDetails = async () => {
    setLoader(true);
    await axios
      .post(
        `${Api_URL}/user/getFollowersAndDetails`,
        {
          walletAddress: account,
        },
        {
          Authorization: `Bearer ${token}`,
        }
      )
      .then((res) => {
        let data1 = res?.data?.data.detailsOfFollowers;
        getFollowers(data1);
        var dumArrey = [];
        for (let i of data1) {
          dumArrey.push(i.walletAddress);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        // console.log("getApi error", err);
      });
  };

  const getOwnedNft = async () => {
    setJustOnwnd(true);
    GetFilterContract();
    // var axios = require("axios");
    // setLoader(true);
    // var data = JSON.stringify({
    //   walletAddress: account,
    //   chainId: chainId?.toString(),
    // });
    // var config = {
    //   method: "post",
    //   url: `${Api_URL}/token/getOwnedTokensOfUserAndDetails`,
    //   headers: {
    //     Authorization: "Bearer " + token,
    //     "Content-Type": "application/json",
    //   },
    //   data: data,
    // };
    // axios(config)
    //   .then(function (response) {
    //     setOwnedCollection(response?.data?.data);
    //     let dumArr = response?.data?.data?.filter((i) => {
    //       return i?.metaData && i?.onSale;
    //     });
    //     setOnsale(dumArr);
    //     setLoader(false);
    //   })
    //   .catch(function (error) {
    //     setLoader(false);
    //     // console.log("getApi error", error);
    //   });
  };

  useEffect(() => {
    if (onsale2 || onauction) {
      GetFilterContract();
    } else {
      GetFilterContract();
    }
  }, [onsale2, onauction])

  useEffect(() => {
    if (account) {
      setToken(localStorage.getItem("accessToken"));
    }
  }, [account, localStorage.getItem("accessToken")]);

  useEffect(() => {
    if (account && chainId && token) {
      getCreatedNft();
      getOwnedNft();
    }
  }, [account, chainId, token]);

  const getCreatedNft = async () => {
    setLoader(true);
    await axios
      .post(`${Api_URL}/token/getCreatedTokensOfUserAndDetails`, {
        walletAddress: account,
        chainId: chainId?.toString(),
      })
      .then((res) => {
        setLoader(false);
        setCreatedCollection(res?.data?.data);
      })
      .catch((err) => {
        setLoader(false);
        // console.log("getApi error", err);
      });
  };

  const getUser = async () => {
    let data = null;
    if (id) {
      data = { walletAddress: id };
    } else {
      data = { walletAddress: account };
    }
    await axios
      .post(`${Api_URL}/user/getUser`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((response) => {
        let find = response?.data?.resultData?.followers?.find((r) => {
          return r.address?.toLowerCase() === account?.toLowerCase();
        });
        if (find) {
          setFollow(true);
        }
        setData(response.data);
      })
      .catch((err) => {
        // toast.error(err?.response?.data.msg, {
        //   position: "top-right",
        //   autoClose: 2000,
        // });
        console?.log(err?.response?.data.msg);
      });
  };

  const getCollectionByUser = async () => {
    const data = {
      creator: account?.toLowerCase()?.toString(),
    };
    await axios
      .post(`${Api_URL}/collection/getCollectionByCreatorAddress`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setCollectionByCreatorAddress(res?.data?.result);
      })
      .catch((err) => {
        toast.error(err?.response?.data.msg, {
          position: "top-right",
          autoClose: 2000,
        });
        console?.log(err?.response?.data.msg);
      });
  };

  const AcceptOffer = async (item) => {
    if (account) {
      if (true) {
        try {
          const contract = getErc20TokenContract(item?.contractAddress, web3);

          let res = await contract.methods.isApprovedForAll(account, Environment?.swap).call()
          // console.log('sdfasdfasd', res)
          if (!res) {
            let res2 = await contract.methods.setApprovalForAll(Environment?.swap, true).send({ from: account })
          }
          let so = item?.signature;
          so = so.substring(2)
          let or = '0x' + so.slice(0, 64);
          let os = '0x' + so.slice(64, 128);
          let ov = '0x' + so.slice(128, 130);
          var ts = item?.time;
          var unix_seconds = ((new Date(ts)).getTime()) / 1000;
          let soliditySha3Expected = null;
          if (chainId === 97) {
            soliditySha3Expected = soliditySha3(
              Environment?.swap,
              account?.toLowerCase(),
              item?.bidderAddress,
              meta.startTime,
              account?.toLowerCase(), //ref address in future will change
              0,
              0
            );
          }
          // else if (chainId === 4) {
          //   soliditySha3Expected = soliditySha3(
          //     Environment?.Eth.MarketContract,
          //     account?.toLowerCase(),
          //     web3.utils.toWei(nft?.metaData?.price.toString()),
          //     nft?.metaData?.startTime
          //   );
          // } else if (chainId === 80001) {
          //   soliditySha3Expected = soliditySha3(
          //     Environment?.Polygon.MarketContract,
          //     account?.toLowerCase(),
          //     web3.utils.toWei(nft?.metaData?.price.toString()),
          //     nft?.metaData?.startTime
          //   );
          // } else if (chainId === 43114) {
          //   soliditySha3Expected = soliditySha3(
          //     Environment?.Avax.MarketContract,
          //     account?.toLowerCase(),
          //     web3.utils.toWei(nft?.metaData?.price.toString()),
          //     nft?.metaData?.startTime
          //   );
          // }
          let signature = null;
          await web3.eth.personal.sign(soliditySha3Expected, account).then(async (res) => {
            signature = res;
            signature = signature.substring(2)
            let r = '0x' + signature.slice(0, 64);
            let s = '0x' + signature.slice(64, 128);
            let v = '0x' + signature.slice(128, 130);
            setLoader(true);
            // const minALLocation1 = new BigNumber(nft?.metaData?.price).multiply(new BigNumber(10).pow(18));
            let bidderNfts = {
              nft: [1],
              tokenIds: [1],
              amounts: [1],
              reqNft: [1],
              reqTokenIds: [1],
              reqTokenAmouts: [2]
            }
            let values1 = {
              r,
              s,
              v,
              time: unix_seconds,
              refAddress: account,
            }
            let values2 = {
              r: or,
              s: os,
              v: ov,
              time: unix_seconds,
              refAddress: account,
            }
            if (true) {
              // console.log('andar', item?.bidderAddress, bidderNfts, values1, values2)
              const contract2 = getSwapContract(Environment?.swap, web3);
              try {
                let swapCont = await contract2.methods.acceptSwapOffer(item?.bidderAddress, bidderNfts, values1, values2).send({ from: account })

              } catch (error) {
                // console.log(error)
              }
              // try {

              //   const res = await BuyMinte(account, values, image1, values2, values1, nft?.contractAddress, nft?.metaData?.startTime);
              //   if (res) {
              //     var data = JSON.stringify({
              //       "bidderAddress": account?.toLowerCase(),
              //       "sellerAddress": nft?.ownerAddress?.toLowerCase(),
              //       "contractAddress": nft?.contractAddress?.toString()?.toLowerCase(),
              //       "tokenID": nft?.tokenID?.toString(),
              //       "amount": nft?.metaData?.price,
              //       "chainId": nft?.chainId
              //     });

              //     var config = {
              //       method: 'post',
              //       url: `${Api_URL}/bid/acceptBid`,
              //       headers: {
              //         Authorization: "Bearer " + auth,
              //         'Content-Type': 'application/json'
              //       },
              //       data: data
              //     };

              //     axios(config)
              //       .then(function (response) {
              //         GetNft();
              //         GetTokenHistory();
              //         toast.success("Nft purchased successfully")
              //         setLoader(false);
              //         window.$("#buymodal").modal("hide")
              //         // setBids(response.data.bid);
              //       })
              //       .catch(function (error) {
              //         setLoader(false);
              //         // console.log(error);
              //       });
              //   }
              // } catch (err) {
              //   toast.error(err);
              //   setLoader(false);
              // }
            }
          }).catch((err) => {
            setLoader(false);
            throw err;
          });
        } catch (error) {
          await toast.error(error.message);
          await setLoader(false);
        }
      } else {
        toast.error("You are blocked from admin");
      }
    } else {
      toast.error("Connect your wallet")
    }
  }

  useEffect(() => {
    if (id) {
      getUser();
    }
  }, [id]);

  useEffect(() => {
    if (token) {
      getUser();
      getCollectionByUser();
    }
  }, [token, account]);

  const copiedHandle = () => {
    setCopied(true);
    setTimeout(
      function () {
        setCopied(false);
      }.bind(this),
      1500
    );
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {copied ? "copied" : "copy"}
    </Tooltip>
  );

  const Unfollow = async (e, id) => {
    setLoader(true);
    var data = JSON.stringify({
      walletAddress: account?.toLowerCase(),
      toFollow: e?.toLowerCase(),
    });
    var config = {
      method: "post",
      url: `${Api_URL}/user/removeFollower`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        let dumArr = following;
        let data2;
        data2 = dumArr?.filter((item) => {
          return item?._id !== id;
        });
        getFollowing(data2);
        getFollowersDetails();
        setLoader(false);
      })
      .catch(function (err) {
        toast.error(err?.response?.data.msg, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
  };

  const followAddress = async () => {
    setLoader(true);
    var data = JSON.stringify({
      walletAddress: account?.toLowerCase(),
      toFollow: address?.toLowerCase(),
    });
    var config = {
      method: "post",
      url:
        follow === false
          ? `${Api_URL}/user/addFollower`
          : `${Api_URL}/user/removeFollower`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setLoader(false);
        if (follow === true) {
          setFollow(false);
        } else {
          setFollow(true);
        }
      })
      .catch(function (err) {
        toast.error(err?.response?.data.msg, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
  };

  const followAddress2 = async (wallet) => {
    setLoader(true);
    var data = JSON.stringify({
      walletAddress: account?.toLowerCase(),
      toFollow: wallet?.toLowerCase(),
    });
    var config = {
      method: "post",
      url:
        follow === false
          ? `${Api_URL}/user/addFollower`
          : `${Api_URL}/user/removeFollower`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        getFollowersDetails();
        getFollowingDetails();
        setLoader(false);
      })
      .catch(function (err) {
        toast.error(err?.response?.data.msg, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
  };

  const getOfferRecieved = async () => {
    setLoader(true);

    var data = JSON.stringify({
      sellerAddress: account?.toLowerCase(),
    });
    var config = {
      method: "post",
      url: `${Api_URL}/bid/getBidByProfile`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let dumArr = [];
        for (let i of response?.data?.offersReceived) {
          if (i.bids?.length > 0) {
            dumArr.push(i);
          }
        }
        setOfferRecieved(dumArr);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  };

  const getMax = (val) => {
    if (val) {
      let v = val;
      v = v.sort(function (a, b) {
        return parseFloat(b.amount) - parseFloat(a.amount);
      });
      return v[0]?.amount?.toFixed(4);
    }
  };

  const getMaxUsd = (val) => {
    if (val) {
      let v = val;
      v = v.sort(function (a, b) {
        return parseFloat(b.amount) - parseFloat(a.amount);
      });
      return (parseFloat(v[0]?.amount) * ethUsd?.USD).toFixed(2);
    }
  };

  const getOfferSent2 = async () => {
    setLoader(true);

    var data = JSON.stringify({
      walletAddress: account.toLowerCase(),
    });
    // console.log("okokok", token);
    var config = {
      method: "post",
      url: `${Api_URL}/offer/getOfferByProfile`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        setLoader(false);
        let dummArr = [];
        for (let u of response?.data?.offersReceived) {
          if (u.bids.length > 0) {
            dummArr.push(u);
          }
        }
        setOffers(dummArr);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  };

  const getSwapOffer = async () => {
    if (token) {
      setLoader(true);
      var data = JSON.stringify({
        walletAddress: account.toLowerCase()
      });
      // console.log("okokok", token)
      var config = {
        method: "post",
        url: `${Api_URL}/swapOffer/getAllOffers`,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        },
        data: data
      };
      await axios(config)
        .then(function (response) {
          setLoader(false);
          // console.log('ldfa;', response?.data?.swapItem)
          setSwapOffers(response?.data?.swapItem)
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("getApi error", error);
        });
    }
  }
  const getSwapSent = async () => {
    setLoader(true);
    var data = JSON.stringify({
      walletAddress: account.toLowerCase()
    });
    var config = {
      method: "post",
      url: `${Api_URL}/swapOffer/getmyBids`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setLoader(false);
        setSwapSent(response?.data?.bidItem)
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  }
  const getOfferSent3 = async () => {
    let tok = localStorage.getItem("accessTok");
    setLoader(true);

    var data = JSON.stringify({
      bidderAddress: account.toLowerCase(),
    });
    var config = {
      method: "post",
      url: `${Api_URL}/offer/getOfferByBidder`,
      headers: {
        Authorization: "Bearer " + tok,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        let dumArr = [];
        for (let i of response?.data?.offer) {
          if (i.offers?.length > 0) {
            dumArr.push(i);
          }
        }
        setOfferSent2(dumArr);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  };

  const GetFilterContract = (val) => {
    if(!account){
      return
    }
    setLoader(true);
    var qs = require("qs");
    let valu = val;
    if (val) {
      setTimeFilts(val);
    } else {
      valu = "Recently added"
    }
    let data = JSON.stringify({
      "walletAddress": account?.toLowerCase(),
      "category": ["Art", "Music", "Domain Names", "Virtual Worlds", "Trading Cards", "Photography", "Collectibles", "Sports", "Utility", "Other"],
      "auctionEndingSoon": onauction,
      "recentlyCreated": false,
      "recentlySold": valu === "Recently Sold" ? true : false,
      "recentlyListed": onsale2,
      // "fromPrice": sprice?.toString(),
      // "toPrice": lprice?.toString(),
      "search": search,
      "limit": 500,
    });
    let config = {
      method: 'post',
      url: `${Api_URL}/token/getTokensFilterbyWalletAddress`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + token,
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        console.log('sdfasldfjlasdfjlas', response)
        setOwnedCollection(response?.data?.data);
        if (response?.data?.data?.length > 0) {
          let dumArr = response?.data?.data?.filter((i) => {
            return i?.metaData && i?.onSale;
          });
          setOnsale(dumArr);
        }

        setLoader(false);
      })
      .catch((error) => {
        // console.log(error);
        setLoader(false);
      });
  };
  const getLentBorrowedData = async (val) => {
    // toast.info('Grape')
    setLoader(true)
    const data = {
      limit: "100",
      page: "1",
      claimed: false,
      type: val
    };
    await axios
      .post(`${Api_URL}/rentOrder/listRent`, data, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setLoader(false)
        setLentBorrowedData(res?.data?.data)
      })
      .catch((err) => {
        setLoader(false)
        toast.error(err?.response?.data.msg, {
          position: "top-right",
          autoClose: 2000,
        });
      });
  };
  const revoke = async (nft) => {
    try {
      setLoader(true)
      const res = await UserApprove(nft?.tokenID, nft?.contractAddress, account)
      const res2 = await rentNfts(nft?.contractAddress, nft?.tokenID, account)
      var data = JSON.stringify({
        "rentId": nft?._id,
      });
      var config = {
        method: "post",
        url: `${Api_URL}/rentOrder/removeRent`,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        },
        data: data
      };
      axios(config)
        .then(function (response) {
          toast.success("Revoke Successfull!")
          getLentBorrowedData('lent')
          setTimeout(() => {
            getLentBorrowedData('borrowed')
          }, 2000);

          setLoader(false);
        })
        .catch(function (error) {
          setLoader(false);
          // console.log("getApi error", error);
        });
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  }
  function getRemainingDays(createdAt, noOfDays) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const currentDate = new Date();
    const futureDate = new Date(createdAt);
    futureDate.setDate(futureDate.getDate() + noOfDays);

    if (futureDate <= currentDate || noOfDays < 1) {
      return 0; // Return 0 for past dates or when noOfDays is less than 1
    } else {
      const remainingTime = futureDate.getTime() - currentDate.getTime();
      const remainingDays = Math.ceil(remainingTime / millisecondsPerDay);
      return remainingDays;
    }
  }
  useEffect(()=>{
    if (subPath){
       setTabState(subPath)
    }
   
  }, [subPath])
  return (
    <>
      <Header />
      {loader && <Loader />}
      <section className="main-Authoronsale">
        <div className="bg-img-author">
          <img
            src={data?.resultData?.coverPicture ? data?.resultData?.coverPicture : dummyImg}
            alt="img"
            className="img-fluid"
          />
        </div>
        <div className="container1920">
          <div className="row">
            <div className="col-sm-12 m-auto ">
              <div className="main_prodiv ">
                <div className="">
                  <img
                    style={{ border: "10px solid white", borderRadius: 30 }}
                    src={
                      data?.resultData?.picture ? data?.resultData?.picture : dummyProfile
                    }
                    alt="img"
                    className="img-fluid profileavtt"
                  />
                </div>
                <div className="icondiv">
                  <ul className="list-inline">
                    {data?.resultData?.telegramLink &&
                      data?.resultData?.telegramLink !== "undefined" ? (
                      <a
                        className="list-inline-item iconbtnnnn"
                        href={
                          data?.resultData?.telegramLink
                        }
                        target="_blank"
                      >
                        <li className="list-inline-item iconbtnnnn">
                          <img src="\fst.svg" alt="img" className="" />
                        </li>
                      </a>
                    ) : null}
                    {data?.resultData?.redditLink &&
                      data?.resultData?.redditLink !== "undefined" ? (
                      <a
                        className="list-inline-item iconbtnnnn"
                        href={
                          data?.resultData?.redditLink
                        }
                        target="_blank"
                      >
                        <li className="list-inline-item iconbtnnnn">
                          <img src="\sseenndd.svg" alt="img" className="" />
                        </li>
                      </a>
                    ) : null}
                    {data?.resultData?.discordLink &&
                      data?.resultData?.discordLink !== "undefined" ? (
                      <a
                        className="list-inline-item iconbtnnnn"
                        href={
                          data?.resultData?.discordLink
                        }
                        target="_blank"
                      >
                        <li className="list-inline-item iconbtnnnn">
                          <img src="\thirdds.svg" alt="img" className="" />
                        </li>
                      </a>
                    ) : null}
                    {data?.resultData?.twitterUserName &&
                      data?.resultData?.twitterUserName !== "undefined" ? (
                      <a
                        className="list-inline-item iconbtnnnn"
                        href={
                          data?.resultData?.twitterUserName
                        }
                        target="_blank"
                      >
                        <li className="list-inline-item iconbtnnnn">
                          <img src="\forrh.svg" alt="img" className="" />
                        </li>
                      </a>
                    ) : null}
                    {data?.resultData?.instagramLink &&
                      data?.resultData?.instagramLink !== "undefined" ? (
                      <a
                        className="list-inline-item iconbtnnnn"
                        href={
                          data?.resultData?.instagramLink
                        }
                        target="_blank"
                      >
                        <li className="list-inline-item iconbtnnnn">
                          <img src="\fifth.svg" alt="img" className="" />
                        </li>
                      </a>
                    ) : null}
                    {data?.resultData?.websiteLink &&
                      data?.resultData?.websiteLink !== "undefined" ? (
                      <a

                        className="list-inline-item iconbtnnnn"
                        href={
                          data?.resultData?.websiteLink
                        }
                        target="_blank"
                      >
                        <li className="list-inline-item iconbtnnnn">
                          <img src="\coicon.svg" alt="img" className="" />
                        </li>
                      </a>
                    ) : null}
                    {data?.resultData?.meduimLink &&
                      data?.resultData?.meduimLink !== "undefined" ? (
                      <a
                        className="list-inline-item iconbtnnnn"
                        href={
                          data?.resultData?.meduimLink
                        }
                        target="_blank"
                      >
                        <li className="list-inline-item iconbtnnnn">
                          <img src="\sixx.svg" alt="img" className="" />
                        </li>
                      </a>
                    ) : null}
                    {data?.resultData?.userEmail &&
                      data?.resultData?.userEmail !== "undefined" ? (
                      <a
                        className="list-inline-item iconbtnnnn"
                        href={
                          data?.resultData?.userEmail
                        }
                        target="_blank"
                      >
                        <li className="list-inline-item iconbtnnnn ">
                          <img src="\envelope-icon.svg" alt="img" className="" />
                        
                        </li>
                      </a>
                    ) : null}
                  </ul>
                </div>
              </div>
              <div className="avatar__row">
                <h3 className="jekkee">{data?.resultData?.userFullName}</h3>
                {!id ? (
                  <button
                    className="editprofilebtnmm"
                    onClick={() => navigate("/EditProfile")}
                  >
                    Edit Profile
                  </button>
                ) : (
                  <>
                    {account !== address && (
                      <button className="btn-common" onClick={followAddress}>
                        {follow ? "Following" : "Follow"}
                      </button>
                    )}
                  </>
                )}
                <div className="followerss d-flex align-items-start">
                  <p className="folowdig ">
                    {data?.resultData?.followersCount > 0
                      ? data?.resultData?.followersCount
                      : 0}{" "}
                    <span className="foll">Followers</span>
                  </p>
                  <p className="folowdig">
                    {data?.resultData?.followingCount > 0
                      ? data?.resultData?.followingCount
                      : 0}{" "}
                    <span className="foll">Following</span>
                  </p>
                </div>
                <p className="ttteexxxtt mt-3 text-justify">{data?.resultData?.bio}</p>
                {/* <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                  className="copycoopppyy"
                > */}
                  <CopyToClipboard
                    text={data?.resultData?.walletAddress}
                    onCopy={() => copiedHandle()}
                  >
             <div className=''>
                      <p className="ttteexxxtt cursor-pointer d-flex align-items-center">
                        {data?.resultData?.walletAddress ||
                          data?.resultData?.walletAddress}
                   {copied ? <p className='copy-text-abs  ml-2'> Copied</p> :
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-copy ml-2"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            strokeWidth="1"
                            stroke="#7a798a"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="8" y="8" width="12" height="12" rx="2" />
                            <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                          </svg>}
                      </p>

                    </div>
                  </CopyToClipboard >
                {/* </OverlayTrigger > */}
              </div >
            </div >
          </div >
          <div className="mainpills landingpills ">
            <div className="row">
              <div className=" col-sm-12  m-auto ">
                <ul className="nav nav-tabs " role="tablist">
                  <li className="nav-item">
                    <a
                      className={subPath === 'sale' ? "nav-link active" : "nav-link"}
                     onClick={() => {  navigate('/myprofile/sale', { replace: true });}}
                      data-toggle="tab"
                      href="#sale"
                      role="tab"
                      aria-controls="sale"
                      aria-selected="true"
                    >
                      On Sale
                    </a>
                  </li>
                  {!id && (
                    <li className="nav-item">
                      <a
                        className={subPath === 'owned' ? "nav-link active" : "nav-link"}
onClick={() => {  navigate('/myprofile/owned', { replace: true });getOwnedNft();}}
                        data-toggle="tab"
                        href="#owned"
                        role="tab"
                        aria-controls="owned"
                        
                        aria-selected="false"
                      >
                        Owned
                      </a>
                    </li>
                  )}
                  {!id && (
                    <li className="nav-item">
                      <a
                        className={subPath === 'created' ? "nav-link active" : "nav-link"}
                        onClick={() => { navigate('/myprofile/created', { replace: true }); getCreatedNft(); }}
                        data-toggle="tab"
                        href="#created"
                        role="tab"
                        aria-controls="created"
                        aria-selected="false"
                      >
                        Created
                      </a>
                    </li>
                  )}
                  <li className="nav-item">
                    <a
                        className={subPath === 'collections' ? "nav-link active" : "nav-link"}
                        onClick={() => { navigate('/myprofile/collections', { replace: true }) }}
                      data-toggle="tab"
                      href="#collections"
                      role="tab"
                      aria-controls="collections"
                      aria-selected="false"
                    >
                      Collections
                    </a>
                  </li>
                  {!id && (
                    <li className="nav-item">
                      <a
                        className={subPath === 'followers' ? "nav-link active" : "nav-link"}
                        onClick={() => { navigate('/myprofile/followers', { replace: true }) }}
                        data-toggle="tab"
                        href="#followers"
                        role="tab"
                        aria-controls="followers"
                        aria-selected="true"
                      >
                        {" "}
                        Followers
                      </a>
                    </li>
                  )}
                  {!id && (
                    <li className="nav-item">
                      <a
                        className={subPath === 'following' ? "nav-link active" : "nav-link"}
                        onClick={() => { navigate('/myprofile/following', { replace: true }) }}
                        data-toggle="tab"
                        href="#following"
                        role="tab"
                        aria-controls="following"
                        aria-selected="false"
                      >
                        Following
                      </a>
                    </li>
                  )}
                  {!id && (
                    <li className="nav-item">
                      <a
                           className={subPath === 'offers' ? "nav-link active" : "nav-link"}
                        onClick={() => { navigate('/myprofile/offers', { replace: true }); getOfferRecieved(); }}
                        data-toggle="tab"
                        href="#offers"
                        role="tab"
                        aria-controls="offers"
                        aria-selected="false"
                      >
                        Offers
                      </a>
                    </li>
                  )}
                  {!id && (
                    <li className="nav-item">
                      <a
                        className={subPath === 'activity' ? "nav-link active" : "nav-link"}
                        onClick={() => { navigate('/myprofile/activity', { replace: true }) }}
                        data-toggle="tab"
                        href="#activity"
                        role="tab"
                        aria-controls="offers"
                        aria-selected="false"
                      >
                        Activity
                      </a>
                    </li>
                  )}
                  <li className="nav-item">
                    <a
                      className={subPath === 'lent' ? "nav-link active" : "nav-link"}
                      onClick={() => { navigate('/myprofile/lent', { replace: true }); getLentBorrowedData('lent'); }}
                      
                      data-toggle="tab"
                      href="#lent"
                      role="tab"
                      aria-controls="lent"
                      aria-selected="true"
                    >
                      Lent NFTs
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className={subPath === 'borrowed' ? "nav-link active" : "nav-link"}
                      onClick={() => { navigate('/myprofile/borrowed', { replace: true }); getLentBorrowedData('borrowed'); }}
                      data-toggle="tab"
                      href="#borrowed"
                      role="tab"
                      aria-controls="borrowed"
                      aria-selected="true"
                    >
                      borrowed nfts
                    </a>
                  </li>

                </ul>
                <div className="tab-content mt-3">
                  <div
                    className={subPath === 'sale'  ? "tab-pane active" : 'tab-pane'}
                    id="sale"
                    role="tabpanel"
                    aria-labelledby="sale-tab"
                  >
                    {/* <div className="main-head noneformobile">
                      <div className="frameimg">
                        <img
                          src="\assets\Frame5555.svg"
                          onClick={() => setShowBtn(!showBtn)}
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="maininput">
                        <input
                          type="text"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search items, collections, and accounts"
                        />
                        <img
                          src="\assets\search-normal.svg"
                          alt="img"
                          className="img-fluid"
                          onClick={() => GetFilterContract()}
                          style={{ cursor: 'pointer' }}
                        />
                      </div>
                      <div className="dropdownbtn">
                        <button
                          className="btnbtnbtn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {timeFilts}
                          <img
                            src="\assets\Icon.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </button>
                        <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">
                          <a style={{ fontSize: 16 }} className="dropdown-item btnbtnbtnnd px-3 py-2" onClick={() => GetFilterContract("Recently added")}>Recently added</a>
                          <a style={{ fontSize: 16 }} className="dropdown-item btnbtnbtnnd px-3 py-2" onClick={() => GetFilterContract("Recently Sold")}>Recently Sold</a>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="showformobile d-none">
                    <button className="btnbtnn "  
                     data-toggle="modal"
                    data-target="#staticBackdrop">   Filter    
                    <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="change-my-color"
                    
                  >
                    <path
                      d="M3 7H21"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M6 11.5H18"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 16.5H16"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>  </button>
                    
                  </div> */}
                    <div className="row ">
                      {/* <div
                        className={
                          showBtn ? "col-xl-3 col-lg-3 col-12" : "d-none"
                        }
                      >
                        <Cofilter GetFilterContract={GetFilterContract} search={search} setSearch={setSearch} setSprice={setSprice} setLprice={setLprice} sprice={sprice} lprice={lprice} onsale={onsale2} onauction={onauction} setOnsale={setOnsale2} setOnauction={setOnauction} />
                      </div> */}
                      <div
                        className={
                          showBtn
                            ? "col-xl-12 col-lg-12 col-12"
                            : "col-xl-12 col-12"
                        }
                      >
                        <ExploreItem recentAddTokens={onsale} />
                        {/* <div className="table-responsive">
                          <table className="table bashbord_table">
                            <thead className="bashbord_table_head">
                              <tr className="tablerow">
                                <th scope="col" className="text-center">
                                  Item
                                </th>
                                <th scope="col">Price</th>
                                <th scope="col">Rarity</th>
                                <th scope="col">Quantity</th>
                                <th scope="col">From</th>
                                <th scope="col">To</th>
                                <th scope="col">Time</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="fstrowtable">
                                <td>
                                  <div className="mainheading">
                                    <div className="leftimg">
                                      <img
                                        src="\assets\Frame1002.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="midimg">
                                      <img
                                        src="\assets\9c15.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="rightext pt-2">
                                      <p>Bored Ap...</p>
                                      <h4>2541</h4>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <h4 className="black">8.254 WETH</h4>
                                  <p className="gray">$13,892.32</p>
                                </td>
                                <td>
                                  <h4 className="black text-center">--</h4>
                                </td>
                                <td>
                                  <h4 className="black text-center">1</h4>
                                </td>
                                <td>
                                  <p className="blue">pawhood</p>
                                </td>
                                <td>
                                  <p className="blue">D3C893</p>
                                </td>
                                <td>
                                  <p className="blue">4m ago</p>
                                </td>
                              </tr>
                              <tr className="fstrowtable">
                                <td>
                                  <div className="mainheading">
                                    <div className="leftimg">
                                      <img
                                        src="\assets\Frame1002.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="midimg">
                                      <img
                                        src="\assets\9c15.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="rightext pt-2">
                                      <p>Bored Ap...</p>
                                      <h4>2541</h4>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <h4 className="black">8.254 WETH</h4>
                                  <p className="gray">$13,892.32</p>
                                </td>
                                <td>
                                  <h4 className="black text-center">--</h4>
                                </td>
                                <td>
                                  <h4 className="black text-center">1</h4>
                                </td>
                                <td>
                                  <p className="blue">pawhood</p>
                                </td>
                                <td>
                                  <p className="blue">D3C893</p>
                                </td>
                                <td>
                                  <p className="blue">4m ago</p>
                                </td>
                              </tr>
                              <tr className="fstrowtable">
                                <td>
                                  <div className="mainheading">
                                    <div className="leftimg">
                                      <img
                                        src="\assets\Frame1002.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="midimg">
                                      <img
                                        src="\assets\9c15.svg"
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </div>
                                    <div className="rightext pt-2">
                                      <p>Bored Ap...</p>
                                      <h4>2541</h4>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <h4 className="black">8.254 WETH</h4>
                                  <p className="gray">$13,892.32</p>
                                </td>
                                <td>
                                  <h4 className="black text-center">--</h4>
                                </td>
                                <td>
                                  <h4 className="black text-center">1</h4>
                                </td>
                                <td>
                                  <p className="blue">pawhood</p>
                                </td>
                                <td>
                                  <p className="blue">D3C893</p>
                                </td>
                                <td>
                                  <p className="blue">4m ago</p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div> */}

                        <div className=" d-none">
                          <div className="left-filter-icon">
                            <button
                              className="filter-left-btn-mbl"
                              data-toggle="modal"
                              data-target="#staticBackdrop"
                            >
                              {" "}
                              Filters{" "}
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="change-my-color"
                              >
                                <path
                                  d="M3 7H21"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M6 11.5H18"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M8 16.5H16"
                                  stroke="white"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </button>
                            <Filter />
                          </div>
                          <div className="right-filter-drop">
                            <div className="dropdown">
                              <button
                                className="btn btn-secondary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                Sort by
                              </button>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <a className="dropdown-item" href="#">
                                  Price: Low to High
                                </a>
                                <a className="dropdown-item" href="#">
                                  Price: High to Low
                                </a>
                                <a className="dropdown-item" href="#">
                                  Auction ending soon
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                    </div>
                  </div>
                  <div
                   className={subPath === 'owned'  ? "tab-pane active" : 'tab-pane'}
                    id="owned"
                    role="tabpanel"
                    aria-labelledby="owned-tab"
                  >
                    <div className="main-head noneformobile">
                      <div className="frameimg">
                        <img
                          src="\assets\Frame5555.svg"
                          onClick={() => setShowBtn(!showBtn)}
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="maininput">

                        <img
                          src="\assets\search-normal.svg"
                          alt="img"
                          className="img-fluid"
                          onClick={() => GetFilterContract()}
                          style={{ cursor: 'pointer' }}
                        />
                        <input
                          type="text"
                          onChange={(e) => setSearch(e.target.value)}
                          placeholder="Search items, collections, and accounts"
                          className="inputttttttttnew "
                        />
                      </div>
                      <div className="dropdownbtn">
                        <button
                          className="btnbtnbtn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          {timeFilts}
                          <img
                            src="\assets\Icon.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </button>
                        <div className="dropdown-menu dfsdgsdgsdgsdgsdfg " aria-labelledby="dropdownMenuButton">
                          <a className="dropdown-item btnbtnbtnnddd " onClick={() => GetFilterContract("Recently added")}>Recently added</a>
                          <a className="dropdown-item btnbtnbtnnddd" onClick={() => GetFilterContract("Recently Sold")}>Recently Sold</a>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div
                        className={
                          showBtn ? "col-xl-3 col-lg-3 col-12 hhhhhhh" : "d-none"
                        }
                      >
                        <Cofilter GetFilterContract={GetFilterContract} search={search} setSearch={setSearch} setSprice={setSprice} setLprice={setLprice} sprice={sprice} lprice={lprice} onsale={onsale2} onauction={onauction} setOnsale={setOnsale2} setOnauction={setOnauction} />
                      </div>
                      <div
                        className={
                          showBtn
                            ? "col-xl-9 col-lg-9 col-12"
                            : "col-xl-12 col-12"
                        }
                      >
                        <ExploreItem
                          recentAddTokens={ownedCollection}
                          owned={true}
                        />
                        {/* {justOwned ? (
                          <div className="Transfer-nfts-buttons-wrapper">
                            <Link to={"/transferitems"}>
                              <button className="tans-dark-btn">
                                {" "}
                                <img
                                  src={whiteTransBtn}
                                  alt="whiteTransBtn"
                                />{" "}
                                Transfer
                              </button>
                            </Link>
                            <button className="tans-light-btn">
                              {" "}
                              <img src={crossBtn} alt="crossBtn" /> Cancel
                            </button>
                          </div>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row ">
                    <div
                      className={
                        showBtn1 ? "col-xl-3 col-lg-3 col-12" : "d-none"
                      }
                    >
                      <Cofilter />
                    </div>
                    <div
                      className={
                        showBtn1
                          ? "col-xl-9 col-lg-9 col-12"
                          : "col-xl-12 col-12"
                      }
                    >
                      <ExploreItem
                        recentAddTokens={ownedCollection}
                        owned={true}
                      />
                      {justOwned ? (
                        <div className="Transfer-nfts-buttons-wrapper">
                          <Link to={"/transferitems"}>
                            <button className="tans-dark-btn">
                              {" "}
                              <img
                                src={whiteTransBtn}
                                alt="whiteTransBtn"
                              />{" "}
                              Transfer
                            </button>
                          </Link>
                          <button className="tans-light-btn">
                            {" "}
                            <img src={crossBtn} alt="crossBtn" /> Cancel
                          </button>
                        </div>
                      ) : null}
                    </div>
                  </div> */}
                  <div
                    className={subPath === 'created'  ? "tab-pane active" : 'tab-pane'}
                    id="created"
                    role="tabpanel"
                    aria-labelledby="created-tab"
                  >
                    <ExploreItem recentAddTokens={createdCollection} />
                  </div>
                  <div
                    className={subPath === 'collections'  ? "tab-pane active" : 'tab-pane'}
                    id="collections"
                    role="tabpanel"
                    aria-labelledby="collections-tab"
                  >
                    <div className="coll-filters-row">
                      <div className="collection-add-wrappers">
                        <div class="btn-group-asdfsdf" style={{ position: "relative" }}>
                          <button type="button" class="btn clickDosts-button dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img src={clickDosts} alt="" />
                          </button>
                          <div class="dropdown-menu clickDosts-dropdown-box">
                            <Link to={'/createitem'} className="dropdown-item">
                              <img src={createNftCollIcon} alt="" />
                              Create Collection
                            </Link>
                            <div class="dropdown-divider"></div>
                            <Link to={'/importcollection'} className="dropdown-item">
                              <img src={importNftColl} alt="importNftColl" />
                              Import Collection</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                    <CollectionCard
                      owner={true}
                      data={collectionByCreatorAddress}
                    />
                  </div>
                  <div
                   className={subPath === 'followers'  ? "tab-pane followerstaabb active" : 'tab-pane followerstaabb'}
                    id="followers"
                    role="tabpanel"
                    aria-labelledby="followers-tab"
                  >
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 m-auto">
                        <div className="followersmain">
                          {followers?.length > 0 ? (
                            <div className="row">
                              {followers?.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="col-xl-3 col-lg-4 col-md-6 col-6 padd-320"
                                  >
                                    <div className="folowcardd ">
                                      <div className="flpic">
                                        <img
                                          src={
                                            item?.picture
                                              ? item?.picture
                                              : "./upload.png"
                                          }
                                          alt=""
                                          className=" img-fluid"
                                        />
                                      </div>
                                      <div className="writing">
                                        <p className="crispin">
                                          {item?.userFullName
                                            ? item?.userFullName
                                            : item?.walletAddress?.slice(
                                              0,
                                              12
                                            ) + "..."}
                                        </p>
                                        <p className="crispineth">
                                          {item?.followersCount + " "}
                                          Followers
                                        </p>
                                      </div>
                                      <div className="fcardbtn">
                                        <>
                                          {item?.followers?.find(
                                            (i) =>
                                              i.address ===
                                              account?.toLowerCase()
                                          ) ? (
                                            <button
                                              className="nfollowcard"
                                              onClick={() =>
                                                Unfollow(
                                                  item?.walletAddress,
                                                  item?._id
                                                )
                                              }
                                            >
                                              Unfollow
                                            </button>
                                          ) : (
                                            <button
                                              className="nfollowcard"
                                              onClick={() =>
                                                followAddress2(
                                                  item?.walletAddress,
                                                  item?._id
                                                )
                                              }
                                            >
                                              Follow
                                            </button>
                                          )}
                                        </>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div className="no_item_display">
                              <img
                                src="\assets\display-follow.png"
                                alt="img"
                                className="img-fluid"
                              />
                              <h5>No Followers To Display</h5>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={subPath === 'following'  ? "tab-pane active" : 'tab-pane'}
                    id="following"
                    role="tabpanel"
                    aria-labelledby="following-tab"
                  >
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 m-auto ">
                        <div className="followersmain">
                          {following?.length > 0 ? (
                            <div className="row">
                              {following?.map((item, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="col-xl-3 col-lg-4 col-md-6 col-6 padd-320"
                                  >
                                    <div className="folowcardd ">
                                      <div className="flpic">
                                        <img
                                          src={
                                            item?.picture
                                              ? item?.picture
                                              : "./upload.png"
                                          }
                                          alt=""
                                          className=" img-fluid"
                                        />
                                      </div>
                                      <div className="writing">
                                        <p className="crispin">
                                          {item?.userFullName
                                            ? item?.userFullName
                                            : item?.walletAddress?.slice(0, 7)}
                                        </p>
                                        <p className="crispineth">
                                          {item?.followersCount + " "}
                                          Followers
                                        </p>
                                      </div>
                                      <div className="fcardbtn">
                                        <button
                                          onClick={() =>
                                            Unfollow(
                                              item?.walletAddress,
                                              item?._id
                                            )
                                          }
                                          className="nfollowcardred"
                                        >
                                          {" "}
                                          Unfollow
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div className="no_item_display">
                              <img
                                src="\assets\display-follow.png"
                                alt="img"
                                className="img-fluid"
                              />
                              <h5>No Followings To Display</h5>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={subPath === 'offers'  ? "tab-pane active" : 'tab-pane'}
                    id="offers"
                    role="tabpanel"
                    aria-labelledby="offers-tab"
                  >
                    <div className="pillsbtnns">
                      <ul
                        className="nav nav-pills sentresive"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item maintabbtnssf" role="presentation">
                          <a
                            className="nav-link recieved active show"
                            id="pills-recieved-tab"
                            data-toggle="pill"
                            href="#pills-recieved"
                            role="tab"
                            aria-controls="pills-recieved"
                            aria-selected="true"
                          >
                            Offers Recieved
                          </a>
                        </li>
                        <li className="nav-item maintabbtnssse" role="presentation">
                          <a
                            className="nav-link ssent "
                            id="pills-ssent-tab"
                            data-toggle="pill"
                            href="#pills-ssent"
                            role="tab"
                            aria-controls="pills-ssent"
                            aria-selected="false"
                            onClick={() => {
                              getOfferSent();
                            }}
                          >
                            Offers Sent
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="Bidoffers">
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade active show"
                          id="pills-recieved"
                          role="tabpanel"
                          aria-labelledby="pills-recieved-tab"
                        >
                          <div className="row">
                            <div className="col-xl-12 col-12 threepillstabs">
                              <div
                                className="nav nav-tabs  oofferssser "
                                id="nav-tab"
                                role="tablist"
                              >
                                <a
                                  className="nav-link oferiner show active"
                                  id="nav-bid-tab"
                                  data-toggle="tab"
                                  href="#nav-bid"
                                  role="tab"
                                  aria-controls="nav-bid"
                                  aria-selected="true"
                                  onClick={() => getOfferRecieved()}
                                >
                                  Bids
                                </a>
                                <a
                                  className="nav-link oferiner"
                                  id="nav-swap-offer-tab"
                                  data-toggle="tab"
                                  href="#nav-swap-offer"
                                  role="tab"
                                  aria-controls="nav-swap-offer"
                                  aria-selected="false"
                                  onClick={getSwapOffer}
                                >
                                  Swap Offers
                                </a>
                                <a
                                  className="nav-link oferiner"
                                  id="nav-swap-tab"
                                  data-toggle="tab"
                                  href="#nav-swap"
                                  role="tab"
                                  aria-controls="nav-swap"
                                  aria-selected="false"
                                  onClick={() => {
                                    getOfferSent2();
                                  }}
                                >
                                  Offers
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="tab-content bidssii" id="nav-tabContent">
                            <div
                              className="tab-pane fade show active"
                              id="nav-bid"
                              role="tabpanel"
                              aria-labelledby="nav-bid-tab"
                            >
                              <div className="newtable">
                                <div className="row">
                                  <div className="col-xl-12 col-12 table-responsive-lg">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="uhvvvv">
                                            <h3 className="headdingrow">
                                              Item
                                            </h3>
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (BNB)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (USD)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Total Offers
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Details
                                            </h3>
                                          </th>
                                        </tr>
                                      </thead>
                                      {offerRecieved?.length > 0 ? (
                                        <tbody>
                                          {offerRecieved?.map((item, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <div className="img-table d-flex">
                                                    <img
                                                      style={{ width: 60 }}
                                                      src={item?.image}
                                                      className="img-fluid"
                                                    />
                                                    <h6 className="">
                                                      {item?.nftName}
                                                    </h6>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="ethicon">
                                                    {item?.bids?.length > 0 && (
                                                      <img
                                                        src="\bnb.svg"
                                                        className="img-fluid"
                                                      />
                                                    )}
                                                    <h6 className="">
                                                      {item?.bids?.length > 0
                                                        ? getMax(item?.bids)
                                                        : "No current bids"}
                                                    </h6>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="paratable">
                                                    <p className="">
                                                      {item?.bids?.length > 0
                                                        ? getMaxUsd(item?.bids)
                                                        : "No current bids"}
                                                    </p>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="paratable">
                                                    <p className="">
                                                      {item?.bids?.length > 0
                                                        ? item?.bids?.length
                                                        : 0}
                                                    </p>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="btn-table">
                                                    <Link
                                                      to={
                                                        "/item-details?id=" +
                                                        item?._id
                                                      }
                                                    >
                                                      <button className="viewdetails">
                                                        View Details
                                                      </button>
                                                    </Link>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      ) : (
                                        <div className="no____nft pt-5 justify-content-start">
                                          <h3>No Offers Found</h3>
                                        </div>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-swap-offer"
                              role="tabpanel"
                              aria-labelledby="nav-swap-offer-tab"
                            >
                              <div className="newtable">
                                <div className="row">
                                  <div className="col-xl-12 col-12 table-responsive-lg">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="uhvvvv">
                                            <h3 className="headdingrow">Items</h3>
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (BNB)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (USD)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Total Offers
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Details
                                            </h3>
                                          </th>
                                        </tr>
                                      </thead>
                                      {swapOffers?.length > 0 ?
                                        <tbody>
                                          {swapOffers?.map((item, index) => {
                                            return (
                                              item?._id?.tokens?.length > 0 &&
                                              <tr key={index}>
                                                <td>
                                                  <div className="img-table d-flex">
                                                    <img
                                                      style={{ width: 60 }}
                                                      src={item?._id?.tokens[0]?.image}
                                                      className="img-fluid"
                                                    />
                                                    <h6 className="">
                                                      {item?._id?.tokens[0]?.nftName}
                                                    </h6>
                                                  </div>
                                                </td>
                                                  <td>
                                                    <div className="ethicon">
                                                      {item?.bids?.length > 0 && (
                                                        <img
                                                          src="\bnb.svg"
                                                          className="img-fluid"
                                                        />
                                                      )}
                                                      <h6 className="">
                                                        {item?._id?.tokens[0]?.bids?.length > 0
                                                          ? getMax(item?._id?.tokens[0]?.bids)
                                                          : "No current bids"}
                                                      </h6>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="paratable">
                                                      <p className="">
                                                        {item?._id?.tokens[0]?.bids?.length > 0
                                                          ? getMaxUsd(item?._id?.tokens[0]?.bids)
                                                          : "No current bids"}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="paratable">
                                                      <p className="">{item?.count}</p>
                                                    </div>
                                                  </td>
                                                <td>
                                                  <div className="btn-table">
                                                    {/* onClick={() => AcceptOffer(item)} */}
                                                    <Link to={`/item-details-03?id=` + item?._id?.tokens[0]?._id}>
                                                      <button className="viewdetails">
                                                        Detail
                                                      </button>
                                                    </Link>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                        :
                                        <div className="no____nft pt-5 justify-content-start">
                                          <h3>No Offers Found</h3>
                                        </div>
                                      }
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-swap"
                              role="tabpanel"
                              aria-labelledby="nav-swap-tab"
                            >
                              <div className="newtable">
                                <div className="row">
                                  <div className="col-xl-12 col-12 table-responsive-lg">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="uhvvvv">
                                            <h3 className="headdingrow">
                                              Items
                                            </h3>
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (BNB)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (USD)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Total Offers
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Details
                                            </h3>
                                          </th>
                                        </tr>
                                      </thead>
                                      {offers?.length > 0 ? (
                                        <tbody>
                                          {offers?.map((item, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <div className="img-table d-flex">
                                                    <img
                                                      style={{ width: 60 }}
                                                      src={item?.image}
                                                      className="img-fluid"
                                                    />
                                                    <h6 className="">
                                                      {item?.nftName}
                                                    </h6>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="ethicon">
                                                    <img
                                                      src="\bnb.svg"
                                                      className="img-fluid"
                                                    />
                                                    <h6 className="">
                                                      {getMax(item?.bids)}
                                                    </h6>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="paratable">
                                                    <p className="">
                                                      {getMaxUsd(item?.bids)}
                                                    </p>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="paratable">
                                                    <p className="">
                                                      {item?.bids?.length > 0
                                                        ? item?.bids?.length
                                                        : 0}
                                                    </p>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="btn-table">
                                                    <Link
                                                      to={
                                                        "/item-details?id=" +
                                                        item?._id
                                                      }
                                                    >
                                                      <button className="viewdetails">
                                                        View Details
                                                      </button>
                                                    </Link>
                                                  </div>
                                                </td>
                                              </tr>
                                            );
                                          })}
                                        </tbody>
                                      ) : (
                                        <div className="no____nft pt-5 justify-content-start">
                                          <h3>No Offers Found</h3>
                                        </div>
                                      )}
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-ssent"
                          role="tabpanel"
                          aria-labelledby="pills-ssent-tab"
                        >
                          <div className="newtable offers-table offer-sent-table">
                            <div className="row">
                              <div className="col-xl-12 col-12 threepillstabs">
                                <div
                                  className="nav nav-tabs  oofferssser "
                                  id="nav-tab1"
                                  role="tablist"
                                >
                                  <a
                                    className="nav-link oferiner show active"
                                    id="nav-bid-tab-sent"
                                    data-toggle="tab"
                                    href="#nav-bid1"
                                    role="tab"
                                    aria-controls="nav-bid1"
                                    aria-selected="true"
                                  >
                                    Bids
                                  </a>
                                  <a
                                    className="nav-link oferiner"
                                    id="nav-swap-offer0-tab"
                                    data-toggle="tab"
                                    href="#nav-swap-offer0"
                                    role="tab"
                                    aria-controls="nav-swap-offer"
                                    aria-selected="false"
                                    onClick={() => { getSwapSent() }}
                                  >
                                    Swap Offers
                                  </a>
                                  <a
                                    className="nav-link oferiner"
                                    id="nav-swap-tab-senttt"
                                    data-toggle="tab"
                                    href="#nav-swap1"
                                    role="tab"
                                    aria-controls="nav-swap1"
                                    aria-selected="false"
                                    onClick={() => {
                                      getOfferSent3();
                                    }}
                                  >
                                    Offers
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-content bidssii"
                              id="nav-tabContent"
                            >
                              <div
                                className="tab-pane fade show active"
                                id="nav-bid1"
                                role="tabpanel"
                                aria-labelledby="nav-bid-tab-sent"
                              >
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th className="uhvvvv">
                                        <h3 className="headdingrow">Items</h3>
                                      </th>
                                      <th className="uhvvvv2">
                                        {" "}
                                        <h3 className="headdingrow">
                                          Highest Bid (BNB)
                                        </h3>{" "}
                                      </th>
                                      <th className="uhvvvv2">
                                        {" "}
                                        <h3 className="headdingrow">
                                          Highest Bid (USD)
                                        </h3>{" "}
                                      </th>
                                      <th className="uhvvvv2">
                                        {" "}
                                        <h3 className="headdingrow">
                                          Total Offers
                                        </h3>{" "}
                                      </th>
                                      <th className="uhvvvv2">
                                        {" "}
                                        <h3 className="headdingrow">Details</h3>
                                      </th>
                                    </tr>
                                  </thead>
                                  {offerSent?.length > 0 ? (
                                    <tbody>
                                      {offerSent?.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <div className="img-table d-flex">
                                                <img
                                                  style={{ width: 60 }}
                                                  src={
                                                    item?.tokens?.length > 0 &&
                                                    item?.tokens[0].image
                                                  }
                                                  className="img-fluid"
                                                />
                                                <h6 className="">
                                                  {item?.tokens?.length > 0 &&
                                                    item?.tokens[0].nftName}
                                                </h6>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="ethicon">
                                                <img
                                                  src="\bnb.svg"
                                                  className="img-fluid"
                                                />
                                                <h6 className="">
                                                  {getMax(item?.bids)}
                                                </h6>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="paratable">
                                                <p className="">
                                                  {getMaxUsd(item?.bids)}
                                                </p>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="paratable">
                                                <p className="">
                                                  {item?.bids?.length > 0
                                                    ? item?.bids?.length
                                                    : 0}
                                                </p>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="btn-table">
                                                <Link
                                                  to={
                                                    "/item-details?id=" +
                                                    item?.tokens[0]._id
                                                  }
                                                >
                                                  <button className="viewdetails">
                                                    View Details
                                                  </button>
                                                </Link>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  ) : (
                                    <div className="no____nft pt-5 justify-content-start">
                                      <h3>No Offers Found</h3>
                                    </div>
                                  )}
                                </table>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="nav-swap-offer0"
                                role="tabpanel"
                                aria-labelledby="nav-swap-offer0-tab"
                              >
                                <div className="newtable">
                                  <div className="row">
                                    <div className="col-xl-12 col-12 table-responsive-lg">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th className="uhvvvv">
                                              <h3 className="headdingrow">Items</h3>
                                            </th>
                                            <th className="uhvvvv2">
                                              {" "}
                                              <h3 className="headdingrow">
                                                Highest Bid (BNB)
                                              </h3>{" "}
                                            </th>
                                            <th className="uhvvvv2">
                                              {" "}
                                              <h3 className="headdingrow">
                                                Highest Bid (USD)
                                              </h3>{" "}
                                            </th>
                                            <th className="uhvvvv2">
                                              {" "}
                                              <h3 className="headdingrow">
                                                Total Offers
                                              </h3>{" "}
                                            </th>
                                            <th className="uhvvvv2">
                                              {" "}
                                              <h3 className="headdingrow">
                                                Details
                                              </h3>
                                            </th>
                                          </tr>
                                        </thead>
                                        {swapSent?.length > 0 ?
                                          <tbody>
                                            {swapSent?.map((item, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td>
                                                    <div className="img-table d-flex">
                                                      <img
                                                        style={{ width: 60 }}
                                                        src={item?._id?.tokens[0]?.image}
                                                        className="img-fluid"
                                                      />
                                                      <h6 className="">
                                                        {item?._id?.tokens[0]?.nftName}
                                                      </h6>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="ethicon">
                                                      {item?.bids?.length > 0 && (
                                                        <img
                                                          src="\bnb.svg"
                                                          className="img-fluid"
                                                        />
                                                      )}
                                                      <h6 className="">
                                                        {item?._id?.tokens[0]?.bids?.length > 0
                                                          ? getMax(item?._id?.tokens[0]?.bids)
                                                          : "No current bids"}
                                                      </h6>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="paratable">
                                                      <p className="">
                                                        {item?._id?.tokens[0]?.bids?.length > 0
                                                          ? getMaxUsd(item?._id?.tokens[0]?.bids)
                                                          : "No current bids"}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="paratable">
                                                      <p className="">{item?.count}</p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="btn-table">
                                                      {/* onClick={() => AcceptOffer(item)} */}
                                                      <Link to={`/item-details-04?id=` + item?._id?.tokens[0]?._id}>
                                                        <button className="viewdetails">
                                                          Detail
                                                        </button>
                                                      </Link>
                                                    </div>
                                                  </td>
                                                </tr>
                                              )
                                            })}
                                          </tbody>
                                          :
                                          <div className="no____nft pt-5 justify-content-start">
                                            <h3>No Offers Found</h3>
                                          </div>
                                        }
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="tab-pane fade"
                                id="nav-swap1"
                                role="tabpanel"
                                aria-labelledby="nav-swap-tab-senttt"
                              >
                                <div className="newtable">
                                  <div className="row">
                                    <div className="col-xl-12 col-12 table-responsive-lg">
                                      <table className="table">
                                        <thead>
                                          <tr>
                                            <th className="uhvvvv">
                                              <h3 className="headdingrow">
                                                Items
                                              </h3>
                                            </th>
                                            <th className="uhvvvv2">
                                              {" "}
                                              <h3 className="headdingrow">
                                                Highest Bid (BNB)
                                              </h3>{" "}
                                            </th>
                                            <th className="uhvvvv2">
                                              {" "}
                                              <h3 className="headdingrow">
                                                Highest Bid (USD)
                                              </h3>{" "}
                                            </th>
                                            <th className="uhvvvv2">
                                              {" "}
                                              <h3 className="headdingrow">
                                                Total Offers
                                              </h3>{" "}
                                            </th>
                                            <th className="uhvvvv2">
                                              {" "}
                                              <h3 className="headdingrow">
                                                Details
                                              </h3>
                                            </th>
                                          </tr>
                                        </thead>
                                        {offerSent2?.length > 0 ? (
                                          <tbody>
                                            {offerSent2?.map((item, index) => {
                                              return (
                                                <tr key={index}>
                                                  <td>
                                                    <div className="img-table d-flex">
                                                      <img
                                                        style={{ width: 60 }}
                                                        src={
                                                          item?.tokens?.length >
                                                          0 &&
                                                          item?.tokens[0]?.image
                                                        }
                                                        className="img-fluid"
                                                      />
                                                      <h6 className="">
                                                        {item?.tokens?.length >
                                                          0 &&
                                                          item?.tokens[0]
                                                            ?.nftName}
                                                      </h6>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="ethicon">
                                                      <img
                                                        src="\bnb.svg"
                                                        className="img-fluid"
                                                      />
                                                      <h6 className="">
                                                        {getMax(item?.offers)}
                                                      </h6>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="paratable">
                                                      <p className="">
                                                        {getMaxUsd(
                                                          item?.offers
                                                        )}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="paratable">
                                                      <p className="">
                                                        {item?.offers?.length >
                                                          0
                                                          ? item?.offers?.length
                                                          : 0}
                                                      </p>
                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div className="btn-table">
                                                      <Link
                                                        to={
                                                          "/item-details?id=" +
                                                          item?.tokens[0]._id
                                                        }
                                                      >
                                                        <button className="viewdetails">
                                                          View Details
                                                        </button>
                                                      </Link>
                                                    </div>
                                                  </td>
                                                </tr>
                                              );
                                            })}
                                          </tbody>
                                        ) : (
                                          <div className="no____nft pt-5 justify-content-start">
                                            <h3>No Offers Found ok</h3>
                                          </div>
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                className={subPath === 'activity'  ? "tab-pane active" : 'tab-pane'}
                    id="activity"
                    role="tabpanel"
                    aria-labelledby="activity-tab"
                  >
                    <Activity01 />
                  </div>
                  <div
                    className={subPath === 'lent'  ? "tab-pane active" : 'tab-pane'}
                    id="lent"
                    role="tabpanel"
                    aria-labelledby="lent-tab"
                  >
                    <div className="row my-5">
                      <div className="col-12 p-0 m-auto">
                        <div className="table-responsive">
                          <table className="table bashbord_table">
                            <thead className="bashbord_table_head">
                              <tr className="tablerow rentTable">

                                <th scope="col" className="text-left">
                                  NFTs
                                </th>
                                <th scope="col">Ends In</th>
                                <th scope="col">Rent Duration</th>

                              </tr>
                            </thead>
                            <tbody>
                              {lentBorrowedData?.map((item) => {
                                const remaningDays = getRemainingDays(item?.createdAt, item?.rentDuration)
                                console.log('ldasflasdf', remaningDays)
                                return (
                                  <>
                                    {remaningDays > 0 &&
                                      <tr className="fstrowtable">
                                        <td className="text-center">
                                          <div className="midimg mainheading mx-2">
                                            <h4 className="black">
                                              <img
                                                src={item?.tokens[0]?.image}
                                                alt="img"
                                                className="img-fluid mx-3 imgSS"
                                              />
                                              {item?.tokens[0]?.nftName} </h4>
                                          </div>
                                        </td>
                                        <td className="text-center">
                                          <h4 className="black">{remaningDays} Days</h4>

                                        </td>
                                        <td>
                                          <h4 className="black text-center">{item?.rentDuration} Days</h4>
                                        </td>
                                      </tr>
                                    }
                                  </>
                                )
                              })}
                            </tbody>
                          </table>
                        </div>
                      </div>


                    </div>

                  </div>
                  <div
                        className={subPath === 'borrowed'  ? "tab-pane active" : 'tab-pane'}
                    id="borrowed"
                    role="tabpanel"
                    aria-labelledby="borrowed-tab"
                  >
                    <div className="row my-5">
                      <div className="col-12 p-0 m-auto">
                        <div className="table-responsive">
                          <table className="table bashbord_table">
                            <thead className="bashbord_table_head">
                              <tr className="tablerow rentTable">
                                <th scope="col" className="text-left">
                                  NFTs
                                </th>
                                <th scope="col">Remaning Days</th>
                                <th scope="col">Rent Duration</th>
                                <th scope="col">Rent Paid</th>
                                {/* <th scope="col">Details</th> */}

                              </tr>
                            </thead>
                            <tbody>
                              {lentBorrowedData?.map((item) => {
                                const remaningDays = getRemainingDays(item?.createdAt, item?.rentDuration)
                                console.log('ldasflasdf', remaningDays)
                                return (
                                  <>
                                    {remaningDays > 0 &&
                                      <tr className="fstrowtable">
                                        <td>


                                          <div className="midimg mainheading mx-2">
                                            <h4 className="black">    <img
                                              src={item?.tokens[0]?.image}
                                              alt="img"
                                              className="img-fluid mx-3 imgSS"
                                            />
                                              {item?.tokens[0]?.nftName} </h4>
                                          </div>
                                        </td>
                                        <td>
                                          <h4 className="black text-center">{remaningDays} Days</h4>
                                        </td>
                                        <td>
                                          <h4 className="black text-center">{item?.rentDuration} Days</h4>
                                        </td>
                                        <td>
                                          <h4 className="black text-center">{item?.totalRentPrice} WNB</h4>
                                        </td>
                                        {/* <td>
                                    <td className="dropdown-img">
                                      <div className="dropdown">
                                        <img
                                          src="\assets\iconamoon_menu-kebab-horizontal-fill.svg"
                                          alt="img"
                                          className="img-fluid"
                                          id="dropdownMenuButton"
                                          data-toggle="dropdown"
                                          aria-haspopup="true"
                                          aria-expanded="false"


                                        />

                                        <div
                                          className="dropdown-menu"
                                          aria-labelledby="dropdownMenuButton"
                                        >
                                          <p onClick={() => revoke(item)} className="dropdown-item" >
                                            Revoke
                                          </p>
                                        </div>
                                      </div>
                                    </td>
                                  </td> */}

                                      </tr>}</>)
                              })}


                            </tbody>
                          </table>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="containerffluid ">
            <div className="tab-content ">
              <div
                className="tab-pane fade show active"
                id="PROFILE"
                role="tabpanel"
                aria-labelledby="PROFILE-tab"
              ></div>
              <div
                className="tab-pane fade"
                id="Inventory"
                role="tabpanel"
                aria-labelledby="Inventory-tab"
              >
                <div className="user__inventory__section col-xl-12 col-lg-12 m-auto ">
                  Inventory
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="Claims"
                role="tabpanel"
                aria-labelledby="Claims-tab"
              >
                Claims
              </div>
              <div
                className="tab-pane fade"
                id="Bids"
                role="tabpanel"
                aria-labelledby="Bids-tab"
              >
                Bids
              </div>
              <div
                className="tab-pane fade"
                id="Avatar"
                role="tabpanel"
                aria-labelledby="Avatar-tab"
              >
                Avatar
              </div>
            </div>
          </div>
        </div >
      </section >


      <div className=''>
        <Modal className='cardmodal' show={show3} onHide={handleClose3} centered>
          <Modal.Header closeButton>
            <Modal.Title>Claim Rent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row p-0'>
              <div className='col-8 m-auto p-0'>

                <img
                  src="\assets\moalpic.svg"
                  alt="img"
                  className="img-fluid"


                />
                <p className=" text-center py-3">
                  Available Rent
                </p>
                <h3 className=" text-center pb-3">
                  1.2 ETH
                </h3>
                <div className="text-center ">
                  <button className=" text-center py-3 w-100">
                    Claim Rent
                  </button>
                </div>


              </div>
            </div>

          </Modal.Body>

        </Modal>
      </div>
      <Footer />
    </>
  );
};

export default Myprofille;
