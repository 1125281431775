import React from "react";
import { Link } from "react-router-dom";
import "./feature.scss";
import { toast } from "react-toastify";

const Feature = () => {
  return (
    <>
      {/* <iframe src="https://effervescent-clafoutis-7d9fa8.netlify.app/" width="1500px" height="790px" /> */}
      <section className="feature desktop-padding-manual">
        <div className="container">
          <div className="heading-live-auctions mg-bt-22 sometext-center">
            <h2 className="tf-title pb-17">Bluemoon Unique Features </h2>
          </div>
          <div className="inner-features flexbox">
            <div className="feature-card">
              <img src="\assets\images\create\integration.png" alt="img" className="img-fluid" />
              <h6>Metaverse Integration</h6>
            </div>
            <div className="feature-card">
              <img src="\assets\images\create\rebase.png" alt="img" className="img-fluid" />
              <h6>Rebates</h6>
            </div>
            <div className="feature-card">
              <img src="\assets\images\create\gamification.png" alt="img" className="img-fluid" />
              <h6>Gamification</h6>
            </div>
            <div className="feature-card">
              {/* <img src="\assets\images\create\comingsoon.png" alt="img" className="img-fluid d-none comingsoon" /> */}
              <img src="\assets\images\create\rentnft.png" alt="img" className="img-fluid" />
              <h6>Rent NFTs</h6>
            </div>
            {/* <Link to="/swap"> */}
              <div onClick={()=>toast.info('Go to some nft and swap there')} className="feature-card">
                {/* <img src="\assets\images\create\comingsoon.png" alt="img" className="img-fluid d-none comingsoon" /> */}
                <img src="\assets\images\create\swapnft.png" alt="img" className="img-fluid" />
                <h6>Swap NFTs</h6>
              </div>
            {/* </Link> */}
            <div className="feature-card">
              {/* <img src="\assets\images\create\comingsoon.png" alt="img" className="img-fluid d-none comingsoon" /> */}
              <img src="\assets\images\create\comparenft.png" alt="img" className="img-fluid" />
              <h6>Compare NFTs</h6>
            </div>
            <div className="feature-card">
              <img src="\assets\images\create\launchpad.png" alt="img" className="img-fluid" />
              <h6>Launchpad</h6>
            </div>
            <div className="feature-card">
              <img src="\assets\images\create\fiatpayment.png" alt="img" className="img-fluid" />
              <h6>Fiat Payments</h6>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Feature;
