import { useCallback, useState, useEffect } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getWethContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

const ApproveWeth = () => {
    const web3 = useWeb3();
    // const contractAddress = environment.weth;
    // const marketAddress = environment.marketPlaceContract;
    const [contractAddress, setContractAddress] = useState(null);
    const [marketAddress, setMarketAddress] = useState(null);
    const context = useWeb3React();
    const { chainId } = context;

    useEffect(() => {
        if (chainId === 97) {
            setContractAddress(environment.weth);
            setMarketAddress(environment.marketPlaceContract);
            // } else if (chainId === 4) {
            //     setContractAddress(environment.Eth.weth);
            //     setMarketAddress(environment.Eth.marketPlaceContract);
            // } else if (chainId === 43114) {
            //     setContractAddress(environment.Eth.weth);
            //     setMarketAddress(environment.Eth.marketPlaceContract);
            // } else if (chainId === 80001) {
            //     setContractAddress(environment.Polygon.weth);
            //     setMarketAddress(environment.Polygon.marketPlaceContract);
        }
    }, [chainId])

    const WethMark = useCallback(
        async (account) => {
            if (marketAddress) {
                // console.log("account", account, contractAddress)
                try {
                    const contract = getWethContract(contractAddress, web3);
                    const gas = await contract.methods
                        .approve(marketAddress, '1000000000000000000000000')
                        .estimateGas({ from: account });
                    let gasPrice = await web3.eth.getGasPrice();
                    const details = await contract.methods
                        .approve(marketAddress, '1000000000000000000000000')
                        .send({
                            from: account,
                            gas,
                            gasPrice: gasPrice
                        })
                    return details;
                } catch (error) {
                    // console.log(error)
                    throw (error)
                }
            }
        },
        [marketAddress]
    );
    return { WethMark: WethMark };
};

export default ApproveWeth;