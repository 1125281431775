const Category1= [
    {
        item: 'Art'
    },
    {
        item: 'Collectibles'
    },
    {
        item: 'Domain Names'
    },
    {
        item: 'Music'
    },
    {
        item: 'Photography'
    },
    {
        item: 'Sports'
    },
    {
        item: 'Trading Cards'
    },
    {
        item: 'Virtual Worlds'
    }
] 
export default Category1;