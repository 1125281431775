import React, { useState, useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import { useWeb3React } from '@web3-react/core'
import useWeb3 from "../hooks/useWeb3";
import { soliditySha3 } from "web3-utils";
import axios from "axios";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Loader from "../hooks/loader";
import TokenAppr from "../hooks/dataFetchers/isApprovedForAll";
import "react-tabs/style/react-tabs.css";
import liveAuctionData from "../assets/fake-data/data-live-auction";
import LiveAuction from "../components/layouts/LiveAuction";
import img1 from "../assets/images/avatar/avt-3.jpg";
import img2 from "../assets/images/avatar/avt-11.jpg";
import img3 from "../assets/images/avatar/avt-1.jpg";
import img4 from "../assets/images/avatar/avt-5.jpg";
import img5 from "../assets/images/avatar/avt-7.jpg";
import img6 from "../assets/images/avatar/avt-8.jpg";
import img7 from "../assets/images/avatar/avt-2.jpg";
import imgdetail1 from "../assets/images/box-item/images-item-details2.jpg";
import OffMint from "../hooks/dataSenders/offersAndMint";
import OffTrans from "../hooks/dataSenders/offerAndTransfer";
import { Api_URL } from "../utils/ApiURL";
import Environment from "../utils/Environment";
import { ToastContainer, toast } from "react-toastify";
import BalOf from "../hooks/dataFetchers/wethBalance";
import CardModal from '../components/layouts/CardModal';
import CheckAllowance from "../hooks/dataFetchers/checkAllowance";
import moment from 'moment'
import { IpfsStorage } from "../hooks/ipfs";
import ApproveWeth from "../hooks/dataSenders/approveMarket";
import BuyMint from "../hooks/dataSenders/buyAndMint";
import BuyTransfer from "../hooks/dataSenders/buyAndTransfer";
import GetApproved from "../hooks/dataSenders/setApproval";
import AcceptBid from "../hooks/dataSenders/acceptBidFirst";
import AmountApprove from "../hooks/dataSenders/approveRenting";
import AcceptBidAg from "../hooks/dataSenders/acceptBidAgain";
import LendNft from "../hooks/dataSenders/lendNft";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import listIcon from '../assets/images/listIcon.svg';
import autionIcon from '../assets/images/autionIcon.svg';
import listFsale from '../assets/images/list-f-sale.svg';
import listFrent from '../assets/images/list-f-rent.svg';
import './Explore/explore.scss';


const ItemDetails02 = () => {
  const [loader, setLoader] = useState(false);
  const [nft, setNft] = useState(null);
  // console.log('fasdfljkljkasfdlkj', nft)
  const [nfts, setNfts] = useState([]);
  const [rentOffers, setRentOffers] = useState([]);
  const [id, setId] = useState(null);
  const [user, setUser] = useState(null);
  const { account } = useWeb3React();
  const [order, setOrder] = useState(null);
  const [owner, setOwner] = useState(null);
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [auth, setAuth] = useState(null);
  const [time, setTime] = useState(false);
  const [collection, setCollection] = useState(null);
  const [approved, setApproved] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [bidAmount, setBidAmount] = useState('');
  const [bids, setBids] = useState(null);
  const { BalanceOFF } = BalOf();
  const [hist, setHist] = useState([]);
  const [r1, setR1] = useState([]);
  const [gas, setGas] = useState(0);
  const [r3, setR3] = useState([]);
  const { CheckAllow } = CheckAllowance();
  const [block, setBlock] = useState("block");
  const { OfferMint } = OffMint();
  const { OfferTrans } = OffTrans();
  const { WethMark } = ApproveWeth();
  const { ApproveMe } = GetApproved();
  const { BuyMinte } = BuyMint();
  const { BuyTrans } = BuyTransfer();
  const web3 = useWeb3()
  const { ApproveToken } = TokenAppr();
  const context = useWeb3React();
  const { chainId } = context;
  const { BidAccept } = AcceptBid();
  const { UserApprove } = AmountApprove();
  const { BidAccept2 } = AcceptBidAg();
  const { lendNfts } = LendNft();
  const [tokBalance, setTokenBalance] = useState(0)
  const [formData, setFormData] = useState({
    rentPrice: '',
    noOfDays: '',
  });
  const AddViews = () => {
    var data = JSON.stringify({
      "contractAddress": nft?.contractAddress?.toLowerCase(),
      "tokenID": parseInt(nft?.tokenID, 10),
    });

    var config = {
      method: 'post',
      url: `${Api_URL}/token/addViewsOfToken`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // setHist(response.data.history?.reverse());
        // setR1(response.data.r1?.reverse());
        // setR3(response.data.r3?.reverse());
        // setLoader(false);
      })
      .catch(function (error) {
        // setLoader(false);
      });
  }

  useEffect(() => {
    if (nft) {
      AddViews();
    }
  }, [nft])

  const GetTokenHistory = () => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("id");

    if (id !== null) {
      var data = JSON.stringify({
        "contractAddress": nft?.contractAddress?.toLowerCase(),
        "tokenID": parseInt(nft?.tokenID, 10),
      });

      var config = {
        method: 'post',
        url: `${Api_URL}/history/getHistoryOfToken`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          setHist(response.data.history?.reverse());
          setR1(response.data.r1?.reverse());
          setR3(response.data.r3?.reverse());
          // setLoader(false);
        })
        .catch(function (error) {
          // setLoader(false);
        });
    }
  }

  useEffect(() => {
    if (nft) {
      GetTokenHistory();
    }
  }, [nft])

  useEffect(() => {
    if (account) {
      getData();
    }
  }, [account])

  const getData = async () => {
    await axios
      .post(
        `${Api_URL}/user/getUserByWalletAddress`,
        {
          walletAddress: account.toLowerCase(),
        })
      .then((response) => {
        setLoader(false);
        setBlock(response.data.user.status)
      })
      .catch(function (error) {
        setLoader(false);
        console.log(error);
      });
  };

  const GetAllBids = () => {
    // setLoader(true)
    var data = JSON.stringify({
      "tokenID": nft?.tokenID?.toString(),
      "contractAddress": nft?.contractAddress?.toString().toLowerCase(),
      "chainId": nft?.chainId
    });
    var config = {
      method: 'post',
      url: `${Api_URL}/bid/getBidAndDetails`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        let bids = response.data.result;
        var data = JSON.stringify({
          "tokenID": nft?.tokenID?.toString(),
          "contractAddress": nft?.contractAddress?.toString().toLowerCase(),
          "chainId": nft?.chainId
        });
        var config = {
          method: 'post',
          url: `${Api_URL}/offer/getOfferAndDetails`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        // setLoader(true)
        axios(config)
          .then(function (response) {
            setBids([...bids, ...response.data.result]);
            setLoader(false)
          })
          .catch(function (error) {
            setLoader(false)
            console.log(error);
          });
      })
  }

  // const RentOffers = () => {
  //   var data = JSON.stringify({
  //     "tokenID": nft?.tokenID?.toString(),
  //     "contractAddress": nft?.contractAddress?.toString().toLowerCase(),
  //     // "chainId": nft?.chainId
  //   });
  //   var config = {
  //     method: 'post',
  //     url: `${Api_URL}/rentoffer/getRentOffer`,
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     data: data
  //   };
  //   axios(config)
  //     .then(function (response) {
  //       setRentOffers(response?.data?.data);
  //     })
  // }

  const DeleteRentOffer = (item) => {
    setLoader(true);
    var data = JSON.stringify({
      "rentId": item?._id
    });

    var config = {
      method: 'post',
      url: `${Api_URL}/rentOffer/removeRentOffer`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + auth,
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        // RentOffers();
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  }

  useEffect(() => {
    if (account) {
      let tok = localStorage.getItem("accessToken")
      setAuth(tok);
    }
  }, [account]);

  const LikeNft = () => {
    if (account) {
      var data = JSON.stringify({
        "walletAddress": account,
        "_id": nft?._id,
        "contractAddress": nft?.contractAddress,
      });
      var config = {
        method: 'post',
        url: `${Api_URL}/token/like`,
        headers: {
          Authorization: "Bearer " + auth,
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
          setLiked(true);
          setLikes(1);
        })
        .catch(function (error) {
          setLiked(false);
          console.log(error);
        });
    } else {
      toast.error("Please connect your wallet")
    }
  }

  const UnLikeNft = () => {
    if (account) {
      var data = JSON.stringify({
        "walletAddress": account,
        "_id": nft?._id,
        "contractAddress": nft?.contractAddress,
      });
      setLiked(false);
      if (likes === 1) {
        setLikes(0);
      } else {
        setLikes(-1);
      }
      setLiked(false);
      var config = {
        method: 'post',
        url: `${Api_URL}/token/unlike`,
        headers: {
          Authorization: "Bearer " + auth,
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios(config)
        .then(function (response) {
          // console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          setLiked(true);
          setLikes(1);
          console.log(error);
        });
    } else {
      toast.error("Please connect your wallet")
    }
  }

  useEffect(() => {
    GetNft();
  }, [account]);

  const GetNft = () => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("id");
    if (id !== null) {
      setId(id);
      setLoader(true);
      var qs = require("qs");
      var data = qs.stringify({
        _id: id,
      });
      var config = {
        method: "post",
        url: `${Api_URL}/token/getTokenById`,
        headers: {
          // 'Authorization': `Bearer ${auth}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          // setLoader(false);
          setNft(response.data.token);
          setUser(response.data.owner);
          setOrder(response.data.order);
          setCollection(response.data?.collection);
          setOwner(response.data.creator);
          let token = response.data.token._id;
          let dumArr = response.data.token.likedBy;
          let Find = dumArr.find((e) => {
            return e.address === account?.toLowerCase()?.toString();
          })
          if (Find) {
            setLiked(true);
          }
        })
        .catch(function (error) {
          setLoader(false);
          console.log(error);
        });
    }
  }

  useEffect(async () => {
    if (account && nft) {
      const res = await ApproveToken(account, nft?.contractAddress);
      await setApproved(res);
    }
  }, [account, nft])

  useEffect(() => {
    if (nft) {
      GetAllBids();
      // RentOffers();
    }
  }, [nft])

  const PurchaseNft = async () => {
    if (account) {
    let balance=await  web3.eth.getBalance(account)
    balance = balance/1e18
      if (parseFloat(balance) <= parseFloat(nft?.metaData?.price)){
        toast.info('BNB Balance is Insufficent')
        return
      }
    
      if (block === "active") {
        try {
          let allowed = await CheckAllow(account);
          if (allowed) {
            allowed = parseFloat(parseFloat(allowed) / 10000000000000000);
          }
          let so = order?.signature;
          so = so.substring(2)
          let or = '0x' + so.slice(0, 64);
          let os = '0x' + so.slice(64, 128);
          let ov = '0x' + so.slice(128, 130);
          var ts = nft?.metaData?.endTime;
          var unix_seconds = ((new Date(ts)).getTime()) / 1000;
          let soliditySha3Expected = null;
          if (chainId === 97) {
            soliditySha3Expected = soliditySha3(
              Environment?.marketPlaceContract,
              account?.toLowerCase(),
              web3.utils.toWei(nft?.metaData?.price.toString()),
              nft?.metaData?.startTime
            );
          }
          // else if (chainId === 4) {
          //   soliditySha3Expected = soliditySha3(
          //     Environment?.Eth.MarketContract,
          //     account?.toLowerCase(),
          //     web3.utils.toWei(nft?.metaData?.price.toString()),
          //     nft?.metaData?.startTime
          //   );
          // } else if (chainId === 80001) {
          //   soliditySha3Expected = soliditySha3(
          //     Environment?.Polygon.MarketContract,
          //     account?.toLowerCase(),
          //     web3.utils.toWei(nft?.metaData?.price.toString()),
          //     nft?.metaData?.startTime
          //   );
          // } else if (chainId === 43114) {
          //   soliditySha3Expected = soliditySha3(
          //     Environment?.Avax.MarketContract,
          //     account?.toLowerCase(),
          //     web3.utils.toWei(nft?.metaData?.price.toString()),
          //     nft?.metaData?.startTime
          //   );
          // }
          let signature = null;
          await web3.eth.personal.sign(soliditySha3Expected, account).then(async (res) => {
            signature = res;
            signature = signature.substring(2)
            let r = '0x' + signature.slice(0, 64);
            let s = '0x' + signature.slice(64, 128);
            let v = '0x' + signature.slice(128, 130);
            setLoader(true);
            // const minALLocation1 = new BigNumber(nft?.metaData?.price).multiply(new BigNumber(10).pow(18));
            let values = {
              _seller: nft?.ownerAddress,
              _bidder: account,
              _amount: web3.utils.toWei(nft?.metaData?.price.toString()),
              tokenId: nft?.tokenID
            }
            let values1 = {
              r,
              s,
              v
            }
            let values2 = {
              r: or,
              s: os,
              v: ov
            }
            let image1 = null;
            if (nft?.bulk === true) {
              let dumImg = {
                name: nft?.nftName,
                image: nft?.imageUrl,
                description: nft?.description,
                attributes: nft?.properties
              }
              const res0 = await IpfsStorage(JSON.stringify(dumImg));
              image1 = res0;
            } else {
              let dumImg = {
                name: nft?.nftName,
                image: nft?.image,
                description: nft?.description,
                attributes: nft?.properties
              }
              const res0 = await IpfsStorage(JSON.stringify(dumImg));
              image1 = res0;
            }
            if (nft?.isMinted === false) {
              try {
                const res = await BuyMinte(account, values, image1, values2, values1, nft?.contractAddress, nft?.metaData?.startTime);
                if (res) {
                  var data = JSON.stringify({
                    "bidderAddress": account?.toLowerCase(),
                    "sellerAddress": nft?.ownerAddress?.toLowerCase(),
                    "contractAddress": nft?.contractAddress?.toString()?.toLowerCase(),
                    "tokenID": nft?.tokenID?.toString(),
                    "amount": nft?.metaData?.price,
                    "chainId": nft?.chainId
                  });

                  var config = {
                    method: 'post',
                    url: `${Api_URL}/bid/acceptBid`,
                    headers: {
                      Authorization: "Bearer " + auth,
                      'Content-Type': 'application/json'
                    },
                    data: data
                  };

                  axios(config)
                    .then(function (response) {
                      GetNft();
                      GetTokenHistory();
                      toast.success("Nft purchased successfully")
                      setLoader(false);
                      window.$("#buymodal").modal("hide")
                      // setBids(response.data.bid);
                    })
                    .catch(function (error) {
                      setLoader(false);
                      console.log(error);
                    });
                }
              } catch (err) {
                toast.error(err);
                setLoader(false);
              }
            } else {
              let res = null;
              if (approved === false) {
                const res0 = await ApproveMe(account, nft?.contractAddress);
                if (res0) {
                  setApproved(true);
                  // res = await OfferTrans(account, values, v, r, s, bv, br, bs, nft?.contractAddress);
                  res = await BuyTrans(account, values, values2, values1, nft?.contractAddress, collection?.royaltiesAddress, collection?.percentageFee, nft?.metaData?.startTime);
                }
              } else {
                // res = await OfferTrans(account, values, v, r, s, bv, br, bs, nft?.contractAddress);
                res = await BuyTrans(account, values, values2, values1, nft?.contractAddress, collection?.royaltiesAddress, collection?.percentageFee, nft?.metaData?.startTime);
              }
              if (res) {
                var data = JSON.stringify({
                  "bidderAddress": account?.toLowerCase(),
                  "sellerAddress": nft?.ownerAddress?.toLowerCase(),
                  "contractAddress": nft?.contractAddress?.toString()?.toLowerCase(),
                  "tokenID": nft?.tokenID?.toString(),
                  "amount": nft?.metaData?.price,
                  "chainId": nft?.chainId
                });

                var config = {
                  method: 'post',
                  url: `${Api_URL}/bid/acceptBid`,
                  headers: {
                    Authorization: "Bearer " + auth,
                    'Content-Type': 'application/json'
                  },
                  data: data
                };

                axios(config)
                  .then(function (response) {
                    GetNft();
                    GetTokenHistory();
                    toast.success("Nft purchased successfully")
                    setLoader(false);
                    window.$("#buymodal").modal("hide")
                    // setBids(response.data.bid);
                  })
                  .catch(function (error) {
                    setLoader(false);
                    console.log(error);
                  });
              }
            }
          }).catch((err) => {
            setLoader(false);
            throw err;
          });
        } catch (error) {
          await toast.error(error.message);
          await setLoader(false);
        }
      } else {
        toast.error("You are blocked from admin");
      }
    } else {
      toast.error("Connect your wallet")
    }
  }

  const SetUpBid = async () => {
    let hight = null;
    let hight2 = null;
    if (bids.length > 0) {
      hight2 = bids[0]?.amount;
      if (parseFloat(bidAmount) >= (hight2 + hight2 * 0.1)) {
        hight = 1;
      } else {
        hight = null;
      }
    } else {
      hight = 1;
    }
    if (hight) {
      let timeNow = new Date();
      timeNow = timeNow.getTime() / 1000
      timeNow = timeNow?.toFixed()
      if (block === "active") {
        if (parseFloat(bidAmount) >= parseFloat(nft?.metaData?.price)) {
          let res = null;
          const res010101 = await BalanceOFF(account);
          console.log('first', res010101)
          if (parseInt(res010101) / 1000000000000000000 > parseFloat(nft?.metaData?.price)) {
            let allowed = await CheckAllow(account);
            if (allowed) {
              allowed = parseFloat(parseFloat(allowed) / 10000000000000000);
              console.log("aaaaa", allowed)
            }
            if (account) {
              if (parseFloat(bidAmount) > allowed) {
                res = await WethMark(account);
              } else {
                res = 1;
              }
              if (res) {
                setLoader(true);
                var ts = nft?.metaData?.endTime;
                var unix_seconds = ((new Date(ts)).getTime()) / 1000;
                let soliditySha3Expected = null;
                // console.log("okokokokok", Environment?.marketPlaceContract,
                //   account,
                //   unix_seconds,
                //   web3.utils.toWei(bidAmount.toString()),
                //   nft?.tokenID,
                //   Environment.weth);
                soliditySha3Expected = soliditySha3(
                  Environment?.marketPlaceContract,
                  account,
                  unix_seconds,
                  web3.utils.toWei(bidAmount.toString()),
                  nft?.tokenID,
                  Environment.weth
                );

                // if (chainId === 97) {
                //   soliditySha3Expected = soliditySha3(
                //     Environment?.Bsc.MarketContract,
                //     account,
                //     unix_seconds,
                //     web3.utils.toWei(bidAmount.toString()),
                //     nft?.tokenID,
                //     Environment.Bsc.weth
                //   );
                // } else if (chainId === 4) {
                //   soliditySha3Expected = soliditySha3(
                //     Environment?.Eth.MarketContract,
                //     account,
                //     unix_seconds,
                //     web3.utils.toWei(bidAmount.toString()),
                //     nft?.tokenID,
                //     Environment.Eth.weth
                //   );
                // } else if (chainId === 80001) {
                //   soliditySha3Expected = soliditySha3(
                //     Environment?.Polygon.MarketContract,
                //     account,
                //     unix_seconds,
                //     web3.utils.toWei(bidAmount.toString()),
                //     nft?.tokenID,
                //     Environment.Polygon.weth
                //   );
                // } else if (chainId === 43114) {
                //   soliditySha3Expected = soliditySha3(
                //     Environment?.Avax.MarketContract,
                //     account,
                //     unix_seconds,
                //     web3.utils.toWei(bidAmount.toString()),
                //     nft?.tokenID,
                //     Environment.Avax.weth
                //   );
                // }
                let something = null;
                setLoader(true);
                await web3.eth.personal.sign(soliditySha3Expected, account).then((res) => {
                  something = res;
                  if (something) {
                    var data = JSON.stringify({
                      "bidderAddress": account?.toLowerCase(),
                      "sellerAddress": nft?.creatorAddress?.toString()?.toLowerCase(),
                      "contractAddress": nft?.contractAddress?.toString()?.toLowerCase(),
                      "tokenID": nft?.tokenID?.toString(),
                      "amount": bidAmount,
                      "onOffer": false,
                      "signature": something,
                      "signTime": timeNow,
                      "chainId": nft?.chainId,
                      "isMinted": nft?.isMinted
                    });
                    var config = {
                      method: 'post',
                      url: `${Api_URL}/bid/addBid`,
                      headers: {
                        Authorization: "Bearer " + auth,
                        'Content-Type': 'application/json'
                      },
                      data: data
                    };

                    axios(config)
                      .then(function (response) {
                        toast.success("Bid added successfully")
                        setModalShow(false)
                        GetAllBids();
                        setLoader(false);
                        // setBids(response.data.bid);
                      })
                      .catch(function (error) {
                        setLoader(false);
                        toast.error('Offer Failed')
                        console.log(error);
                      });
                  } else {
                    setLoader(false);
                    toast.error('Offer Failed')
                  }
                }).catch((err) => {

                  toast.error('Offer Failed')
                  setLoader(false);
                  throw err;
                });
              } else {
                toast.error("Offer Failed")
                setLoader(false);
              }
            } else {
              toast.error("Please connect your wallet first")
            }
          } else {
            toast.error("Not enough WBNB balance")
          }
        } else {
          toast.error(`Minimum bid amount ${nft?.metaData?.price}`)
        }
      } else {
        toast.error("You are blocked from admin");
      }
    } else {
      toast.error(`Minimum bid amount ${(hight2 + hight2 * 0.1)}`);
    }
  }

  const SendOffer = async () => {
    if (!bidAmount || bidAmount === ''){
      toast.error('Enter Valid Amount')
      return
    }
    setLoader(true);
    // return 0;
    if (bidAmount !== 0) {
      let timeNow = new Date();
      timeNow = timeNow.getTime() / 1000
      timeNow = timeNow?.toFixed()
      if (block === "active") {
        let res = null;
        let allowed = await CheckAllow(account);
        if (allowed) {
          allowed = parseFloat(parseFloat(allowed) / 10000000000000000);
        }
        let pric = parseFloat(nft?.metaData?.price);
        if (isNaN(pric)) {
          pric = 0;
        }
        if (account) {
          //jawad
          const res010101 = await BalanceOFF(account);
          console.log("!!!!", parseInt(res010101, 10) / 1000000000000000000, pric)
          if (parseInt(res010101, 10) / 1000000000000000000 > pric) {
            if (parseFloat(bidAmount) > allowed) {
              console.log("res010101coolqq", allowed)
              res = await WethMark(account);
            } else {
              res = 1;
            }
            console.log("res010101cool", res)
            if (res) {
              let soliditySha3Expected = null;
              console.log("res010101", Environment?.marketPlaceContract,
                account,
                web3.utils.toWei(bidAmount.toString()),
                timeNow,
                Environment?.marketPlaceContract,
                nft?.tokenID,
                Environment?.weth)
              // if (chainId === 97) {
              soliditySha3Expected = soliditySha3(
                Environment?.marketPlaceContract,
                account,
                web3.utils.toWei(bidAmount.toString()),
                timeNow,
                nft?.tokenID,
                Environment?.weth
              );
              // } else if (chainId === 4) {
              //   soliditySha3Expected = soliditySha3(
              //     Environment?.Eth.MarketContract,
              //     account,
              //     web3.utils.toWei(bidAmount.toString()),
              //     timeNow,
              //     nft?.tokenID,
              //     Environment?.Eth.weth
              //   );
              // } else if (chainId === 80001) {
              //   soliditySha3Expected = soliditySha3(
              //     Environment?.Polygon.MarketContract,
              //     account,
              //     web3.utils.toWei(bidAmount.toString()),
              //     timeNow,
              //     nft?.tokenID,
              //     Environment?.Polygon.weth
              //   );
              // } else if (chainId === 43114) {
              //   soliditySha3Expected = soliditySha3(
              //     Environment?.Avax.MarketContract,
              //     account,
              //     web3.utils.toWei(bidAmount.toString()),
              //     timeNow,
              //     nft?.tokenID,
              //     Environment?.Polygon.weth
              //   );
              // }
              setLoader(true);
              let something = null;
              await web3.eth.personal.sign(soliditySha3Expected, account).then((res) => {
                something = res;
                if (something) {
                  var data = JSON.stringify({
                    "bidderAddress": account?.toLowerCase(),
                    "sellerAddress": nft?.ownerAddress?.toString()?.toLowerCase(),
                    "contractAddress": nft?.contractAddress?.toString()?.toLowerCase(),
                    "tokenID": nft?.tokenID?.toString(),
                    "amount": bidAmount,
                    "onOffer": true,
                    "signature": something,
                    "signTime": timeNow,
                    "chainId": nft?.chainId,
                    "isMinted": nft?.isMinted
                  });

                  var config = {
                    method: 'post',
                    url: `${Api_URL}/offer/addOffer`,
                    headers: {
                      Authorization: "Bearer " + auth,
                      'Content-Type': 'application/json'
                    },
                    data: data
                  };

                  axios(config)
                    .then(function (response) {
                      setModalShow(false)
                      new Date(nft.metaData.endTime) > new Date() ?
                        toast.success("Bid added successfully") : toast.success("Offer added successfully") 
                      window.$("#offermodal").modal("hide")
                      GetAllBids();
                      setLoader(false);
                      // setBids(response.data.bid);
                    })
                    .catch(function (error) {
                      setLoader(false);
                      toast.error('Offer Failed');
                      console.error(error); // Log the error for debugging purposes
                    });
                } else {
                  setLoader(false);
                  toast.error('Offer Failed: Signature is empty');
                }
              }).catch((err) => {
                if (err.message.includes("User denied message signature")) {
                  toast.error('Offer Failed: User denied signature');
                } else {
                  toast.error('Offer Failed: Signature error');
                  console.error(err); // Log the error for debugging purposes
                }
                setLoader(false);
              });

            } else {
              setLoader(false);
            }
          } else {
            setLoader(false)
            toast.error(`Not enough WBNB`)
          }
        } else {
          setLoader(false)
          toast.error("Please connect your wallet first")
        }
      } else {
        setLoader(false)
        toast.error("You are blocked from admin");
      }
    } else {
      setLoader(false)
      toast.error("Offer amount can't be 0");
    }
    setLoader(false)
  }

  const OfferAndMint = async (item) => {
    if (block === "active") {
      let sign = item?.signature.substring(2)
      let br = '0x' + sign.slice(0, 64)
      let bs = '0x' + sign.slice(64, 128);
      let bv = '0x' + sign.slice(128, 130);
      // const soliditySha3Expected = soliditySha3(
      //   Environment?.MarketContract,
      //   account,
      //   web3.utils.toWei(item?.amount.toString())
      // );
      console.log("sign", sign)
      console.log(Environment?.marketPlaceContract,
        account,
        web3.utils.toWei(item?.amount.toString()),
        item?.signTime,
        nft?.tokenID,
        Environment?.weth)
      let soliditySha3Expected = null;
      // if (chainId === 97) {
      soliditySha3Expected = soliditySha3(
        Environment?.marketPlaceContract,
        account,
        web3.utils.toWei(item?.amount.toString()),
        item?.signTime,
        nft?.tokenID,
        Environment?.weth
      );
      // } else if (chainId === 4) {
      //   soliditySha3Expected = soliditySha3(
      //     Environment?.Eth.MarketContract,
      //     account,
      //     web3.utils.toWei(item?.amount.toString()),
      //     item?.signTime,
      //     Environment?.Eth.MarketContract,
      //     nft?.tokenID,
      //     Environment?.Eth.weth
      //   );
      // } else if (chainId === 80001) {
      //   soliditySha3Expected = soliditySha3(
      //     Environment?.Polygon.MarketContract,
      //     account,
      //     web3.utils.toWei(item?.amount.toString()),
      //     item?.signTime,
      //     nft?.tokenID,
      //     Environment?.Polygon.weth
      //   );
      // } else if (chainId === 43114) {
      //   soliditySha3Expected = soliditySha3(
      //     Environment?.Avax.MarketContract,
      //     account,
      //     web3.utils.toWei(item?.amount.toString()),
      //     item?.signTime,
      //     nft?.tokenID,
      //     Environment?.Polygon.weth
      //   );
      // }
      // let signature = await web3.eth.sign(soliditySha3Expected, account);
      let signature = null;
      await web3.eth.personal.sign(soliditySha3Expected, account).then(async (res) => {
        signature = res;
        signature = signature.substring(2)
        let r = '0x' + signature.slice(0, 64);
        let s = '0x' + signature.slice(64, 128);
        let v = '0x' + signature.slice(128, 130);
        setLoader(true);
        let values = {
          _seller: account,
          _bidder: item?.bidderAddress,
          _amount: web3.utils.toWei(item?.amount.toString()),
          tokenId: nft?.tokenID
        }
        let values2 = {
          r,
          s,
          v
        }
        let values3 = {
          r: br,
          s: bs,
          v: bv
        }
        if (nft?.isMinted === false) {
          let image1 = null;
          if (nft?.bulk === true) {
            let dumImg = {
              name: nft?.nftName,
              image: nft?.imageUrl,
              description: nft?.description,
              attributes: nft?.properties
            }
            const res0 = await IpfsStorage(JSON.stringify(dumImg));
            image1 = res0;
          } else {
            let dumImg = {
              name: nft?.nftName,
              image: nft?.image,
              description: nft?.description,
              attributes: nft?.properties
            }
            const res0 = await IpfsStorage(JSON.stringify(dumImg));
            image1 = res0;
          }
          console.log("image1image1image1image1", image1);
          if (image1) {
            try {
              const res = await OfferMint(account, values, image1, values2, values3, nft?.contractAddress, item?.signTime);
              if (res) {
                var data = JSON.stringify({
                  "bidderAddress": item?.bidderAddress?.toLowerCase(),
                  "sellerAddress": account?.toLowerCase(),
                  "contractAddress": nft?.contractAddress?.toString()?.toLowerCase(),
                  "tokenID": nft?.tokenID?.toString(),
                  "amount": item?.amount,
                  "chainId": nft?.chainId
                });

                var config = {
                  method: 'post',
                  url: `${Api_URL}/offer/acceptOffer`,
                  headers: {
                    Authorization: "Bearer " + auth,
                    'Content-Type': 'application/json'
                  },
                  data: data
                };

                axios(config)
                  .then(function (response) {
                    GetNft();
                    GetTokenHistory();
                    toast.success("Nft sold successfully")
                    setLoader(false);
                    // setBids(response.data.bid);
                  })
                  .catch(function (error) {
                    setLoader(false);
                    console.log(error);
                  });
              }
            } catch (err) {
              toast.error(err);
              setLoader(false);
            }
          }
        } else {
          let res = null;
          setLoader(true);
          if (approved === false) {
            const res0 = await ApproveMe(account, nft?.contractAddress);
            if (res0) {
              setApproved(true);
              try {
                res = await OfferTrans(account, values, values2, values3, nft?.contractAddress, collection?.royaltiesAddress, collection?.percentageFee, item?.signTime);
              } catch (e) {
                setLoader(e?.message);
              }
            }
          } else {
            try {
              res = await OfferTrans(account, values, values2, values3, nft?.contractAddress, collection?.royaltiesAddress, collection?.percentageFee, item?.signTime)
            } catch (e) {
              setLoader(e?.message);
            }
          }
          if (res) {
            var data = JSON.stringify({
              "bidderAddress": item?.bidderAddress?.toLowerCase(),
              "sellerAddress": account?.toLowerCase(),
              "contractAddress": nft?.contractAddress?.toString()?.toLowerCase(),
              "tokenID": nft?.tokenID?.toString(),
              "amount": item?.amount,
              "chainId": nft?.chainId
            });

            var config = {
              method: 'post',
              url: `${Api_URL}/offer/acceptOffer`,
              headers: {
                Authorization: "Bearer " + auth,
                'Content-Type': 'application/json'
              },
              data: data
            };

            axios(config)
              .then(function (response) {
                GetNft();
                GetTokenHistory();
                toast.success("Nft sold successfully")
                setLoader(false);
                // setBids(response.data.bid);
              })
              .catch(function (error) {
                setLoader(false);
                console.log(error);
              });
          }
        }
      }).catch((err) => {
        setLoader(false);
        throw err;
      });
    } else {
      toast.error("You are blocked from admin");
    }
  }

  const AcceptBids = async (item) => {
    if (block === "active") {
      let sign = item?.signature.substring(2)
      let br = '0x' + sign.slice(0, 64)
      let bs = '0x' + sign.slice(64, 128);
      let bv = '0x' + sign.slice(128, 130);
      var ts = nft?.metaData?.endTime;
      var unix_seconds = ((new Date(ts)).getTime()) / 1000;
      // const soliditySha3Expected = soliditySha3(
      //   Environment?.MarketContract,
      //   account,
      //   unix_seconds,
      //   web3.utils.toWei(item?.amount.toString())
      // );
      let soliditySha3Expected = null;
      console.log("okokokokokok",
        Environment?.marketPlaceContract,
        account,
        unix_seconds,
        web3.utils.toWei(item?.amount.toString()),
        nft?.tokenID,
        Environment?.weth)
      if (chainId === 97) {
        soliditySha3Expected = soliditySha3(
          Environment?.marketPlaceContract,
          account,
          unix_seconds,
          web3.utils.toWei(item?.amount.toString()),
          nft?.tokenID,
          Environment?.weth
        );
      }
      // else if (chainId === 4) {
      //   soliditySha3Expected = soliditySha3(
      //     Environment?.Eth.MarketContract,
      //     account,
      //     unix_seconds,
      //     web3.utils.toWei(item?.amount.toString()),
      //     nft?.tokenID,
      //     Environment?.Eth.weth
      //   );
      // } else if (chainId === 80001) {
      //   soliditySha3Expected = soliditySha3(
      //     Environment?.Polygon.MarketContract,
      //     account,
      //     unix_seconds,
      //     web3.utils.toWei(item?.amount.toString()),
      //     nft?.tokenID,
      //     Environment?.Polygon.weth
      //   );
      // } else if (chainId === 43114) {
      //   soliditySha3Expected = soliditySha3(
      //     Environment?.Avax.MarketContract,
      //     account,
      //     unix_seconds,
      //     web3.utils.toWei(item?.amount.toString()),
      //     nft?.tokenID,
      //     Environment?.Avax.weth
      //   );
      // }
      // let signature = await web3.eth.sign(soliditySha3Expected, account);
      let signature = null;
      setLoader(true);
      await web3.eth.personal.sign(soliditySha3Expected, account).then(async (res) => {
        signature = res;
        signature = signature.substring(2)
        let r = '0x' + signature.slice(0, 64);
        let s = '0x' + signature.slice(64, 128);
        let v = '0x' + signature.slice(128, 130);
        let values = {
          _seller: account,
          _bidder: item?.bidderAddress,
          _amount: web3.utils.toWei(item?.amount.toString()),
          // _amount: parseFloat(item?.amount) * 1000000000000000000,
          tokenId: nft?.tokenID
        }
        let values1 = {
          r,
          s,
          v
        }
        let values2 = {
          r: br,
          s: bs,
          v: bv
        }

        // test
        // const ressssss = await CheckVer(account, account, unix_seconds, parseFloat(item?.amount) * 1000000000000000000, v, r, s, v, r, s);
        // return 0;
        // 
        if (nft?.isMinted === false) {
          // toast.danger('danger')
          let image1 = null;
          if (nft?.bulk === true) {
            let dumImg = {
              name: nft?.nftName,
              image: nft?.imageUrl,
              description: nft?.description,
              attributes: nft?.properties
            }
            const res0 = await IpfsStorage(JSON.stringify(dumImg));
            image1 = res0;
          } else {
            let dumImg = {
              name: nft?.nftName,
              image: nft?.image,
              description: nft?.description,
              attributes: nft?.properties
            }
            const res0 = await IpfsStorage(JSON.stringify(dumImg));
            image1 = res0;
          }
          if (image1) {
            const res = await BidAccept(account, values, image1, unix_seconds, values1, values2, nft?.contractAddress);
            // const res = true;
            await setLoader(false);
            if (res?.code === 4001) {
              await toast.error("Transaction rejected by user!");
            } else if (res?.code) {
              await toast.error("Transaction failed!");
            } else {
              var data = JSON.stringify({
                "bidderAddress": item?.bidderAddress?.toLowerCase(),
                "sellerAddress": account?.toLowerCase(),
                "contractAddress": nft?.contractAddress?.toString()?.toLowerCase(),
                "tokenID": nft?.tokenID?.toString(),
                "amount": item?.amount,
                "chainId": nft?.chainId
              });

              var config = {
                method: 'post',
                url: `${Api_URL}/bid/acceptBid`,
                headers: {
                  Authorization: "Bearer " + auth,
                  'Content-Type': 'application/json'
                },
                data: data
              };

              axios(config)
                .then(function (response) {
                  GetNft();
                  GetTokenHistory();
                  toast.success("Bid accepted successfully")
                  setLoader(false);
                  // setBids(response.data.bid);
                })
                .catch(function (error) {
                  setLoader(false);
                  console.log(error);
                });
            }
          }
        } else {
          let res = null;
          if (approved === false) {
            const res0 = await ApproveMe(account, nft?.contractAddress);
            if (res0) {
              setApproved(true);
              res = res = await BidAccept2(account, values, unix_seconds, values1, values2, nft?.contractAddress, collection?.royaltiesAddress, collection?.percentageFee);
              await setLoader(false);
            }
          } else {
            res = res = await BidAccept2(account, values, unix_seconds, values1, values2, nft?.contractAddress, collection?.royaltiesAddress, collection?.percentageFee);
            await setLoader(false);
          }
          var data = JSON.stringify({
            "bidderAddress": item?.bidderAddress?.toLowerCase(),
            "sellerAddress": account?.toLowerCase(),
            "contractAddress": nft?.contractAddress?.toString()?.toLowerCase(),
            "tokenID": nft?.tokenID?.toString(),
            "amount": item?.amount,
            "chainId": nft?.chainId
          });

          var config = {
            method: 'post',
            url: `${Api_URL}/bid/acceptBid`,
            headers: {
              Authorization: "Bearer " + auth,
              'Content-Type': 'application/json'
            },
            data: data
          };

          axios(config)
            .then(function (response) {
              GetNft();
              GetTokenHistory();
              toast.success("Bid accepted successfully")
              setLoader(false);
              // setBids(response.data.bid);
            })
            .catch(function (error) {
              setLoader(false);
              console.log(error);
            });
        }
      }).catch((err) => {
        setLoader(false);
        throw err;
      });
    } else {
      toast.error("You are blocked from admin");
    }
  }

  useEffect(() => {
    var qs = require("qs");
    var data = qs.stringify({
      chainId: chainId?.toString(),
    });
    var config = {
      method: "get",
      url: `${Api_URL}/token/getAllToken`,
      headers: {},
    };

    axios(config)
      .then(function (response) {
        let dumArr = [];
        for (let i of response.data.user) {
          if (i?.metaData?.method === "auction" && i?.onSale) {
            if (new Date(i.metaData.endTime) > new Date()) {
              dumArr.push(i);
            }
          }
        }
        setNfts(dumArr);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  const DeleteOffer = (item) => {
    setLoader(true);
    var data = JSON.stringify({
      "contractAddress": item?.contractAddress,
      "tokenID": item?.tokenID,
      "bidderAddress": item?.bidderAddress,
      "chainId": item?.chainId,
      "amount": item?.amount
    });

    var config = {
      method: 'post',
      url: `${Api_URL}/offer/deleteoffer`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + auth,
      },
      data: data
    };

    axios(config)
      .then(function (response) {

        GetAllBids();
        // setHist(response.data.history?.reverse());
        // setR1(response.data.r1?.reverse());
        // setR3(response.data.r3?.reverse());
        // setLoader(false);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  }

  const DeleteBid = (item) => {
    setLoader(true);
    var data = JSON.stringify({
      "contractAddress": item?.contractAddress,
      "tokenID": item?.tokenID,
      "bidderAddress": item?.bidderAddress,
      "chainId": item?.chainId,
      "amount": item?.amount
    });

    var config = {
      method: 'post',
      url: `${Api_URL}/bid/deletebid`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: "Bearer " + auth,
      },
      data: data
    };

    axios(config)
      .then(function (response) {

        GetAllBids();
        // setHist(response.data.history?.reverse());
        // setR1(response.data.r1?.reverse());
        // setR3(response.data.r3?.reverse());
        // setLoader(false);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
      });
  }

  useEffect(() => {
    if (nft) {
      if (nft?.metaData) {
        if (new Date(nft?.metaData.endTime) > new Date()) {
          setTime(true);
        }
      }
    }
  }, [nft])

  useEffect(() => {
    const intervalId = setInterval(() => {
      GetGas();
    }, 30000)
    return () => clearInterval(intervalId); //This is important
  }, [])

  const GetGas = async () => {
    let gas0 = await web3.eth.getGasPrice();
    let gas1 = web3.utils.fromWei(gas0)
    // gas0 = parseInt(gas0) / 10000000000000000;
    setGas(gas1.toString());
  }



  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setrentstate(false);
  };
  const handleShow = () => setShow(true);

  const [rentstate, setrentstate] = useState(false);
  const [salestate, setsalestate] = useState(false);
  // console.log(rentstate, 'rentstate');
  const renthandle = () => {
    console.log('.....>>>>>>>>>>>>>>1');
    setrentstate(true);
    setsalestate(false);
    console.log('...........>>>>>>>>>>>22222');
  }

  const salehandle = () => {
    setrentstate(false);
    setsalestate(true);
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  }

  function getEpochTimeAfterDays(days) {
    const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

    const currentDate = new Date();
    const futureDate = new Date(currentDate.getTime() + days * millisecondsPerDay);
    const epochTime = Math.floor(futureDate.getTime() / 1000);
    return epochTime;
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData?.noOfDays < 2) {
      toast.info('Rent For Atleast 2 days'
      )
      return;
    }
    if (formData?.rentPrice &&
      formData?.noOfDays) {
      let token = localStorage.getItem("accessToken");

      // ***************************************************************************************
      setLoader(true)
      let soliditySha3Expected = null;
      let signature = false;
      if (chainId === 97) {
        soliditySha3Expected = soliditySha3(
          // Environment?.marketPlaceContract,
          account?.toLowerCase(),
          // web3.utils.toWei(meta?.price.toString()),
          // meta.startTime
        );
      } else if (chainId === 5) {
        soliditySha3Expected = soliditySha3(
          // Environment?.marketPlaceContract,
          account?.toLowerCase(),
          // web3.utils.toWei(meta?.price.toString()),
          // meta.startTime
        );
      } else if (chainId === 80001) {
        soliditySha3Expected = soliditySha3(
          // Environment?.marketPlaceContract,
          account?.toLowerCase(),
          // web3.utils.toWei(meta?.price.toString()),
          // meta.startTime
        );
      } else if (chainId === 43114) {
        soliditySha3Expected = soliditySha3(
          // Environment?.marketPlaceContract,
          account?.toLowerCase(),
          // web3.utils.toWei(meta?.price.toString()),
          // meta.startTime
        );
      }
      await web3.eth.personal.sign(soliditySha3Expected, account).then(async (res) => {
        signature = res;
        if (signature) {
          // let respond
          // try {
          //   const res = await UserApprove(nft?.tokenID, nft?.contractAddress, account)
          //   respond = true
          // } catch (error) {
          //   respond = false
          // }
          if (true) {
            try {
              const epochTimeExpiryTime = getEpochTimeAfterDays(formData?.noOfDays);
              console.log('wowowowowow', epochTimeExpiryTime)
              const resCon = await lendNfts(nft?.contractAddress, nft?.tokenID, formData?.rentPrice, epochTimeExpiryTime, account)
              // return null;
              const data = {
                contractAddress: nft?.contractAddress,
                signature: signature,
                tokenID: nft?.tokenID,
                chainId: nft?.chainId,
                rentPricePerDay: formData?.rentPrice,
                noOfDays: formData?.noOfDays,
              };
              var config = {
                method: "post",
                url: `${Api_URL}/rentOrder/addRentOrder`,
                headers: {
                  Authorization: "Bearer " + token,
                  "Content-Type": "application/json"
                },
                data: data
              };
              axios(config)
                .then(function (response) {
                  toast.success("Listed For Rent")
                  GetNft();
                  handleClose()
                  setLoader(false);
                })
                .catch(function (error) {
                  setLoader(false);
                  // console.log("getApi error", error);
                });
            } catch (error) {
              setLoader(false);
              toast.info('Renting Failed')
            }
          } else {
            setLoader(false);
            toast.info('Renting Contract not approved')
          }
        } else {
          setLoader(false);
        }
      }).catch((err) => {
        // console.log("ddddd", err)
        setLoader(false);
        throw err;
      });



      const url = Api_URL + '/rentOrder/addRentOrder'; // Replace with your API endpoint URL

      const data = {
        contractAddress: nft?.contractAddress,
        signature: '0x6513ddc13ddc13ddc13ddc13ddc13ddc',
        tokenID: nft?.tokenID,
        chainId: nft?.chainId,
        rentPrice: formData?.rentPrice,
        noOfDays: formData?.noOfDays,
      };
      // Perform the POST request
      // axios.post(url, data)
      //   .then(response => {
      //     // Handle the response data
      //     console.log(response.data);
      //   })
      //   .catch(error => {
      //     // Handle any errors
      //     console.error('Error:', error);
      //   });
    } else {
      toast.info('Fill all fields')
    }

  }
  const WEI_FACTOR = 1e18; // 1 wei = 10^18
  const makeOfferFunc = async (e) => {
    const inputValue = e.target.value;
    console.log('inoooo', WEI_FACTOR)
    if (inputValue?.includes('-')) {
      setBidAmount('');
      toast.error('Enter Valid no')
      return;
    }
    if (!inputValue) {
      setBidAmount('');
      return;
    }

    let tokenBalance = parseFloat(tokBalance) / WEI_FACTOR;
    if (tokenBalance === 0) {
      // Display toast when token balance is zero
      toast.error("Token balance is zero. Cannot make an offer.");
      return;
    }

    if (parseFloat(inputValue) <= tokenBalance) {
      console.log("!!!!", tokenBalance);
      setBidAmount(inputValue);
    } else {
      toast.info(`Value can't be greater then your Token Balance ${parseFloat(tokenBalance)?.toFixed(2)} `)
      setBidAmount('');
    }
  };

  useEffect(() => {
    // self calling function
    async function name() {
      const res = await BalanceOFF(account);
      setTokenBalance(res)
    }
    if (account) {
      name()
    }
  }, [account])

  return (
    <div className="item-details putonsale">
      {loader && <Loader />}
      <Header />
      <div className="tf-section tf-item-details style-2">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-md-12">
              <div className="content-left">
                <div className="media w-100">
                  {/* <img src={imgdetail1} alt="Axies" /> */}
                  <img className="w-100" src={nft?.image} alt="Axies" />
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-md-12">
              <div className="content-right">
                <div className="sc-item-details">
                  <div className="metaaa-item">
                    <div className="left d-flex justify-content-between align-items-center">
                      <h2>“{nft?.nftName?.length > 40 ? <>{nft?.nftName + "..."}</> : nft?.nftName}”</h2>
                      {user?.walletAddress?.toLowerCase() === account?.toLowerCase() || (account && <Link to={`/swap?id=${user?.walletAddress}|${id}`}><button className="oooo">Swap</button></Link>)}
                    </div>
                    <div className="sec-lineflex">
                      <div className=" w-100 d-flex justify-content-between align-items-center">
                        <div className="ffirstone d-flex">
                          <span className="vieweddd eye mg-r-8 item-viewed">
                            {" "}
                            <img src="\eyes-black.png" alt="Axies" className="pr-3" />
                            {nft?.views}
                          </span>
                          <span className="vieweddd eye mg-r-8 item-viewed ">
                            {" "}
                            {liked ? (
                              <img
                                onClick={() => UnLikeNft()}
                                src="\newheart.png"
                                alt="Axies"
                                className="pr-3"
                              />
                            ) : (
                              <img
                                onClick={() => LikeNft()}
                                src="\heart-black.png"
                                alt="Axies"
                                className="pr-3 "
                              />
                            )}
                            {nft?.likedBy?.length + likes}
                          </span>

                          {/* <span to="/login" className="liked heart wishlist-button"><span className="number-like vieweddd">100</span></span> */}
                        </div>
                        <Link data-toggle="tooltip" data-placement="top" title="Comparison" to={`/comparison?id=${nft?._id}`}><svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className="mb-2 colorchange">
                          <rect width="36" height="36" rx="18" fill="#F2F2F2" />
                          <g clip-path="url(#clip0_7385_79288)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9087 15.0274C13.9511 14.7812 14.7269 13.8447 14.7269 12.727C14.7269 11.4215 13.6686 10.3633 12.3632 10.3633C11.0578 10.3633 9.99951 11.4215 9.99951 12.727C9.99951 13.8447 10.7753 14.7812 11.8177 15.0274V21.0908C11.8177 22.5971 13.0388 23.8181 14.5451 23.8181H17.501L16.614 24.7052C16.4009 24.9182 16.4009 25.2635 16.614 25.4765C16.827 25.6896 17.1723 25.6896 17.3853 25.4765L19.2036 23.6583C19.4166 23.4453 19.4166 23.1 19.2036 22.887L17.3853 21.0687C17.1723 20.8557 16.827 20.8557 16.614 21.0687C16.4009 21.2818 16.4009 21.6271 16.614 21.8401L17.501 22.7272H14.5451C13.6413 22.7272 12.9087 21.9945 12.9087 21.0908V15.0274ZM12.3632 13.9997C13.0661 13.9997 13.6359 13.4299 13.6359 12.727C13.6359 12.024 13.0661 11.4542 12.3632 11.4542C11.6603 11.4542 11.0904 12.024 11.0904 12.727C11.0904 13.4299 11.6603 13.9997 12.3632 13.9997Z" fill="#1F1F2C" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3856 11.2944C19.5986 11.0814 19.5986 10.7361 19.3856 10.523C19.1726 10.31 18.8272 10.31 18.6142 10.523L16.796 12.3413C16.583 12.5543 16.583 12.8997 16.796 13.1127L18.6142 14.9309C18.8272 15.1439 19.1726 15.1439 19.3856 14.9309C19.5986 14.7179 19.5986 14.3725 19.3856 14.1595L18.4986 13.2724H21.4545C22.3582 13.2724 23.0909 14.0051 23.0909 14.9088V20.9722C22.0485 21.2184 21.2727 22.1549 21.2727 23.2726C21.2727 24.578 22.3309 25.6363 23.6364 25.6363C24.9418 25.6363 26 24.578 26 23.2726C26 22.1549 25.2242 21.2184 24.1818 20.9722V14.9088C24.1818 13.4026 22.9608 12.1815 21.4545 12.1815H18.4986L19.3856 11.2944ZM22.3636 23.2726C22.3636 22.5697 22.9334 21.9999 23.6364 21.9999C24.3393 21.9999 24.9091 22.5697 24.9091 23.2726C24.9091 23.9756 24.3393 24.5454 23.6364 24.5454C22.9334 24.5454 22.3636 23.9756 22.3636 23.2726Z" fill="#1F1F2C" />
                          </g>
                          <defs>
                            <clipPath id="clip0_7385_79288">
                              <rect width="16" height="16" fill="white" transform="translate(10 10)" />
                            </clipPath>
                          </defs>
                        </svg>
                        </Link>
                      </div>

                      <div className="">
                        {/* <span className="vieweddd eye mg-r-8">
                          {" "}
                          <img
                            src="\send-Regularrr.png"
                            alt="Axies"
                            className=""
                          />
                        </span>
                        <span className="vieweddd eye mg-r-8">
                          {" "}
                          <img src="\doott.png" alt="Axies" className="" />
                        </span> */}
                        {/* <span className="viewed eye mg-r-8 vieweddd">225</span>
                                            <span to="/login" className="liked heart wishlist-button"><span className="number-like vieweddd">100</span></span> */}
                      </div>
                    </div>
                  </div>
                  <div className="client-infor sc-card-product">
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img style={{ height: 45 }} src={user?.picture} alt="Axies" />
                        </div>
                        <div className="info">
                          <span>Owned By</span>
                          <h6>
                            {" "}
                            <Link to={"/user?id=" + user?.walletAddress}>{user?.userFullName ? user?.userFullName : user?.walletAddress?.slice(0, 12) + "..."}</Link>{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="meta-info">
                      <div className="author">
                        <div className="avatar">
                          <img style={{ height: 45 }} src={owner?.picture} alt="Axies" />
                        </div>
                        <div className="info">
                          <span>Created By</span>
                          <h6>
                            {" "}
                            <Link to={"/user?id=" + owner?.walletAddress}>{owner?.userFullName ? owner?.userFullName : owner?.walletAddress?.slice(0, 12) + "..."}</Link>{" "}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="pb-4 text-justify">
                    {nft?.description}
                  </p>
                  {/* <p>Habitant sollicitudin faucibus cursus lectus pulvinar dolor non ultrices eget.
                                        Facilisi lobortisal morbi fringilla urna amet sed ipsum vitae ipsum malesuada.
                                        Habitant sollicitudin faucibus cursus lectus pulvinar dolor non ultrices eget.
                                        Facilisi lobortisal morbi fringilla urna amet sed ipsum</p> */}
                  {nft?.metaData?.method === "auction" && new Date(nft.metaData.endTime) > new Date() ?
                    <>
                      {nft?.onSale &&
                        <div className="meta-item-details style2">
                          <div className="item meta-price">
                            <span className="heading">Current Bid</span>
                            <div className="price">
                              <div className="price-box">
                                {console.log('sdfasfasdf', bids)}
                                <h5> {bids?.length > 0 && bids[0].amount || 0} {nft?.chainId === "97" && "BNB"}{nft?.chainId === "80001" && "Matic"}{nft?.chainId === "5" && "Eth"}</h5>
                                {/* <span>= $12.246</span> */}
                              </div>
                            </div>
                          </div>
                          <div className="item count-down">
                            <span className="heading style-2">Countdown</span>
                            <Countdown date={new Date(nft?.metaData?.endTime)}>
                              <span>You are good to go!</span>
                            </Countdown>
                          </div>
                        </div>
                      }
                    </>
                    :
                    <></>
                  }
                  {nft?.blockStatus === false ?
                    <>
                      {account ?
                        <>
                          {account?.toLowerCase() === user?.creatorAddress && order !== null ?
                            <>
                              <Link
                                to={"/create?id=" + id}
                                className="sc-button loadmore style fl-button pri-3 w-100 "
                              >
                                <span className="d-flex justify-content-center">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="change-my-color mr-2"
                                  >
                                    <path
                                      d="M18.6333 6.89297L18.3916 4.58463C18.0416 2.06797 16.9 1.04297 14.4583 1.04297H12.4916H11.2583H8.72495H7.49162H5.49162C3.04162 1.04297 1.90829 2.06797 1.54995 4.60964L1.32495 6.9013C1.24162 7.79297 1.48329 8.65963 2.00829 9.33463C2.64162 10.1596 3.61662 10.6263 4.69995 10.6263C5.74995 10.6263 6.75829 10.1013 7.39162 9.25963C7.95829 10.1013 8.92495 10.6263 9.99995 10.6263C11.075 10.6263 12.0166 10.1263 12.5916 9.29297C13.2333 10.118 14.225 10.6263 15.2583 10.6263C16.3666 10.6263 17.3666 10.1346 17.9916 9.26797C18.4916 8.6013 18.7166 7.75963 18.6333 6.89297Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M9.45831 13.8848C8.39998 13.9932 7.59998 14.8932 7.59998 15.9598V18.2432C7.59998 18.4682 7.78331 18.6515 8.00831 18.6515H11.9833C12.2083 18.6515 12.3916 18.4682 12.3916 18.2432V16.2515C12.4 14.5098 11.375 13.6848 9.45831 13.8848Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M17.8083 12.001V14.4844C17.8083 16.7844 15.9416 18.651 13.6416 18.651C13.4166 18.651 13.2333 18.4677 13.2333 18.2427V16.251C13.2333 15.1844 12.9083 14.351 12.275 13.7844C11.7166 13.276 10.9583 13.026 10.0166 13.026C9.80832 13.026 9.59998 13.0344 9.37498 13.0594C7.89165 13.2094 6.76665 14.4594 6.76665 15.9594V18.2427C6.76665 18.4677 6.58332 18.651 6.35832 18.651C4.05832 18.651 2.19165 16.7844 2.19165 14.4844V12.0177C2.19165 11.4344 2.76665 11.0427 3.30832 11.2344C3.53332 11.3094 3.75832 11.3677 3.99165 11.401C4.09165 11.4177 4.19998 11.4344 4.29998 11.4344C4.43332 11.451 4.56665 11.4594 4.69998 11.4594C5.66665 11.4594 6.61665 11.101 7.36665 10.4844C8.08332 11.101 9.01665 11.4594 9.99998 11.4594C10.9916 11.4594 11.9083 11.1177 12.625 10.501C13.375 11.1094 14.3083 11.4594 15.2583 11.4594C15.4083 11.4594 15.5583 11.451 15.7 11.4344C15.8 11.426 15.8916 11.4177 15.9833 11.401C16.2416 11.3677 16.475 11.2927 16.7083 11.2177C17.25 11.0344 17.8083 11.4344 17.8083 12.001Z"
                                      fill="white"
                                    />
                                  </svg>
                                  Put on Sale
                                </span>
                              </Link>
                            </>
                            :
                            <>
                              {nft?.onSale ?
                                <>
                                  {nft?.metaData?.method === "fixed" ?
                                    <>
                                      {order === null ?
                                        <>
                                          {account?.toLowerCase() === user?.walletAddress ?
                                            <Link
                                              to={"/create?id=" + id}
                                              className="sc-button loadmore style fl-button pri-3 w-100 "
                                            >
                                              <span className="d-flex justify-content-center">
                                                <svg
                                                  width="20"
                                                  height="20"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="change-my-color mr-2"
                                                >
                                                  <path
                                                    d="M18.6333 6.89297L18.3916 4.58463C18.0416 2.06797 16.9 1.04297 14.4583 1.04297H12.4916H11.2583H8.72495H7.49162H5.49162C3.04162 1.04297 1.90829 2.06797 1.54995 4.60964L1.32495 6.9013C1.24162 7.79297 1.48329 8.65963 2.00829 9.33463C2.64162 10.1596 3.61662 10.6263 4.69995 10.6263C5.74995 10.6263 6.75829 10.1013 7.39162 9.25963C7.95829 10.1013 8.92495 10.6263 9.99995 10.6263C11.075 10.6263 12.0166 10.1263 12.5916 9.29297C13.2333 10.118 14.225 10.6263 15.2583 10.6263C16.3666 10.6263 17.3666 10.1346 17.9916 9.26797C18.4916 8.6013 18.7166 7.75963 18.6333 6.89297Z"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M9.45831 13.8848C8.39998 13.9932 7.59998 14.8932 7.59998 15.9598V18.2432C7.59998 18.4682 7.78331 18.6515 8.00831 18.6515H11.9833C12.2083 18.6515 12.3916 18.4682 12.3916 18.2432V16.2515C12.4 14.5098 11.375 13.6848 9.45831 13.8848Z"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M17.8083 12.001V14.4844C17.8083 16.7844 15.9416 18.651 13.6416 18.651C13.4166 18.651 13.2333 18.4677 13.2333 18.2427V16.251C13.2333 15.1844 12.9083 14.351 12.275 13.7844C11.7166 13.276 10.9583 13.026 10.0166 13.026C9.80832 13.026 9.59998 13.0344 9.37498 13.0594C7.89165 13.2094 6.76665 14.4594 6.76665 15.9594V18.2427C6.76665 18.4677 6.58332 18.651 6.35832 18.651C4.05832 18.651 2.19165 16.7844 2.19165 14.4844V12.0177C2.19165 11.4344 2.76665 11.0427 3.30832 11.2344C3.53332 11.3094 3.75832 11.3677 3.99165 11.401C4.09165 11.4177 4.19998 11.4344 4.29998 11.4344C4.43332 11.451 4.56665 11.4594 4.69998 11.4594C5.66665 11.4594 6.61665 11.101 7.36665 10.4844C8.08332 11.101 9.01665 11.4594 9.99998 11.4594C10.9916 11.4594 11.9083 11.1177 12.625 10.501C13.375 11.1094 14.3083 11.4594 15.2583 11.4594C15.4083 11.4594 15.5583 11.451 15.7 11.4344C15.8 11.426 15.8916 11.4177 15.9833 11.401C16.2416 11.3677 16.475 11.2927 16.7083 11.2177C17.25 11.0344 17.8083 11.4344 17.8083 12.001Z"
                                                    fill="white"
                                                  />
                                                </svg>
                                                Put on Sale
                                              </span>
                                            </Link>
                                            :
                                            <>
                                              {nft?.metaData.method === "auction" && order !== null ?
                                                <>
                                                  {
                                                    new Date(nft.metaData.endTime) > new Date() ?
                                                      <div className="meta-item-details style2">
                                                        <div className="item meta-price">
                                                          <span className="heading">Current Bid</span>
                                                          <div className="price">
                                                            <div className="price-box">
                                                              <h5> 4.89 ETH</h5>
                                                              {/* <span>= $12.246</span> */}
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div className="item count-down">
                                                          <span className="heading style-2">Countdown</span>
                                                          <Countdown date={Date.now() + 500000000}>
                                                            <span>You are good to go!</span>
                                                          </Countdown>
                                                        </div>
                                                      </div>
                                                      :
                                                      <>
                                                        {user?.walletAddress === account?.toLowerCase() ?
                                                          <Link
                                                            to={"/create?id=" + id}
                                                            className="sc-button loadmore style fl-button pri-3 w-100 "
                                                          >
                                                            <span className="d-flex justify-content-center">
                                                              <svg
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="change-my-color mr-2"
                                                              >
                                                                <path
                                                                  d="M18.6333 6.89297L18.3916 4.58463C18.0416 2.06797 16.9 1.04297 14.4583 1.04297H12.4916H11.2583H8.72495H7.49162H5.49162C3.04162 1.04297 1.90829 2.06797 1.54995 4.60964L1.32495 6.9013C1.24162 7.79297 1.48329 8.65963 2.00829 9.33463C2.64162 10.1596 3.61662 10.6263 4.69995 10.6263C5.74995 10.6263 6.75829 10.1013 7.39162 9.25963C7.95829 10.1013 8.92495 10.6263 9.99995 10.6263C11.075 10.6263 12.0166 10.1263 12.5916 9.29297C13.2333 10.118 14.225 10.6263 15.2583 10.6263C16.3666 10.6263 17.3666 10.1346 17.9916 9.26797C18.4916 8.6013 18.7166 7.75963 18.6333 6.89297Z"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M9.45831 13.8848C8.39998 13.9932 7.59998 14.8932 7.59998 15.9598V18.2432C7.59998 18.4682 7.78331 18.6515 8.00831 18.6515H11.9833C12.2083 18.6515 12.3916 18.4682 12.3916 18.2432V16.2515C12.4 14.5098 11.375 13.6848 9.45831 13.8848Z"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M17.8083 12.001V14.4844C17.8083 16.7844 15.9416 18.651 13.6416 18.651C13.4166 18.651 13.2333 18.4677 13.2333 18.2427V16.251C13.2333 15.1844 12.9083 14.351 12.275 13.7844C11.7166 13.276 10.9583 13.026 10.0166 13.026C9.80832 13.026 9.59998 13.0344 9.37498 13.0594C7.89165 13.2094 6.76665 14.4594 6.76665 15.9594V18.2427C6.76665 18.4677 6.58332 18.651 6.35832 18.651C4.05832 18.651 2.19165 16.7844 2.19165 14.4844V12.0177C2.19165 11.4344 2.76665 11.0427 3.30832 11.2344C3.53332 11.3094 3.75832 11.3677 3.99165 11.401C4.09165 11.4177 4.19998 11.4344 4.29998 11.4344C4.43332 11.451 4.56665 11.4594 4.69998 11.4594C5.66665 11.4594 6.61665 11.101 7.36665 10.4844C8.08332 11.101 9.01665 11.4594 9.99998 11.4594C10.9916 11.4594 11.9083 11.1177 12.625 10.501C13.375 11.1094 14.3083 11.4594 15.2583 11.4594C15.4083 11.4594 15.5583 11.451 15.7 11.4344C15.8 11.426 15.8916 11.4177 15.9833 11.401C16.2416 11.3677 16.475 11.2927 16.7083 11.2177C17.25 11.0344 17.8083 11.4344 17.8083 12.001Z"
                                                                  fill="white"
                                                                />
                                                              </svg>
                                                              View Listing
                                                            </span>
                                                          </Link>
                                                          :
                                                          <a
                                                            className="sc-button loadmore style fl-button pri-3 w-100 mr-4"
                                                            data-toggle="modal"
                                                            data-target="#offermodal"
                                                          >
                                                            <span className="d-flex justify-content-center">
                                                              {" "}
                                                              <svg
                                                                width="20"
                                                                height="20"
                                                                viewBox="0 0 20 20"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                className="change-my-color mr-2"
                                                              >
                                                                <path
                                                                  d="M17.1166 15.8677C16.9916 15.9927 16.8333 16.051 16.6749 16.051C16.5166 16.051 16.3583 15.9927 16.2333 15.8677L12.1083 11.7427L12.5499 11.301L12.9916 10.8594L17.1166 14.9844C17.3583 15.226 17.3583 15.626 17.1166 15.8677Z"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M5.39172 7.73308L10.225 12.5664C10.55 12.8914 10.55 13.4164 10.225 13.7414L9.47505 14.4997C8.80005 15.1664 7.73338 15.1664 7.06672 14.4997L3.45005 10.8831C2.79172 10.2247 2.79172 9.14975 3.45005 8.48308L4.20838 7.72475C4.53338 7.40808 5.06672 7.40808 5.39172 7.73308Z"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M15.4917 8.49141L12.3167 11.6581C11.9834 11.9914 11.45 11.9914 11.1167 11.6581L6.30005 6.84141C5.96672 6.50807 5.96672 5.97474 6.30005 5.64141L9.47505 2.46641C10.1334 1.80807 11.2084 1.80807 11.875 2.46641L15.4917 6.08307C16.15 6.74974 16.15 7.81641 15.4917 8.49141Z"
                                                                  fill="white"
                                                                />
                                                                <path
                                                                  d="M6.66663 18.125H1.66663C1.32496 18.125 1.04163 17.8417 1.04163 17.5C1.04163 17.1583 1.32496 16.875 1.66663 16.875H6.66663C7.00829 16.875 7.29163 17.1583 7.29163 17.5C7.29163 17.8417 7.00829 18.125 6.66663 18.125Z"
                                                                  fill="white"
                                                                />
                                                              </svg>
                                                              Make an offer
                                                            </span>
                                                          </a>
                                                        }
                                                      </>
                                                  }
                                                </>
                                                :
                                                <>
                                                  {nft?.metaData?.method === "fixed" &&
                                                    <a
                                                      className="sc-button loadmore style fl-button pri-3 w-100 mr-4"
                                                      data-toggle="modal"
                                                      data-target="#offermodal"
                                                    >
                                                      <span className="d-flex justify-content-center">
                                                        {" "}
                                                        <svg
                                                          width="20"
                                                          height="20"
                                                          viewBox="0 0 20 20"
                                                          fill="none"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          className="change-my-color mr-2"
                                                        >
                                                          <path
                                                            d="M17.1166 15.8677C16.9916 15.9927 16.8333 16.051 16.6749 16.051C16.5166 16.051 16.3583 15.9927 16.2333 15.8677L12.1083 11.7427L12.5499 11.301L12.9916 10.8594L17.1166 14.9844C17.3583 15.226 17.3583 15.626 17.1166 15.8677Z"
                                                            fill="white"
                                                          />
                                                          <path
                                                            d="M5.39172 7.73308L10.225 12.5664C10.55 12.8914 10.55 13.4164 10.225 13.7414L9.47505 14.4997C8.80005 15.1664 7.73338 15.1664 7.06672 14.4997L3.45005 10.8831C2.79172 10.2247 2.79172 9.14975 3.45005 8.48308L4.20838 7.72475C4.53338 7.40808 5.06672 7.40808 5.39172 7.73308Z"
                                                            fill="white"
                                                          />
                                                          <path
                                                            d="M15.4917 8.49141L12.3167 11.6581C11.9834 11.9914 11.45 11.9914 11.1167 11.6581L6.30005 6.84141C5.96672 6.50807 5.96672 5.97474 6.30005 5.64141L9.47505 2.46641C10.1334 1.80807 11.2084 1.80807 11.875 2.46641L15.4917 6.08307C16.15 6.74974 16.15 7.81641 15.4917 8.49141Z"
                                                            fill="white"
                                                          />
                                                          <path
                                                            d="M6.66663 18.125H1.66663C1.32496 18.125 1.04163 17.8417 1.04163 17.5C1.04163 17.1583 1.32496 16.875 1.66663 16.875H6.66663C7.00829 16.875 7.29163 17.1583 7.29163 17.5C7.29163 17.8417 7.00829 18.125 6.66663 18.125Z"
                                                            fill="white"
                                                          />
                                                        </svg>
                                                        Make an offer
                                                      </span>
                                                    </a>
                                                  }
                                                </>
                                              }
                                            </>
                                          }
                                        </>
                                        :
                                        <>
                                          {user?.walletAddress === account?.toLowerCase() ?
                                            <Link
                                              to={"/create?id=" + id}
                                              className="sc-button loadmore style fl-button pri-3 w-100 "
                                            >
                                              <span className="d-flex justify-content-center">
                                                <svg
                                                  width="20"
                                                  height="20"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="change-my-color mr-2"
                                                >
                                                  <path
                                                    d="M18.6333 6.89297L18.3916 4.58463C18.0416 2.06797 16.9 1.04297 14.4583 1.04297H12.4916H11.2583H8.72495H7.49162H5.49162C3.04162 1.04297 1.90829 2.06797 1.54995 4.60964L1.32495 6.9013C1.24162 7.79297 1.48329 8.65963 2.00829 9.33463C2.64162 10.1596 3.61662 10.6263 4.69995 10.6263C5.74995 10.6263 6.75829 10.1013 7.39162 9.25963C7.95829 10.1013 8.92495 10.6263 9.99995 10.6263C11.075 10.6263 12.0166 10.1263 12.5916 9.29297C13.2333 10.118 14.225 10.6263 15.2583 10.6263C16.3666 10.6263 17.3666 10.1346 17.9916 9.26797C18.4916 8.6013 18.7166 7.75963 18.6333 6.89297Z"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M9.45831 13.8848C8.39998 13.9932 7.59998 14.8932 7.59998 15.9598V18.2432C7.59998 18.4682 7.78331 18.6515 8.00831 18.6515H11.9833C12.2083 18.6515 12.3916 18.4682 12.3916 18.2432V16.2515C12.4 14.5098 11.375 13.6848 9.45831 13.8848Z"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M17.8083 12.001V14.4844C17.8083 16.7844 15.9416 18.651 13.6416 18.651C13.4166 18.651 13.2333 18.4677 13.2333 18.2427V16.251C13.2333 15.1844 12.9083 14.351 12.275 13.7844C11.7166 13.276 10.9583 13.026 10.0166 13.026C9.80832 13.026 9.59998 13.0344 9.37498 13.0594C7.89165 13.2094 6.76665 14.4594 6.76665 15.9594V18.2427C6.76665 18.4677 6.58332 18.651 6.35832 18.651C4.05832 18.651 2.19165 16.7844 2.19165 14.4844V12.0177C2.19165 11.4344 2.76665 11.0427 3.30832 11.2344C3.53332 11.3094 3.75832 11.3677 3.99165 11.401C4.09165 11.4177 4.19998 11.4344 4.29998 11.4344C4.43332 11.451 4.56665 11.4594 4.69998 11.4594C5.66665 11.4594 6.61665 11.101 7.36665 10.4844C8.08332 11.101 9.01665 11.4594 9.99998 11.4594C10.9916 11.4594 11.9083 11.1177 12.625 10.501C13.375 11.1094 14.3083 11.4594 15.2583 11.4594C15.4083 11.4594 15.5583 11.451 15.7 11.4344C15.8 11.426 15.8916 11.4177 15.9833 11.401C16.2416 11.3677 16.475 11.2927 16.7083 11.2177C17.25 11.0344 17.8083 11.4344 17.8083 12.001Z"
                                                    fill="white"
                                                  />
                                                </svg>
                                                View Listing
                                              </span>
                                            </Link>
                                            :
                                            <>
                                              {time &&
                                                <a
                                                  className="sc-button loadmore style fl-button pri-3 w-100"
                                                  data-toggle="modal"
                                                  data-target="#buymodal"
                                                >
                                                  <span className="d-flex justify-content-center">
                                                    <svg
                                                      width="20"
                                                      height="20"
                                                      viewBox="0 0 20 20"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className="change-my-color mr-2"
                                                    >
                                                      <path
                                                        d="M16.0333 4.64948H15.7L12.8833 1.83281C12.6583 1.60781 12.2916 1.60781 12.0583 1.83281C11.8333 2.05781 11.8333 2.42448 12.0583 2.65781L14.05 4.64948H5.94996L7.94163 2.65781C8.16663 2.43281 8.16663 2.06615 7.94163 1.83281C7.71663 1.60781 7.34996 1.60781 7.11663 1.83281L4.30829 4.64948H3.97496C3.22496 4.64948 1.66663 4.64948 1.66663 6.78281C1.66663 7.59115 1.83329 8.12448 2.18329 8.47448C2.38329 8.68281 2.62496 8.79115 2.88329 8.84948C3.12496 8.90781 3.38329 8.91615 3.63329 8.91615H16.3666C16.625 8.91615 16.8666 8.89948 17.1 8.84948C17.8 8.68281 18.3333 8.18281 18.3333 6.78281C18.3333 4.64948 16.775 4.64948 16.0333 4.64948Z"
                                                        fill="white"
                                                      />
                                                      <path
                                                        d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
                                                        fill="white"
                                                      />
                                                    </svg>
                                                    Buy Now for {nft?.metaData?.price} BNB
                                                  </span>
                                                </a>
                                              }
                                              {nft?.metaData?.endTime !== "101" &&
                                                <a
                                                  className="sc-button loadmore style fl-button pri-3 w-100 mr-4"
                                                  data-toggle="modal"
                                                  data-target="#offermodal"
                                                >
                                                  <span className="d-flex justify-content-center">
                                                    {" "}
                                                    <svg
                                                      width="20"
                                                      height="20"
                                                      viewBox="0 0 20 20"
                                                      fill="none"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      className="change-my-color mr-2"
                                                    >
                                                      <path
                                                        d="M17.1166 15.8677C16.9916 15.9927 16.8333 16.051 16.6749 16.051C16.5166 16.051 16.3583 15.9927 16.2333 15.8677L12.1083 11.7427L12.5499 11.301L12.9916 10.8594L17.1166 14.9844C17.3583 15.226 17.3583 15.626 17.1166 15.8677Z"
                                                        fill="white"
                                                      />
                                                      <path
                                                        d="M5.39172 7.73308L10.225 12.5664C10.55 12.8914 10.55 13.4164 10.225 13.7414L9.47505 14.4997C8.80005 15.1664 7.73338 15.1664 7.06672 14.4997L3.45005 10.8831C2.79172 10.2247 2.79172 9.14975 3.45005 8.48308L4.20838 7.72475C4.53338 7.40808 5.06672 7.40808 5.39172 7.73308Z"
                                                        fill="white"
                                                      />
                                                      <path
                                                        d="M15.4917 8.49141L12.3167 11.6581C11.9834 11.9914 11.45 11.9914 11.1167 11.6581L6.30005 6.84141C5.96672 6.50807 5.96672 5.97474 6.30005 5.64141L9.47505 2.46641C10.1334 1.80807 11.2084 1.80807 11.875 2.46641L15.4917 6.08307C16.15 6.74974 16.15 7.81641 15.4917 8.49141Z"
                                                        fill="white"
                                                      />
                                                      <path
                                                        d="M6.66663 18.125H1.66663C1.32496 18.125 1.04163 17.8417 1.04163 17.5C1.04163 17.1583 1.32496 16.875 1.66663 16.875H6.66663C7.00829 16.875 7.29163 17.1583 7.29163 17.5C7.29163 17.8417 7.00829 18.125 6.66663 18.125Z"
                                                        fill="white"
                                                      />
                                                    </svg>
                                                    Make an offer
                                                  </span>
                                                </a>
                                              }
                                            </>
                                          }
                                        </>
                                      }
                                    </>
                                    :
                                    <>
                                      {user?.walletAddress === account?.toLowerCase() ?
                                        <Link
                                          to={"/create?id=" + id}
                                          className="sc-button loadmore style fl-button pri-3 w-100 "
                                        >
                                          <span className="d-flex justify-content-center">
                                            <svg
                                              width="20"
                                              height="20"
                                              viewBox="0 0 20 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="change-my-color mr-2"
                                            >
                                              <path
                                                d="M18.6333 6.89297L18.3916 4.58463C18.0416 2.06797 16.9 1.04297 14.4583 1.04297H12.4916H11.2583H8.72495H7.49162H5.49162C3.04162 1.04297 1.90829 2.06797 1.54995 4.60964L1.32495 6.9013C1.24162 7.79297 1.48329 8.65963 2.00829 9.33463C2.64162 10.1596 3.61662 10.6263 4.69995 10.6263C5.74995 10.6263 6.75829 10.1013 7.39162 9.25963C7.95829 10.1013 8.92495 10.6263 9.99995 10.6263C11.075 10.6263 12.0166 10.1263 12.5916 9.29297C13.2333 10.118 14.225 10.6263 15.2583 10.6263C16.3666 10.6263 17.3666 10.1346 17.9916 9.26797C18.4916 8.6013 18.7166 7.75963 18.6333 6.89297Z"
                                                fill="white"
                                              />
                                              <path
                                                d="M9.45831 13.8848C8.39998 13.9932 7.59998 14.8932 7.59998 15.9598V18.2432C7.59998 18.4682 7.78331 18.6515 8.00831 18.6515H11.9833C12.2083 18.6515 12.3916 18.4682 12.3916 18.2432V16.2515C12.4 14.5098 11.375 13.6848 9.45831 13.8848Z"
                                                fill="white"
                                              />
                                              <path
                                                d="M17.8083 12.001V14.4844C17.8083 16.7844 15.9416 18.651 13.6416 18.651C13.4166 18.651 13.2333 18.4677 13.2333 18.2427V16.251C13.2333 15.1844 12.9083 14.351 12.275 13.7844C11.7166 13.276 10.9583 13.026 10.0166 13.026C9.80832 13.026 9.59998 13.0344 9.37498 13.0594C7.89165 13.2094 6.76665 14.4594 6.76665 15.9594V18.2427C6.76665 18.4677 6.58332 18.651 6.35832 18.651C4.05832 18.651 2.19165 16.7844 2.19165 14.4844V12.0177C2.19165 11.4344 2.76665 11.0427 3.30832 11.2344C3.53332 11.3094 3.75832 11.3677 3.99165 11.401C4.09165 11.4177 4.19998 11.4344 4.29998 11.4344C4.43332 11.451 4.56665 11.4594 4.69998 11.4594C5.66665 11.4594 6.61665 11.101 7.36665 10.4844C8.08332 11.101 9.01665 11.4594 9.99998 11.4594C10.9916 11.4594 11.9083 11.1177 12.625 10.501C13.375 11.1094 14.3083 11.4594 15.2583 11.4594C15.4083 11.4594 15.5583 11.451 15.7 11.4344C15.8 11.426 15.8916 11.4177 15.9833 11.401C16.2416 11.3677 16.475 11.2927 16.7083 11.2177C17.25 11.0344 17.8083 11.4344 17.8083 12.001Z"
                                                fill="white"
                                              />
                                            </svg>
                                            View Listing
                                          </span>
                                        </Link>
                                        :
                                        <>
                                          {/* jawad */}
                                          {nft?.metaData?.method === "auction" && order !== null ?
                                            <>
                                              {
                                                new Date(nft.metaData.endTime) > new Date() ?
                                                  <div className="sc-button sc-button-1 loadmore style bag fl-button pri-3 w-100 bbbtttnnn" onClick={setModalShow}><span>
                                                    <svg className='hthora' xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
                                                      <path d="M17.6167 15.8667C17.4917 15.9917 17.3334 16.0501 17.1751 16.0501C17.0167 16.0501 16.8584 15.9917 16.7334 15.8667L12.6084 11.7417L13.0501 11.3001L13.4917 10.8584L17.6167 14.9834C17.8584 15.2251 17.8584 15.6251 17.6167 15.8667Z" fill="#1F1F2C" />
                                                      <path d="M5.89172 7.73357L10.725 12.5669C11.05 12.8919 11.05 13.4169 10.725 13.7419L9.97505 14.5002C9.30005 15.1669 8.23338 15.1669 7.56672 14.5002L3.95005 10.8836C3.29172 10.2252 3.29172 9.15024 3.95005 8.48357L4.70838 7.72524C5.03338 7.40857 5.56672 7.40857 5.89172 7.73357Z" fill="#1F1F2C" />
                                                      <path d="M15.9917 8.49189L12.8167 11.6586C12.4834 11.9919 11.95 11.9919 11.6167 11.6586L6.80005 6.84189C6.46672 6.50856 6.46672 5.97523 6.80005 5.64189L9.97505 2.46689C10.6334 1.80856 11.7084 1.80856 12.375 2.46689L15.9917 6.08356C16.65 6.75023 16.65 7.81689 15.9917 8.49189Z" fill="#1F1F2C" />
                                                      <path d="M7.16663 18.125H2.16663C1.82496 18.125 1.54163 17.8417 1.54163 17.5C1.54163 17.1583 1.82496 16.875 2.16663 16.875H7.16663C7.50829 16.875 7.79163 17.1583 7.79163 17.5C7.79163 17.8417 7.50829 18.125 7.16663 18.125Z" fill="#1F1F2C" />
                                                    </svg>
                                                    Place a bid</span></div>
                                                  :
                                                  <>
                                                    {user?.walletAddress === account?.toLowerCase() ?
                                                      <Link
                                                        to={"/create?id=" + id}
                                                        className="sc-button loadmore style fl-button pri-3 w-100 "
                                                      >
                                                        <span className="d-flex justify-content-center">
                                                          <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="change-my-color mr-2"
                                                          >
                                                            <path
                                                              d="M18.6333 6.89297L18.3916 4.58463C18.0416 2.06797 16.9 1.04297 14.4583 1.04297H12.4916H11.2583H8.72495H7.49162H5.49162C3.04162 1.04297 1.90829 2.06797 1.54995 4.60964L1.32495 6.9013C1.24162 7.79297 1.48329 8.65963 2.00829 9.33463C2.64162 10.1596 3.61662 10.6263 4.69995 10.6263C5.74995 10.6263 6.75829 10.1013 7.39162 9.25963C7.95829 10.1013 8.92495 10.6263 9.99995 10.6263C11.075 10.6263 12.0166 10.1263 12.5916 9.29297C13.2333 10.118 14.225 10.6263 15.2583 10.6263C16.3666 10.6263 17.3666 10.1346 17.9916 9.26797C18.4916 8.6013 18.7166 7.75963 18.6333 6.89297Z"
                                                              fill="white"
                                                            />
                                                            <path
                                                              d="M9.45831 13.8848C8.39998 13.9932 7.59998 14.8932 7.59998 15.9598V18.2432C7.59998 18.4682 7.78331 18.6515 8.00831 18.6515H11.9833C12.2083 18.6515 12.3916 18.4682 12.3916 18.2432V16.2515C12.4 14.5098 11.375 13.6848 9.45831 13.8848Z"
                                                              fill="white"
                                                            />
                                                            <path
                                                              d="M17.8083 12.001V14.4844C17.8083 16.7844 15.9416 18.651 13.6416 18.651C13.4166 18.651 13.2333 18.4677 13.2333 18.2427V16.251C13.2333 15.1844 12.9083 14.351 12.275 13.7844C11.7166 13.276 10.9583 13.026 10.0166 13.026C9.80832 13.026 9.59998 13.0344 9.37498 13.0594C7.89165 13.2094 6.76665 14.4594 6.76665 15.9594V18.2427C6.76665 18.4677 6.58332 18.651 6.35832 18.651C4.05832 18.651 2.19165 16.7844 2.19165 14.4844V12.0177C2.19165 11.4344 2.76665 11.0427 3.30832 11.2344C3.53332 11.3094 3.75832 11.3677 3.99165 11.401C4.09165 11.4177 4.19998 11.4344 4.29998 11.4344C4.43332 11.451 4.56665 11.4594 4.69998 11.4594C5.66665 11.4594 6.61665 11.101 7.36665 10.4844C8.08332 11.101 9.01665 11.4594 9.99998 11.4594C10.9916 11.4594 11.9083 11.1177 12.625 10.501C13.375 11.1094 14.3083 11.4594 15.2583 11.4594C15.4083 11.4594 15.5583 11.451 15.7 11.4344C15.8 11.426 15.8916 11.4177 15.9833 11.401C16.2416 11.3677 16.475 11.2927 16.7083 11.2177C17.25 11.0344 17.8083 11.4344 17.8083 12.001Z"
                                                              fill="white"
                                                            />
                                                          </svg>
                                                          View Listing
                                                        </span>
                                                      </Link>
                                                      :
                                                      <a
                                                        className="sc-button loadmore style fl-button pri-3 w-100 mr-4"
                                                        data-toggle="modal"
                                                        data-target="#offermodal"
                                                      >
                                                        <span className="d-flex justify-content-center">
                                                          {" "}
                                                          <svg
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 20 20"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="change-my-color mr-2"
                                                          >
                                                            <path
                                                              d="M17.1166 15.8677C16.9916 15.9927 16.8333 16.051 16.6749 16.051C16.5166 16.051 16.3583 15.9927 16.2333 15.8677L12.1083 11.7427L12.5499 11.301L12.9916 10.8594L17.1166 14.9844C17.3583 15.226 17.3583 15.626 17.1166 15.8677Z"
                                                              fill="white"
                                                            />
                                                            <path
                                                              d="M5.39172 7.73308L10.225 12.5664C10.55 12.8914 10.55 13.4164 10.225 13.7414L9.47505 14.4997C8.80005 15.1664 7.73338 15.1664 7.06672 14.4997L3.45005 10.8831C2.79172 10.2247 2.79172 9.14975 3.45005 8.48308L4.20838 7.72475C4.53338 7.40808 5.06672 7.40808 5.39172 7.73308Z"
                                                              fill="white"
                                                            />
                                                            <path
                                                              d="M15.4917 8.49141L12.3167 11.6581C11.9834 11.9914 11.45 11.9914 11.1167 11.6581L6.30005 6.84141C5.96672 6.50807 5.96672 5.97474 6.30005 5.64141L9.47505 2.46641C10.1334 1.80807 11.2084 1.80807 11.875 2.46641L15.4917 6.08307C16.15 6.74974 16.15 7.81641 15.4917 8.49141Z"
                                                              fill="white"
                                                            />
                                                            <path
                                                              d="M6.66663 18.125H1.66663C1.32496 18.125 1.04163 17.8417 1.04163 17.5C1.04163 17.1583 1.32496 16.875 1.66663 16.875H6.66663C7.00829 16.875 7.29163 17.1583 7.29163 17.5C7.29163 17.8417 7.00829 18.125 6.66663 18.125Z"
                                                              fill="white"
                                                            />
                                                          </svg>
                                                          Make an offer
                                                        </span>
                                                      </a>
                                                    }
                                                  </>
                                              }
                                            </>
                                            :
                                            <a
                                              className="sc-button loadmore style fl-button pri-3 w-100 mr-4"
                                              data-toggle="modal"
                                              data-target="#offermodal"
                                            >
                                              <span className="d-flex justify-content-center">
                                                {" "}
                                                <svg
                                                  width="20"
                                                  height="20"
                                                  viewBox="0 0 20 20"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="change-my-color mr-2"
                                                >
                                                  <path
                                                    d="M17.1166 15.8677C16.9916 15.9927 16.8333 16.051 16.6749 16.051C16.5166 16.051 16.3583 15.9927 16.2333 15.8677L12.1083 11.7427L12.5499 11.301L12.9916 10.8594L17.1166 14.9844C17.3583 15.226 17.3583 15.626 17.1166 15.8677Z"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M5.39172 7.73308L10.225 12.5664C10.55 12.8914 10.55 13.4164 10.225 13.7414L9.47505 14.4997C8.80005 15.1664 7.73338 15.1664 7.06672 14.4997L3.45005 10.8831C2.79172 10.2247 2.79172 9.14975 3.45005 8.48308L4.20838 7.72475C4.53338 7.40808 5.06672 7.40808 5.39172 7.73308Z"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M15.4917 8.49141L12.3167 11.6581C11.9834 11.9914 11.45 11.9914 11.1167 11.6581L6.30005 6.84141C5.96672 6.50807 5.96672 5.97474 6.30005 5.64141L9.47505 2.46641C10.1334 1.80807 11.2084 1.80807 11.875 2.46641L15.4917 6.08307C16.15 6.74974 16.15 7.81641 15.4917 8.49141Z"
                                                    fill="white"
                                                  />
                                                  <path
                                                    d="M6.66663 18.125H1.66663C1.32496 18.125 1.04163 17.8417 1.04163 17.5C1.04163 17.1583 1.32496 16.875 1.66663 16.875H6.66663C7.00829 16.875 7.29163 17.1583 7.29163 17.5C7.29163 17.8417 7.00829 18.125 6.66663 18.125Z"
                                                    fill="white"
                                                  />
                                                </svg>
                                                Make an offer
                                              </span>
                                            </a>
                                          }
                                        </>
                                      }
                                    </>
                                  }
                                </>
                                :
                                <>
                                  {account?.toLowerCase() === user?.walletAddress ?
                                    <Link
                                      to={"/create?id=" + id}
                                      className="sc-button loadmore style fl-button pri-3 w-100 "
                                    >
                                      <span className="d-flex justify-content-center">
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="change-my-color mr-2"
                                        >
                                          <path
                                            d="M18.6333 6.89297L18.3916 4.58463C18.0416 2.06797 16.9 1.04297 14.4583 1.04297H12.4916H11.2583H8.72495H7.49162H5.49162C3.04162 1.04297 1.90829 2.06797 1.54995 4.60964L1.32495 6.9013C1.24162 7.79297 1.48329 8.65963 2.00829 9.33463C2.64162 10.1596 3.61662 10.6263 4.69995 10.6263C5.74995 10.6263 6.75829 10.1013 7.39162 9.25963C7.95829 10.1013 8.92495 10.6263 9.99995 10.6263C11.075 10.6263 12.0166 10.1263 12.5916 9.29297C13.2333 10.118 14.225 10.6263 15.2583 10.6263C16.3666 10.6263 17.3666 10.1346 17.9916 9.26797C18.4916 8.6013 18.7166 7.75963 18.6333 6.89297Z"
                                            fill="white"
                                          />
                                          <path
                                            d="M9.45831 13.8848C8.39998 13.9932 7.59998 14.8932 7.59998 15.9598V18.2432C7.59998 18.4682 7.78331 18.6515 8.00831 18.6515H11.9833C12.2083 18.6515 12.3916 18.4682 12.3916 18.2432V16.2515C12.4 14.5098 11.375 13.6848 9.45831 13.8848Z"
                                            fill="white"
                                          />
                                          <path
                                            d="M17.8083 12.001V14.4844C17.8083 16.7844 15.9416 18.651 13.6416 18.651C13.4166 18.651 13.2333 18.4677 13.2333 18.2427V16.251C13.2333 15.1844 12.9083 14.351 12.275 13.7844C11.7166 13.276 10.9583 13.026 10.0166 13.026C9.80832 13.026 9.59998 13.0344 9.37498 13.0594C7.89165 13.2094 6.76665 14.4594 6.76665 15.9594V18.2427C6.76665 18.4677 6.58332 18.651 6.35832 18.651C4.05832 18.651 2.19165 16.7844 2.19165 14.4844V12.0177C2.19165 11.4344 2.76665 11.0427 3.30832 11.2344C3.53332 11.3094 3.75832 11.3677 3.99165 11.401C4.09165 11.4177 4.19998 11.4344 4.29998 11.4344C4.43332 11.451 4.56665 11.4594 4.69998 11.4594C5.66665 11.4594 6.61665 11.101 7.36665 10.4844C8.08332 11.101 9.01665 11.4594 9.99998 11.4594C10.9916 11.4594 11.9083 11.1177 12.625 10.501C13.375 11.1094 14.3083 11.4594 15.2583 11.4594C15.4083 11.4594 15.5583 11.451 15.7 11.4344C15.8 11.426 15.8916 11.4177 15.9833 11.401C16.2416 11.3677 16.475 11.2927 16.7083 11.2177C17.25 11.0344 17.8083 11.4344 17.8083 12.001Z"
                                            fill="white"
                                          />
                                        </svg>
                                        Put on Sale
                                      </span>
                                    </Link>
                                    :
                                    <a
                                      className="sc-button loadmore style fl-button pri-3 w-100 mr-4"
                                      data-toggle="modal"
                                      data-target="#offermodal"
                                    >
                                      <span className="d-flex justify-content-center">
                                        {" "}
                                        <svg
                                          width="20"
                                          height="20"
                                          viewBox="0 0 20 20"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="change-my-color mr-2"
                                        >
                                          <path
                                            d="M17.1166 15.8677C16.9916 15.9927 16.8333 16.051 16.6749 16.051C16.5166 16.051 16.3583 15.9927 16.2333 15.8677L12.1083 11.7427L12.5499 11.301L12.9916 10.8594L17.1166 14.9844C17.3583 15.226 17.3583 15.626 17.1166 15.8677Z"
                                            fill="white"
                                          />
                                          <path
                                            d="M5.39172 7.73308L10.225 12.5664C10.55 12.8914 10.55 13.4164 10.225 13.7414L9.47505 14.4997C8.80005 15.1664 7.73338 15.1664 7.06672 14.4997L3.45005 10.8831C2.79172 10.2247 2.79172 9.14975 3.45005 8.48308L4.20838 7.72475C4.53338 7.40808 5.06672 7.40808 5.39172 7.73308Z"
                                            fill="white"
                                          />
                                          <path
                                            d="M15.4917 8.49141L12.3167 11.6581C11.9834 11.9914 11.45 11.9914 11.1167 11.6581L6.30005 6.84141C5.96672 6.50807 5.96672 5.97474 6.30005 5.64141L9.47505 2.46641C10.1334 1.80807 11.2084 1.80807 11.875 2.46641L15.4917 6.08307C16.15 6.74974 16.15 7.81641 15.4917 8.49141Z"
                                            fill="white"
                                          />
                                          <path
                                            d="M6.66663 18.125H1.66663C1.32496 18.125 1.04163 17.8417 1.04163 17.5C1.04163 17.1583 1.32496 16.875 1.66663 16.875H6.66663C7.00829 16.875 7.29163 17.1583 7.29163 17.5C7.29163 17.8417 7.00829 18.125 6.66663 18.125Z"
                                            fill="white"
                                          />
                                        </svg>
                                        Make an offer
                                      </span>
                                    </a>
                                  }
                                </>
                              }
                            </>
                          }
                        </>
                        :
                        <h3 style={{ fontSize: '32px !important' }} className="py-3 text-center">Connect your wallet</h3>
                      }
                    </>
                    :
                    <>
                      {nft?.blockStatus === true &&
                        <div
                          className="sc-button loadmore style fl-button pri-3 w-100 "
                        >
                          <span className="d-flex justify-content-center">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="change-my-color mr-2"
                            >
                              <path
                                d="M18.6333 6.89297L18.3916 4.58463C18.0416 2.06797 16.9 1.04297 14.4583 1.04297H12.4916H11.2583H8.72495H7.49162H5.49162C3.04162 1.04297 1.90829 2.06797 1.54995 4.60964L1.32495 6.9013C1.24162 7.79297 1.48329 8.65963 2.00829 9.33463C2.64162 10.1596 3.61662 10.6263 4.69995 10.6263C5.74995 10.6263 6.75829 10.1013 7.39162 9.25963C7.95829 10.1013 8.92495 10.6263 9.99995 10.6263C11.075 10.6263 12.0166 10.1263 12.5916 9.29297C13.2333 10.118 14.225 10.6263 15.2583 10.6263C16.3666 10.6263 17.3666 10.1346 17.9916 9.26797C18.4916 8.6013 18.7166 7.75963 18.6333 6.89297Z"
                                fill="white"
                              />
                              <path
                                d="M9.45831 13.8848C8.39998 13.9932 7.59998 14.8932 7.59998 15.9598V18.2432C7.59998 18.4682 7.78331 18.6515 8.00831 18.6515H11.9833C12.2083 18.6515 12.3916 18.4682 12.3916 18.2432V16.2515C12.4 14.5098 11.375 13.6848 9.45831 13.8848Z"
                                fill="white"
                              />
                              <path
                                d="M17.8083 12.001V14.4844C17.8083 16.7844 15.9416 18.651 13.6416 18.651C13.4166 18.651 13.2333 18.4677 13.2333 18.2427V16.251C13.2333 15.1844 12.9083 14.351 12.275 13.7844C11.7166 13.276 10.9583 13.026 10.0166 13.026C9.80832 13.026 9.59998 13.0344 9.37498 13.0594C7.89165 13.2094 6.76665 14.4594 6.76665 15.9594V18.2427C6.76665 18.4677 6.58332 18.651 6.35832 18.651C4.05832 18.651 2.19165 16.7844 2.19165 14.4844V12.0177C2.19165 11.4344 2.76665 11.0427 3.30832 11.2344C3.53332 11.3094 3.75832 11.3677 3.99165 11.401C4.09165 11.4177 4.19998 11.4344 4.29998 11.4344C4.43332 11.451 4.56665 11.4594 4.69998 11.4594C5.66665 11.4594 6.61665 11.101 7.36665 10.4844C8.08332 11.101 9.01665 11.4594 9.99998 11.4594C10.9916 11.4594 11.9083 11.1177 12.625 10.501C13.375 11.1094 14.3083 11.4594 15.2583 11.4594C15.4083 11.4594 15.5583 11.451 15.7 11.4344C15.8 11.426 15.8916 11.4177 15.9833 11.401C16.2416 11.3677 16.475 11.2927 16.7083 11.2177C17.25 11.0344 17.8083 11.4344 17.8083 12.001Z"
                                fill="white"
                              />
                            </svg>
                            Blocked by Admin
                          </span>
                        </div>
                      }
                    </>
                  }


                  {/* New design */}


                  <div className="new-buttons-item-detail">
                    <div className="put-onlist-wrapper new-buttom-item w-100">
                      {!nft?.isRent && user?.walletAddress?.toLowerCase() === account?.toLowerCase() && nft?.isMinted && (
                        <Button className="on-list-btn" onClick={handleShow}>
                          <img src={listIcon} alt='listIcon' />
                          List For Rent
                        </Button>
                      )}
                      {/* ahmad show */}
                      <Modal className="newww-mdl-box" show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton className="thiss-header-upper">
                          <Modal.Title className="thiss-header">List on Marketplace</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="new-design-modal-data">

                            {/* {!rentstate ?
                              <>
                                <button className="for-sale-btn">
                                  <img src={listFsale} alt="list for sale" />
                                  List for Sale
                                </button>
                                <button className="for-rent-btn" onClick={() => renthandle()}>
                                  <img src={listFrent} alt="list for rent" />
                                  List for Rent
                                </button>
                              </>
                              : */}
                            <form onSubmit={handleSubmit} className="on-rent-form-wrapper">
                              <div className="form-div">
                                <label>Price Per Day</label>
                                <input placeholder="Enter Price Per Day" type="number"
                                  name="rentPrice"
                                  value={formData.rentPrice}
                                  onChange={handleChange} />
                              </div>
                              <div className="form-div">
                                <label>No of Days</label>
                                <input placeholder="No of Days" type="number"
                                  name="noOfDays"
                                  value={formData.noOfDays}
                                  onChange={handleChange} />
                              </div>
                              {/* <div className="from-div-row">
                                <div className="form-div">
                                  <label>Start Date</label>
                                  <input placeholder="Enter Price Per Day" type="date"
                                    name="rentStart"
                                    value={formData.rentStart}
                                    onChange={handleChange} />
                                </div>
                                <div className="form-div ">
                                  <label>End Date</label>
                                  <input placeholder="Enter Price Per Day" type="date"
                                    name="rentEnd"
                                    value={formData.rentEnd}
                                    onChange={handleChange} />
                                </div>
                              </div> */}
                              <div className="d-flex mb-5 ">
                                <button type="submit" className="mr-2">Put on Rent</button>
                                <button className="ml-2" >Cancel</button>
                              </div>
                            </form>
                            {/* } */}
                          </div>
                        </Modal.Body>
                        {
                          rentstate ?
                            <Modal.Footer className="new-design-modal-footer">
                              <Button className="put-on-rent" variant="primary" onClick={handleClose}>
                                Put on Rent
                              </Button>
                              <Button variant="secondary" className="cancel-btn" onClick={handleClose}>
                                Cancel
                              </Button>
                            </Modal.Footer>
                            : null}
                      </Modal>
                    </div>
                    {/* <div className="new-buttom-item">
                      <button className="acution-btn">
                        <img src={autionIcon} alt="autionIcon" />
                        Put on Auction
                      </button>
                    </div> */}
                  </div>


                  {/* New design */}


                  {/* <div className="centerheading">
                    <h4 className="mavvrketplace">
                      You can sell this token on our marketplace
                    </h4>
                  </div> */}
                  <div className="flat-tabs themesflat-tabs">
                    <Tabs>
                      <TabList>
                        <Tab>Details</Tab>
                        <Tab>History</Tab>
                        {/* <Tab>Rent Offers</Tab> */}
                        {/* <Tab>Provenance</Tab> */}
                      </TabList>
                      <TabPanel>
                        <ul className="bid-history-list">
                          {bids?.map((item, index) => {
                            return (
                              <li key={index}>
                                <div className="content">
                                  <div className="client">
                                    <div className="sc-author-box style-2">
                                      <div className="author-avatar">
                                        <Link to="#">
                                          <img
                                            src={item?.bidder[0]?.picture}
                                            alt="Axies"
                                            className="avatar"
                                          />
                                        </Link>
                                        {item?.bidder[0]?.verified === true &&
                                          <div className="badge"></div>
                                        }
                                      </div>
                                      <div className="author-infor">
                                        <div className="name">
                                          <h6>
                                            {" "}
                                            <Link to={"/user?id=" + item?.bidder[0]?.walletAddress}>
                                              {item?.bidder[0]?.userFullName ? item?.bidder[0]?.userFullName : item?.bidder[0]?.walletAddress?.slice(0, 12) + "..."}
                                            </Link>
                                          </h6>{" "}
                                          {item?.onOffer === true ?
                                            <span> placed an offer</span>
                                            :
                                            <span> placed a bid</span>
                                          }
                                        </div>
                                        {/* <span className="time">8 hours ago</span> */}
                                        <span className="time">{moment(item?.createdAt).fromNow()}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="price">
                                    <h5 style={{ width: "100px" }} >{parseFloat(item.amount)?.toFixed(3)} WBNB</h5>
                                    {/* <span>= {item.priceChange}</span> */}
                                  </div>
                                  {nft?.ownerAddress === account?.toLowerCase() &&
                                    <>
                                      {item?.onOffer === true ?
                                        <div className="content">
                                          <h5 className="sc-button loadmore style fl-button pri-3 w-100 mb-0 ml-2 asersrtfsef acceptBid" onClick={() => OfferAndMint(item)}>Accept Offer</h5>
                                        </div>
                                        :
                                        <div className="price">
                                        <h5 style={{ padding: " 8px 20px"}} onClick={() => AcceptBids(item)} className="sc-button loadmore style fl-button pri-3 w-100 mb-0 ml-2  mt-0 acceptBid">Accept Bid</h5>
                                        </div>
                                      }
                                    </>
                                  }
                                  {item?.bidderAddress === account?.toLowerCase() &&
                                    <>
                                      {item?.onOffer === true ?
                                        <svg onClick={() => DeleteOffer(item)} xmlns="http://www. w3.org/2000/svg" className="curp icon icon-tabler icon-tabler-square-x" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          {/* <rect x="4" y="4" width="16" height="16" rx="2" /> */}
                                          <path d="M10 10l4 4m0 -4l-4 4" />
                                        </svg>
                                        :
                                        <svg onClick={() => DeleteBid(item)} xmlns="http://www. w3.org/2000/svg" className="curp icon icon-tabler icon-tabler-square-x" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                          {/* <rect x="4" y="4" width="16" height="16" rx="2" /> */}
                                          <path d="M10 10l4 4m0 -4l-4 4" />
                                        </svg>
                                      }
                                    </>
                                  }
                                </div>
                              </li>
                            )
                          })}
                          <li>
                            <div className="upper-heading">
                              <h5>Property</h5>
                            </div>
                            <div className="bottom-content">
                              {nft?.properties?.map((item, index) => {
                                return <div className="mr-3" key={index}>
                                  {item?.name !== "" &&
                                    <div className="tile">
                                      <p>{item?.name}</p>
                                      <h6>{item?.value}</h6>

                                    </div>
                                  }
                                </div>
                              })}
                            </div>
                          </li>
                        </ul>
                      </TabPanel>
                      <TabPanel>
                        <ul className="bid-history-list">
                          {hist?.length > 0 || r1?.length > 0 || r3?.length > 0 ?
                            <>
                              {r1?.length > 0 && <h6 className="cvbxcvbcvbvcbxcvxbxb">Purchases</h6>}
                              {r1?.reverse().map((item, index) => (
                                <li key={index} item={item}>
                                  <div className="content">
                                    <div className="client">
                                      <div className="sc-author-box style-2">
                                        <div className="author-avatar">
                                          <Link to="#">
                                            <img
                                              src={item?.buyer?.picture}
                                              alt="Axies"
                                              className="avatar"
                                            />
                                          </Link>
                                          <div className="badge"></div>
                                        </div>
                                        <div className="author-infor">
                                          <div className="name">
                                            <h6>
                                              {console.log('history', item)}
                                              <Link to={"/user?id=" + item?.buyer?.walletAddress}>{item?.buyer?.userFullName ? item?.buyer?.userFullName : item?.buyer?.walletAddress?.slice(0, 5)}</Link>
                                            </h6>{" "}
                                            {/* {item?.sale?.tokenID} edition */}
                                            <span> Purchased token by {collection?.name} </span>
                                          </div>
                                          <span className="time">{moment(item?.sale?.time).fromNow()}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="price">
                                      {/* <h5>{item.price}</h5>
                                      <span>= {item.priceChange}</span> */}
                                    </div>
                                  </div>
                                </li>
                              ))}
                              {r3?.length > 0 && <h6 className=" cvbxcvbcvbvcbxcvxbxb">Listings</h6>}
                              {r3?.reverse()?.map((item, index) => (
                                <li key={index} item={item}>
                                  <div className="content">
                                    <div className="client">
                                      <div className="sc-author-box style-2">
                                        <div className="author-avatar">
                                          <Link to="#">
                                            <img
                                              src={item?.owner?.picture}
                                              alt="Axies"
                                              className="avatar"
                                            />
                                          </Link>
                                          <div className="badge"></div>
                                        </div>
                                        <div className="author-infor">
                                          <div className="name">
                                            <h6>
                                              <Link to={"/user?id=" + item?.owner?.walletAddress}>{item?.owner?.userFullName ? item?.owner?.userFullName : item?.owner?.walletAddress?.slice(0, 5)}</Link>
                                            </h6>{" "}
                                            {/* {item?.order?.tokenID} edition */}
                                            <span> Listed token by {collection?.name} </span>
                                          </div>
                                          <span className="time">{moment(item?.order?.time).fromNow()}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="price">
                                      {/* <h5>{item.price}</h5> */}
                                      {/* <span>= {item.priceChange}</span> */}
                                    </div>
                                  </div>
                                </li>
                              ))}
                              {hist.length > 0 && <h6 className="cvbxcvbcvbvcbxcvxbxb">Minted</h6>}
                              {hist?.reverse()?.map((item, index) => (
                                <li key={index} item={item}>
                                  <div className="content">
                                    <div className="client">
                                      <div className="sc-author-box style-2">
                                        <div className="author-avatar">
                                          <Link to="#">
                                            <img
                                              src={item?.creator?.picture}
                                              alt="Axies"
                                              className="avatar"
                                            />
                                          </Link>
                                          <div className="badge"></div>
                                        </div>
                                        <div className="author-infor">
                                          <div className="name">
                                            <h6>
                                              <Link to={"/user?id=" + item?.creator?.walletAddress}>{item?.creator?.userFullName ? item?.creator?.userFullName : item?.creator?.walletAddress?.slice(0, 5)}</Link>
                                            </h6>{" "}
                                            {/* {item?.mint?.tokenID} edition */}
                                            <span className=""> Minted token by {collection?.name} </span>
                                          </div>
                                          <span className="time">{moment(item?.mint?.time).fromNow()}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="price">
                                      {/* <h5>{nft?.metaData?.price} Eth</h5> */}
                                      {/* <span>= {item.priceChange}</span> */}
                                    </div>
                                  </div>
                                </li>
                              ))}
                            </>
                            :
                            <p className="text-center">No history to show</p>
                          }
                        </ul>
                        {/* </TabPanel>
                      <TabPanel> */}
                        <ul className="bid-history-list">
                          {rentOffers?.map((item, index) => {
                            return (
                              <li key={index}>
                                <div className="content">
                                  <div className="client">
                                    <div className="sc-author-box style-2">
                                      <div className="author-avatar">
                                        <Link to="#">
                                          <img
                                            src={item?.renterAddress[0]?.picture}
                                            alt="Axies"
                                            className="avatar"
                                          />
                                        </Link>
                                        {item?.renterAddress[0]?.verified === true &&
                                          <div className="badge"></div>
                                        }
                                      </div>
                                      <div className="author-infor">
                                        <div className="name">
                                          <h6>
                                            {" "}
                                            <Link to={"/user?id=" + item?.renterAddress[0]?.walletAddress}>
                                              {item?.renterAddress[0]?.walletAddress?.slice(0, 12) + "..."}
                                            </Link>
                                          </h6>{" "}
                                          <span> placed an offer for rent</span>
                                        </div>
                                        <span className="time">{moment(item?.createdAt).fromNow()}</span>
                                      </div>
                                      <div className="author-infor">
                                        <div className="name ml-2">
                                          <br />
                                          <h6>
                                            {" "}
                                            Days: {item?.rentDuration},
                                            Rent Price: {item?.rentPrice},
                                            Colateral: {item?.noOfDays}
                                          </h6>{" "}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {nft?.ownerAddress?.toLowerCase() === account?.toLowerCase() &&
                                    <>
                                      <div className="price">
                                        <h5 onClick={() => AcceptBids(item)} className="hov curp">Accept Offer</h5>
                                      </div>
                                    </>
                                  }
                                  {item?.renterAddress[0]?.walletAddress === account?.toLowerCase() &&
                                    <svg onClick={() => DeleteRentOffer(item)} xmlns="http://www. w3.org/2000/svg" className="curp icon icon-tabler icon-tabler-square-x" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />

                                      <path d="M10 10l4 4m0 -4l-4 4" />
                                    </svg>
                                  }
                                </div>
                              </li>
                            )
                          })}
                        </ul>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            <p className="px-4 mt-4" style={{ fontSize: 14 }}><span style={{ fontWeight: 700 }}>Collection Contract:</span> {nft?.contractAddress}</p>
          </div>
        </div >
      </div >
      {nfts?.length > 0 &&
        <LiveAuction nfts={nfts} />
      }
      <Footer />
      <div className="modal-offer">
        <div
          className="modal fade offeereed1 p-0"
          id="offermodal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered uuuuuuu">
            <div className="modal-content gcyctycytc">
              <div className="modal-header unknown-header">
                <h5 className="modal-title uk111u" id="exampleModalLabel">
                  Make an Offer
                </h5>
                <button
                  type="button"
                  className="unknown-image"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={()=>setBidAmount('')}
                >
                  <img
                    src="\asssests\layer122.png"
                    alt="img"
                    className="img-fluid"
                  />
                </button>
              </div>
              <div className="modal-body unknown-body">
                <p className="unknown-parag">
                  you are about to make an offer for{" "}
                  <span className="unknown-graph">#{nft?.tokenID} {" " + nft?.nftName}</span> From{" "}
                  <span className="unknown-graph">{collection?.name}</span> collection.
                </p>
                <div>
                  <label htmlFor="efkehakf" className="form-label unknown-label">
                    Offer amount
                  </label>
                  <div className="input-group efkehakf">
                    <input
                      type="number"
                      value={bidAmount} onChange={makeOfferFunc} min="0"
                      className="form-control unknown-input"
                      placeholder="Amount"
                      aria-describedby="button-addon2"
                    />
                    <div>
                      <span
                        className="unknown-eth"
                        id="dropdownMenu2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {nft?.chainId === "5" && "ETH"}
                        {nft?.chainId === "97" && "WBNB"}
                        {nft?.chainId === "80001" && "Matic"}
                        {/* {nft?.chainId === "5" && "ETH"} */}
                        &nbsp;&nbsp;
                        {/* <img
                          src="\arrow-down-black.png"
                          className="img-fluid w200"
                          alt=""
                        /> */}
                      </span>
                      {/* <div
                        className="dropdown-menu"
                        aria-labelledby="dropdownMenu2"
                      >
                        <button className="dropdown-item" type="button">
                          BTC
                        </button>
                        <button className="dropdown-item" type="button">
                          BNB
                        </button>
                        <button className="dropdown-item" type="button">
                          BUSD
                        </button>
                      </div> */}
                    </div>
                  </div>
                  {/* <label
                    for="input-group eafrfeze3r"
                    className="form-label unknown-label"
                  >
                    Offer expiration
                  </label>
                  <div className="input-group known-input">
                    <select
                      className="form-select unknown-select"
                      id="inputGroupSelect04"
                      aria-label="Example select with button addon"
                    >
                      <option selected>1 Day</option>
                      <option value="1">3 Days</option>
                      <option value="2">7 Days</option>
                      <option value="3">1 Month</option>
                    </select>
                  </div> */}
                </div>
                <div className="worry22">
                  <button disabled={loader} className="btn21btn61" onClick={() => SendOffer()}>
                    Make Offer
                  </button>
                  <a href="" onClick={()=>setBidAmount('')} data-dismiss="modal"
                    aria-label="Close" className="btn21btn61">
                    Cancel
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-buy">
        <div
          className="modal fade buyed11 p-0"
          id="buymodal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content gcyctycytc">
              <div className="modal-header unknown-header">
                <h5 className="modal-title uk111u" id="exampleModalLabel">
                  Buy Now
                </h5>
                <button
                  type="button"
                  className="unknown-image"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="\asssests\layer122.png"
                    alt="img"
                    className="img-fluid"
                  />
                </button>
              </div>
              <div className="modal-body unknown-body">
                <p className="unknown-parag">
                  you are about to buy{" "}
                  <span className="unknown-graph">#{nft?.tokenID}</span> From{" "}
                  <span className="unknown-graph">{collection?.name}</span> collection.
                </p>
                <div className="worry11">
                  <h6 className="uuu111pp111">Item Price</h6>
                  <p className="uuu111pp">
                    <img
                      src="\bnb.svg"
                      className="img-fluid"
                      alt="img"
                    />{" "}
                    {nft?.metaData?.price} BNB{" "}
                  </p>
                </div>
                <div className="worry11 mt-4">
                  <h6 className="uuu111pp111">Gas Fees</h6>
                  <p className="uuu111pp">
                    <img
                      src="\bnb.svg"
                      className="img-fluid"
                      alt="img"
                    />{" "}
                    {gas} BNB{" "}
                  </p>
                </div>

                {/* <div className="worry">
                  <p className="xdtrg4rs">Service fee</p>
                  <p className="uuu111pp">2.5%</p>
                </div> */}
                {/* <div className="worry">
                  <p className="xdtrg4rs">Creator fee</p>
                  <p className="uuu111pp">{collection?.percentageFee}%</p>
                </div>
                <div className="worry">
                  <p className="xdtrg4rs">Total Nft amount</p>
                  <p className="uuu111pp">{(parseFloat(nft?.metaData?.price) - ((parseFloat(nft?.metaData?.price)*parseFloat(collection?.percentageFee))).toFixed(6)/100)} Bnb</p>
                </div> */}
                <div className="worry22">
                  <button onClick={() => PurchaseNft()} className="btn21btn61 btn21btn612">
                    Buy Now
                  </button>
                  <button
                    type="button"
                    className="unknown-button btn21btn61"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CardModal
        show={modalShow}
        nft={nft}
        loader={loader}
        bids={bids}
        chainId={chainId}
        collection={collection}
        bidAmount={bidAmount}
        makeOfferFunc={makeOfferFunc}
        SetUpBid={SetUpBid}
        onHide={() => {setBidAmount(''); setModalShow(false)}}
      />
    </div >
  );
};

export default ItemDetails02;
