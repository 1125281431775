

import ReactApexChart from "react-apexcharts";
import React, { useState, useEffect } from "react";
import './stabl3graph.scss';
function Bubblecharts({ charts }) {

  const [data, setData] = useState(null);

  useEffect(() => {
    if (charts?.length > 0) {
      let counts = 0;
      // handling API data start
      for (let i of charts) {
        counts = i.count + counts;
      }
      let datee = null;
      if (charts?.length > 0) {
        datee = charts[0]._id;
        datee = datee?.day + "/" + datee?.month + "/" + datee?.year;
      }
      var dateParts = datee?.split("/");
      var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      dateObject = dateObject.toString();
      // handling API data close


      const state = {
        series: [{
          name: 'TEAM 1',
          data: generateDayWiseTimeSeries(new Date(dateObject).getTime(), counts, {
            min: 10,
            max: 60
          })
        }
        ],
        options: {
          chart: {
            height: 350,
            type: 'scatter',
            zoom: {
              type: 'xy'
            }
          },
          dataLabels: {
            enabled: false
          },
          grid: {
            xaxis: {
              lines: {
                show: true
              }
            },
            yaxis: {
              lines: {
                show: true
              }
            },
          },
          xaxis: {
            type: 'datetime',
          },
          yaxis: {
            max: 70
          }
        },
      };
      function generateDayWiseTimeSeries(baseval, count, yrange) {
        var i = 0;
        var series = [];
        while (i < count) {
          var x = baseval;
          var y =
            Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

          series.push([x, y]);
          baseval += 86400000;
          i++;
        }
        return series;
      }
      setData(state)
    }
  }, [charts])


  return (
    <div className="row">
      <div className="col-12 col-sm-11 m-auto " >
        {data &&
          <div id="chart">
            <ReactApexChart options={data.options} series={data.series} type="scatter" height={350} />
          </div>
        }
      </div>
    </div>


  );
}
export default Bubblecharts;
