import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getRentContract } from "../../utils/contractHelpers";

const RemoveRentNft = () => {
    const web3 = useWeb3();
    const tokenAddress = environment.renting;
    const contract = getRentContract(tokenAddress, web3);
    const rentNfts = useCallback(
        async (nftContract, tokenId,account) => {
            try {
                const gas = await contract.methods
                    .claimCollateral(nftContract,tokenId)
                    .estimateGas({value: 0, from: account });
                let gasPrice = await web3.eth.getGasPrice();
                const details = await contract.methods
                    .claimCollateral(nftContract,tokenId)
                    .send({
                        value: 0,
                        from: account,
                        gas,
                        gasPrice: gasPrice
                    })
                return details;
            } catch (error) {
                console.log("borrow", error)
                throw (error)
            }
        },
        [contract]
    );
    return { rentNfts: rentNfts };
};
export default RemoveRentNft;