const Category = [
    {
        item: 'Ethereum',
        url: 'https://res.cloudinary.com/quecko012356/image/upload/v1658760197/ethereum-eth_1_u53rnu.png',
        chainId: 5,
    },
    // {
    //     item: 'Avalanche',
    //     url: 'https://res.cloudinary.com/quecko012356/image/upload/v1658760197/avalanche-avax-logo_1_x7xlmj.png',
    //     chainId:43114
    // },
    {
        item: 'Binance Smart Chain',
        url: 'https://res.cloudinary.com/quecko012356/image/upload/v1658760197/Frame_9416_jazirr.png',
        chainId: 97
    },
    // ,
    {
        item: 'Polygon',
        url: 'https://res.cloudinary.com/quecko012356/image/upload/v1658760197/Frame_1357_wmxgo7.png',
        chainId:80001
    },
    // {
    //     item: 'Phantum',
    //     url: 'https://res.cloudinary.com/hamza1234/image/upload/v1653662264/drop-in_wkkhmu.png'
    // }
]
export default Category;