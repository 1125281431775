

import ReactApexChart from "react-apexcharts";
import React, { useState, useEffect } from "react";
import './stabl3graph.scss';
function Treasure({ charts }) {
  const [chartData, setChartData] = useState(null);

  const state = {
    series: [
      {
        name: '',
        type: 'column',
        data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
      }, {
        name: '',
        type: 'line',
        data: [23, 42, 35, 27, 43, 22, 17, 31, 22, 22, 12, 16]
      }
    ],
    options: {
      chart: {
        height: 278,
        type: 'line',
      },
      stroke: {
        width: [0, 4]
      },
      title: {
        text: 'Volume and Price'
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
      xaxis: {
        type: 'datetime'
      },
      yaxis: [{
        title: {
          text: 'Volume',
        },

      }, {
        opposite: true,
        title: {
          text: 'Price'
        }
      }
      ]
    },
  };

  useEffect(() => {
    if (charts?.length > 0) {
      let avgPrices = [];
      let volume = [];
      let datt = [];
      for (let i of charts) {
        avgPrices.push(i.average.toFixed(4));
        volume.push(i.volume.toFixed(4));
        let datee = null;
        datee = i._id;
        datee = datee?.day + "/" + datee?.month + "/" + datee?.year;
        var dateParts = datee.split("/");
        var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
        dateObject = dateObject.toString();
        datt.push(dateObject?.slice(0, 15));
      }
      let staticData = {
        series: [
          {
            name: '',
            type: 'column',
            data: volume
          }, {
            name: '',
            type: 'line',
            data: avgPrices
          }
        ],
        options: {
          chart: {
            height: 278,
            type: 'line',
          },
          stroke: {
            width: [0, 4]
          },
          title: {
            text: 'Volume and Price'
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1]
          },
          labels: datt,
          xaxis: {
            type: 'datetime'
          },
          yaxis: [{
            title: {
              text: 'Volume',
            },

          }, {
            opposite: true,
            title: {
              text: 'Price'
            }
          }
          ]
        },
      };
      setChartData(staticData);
    }
  }, [charts])

  return (
    <div className="row">
      <div className="col-11 col-sm-11 m-auto p-0 " >
        {chartData?.series &&
          <div id="chart" className="stabl3graph treasure-graph">
            <ReactApexChart options={chartData.options} series={chartData.series} type="line" height={278} />
          </div>
        }
      </div>
    </div>
  );
}
export default Treasure;
