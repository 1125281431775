import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import Countdown from "react-countdown";
import CardModal from './CardModal'
import { useWeb3React } from '@web3-react/core'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import axios from 'axios';
import { Api_URL } from '../../utils/ApiURL';
import LiveAuctionCard from '../../pages/LiveAuctionMobile/LiveAuctionCard';
import loaderImg from "../../assets/loader.svg"
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'

// import 'swiper/swiper.less';
// import 'swiper/swiper.scss';
// import 'swiper/swiper-bundle.css';
// import 'swiper/swiper-bundle.min/css';
// import 'swiper/modules/pagination/pagination.min.css'
// import 'swiper/swiper.min.css'

// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'


const LiveAuction = props => {
    // const data = props.data;
    const [loader, setLoader] = useState(false);
    const context = useWeb3React();
    const { chainId } = context;
    const { account } = useWeb3React();
    const [modalShow, setModalShow] = useState(false);
    const [data, setAucData] = useState(null);

    const getLiveAuction = async () => {
        setLoader(true)
        var qs = require("qs");
        var data = qs.stringify({
            chainId: chainId?.toString(),
        });
        var config = {
            method: "get",
            url: `${Api_URL}/token/getAllToken`,
            headers: {},
        };

        axios(config)
            .then(function (response) {

                let dumArr = [];
                for (let i of response.data.user) {
                    if (i?.metaData?.method === "auction" && i?.onSale) {
                        if (new Date(i.metaData.endTime) > new Date()) {
                            dumArr.push(i);
                        }
                    }
                }
                setAucData(dumArr);
                setLoader(false)
            })
            .catch(function (error) {
                setLoader(false)
                // console.log(error);
            });
        // await axios.get(`${Api_URL}/token/getAuctionLive`)
        //     .then((res) => {
        //         setAucData(res?.data?.data);
        //     })
        //     .catch((err) => {

        //     })
    }

    useEffect(() => {
        getLiveAuction()
    }, [])

    return (
        <Fragment>
            <section className="Live_____Auctions live-auctions desktop-padding-manual">
                <div className="container">
                    <div className="row">
                    {/* data?.length >= 1 */}
                        { data?.length >= 1 ?
                            <>
                                <div className="col-md-12">
                                    <div className="heading-live-auctions">
                                        <h2 className="tf-title">
                                            Live Auctions</h2>
                                        <Link to="/explore?state=auction" className="exp style2">EXPLORE MORE</Link>
                                    </div>
                                </div>
                                <div className="col-md-12 none-mobile-sm">
                                    <Swiper
                                        modules={[Pagination, Navigation, Scrollbar, A11y]}
                                        spaceBetween={10}

                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1.1,
                                                slidesOffsetBefore: 0,
                                            },
                                            767: {
                                                slidesPerView: 2.5,
                                            },
                                            991: {
                                                slidesPerView: 3,
                                            },
                                            1300: {
                                                slidesPerView: 4,
                                            },
                                        }}
                                        navigation
                                        pagination={{ clickable: true }}
                                        scrollbar={{ draggable: true }}
                                    >
                                        {
                                            data?.slice(0, 7).map((item, index) => (
                                                <SwiperSlide key={index}>
                                                    <div className="swiper-container show-shadow carousel auctions">
                                                        <div className="swiper-wrapper">
                                                            <div className="swiper-slide">
                                                                <div className="slider-item">
                                                                    <div className="sc-card-product">
                                                                        <div className="card-media">
                                                                            <a href={'/item-details?id=' + item?._id}><img src={item?.image} alt="axies" /></a>
                                                                            <a href={'/item-details?id=' + item?._id} className="wishlist-button heart"><span className="number-like">{item?.likedBy?.length}</span></a>
                                                                            <div className="featured-countdown">
                                                                                <span className="slogan"></span>
                                                                                <Countdown date={new Date(item?.metaData?.endTime)}>
                                                                                    <span>You are good to go!</span>
                                                                                </Countdown>
                                                                            </div>
                                                                            {/* <div className="button-place-bid">
                                                                                <Link to="/item-details-01"> <button className="sc-button style-place-bid style bag fl-button pri-3"><span>Place Bid</span></button></Link>
                                                                            </div> */}
                                                                            <div className="button-place-bid">
                                                                                {account?.toLowerCase() == item?.ownerAddress ? (
                                                                                    <a href={'/item-details?id=' + item?._id}>
                                                                                        <button className="sc-button style-place-bid style bag fl-button pri-3">
                                                                                            <span>View</span>
                                                                                        </button>
                                                                                    </a>
                                                                                ) : (
                                                                                    <a href={'/item-details?id=' + item?._id}>
                                                                                        <button className="sc-button style-place-bid style bag fl-button pri-3"><span>{item?.metaData?.method === "auction" && item?.onSale ?
                                                                                            "Place a Bid"
                                                                                            :
                                                                                            "Buy Now"
                                                                                        }</span></button></a>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-title">
                                                                            <h5><a href={'/item-details?id=' + item?._id}>"{item?.nftName}"</a></h5>
                                                                            {item?.chainId === "97" && <div className="tags">bsc</div>}
                                                                            {item?.chainId === "5" && <div className="tags">Eth</div>}
                                                                            {item?.chainId === "80001" && <div className="tags">Matic</div>}
                                                                            {/* {item?.chainId === "97" && <div className="tags">bsc</div>} */}
                                                                        </div>
                                                                        <div className="meta-info">
                                                                            <div className="author">
                                                                                <div className="avatar">
                                                                                    <img src={item?.creators[0]?.picture} alt="axies" />
                                                                                    {
                                                                                        item?.verified ?
                                                                                            <div className="badge"><i className="ripple"></i></div>
                                                                                            : null
                                                                                    }
                                                                                </div>
                                                                                <div className="info">
                                                                                    <span>Creator</span>
                                                                                    <h6>
                                                                                        <a href={"/user?id=" + item?.creators[0]?.walletAddress}>{item?.creators[0]?.userFullName ? item?.creators[0]?.userFullName : item?.walletAddress}</a>{" "}
                                                                                    </h6>
                                                                                </div>
                                                                            </div>
                                                                            {item?.highestBid?.length > 0 ?
                                                                                <div className="price">
                                                                                    <span style={{whiteSpace: "nowrap"}}>Minimum Bid</span>
                                                                                    <h5> {item?.highestBid?.length > 0 ? (item?.highestBid[0]?.highestBid + (item?.highestBid[0]?.highestBid * 0.1))?.toFixed(4) : ""}</h5>
                                                                                </div>
                                                                                :
                                                                                <div className="price">
                                                                                    <span style={{whiteSpace: "nowrap"}}>Minimum Bid</span>
                                                                                    <h5> {item?.metaData?.price > 0 ? parseFloat(item?.metaData?.price)?.toFixed(4) : ""}</h5>
                                                                                </div>
                                                                            }
                                                                            {/* <div className="price">
                                                                                <span>Current Bid</span>
                                                                                <h5> {item?.price}</h5>
                                                                            </div> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                        }
                                    </Swiper>
                                </div>
                                <div className="col-md-12 show-mobile-sm d-none">
                                    <LiveAuctionCard data={data} />
                                </div>
                            </>
                            :
                            <div className='py-5 my-5 w-100'>
                                {loader ?
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="heading-live-auctions">
                                                    <h2 className="tf-title">
                                                        Live Auctions</h2>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="h-100 d-flex align-items-center justify-content-center">
                                                    <div className="d-flex flex-wrap align-items-center justify-content-center">
                                                        <img
                                                            width={150}
                                                            src={loaderImg}
                                                            alt="loader"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                    :
                                    <h2 className='py-5 my-5'>No NFT in Auction</h2>
                                }
                            </div>

                        }
                    </div>
                </div>
            </section>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>

    );
}

LiveAuction.propTypes = {
    data: PropTypes?.array?.isRequired,
}


export default LiveAuction;
