import React from 'react';
import { Link } from 'react-router-dom'
import { Modal } from "react-bootstrap";

const CardModal = (props) => {

    return (

        <Modal
            show={props.show}
            onHide={props.onHide}
            centered
        >
            <Modal.Header closeButton></Modal.Header>

            <div className="modal-body space-y-20 pd-40">
                <h3 className='sdsuydy'>Place a Bid</h3>
                {/* {props?.bids?.length > 0 ?
                    <>
                        {props?.bids[0]?.amount > props?.nft?.metaData?.price ?
                            <>
                                <p className="pl-2">  </p>
                                <p className="text-center">You must bid at least <span className="price color-popup">{props?.bids[0]?.amount + " "} {props?.chainId === 97 && "BSC"} {props?.chainId === 4 && "ETH"} {props?.chainId === 43114 && "AVX"} {props?.chainId === 80001 && "MATIC"}</span></p>
                            </>
                            :
                            <p className="text-center">You must bid at least <span className="price color-popup">{props?.nft?.metaData?.price + " "} {props?.chainId === 97 && "BSC"} {props?.chainId === 4 && "ETH"} {props?.chainId === 43114 && "AVX"} {props?.chainId === 80001 && "MATIC"}</span></p>
                        }
                    </>
                    : */}
                {props?.bids?.length > 0 ? <p className="text-center syusuvucvu">You must bid at least <span className="price color-popup">10% </span>more of previous bid</p> : <p className="text-center">You must bid at least <span className="price color-popup">{props?.nft?.metaData?.price} </span></p>}
                {/* } */}
                {/* <input type="number" className="form-control"
                    placeholder="0.00 ETH" />
                <p>Enter quantity. <span className="color-popup">5 available</span>
                </p> */}
                {/* <label className='newmaaaaddaa'>
                    Your Bid
                </label> */}
                <p>       Your Bid
                </p>
                <input onChange={props?.makeOfferFunc} value={props?.bidAmount} min="0" step={0.01} type={"number"} className="form-control dfgsgsgsgsdgsdgs" placeholder="Enter Your Bid" />
                <div className="hr"></div>
                <div className="d-flex justify-content-between">
                    <p className='dysuysc'> You must bid at least:</p>
                    {props?.bids?.length > 0 ?
                        <>
                            {props?.bids[0]?.amount >= parseFloat(props?.nft?.metaData?.price) ?
                                <>
                                    {/* {// console.log("(props?.bids[0]?.amount + (props?.bids[0]?.amount * 0.1))?.toFixed(2)", props?.bids[0]?.amount  (props?.bids[0]?.amount * 0.1))} */}
                                    <p className="text-right price color-popup">{(props?.bids[0]?.amount + (props?.bids[0]?.amount * 0.1))?.toFixed(4) + " "} {props?.chainId === 97 && "WBNB"} {props?.chainId === 4 && "ETH"} {props?.chainId === 43114 && "AVX"} {props?.chainId === 80001 && "MATIC"}</p>
                                </>
                                :
                                <p className="text-center"> <span className="price color-popup">{props?.nft?.metaData?.price + " "} {props?.chainId === 97 && "BNB"} {props?.chainId === 4 && "ETH"} {props?.chainId === 43114 && "AVX"} {props?.chainId === 80001 && "MATIC"}</span></p>
                            }
                        </>
                        :
                        <p className="text-right price color-popup">{props?.nft?.metaData?.price + " "} {props?.chainId === 97 && "BNB"} {props?.chainId === 4 && "ETH"} {props?.chainId === 43114 && "AVX"} {props?.chainId === 80001 && "MATIC"}</p>
                        // <p className="text-center">You must bid at least <span className="price color-popup">{props?.nft?.metaData?.price + " "} {props?.chainId === 97 && "BSC"} {props?.chainId === 4 && "ETH"} {props?.chainId === 43114 && "AVX"} {props?.chainId === 80001 && "MATIC"}</span></p>
                    }
                    {/* <p className="text-right price color-popup"> 4.89 ETH </p> */}
                </div>
                {/* <div className="d-flex justify-content-between">
                    <p> Service fee:</p>
                    <p className="text-right price color-popup">{2.5}%</p>
                </div> */}
                <div className="d-flex justify-content-between">
                    <p> Creator fee:</p>
                    <p className="text-right price color-popup">{props?.collection?.percentageFee ? props?.collection?.percentageFee : 0}%</p>
                </div>
                <div className="d-flex justify-content-between">
                    <p> Total bid amount:</p>
                    <p className="text-right price color-popup">{props?.bidAmount} {props?.chainId === 97 && "BNB"} {props?.chainId === 4 && "ETH"} {props?.chainId === 43114 && "AVX"} {props?.chainId === 80001 && "MATIC"}</p>
                </div>
                <button disabled={props?.loader} onClick={() => props?.SetUpBid()} className="btn btn-primary" data-toggle="modal" data-target="#popup_bid_success" data-dismiss="modal" aria-label="Close"> Place a bid</button>
            </div>
        </Modal>

    );
};

export default CardModal;
