// import { useCallback, useEffect, useState } from "react";
// import useWeb3 from "../useWeb3";
// import environment from "../../utils/Environment";
// import { getWethContract } from "../../utils/contractHelpers";
// import { useWeb3React } from "@web3-react/core";

// export const CheckAllowance = () => {
//   const web3 = useWeb3();
//   // const tokenAddress = environment.weth;
//   // const marketAddress = environment.MarketContract;
//   const context = useWeb3React();
//   const { chainId } = context;
//   const [contractAddress, setContractAddress] = useState(null);
//   const [marketAddress, setMarketAddress] = useState(null);
//   const [contract, setContract] = useState(null);

//   useEffect(() => {
//     if (chainId === 97) {
//       setContractAddress(environment.Bsc.weth);
//       setMarketAddress(environment.Bsc.MarketContract);
//       setContract(getWethContract(environment.Bsc.weth, web3));
//     } else if (chainId === 4) {
//       setContractAddress(environment.Eth.weth);
//       setMarketAddress(environment.Eth.MarketContract);
//       setContract(getWethContract(environment.Eth.weth, web3))
//     } else if (chainId === 43114) {
//       setContractAddress(environment.Eth.weth);
//       setMarketAddress(environment.Eth.MarketContract);
//       setContract(getWethContract(environment.Eth.weth, web3))
//     } else if (chainId === 80001) {
//       setContractAddress(environment.Polygon.weth);
//       setMarketAddress(environment.Polygon.MarketContract);
//       setContract(getWethContract(environment.Polygon.weth, web3))
//     }
//   }, [chainId])

//   const CheckAllow = useCallback(async (account) => {
//     // console.log("okokokokokok", contractAddress, marketAddress, contract);
//     if (contract && web3) {
//       const approved = await contract.methods.allowance(account, marketAddress).call();
//       return approved;
//     }
//   }, [contract, web3]);

//   return { CheckAllow: CheckAllow };
// };

// export default CheckAllowance;

// import { useCallback } from "react";
// import useWeb3 from "../useWeb3";
// import environment from "../../utils/Environment";
// import { getWethContract } from "../../utils/contractHelpers";

// export const CheckAllowance = () => {
//   const web3 = useWeb3();
//   const tokenAddress = environment.Polygon.weth;
//   const marketAddress = environment.Polygon.MarketContract;
//   const contract = getWethContract(tokenAddress, web3);
//   const CheckAllow = useCallback(async (account) => {
//     const approved = await contract.methods.allowance(account, marketAddress).call();
//     return approved;
//   }, [contract]);

//   return { CheckAllow: CheckAllow };
// };

// export default CheckAllowance;

import { useCallback, useState, useEffect } from "react";
import useWeb3 from "../useWeb3";
import { getWethContract } from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";
// import { useWeb3React } from "@web3-react/core";

export const CheckAllowance = () => {
  const web3 = useWeb3();
//   const context = useWeb3React();
//   const { chainId } = context;
  const [marketAddress, setMarketAddress] = useState(null);
  const [contractAddress, setContractAddress] = useState(null);

//   useEffect(() => {
//     if (chainId === 97) {
//       setMarketAddress(Environment.Bsc.MarketContract);
//       setContractAddress(Environment.Bsc.weth);
//     } else if (chainId === 4) {
//       setMarketAddress(Environment.Eth.MarketContract);
//       setContractAddress(Environment.Eth.weth);
//     } else if (chainId === 43114) {
//       setMarketAddress(Environment.Eth.MarketContract);
//       setContractAddress(Environment.Eth.weth);
//     } else if (chainId === 80001) {
//       setMarketAddress(Environment.Polygon.MarketContract);
//       setContractAddress(Environment.Polygon.weth);
//     }
//   }, [chainId])

  useEffect(() => {
    setMarketAddress(Environment.marketPlaceContract);
    setContractAddress(Environment.weth);
}, [])

  const CheckAllow = useCallback(async (account) => {
    if (marketAddress) {
      try {
        const contract = getWethContract(contractAddress, web3);
        const approved = await contract.methods.allowance(account, marketAddress).call();
        return approved;
      } catch (e) {
        // console.log("AAAA", e)
      }
    }
  }, [marketAddress]);

  return { CheckAllow: CheckAllow };
};

export default CheckAllowance;