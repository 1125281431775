// Set of helper functions to facilitate wallet setup

// import { nodes } from './getRpcUrl'

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
// export const setupNetwork = async () => {
//   const provider = (window).ethereum
//   if (provider) {
//     // const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
//     const chainId = "97"
//     try {
//       await provider.request({
//         method: 'wallet_addEthereumChain',
//         params: [
//           {
//             chainId: `0x${chainId.toString(16)}`,
//             chainName: 'Binance Smart Chain TestNet',
//             nativeCurrency: {
//               name: 'BNB',
//               symbol: 'bnb',
//               decimals: 18,
//             },
//             rpcUrls: nodes,
//             blockExplorerUrls: ['https://bscscan.com/'],
//             // blockExplorerUrls: ['https://testnet.bscscan.com/'],

//           },
//         ],
//       })
//       return true
//     } catch (error) {
//       console.error(error)
//       return false
//     }
//   } else {
//     console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
//     return false
//   }
// }

// /**
//  * Prompt the user to add a custom token to metamask
//  * @param tokenAddress
//  * @param tokenSymbol
//  * @param tokenDecimals
//  * @param tokenImage
//  * @returns {boolean} true if the token has been added, false otherwise
//  */
// export const registerToken = async (
//   tokenAddress,
//   tokenSymbol,
//   tokenDecimals,
//   tokenImage,
// ) => {
//   const tokenAdded = await (window).ethereum.request({
//     method: 'wallet_watchAsset',
//     params: {
//       type: 'ERC20',
//       options: {
//         address: tokenAddress,
//         symbol: tokenSymbol,
//         decimals: tokenDecimals,
//         image: tokenImage,
//       },
//     },
//   })

//   return tokenAdded
// }




// // Set of helper functions to facilitate wallet setup

// /**
//  * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
//  * @returns {boolean} true if the setup succeeded, false otherwise
//  */
//  export const setupNetwork = async () => {
//   const provider = (window).ethereum
//   if (provider) {
//     const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
//     // const chainId = 4
//     try {
//       await provider.request({
//         method: 'wallet_switchEthereumChain',
//         params: [
//           {
//             chainId: `0x${chainId.toString(16)}`,
//             // chainName: 'Rinkeby Smart Chain TestNet',
//             // nativeCurrency: {
//             //   name: 'ETH',
//             //   symbol: 'ETH',
//             //   decimals: 18,
//             // },
//             // rpcUrls: [nodes],
//             // blockExplorerUrls: ['https://rinkeby.etherscan.com/'],

//           },
//         ],
//       })
//       return true
//     } catch (error) {
//       console.error(error)
//       return false
//     }
//   } else {
//     console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
//     return false
//   }
// }
// /**
//  * Prompt the user to add a custom token to metamask
//  * @param tokenAddress
//  * @param tokenSymbol
//  * @param tokenDecimals
//  * @param tokenImage
//  * @returns {boolean} true if the token has been added, false otherwise
//  */
// export const registerToken = async (
//   tokenAddress,
//   tokenSymbol,
//   tokenDecimals,
//   tokenImage,
// ) => {
//   const tokenAdded = await (window).ethereum.request({
//     method: 'wallet_watchAsset',
//     params: {
//       type: 'ERC20',
//       options: {
//         address: tokenAddress,
//         symbol: tokenSymbol,
//         decimals: tokenDecimals,
//         image: tokenImage,
//       },
//     },
//   })

//   return tokenAdded
// }


// Set of helper functions to facilitate wallet setup
import { nodes } from './getRpcUrl'
/**
* Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
* @returns {boolean} true if the setup succeeded, false otherwise
*/

export const setupNetwork = async (id) => {
  // console.log("idididid", id)
  if (id == 5) {
    const provider = (window).ethereum
    if (provider) {
      // const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
      const chainId = id;
      let nodes = [process.env.REACT_APP_NODE_1ETH, process.env.REACT_APP_NODE_2ETH, process.env.REACT_APP_NODE_3ETH];
      // console.log("nodes we get here", nodes)
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }]
          // params: [
          //   {
          //     chainId: `0x${chainId.toString(16)}`,
          //     chainName: 'Rinkeby Test Network',
          //     nativeCurrency: {
          //       name: 'Rinkeby Test Network',
          //       symbol: 'ETH',
          //       decimals: 18,
          //     },
          //     rpcUrls: nodes,
          //     blockExplorerUrls: ['https://rinkeby.etherscan.io/'],
          //   },
          // ],
        })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    } else {
      console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
      return false
    }
  } else if (id == 97) {
    const provider = (window).ethereum
    if (provider) {
      const chainId = id;
      let nodes = [process.env.REACT_APP_NODE_1BSC, process.env.REACT_APP_NODE_2BSC, process.env.REACT_APP_NODE_3BSC];
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }],
          // params: [
          //   {
          //     chainId: `0x${chainId.toString(16)}`,
          //     chainName: 'Binance Smart Chain TestNet',
          //     nativeCurrency: {
          //       name: 'BNB',
          //       symbol: 'bnb',
          //       decimals: 18,
          //     },
          //     rpcUrls: nodes,
          //     blockExplorerUrls: ['https://testnet.bscscan.com/'],
          //   },
          // ],
        })

        // await provider.request({
        //   method: 'wallet_addEthereumChain',
        //   params: [
        //     {
        //       chainId: `0x${chainId.toString(16)}`,
        //       chainName: 'Binance Smart Chain TestNet',
        //       nativeCurrency: {
        //         name: 'BNB',
        //         symbol: 'BNB',
        //         decimals: 18
        //       },
        //       rpcUrls: nodes,
        //       blockExplorerUrls: ['https://testnet.bscscan.com/']
        //       // blockExplorerUrls: ['https://testnet.bscscan.com/'],
        //     }
        //   ]
        // })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    } else {
      console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
      return false
    }
  } else if (id === 43114) {
    const provider = (window).ethereum
    if (provider) {
      const chainId = id;
      // let nodes = [process.env.REACT_APP_NODE_1BSC, process.env.REACT_APP_NODE_2BSC, process.env.REACT_APP_NODE_3BSC];
      try {
        await provider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${chainId.toString(16)}` }]
          // params: [
          //   {
          //     chainId: `0x${chainId.toString(16)}`,
          //     chainName: 'Binance Smart Chain TestNet',
          //     nativeCurrency: {
          //       name: 'BNB',
          //       symbol: 'bnb',
          //       decimals: 18,
          //     },
          //     rpcUrls: nodes,
          //     blockExplorerUrls: ['https://testnet.bscscan.com/'],
          //   },
          // ],
        })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    } else {
      console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
      return false
    }
  } else if (id === 137) {
    const provider = (window).ethereum
    if (provider) {
      const chainId = id;
      let nodes = [process.env.REACT_APP_NODE_1POLY, process.env.REACT_APP_NODE_2POLY, process.env.REACT_APP_NODE_3POLY];
      // console.log("nodes we get here", nodes)
      try {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: `0x${chainId.toString(16)}`,
              chainName: 'Polygon TestNet',
              nativeCurrency: {
                name: 'MATIC',
                symbol: 'MATIC',
                decimals: 18
              },
              rpcUrls: nodes,
              blockExplorerUrls: ['https://polygonscan.com/']
              // blockExplorerUrls: ['https://testnet.bscscan.com/'],
            }
          ]
        })
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    } else {
      console.error("Can't setup the BSC network on metamask because window.ethereum is undefined")
      return false
    }
  }
}
/**
* Prompt the user to add a custom token to metamask
* @param tokenAddress
* @param tokenSymbol
* @param tokenDecimals
* @param tokenImage
* @returns {boolean} true if the token has been added, false otherwise
*/
export const registerToken = async (
  tokenAddress,
  tokenSymbol,
  tokenDecimals,
  tokenImage
) => {
  const tokenAdded = await (window).ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: tokenImage
      }
    }
  })
  return tokenAdded
}