import { useCallback, useState, useEffect } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

const OffTrans = () => {
    const web3 = useWeb3();
    // const contractAddress = environment.MarketContract;
    const [contractAddress, setContractAddress] = useState(null);
    const context = useWeb3React();
    const { chainId } = context;

    useEffect(() => {
        if (chainId === 97) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 4) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 43114) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 80001) {
            setContractAddress(environment.marketPlaceContract);
        }
    }, [chainId])

    const contract = getMarketContract(contractAddress, web3);
    const OfferTrans = useCallback(
        async (account, values, signature, signature2, contractAddress, raddress, fee, offeTime) => {
            if (contractAddress) {
                try {
                    // // console.log("seller, buyer, amount, uri, tokenId, deadline, v, r, s, contaddress", account, values, signature, signature2, contractAddress, raddress, fee, offeTime)
                    // return // console.log("okokokokokok", account, values, signature, signature2, contractAddress, raddress, fee, offeTime, contractAddress)
                    const gas = await contract.methods
                        .OfferAndTransfer(values, signature, signature2, contractAddress, raddress, fee, offeTime)
                        .estimateGas({ from: account });
                    let gasPrice = await web3.eth.getGasPrice();
                    const details = await contract.methods
                        .OfferAndTransfer(values, signature, signature2, contractAddress, raddress, fee, offeTime)
                        .send({
                            from: account,
                            gas,
                            gasPrice: gasPrice
                        })

                    return details;
                } catch (error) {
                    // console.log(error)
                    throw (error)
                }
            }
        },
        [contract, contractAddress]
    );
    return { OfferTrans: OfferTrans };
};

export default OffTrans;