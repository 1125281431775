import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

import "./createcompetition.scss";
import Category from "./Listarray";
const Createcompetition = () => {
  const [category, setcategory] = useState("Ethereum");
  const [img, setimg] = useState(
    "https://res.cloudinary.com/hamza1234/image/upload/v1653662264/drop-in_wkkhmu.png"
  );

  const categoriess = (a, b) => {
    setcategory(a);
    setimg(b);
  };

  return (
    <>
    <Header />
    <section className="createcompetition ptb">
      <div className="container text-left">
      <div className='row'>
            <div className='col-sm-12'>
              <div className="mainheadings">
                <h1>Edit Your Profile</h1>
                <p>You can set preferred display name and manage other personal settings</p>
              </div>
              </div>
              </div>
        <div className="row">
          <div className="col-lg-12 col-12 m-auto">
            <div className="row">
              <div className="col-xl-9 col-12">
                <div className="row">
                  <div className="col-xl-4">
                    <div className="upload">
                      <h6 className="m-0">Logo</h6>
                      <p>350 x 350 recommended.</p>
                      <div className="piciploddiv piciploddiv1">
                        <label for="upload" className="upload-btn">Upload file</label>
                        <input id="upload" type="file" className="d-none" />
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-8">
                    <div className="upload">
                      <h6 className="m-0">Featured image</h6>
                      <p>600 x 400 recommended.</p>
                      <div className="piciploddiv1">
                        <label for="upload" className="upload-btn">Upload file</label>
                        <input id="upload" type="file" className="d-none" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="upload">
                  <h6>Banner image</h6>
                  <div className="piciploddiv">
                    <p className="inerpicpara m-0">
                    1400 x 400 recommended.
                    </p>
                    <label for="upload" className="upload-btn">Upload file</label>
                    <input id="upload" type="file" className="d-none" />
                  </div>
                </div>
                <div className="description">
                  <div className="input-field">
                    <h6>Name</h6>
                    <input
                      name=""
                      type="text"
                      placeholder="Example: treasures of moon"
                      className="input-create"
                    />
                  </div>
                  <div className="input-field">
                    <h6 className="m-0">URL</h6>
                    <p>Customize your URL on Bluemoon. Must only contain lowercase letters, numbers, and hyphens.</p>
                    <input
                      name=""
                      type="text"
                      placeholder="https://bluemoon.io/collection/treasures-of-moon"
                      className="input-create"
                    />
                  </div>
                  <div className="input-field">
                    <h6>Description</h6>
                    <textarea
                      name=""
                      type="text"
                      placeholder="e.g. “This is very limited item”"
                      className="input-create"
                    />
                  </div>
                  <div className="input-field">
                    <h6>Category</h6>

                    <div className="button-list">
                      <div className="dropdown buttons-list-all">
                        <button
                          className="button-listing"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="left d-flex justify-content-center align-items-center">
                            <img src={img} alt="img" className="img-fluid " />
                            &nbsp;&nbsp;
                            <p className="m-0">{category}</p>
                          </div>
                          <img
                            src="bluemoon-nft\bbarrow.png"
                            alt="img"
                            className="img-fluid"
                          />
                        </button>

                        <div
                          className="dropdown-menu gfghfhgfv"
                          aria-labelledby="dropdownMenuButton"
                        >
                          {Category.map((elem) => {
                            return (
                              <a
                                className="dropdown-item"
                                onClick={() => categoriess(elem.item, elem.url)}
                              >
                                <img
                                  src={elem?.url}
                                  alt="img"
                                  className="img-fluid"
                                />
                                &nbsp;&nbsp;
                                {elem?.item}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="input-field">
                    <h6>Links</h6>
                   
                    <div className="inner-input">
                      <img
                        src="\bluemoon-nft\global.png"
                        alt="img"
                        className="img-fluid"
                      />
                      <input className="upperinput" type="text" placeholder="yoursite.io" />
                    </div>
                    <div className="inner-input">
                      <img
                        src=".\create\discord.png"
                        alt="img"
                        className="img-fluid"
                      />
                      <input className="linkinput" type="text" placeholder="https://discord.gg/abscdef" />
                    </div>
                    <div className="inner-input">
                      <img
                        src=".\create\insta.png"
                        alt="img"
                        className="img-fluid"
                      />
                      <input className="linkinput" type="text" placeholder="https://instagram.com/YourinstagramHandle" />
                    </div>
                    <div className="inner-input">
                      <img
                        src="\create\mediumShape.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <input className="linkinput" type="text" placeholder="https://medium.com/@YourmediumHandle" />
                    </div>
                    <div className="inner-input">
                      <img
                        src=".\create\telegram.png"
                        alt="img"
                        className="img-fluid"
                      />
                      <input className="linkinput" type="text" placeholder="https://t.me/abcdef" />
                    </div>
                    <div className="inner-input">
                      <img
                        src="\create\twitter.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <input className="lowerinput" type="text" placeholder="https://twitter.com/abcdef" />
                    </div>
                    </div>
        
                  {/* <div className="input-field">
                    <h6>Creator Earnings</h6>
                    <p>
                      Collect a fee when a user re-sells an item you originally created.
                    </p>
                    <span className="unknow11">%</span>
                    <input
                      name=""
                      type="text"
                      placeholder="e.g. 2.5"
                      className="input-create"
                    />
                    
                  </div> */}
                </div>
                {/* <div className="collection-items">
                  <div className="parent">
                    <div className="first">
                      <div className="inner-img">
                        <img
                          src=".\create\hazard.png"
                          alt="img"
                          className="img-fluid"
                        />
                      </div>
                      <div className="inner-text">
                        <h6>Explicit & Sensitive Content</h6>
                        <p>
                          Set this item as explicit and sensitive content info
                        </p>
                        

                      </div>
                    </div>
                    <div className="second">
                    <div className="my-item">
                      <label className="toggle">
                        <input className="toggle-checkbox" type="checkbox" />
                        <div className="toggle-switch"></div>
                      </label>
                    </div>
                    </div>
                  </div>
                </div> */}
                <div className="bottommmmmmmmm">
                  <div className="unknownbtn">
                    <button className="btn-blue">Create</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer />
  </>
  );
};

export default Createcompetition;