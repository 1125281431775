import { useEffect, useState } from 'react';
import axios from 'axios';
import { Api_URL } from '../../utils/ApiURL';
import Accordion from "react-bootstrap/Accordion";
import moment from 'moment';

const ActivitiesTab = ({ showBtn, setShowBtn, contractId, GetContract }) => {
    const [loader, setLoader] = useState(false);
    const [rend, setRend] = useState(false);
    const [nfts, setNfts] = useState([]);
    const [timeFilts, setTimeFilts] = useState("year");
    const [showMore, setShowMore] = useState(8);
    const [filts, setFilts] = useState(["Listing", "Sale", "Transfer", "Swap"]);

    const GetFilterContract = (val) => {
        var qs = require("qs");
        if (val) {
            setTimeFilts(val);
        } else {
            val = "year"
        }
        let data = JSON.stringify({
            "contractAddress": contractId,
            "queryBy": val
        });
        let config = {
            method: 'post',
            url: `${Api_URL}/collection/collectionActivity`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios.request(config)
            .then((response) => {
                if (response?.data?.collectionActivity?.length > 0) {
                    setNfts(response?.data?.collectionActivity[0]?.activity);
                }
                setLoader(false);
            })
            .catch((error) => {
                // console.log(error);
                setLoader(false);
            });
    };

    useEffect(() => {
    }, [filts])

    useEffect(() => {
        if (contractId) {
            GetFilterContract();
        }
    }, [contractId])

    const markFil = (key) => {
        let dumObj = filts.find(i => i === key);
        if (dumObj) {
            return true;
        } else {
            return false;
        }
    }

    const SetFilters = (key) => {
        let dumObj = filts.find(i => i === key);
        if (dumObj) {
            let dumArr = filts;
            dumArr = dumArr.filter((i) => {
                return i !== key;
            })
            setFilts(dumArr);
        } else {
            let dumArr = filts;
            dumArr.push(key);
            setFilts(dumArr);
        }
        setRend(!rend);
    }

    return <>
        <div className='row'>
            <div className='col-sm-12 m-auto'>
                <div className="main-head">
                    <div className="frameimg d-flex align-items-center justify-between"  >
                        <img src="\assets\Frame5555.svg" onClick={() => setShowBtn(!showBtn)} alt="img" style={{ cursor: 'pointer' }} className="img-fluid" />
                        <p className='result'>{nfts?.length} results</p>
                    </div>
                    <div className="maininput">
                        {/* <input type="text" placeholder="Search items, collections, and accounts" />
                        <img src="\assets\search-normal.svg" alt="img" className="img-fluid" style={{ cursor: 'pointer' }} /> */}
                    </div>
                    <div className="dropdownbtn">
                        <button className="btnbtnbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {timeFilts} <img src="\assets\Icon.svg" alt="img" className="img-fluid" />
                        </button>
                        <div className="dropdown-menu dfsdgsdgsdgsdgsdfg" aria-labelledby="dropdownMenuButton">
                            <a className="dropdown-item btnbtnbtnnddd" onClick={() => GetFilterContract("day")}>Day</a>
                            <a className="dropdown-item btnbtnbtnnddd" onClick={() => GetFilterContract("week")}>Week</a>
                            <a className="dropdown-item btnbtnbtnnddd" onClick={() => GetFilterContract("month")}>Month</a>
                            <a className="dropdown-item btnbtnbtnnddd" onClick={() => GetFilterContract("year")}>Year</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className='row'>
            <div className='col-12 m-auto'>
                <div className='row'>
                    <div className={showBtn ? " col-xl-3 col-lg-3 col-12" : 'd-none'}>
                        <div className="container1920">
                            <div className="row">
                                <div className="col-11 p-0">
                                    <section id="Cofilter" className="filter">
                                        <header className="header">
                                            <h3>Filters</h3>
                                            {/* <a onClick={() => GetContract(contractId)}>Reset</a> */}
                                        </header>
                                        <div className="main">
                                            <Accordion defaultActiveKey={["0"]} alwaysOpen>
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Status</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="option-field">
                                                            <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                                <p>Listings</p>
                                                                <label className="checkbox-button">
                                                                    <input
                                                                        className="checkbox-button__input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        checked={markFil("Listing")}
                                                                        onChange={(e) => SetFilters("Listing")}
                                                                        id="defaultCheck1"
                                                                    />
                                                                    <span className="checkbox-button__control"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="option-field">
                                                            <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                                <p>Offers</p>
                                                                <label className="checkbox-button">
                                                                    <input
                                                                        className="checkbox-button__input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        checked={markFil("Sale")}
                                                                        onChange={(e) => SetFilters("Sale")}
                                                                        id="defaultCheck2"
                                                                    />
                                                                    <span className="checkbox-button__control"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="option-field">
                                                            <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                                <p>Transfers</p>
                                                                <label className="checkbox-button">
                                                                    <input
                                                                        className="checkbox-button__input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        checked={markFil("Transfer")}
                                                                        onChange={(e) => SetFilters("Transfer")}
                                                                        id="defaultCheck1"
                                                                    />
                                                                    <span className="checkbox-button__control"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="option-field">
                                                            <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                                                                <p>Swaps</p>
                                                                <label className="checkbox-button">
                                                                    <input
                                                                        className="checkbox-button__input"
                                                                        type="checkbox"
                                                                        value=""
                                                                        checked={markFil("Swap")}
                                                                        onChange={(e) => SetFilters("Swap")}
                                                                        id="defaultCheck1"
                                                                    />
                                                                    <span className="checkbox-button__control"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    </section>

                                    <section className="filter-mbl ">
                                        <div
                                            className="modal fade"
                                            id="staticBackdrop"
                                            data-backdrop="static"
                                            data-keyboard="false"
                                            tabindex="-1"
                                            aria-labelledby="staticBackdropLabel"
                                            aria-hidden="true"
                                        >
                                            <div className="modal-dialog">
                                                <div className="modal-content">
                                                    <div className="modal-body">
                                                        <header className="header">
                                                            <h3>Filters</h3>
                                                            <button
                                                                type="button"
                                                                className="close"
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">
                                                                    <img
                                                                        src="\bluemoon-nft\close.png"
                                                                        alt="img"
                                                                        className="img-fluid"
                                                                    />
                                                                </span>
                                                            </button>
                                                        </header>
                                                        <div className="main">
                                                            <Accordion defaultActiveKey={["0"]} alwaysOpen>
                                                                <Accordion.Item eventKey="0">
                                                                    <Accordion.Header>Status</Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <div className="option-field">
                                                                            <p>Buy Now</p>
                                                                            {/* <Form.Check

                                                                /> */}
                                                                            <div className="content">
                                                                                <label className="checkBox">
                                                                                    <input id="ch2" type="checkbox" />
                                                                                    <div className="transition"></div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="option-field">
                                                                            <p>On Auction</p>
                                                                            {/* <Form.Check

                                                                /> */}
                                                                            <div className="content">
                                                                                <label className="checkBox">
                                                                                    <input id="ch2" type="checkbox" />
                                                                                    <div className="transition"></div>
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey="1">
                                                                    <Accordion.Header>Price</Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <div className="price">
                                                                            <div className="set-arrow">

                                                                                <select disabled className="custom-select">

                                                                                    <option value="1">
                                                                                        BNB
                                                                                    </option>

                                                                                </select>
                                                                            </div>
                                                                            <input
                                                                                type="number"
                                                                                min={0}

                                                                                placeholder=""
                                                                            />
                                                                            <p>to</p>
                                                                            <input
                                                                                type="number"
                                                                                min={0}

                                                                                placeholder=""
                                                                            />
                                                                        </div>
                                                                        <div className="apply-btn">
                                                                            {/* <Link to="/timeauction"> */}{" "}
                                                                            <button

                                                                                className="apply-btn-btn"
                                                                            >
                                                                                Apply
                                                                            </button>
                                                                            {/* </Link> */}
                                                                        </div>
                                                                    </Accordion.Body>
                                                                </Accordion.Item>

                                                                <Accordion.Item eventKey="2">
                                                                    <Accordion.Header>Collection</Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <div className="option-field">
                                                                            <p>Bored Ape Kenne...</p>
                                                                            {/* <Form.Check /> */}
                                                                            <div className="content">
                                                                                <label className="checkBox">
                                                                                    <input id="ch2" type="checkbox" />
                                                                                    <div className="transition"></div>
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                                <Accordion.Item eventKey="4">
                                                                    <Accordion.Header>Chains</Accordion.Header>
                                                                    <Accordion.Body>
                                                                        <div className="option-field">
                                                                            <p className="align-item-center"><img src="\assets\ethereum-eth 1.svg" alt="img" className="img-fluid" />  Ethereum</p>
                                                                            {/* <Form.Check /> */}
                                                                            <div className="content">
                                                                                <label className="checkBox">
                                                                                    <input id="ch2" type="checkbox" />
                                                                                    <div className="transition"></div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="option-field">
                                                                            <p><img src="\assets\OneArt-_x2022_-Desktop-_x2022_-Light.svg" alt="img" className="img-fluid" /> BSC</p>
                                                                            {/* <Form.Check /> */}
                                                                            <div className="content">
                                                                                <label className="checkBox">
                                                                                    <input id="ch2" type="checkbox" />
                                                                                    <div className="transition"></div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="option-field">
                                                                            <p> <img src="\assets\polygon-matic-seeklogo.com 1.svg" alt="img" className="img-fluid" />  Polygon</p>
                                                                            {/* <Form.Check /> */}
                                                                            <div className="content">
                                                                                <label className="checkBox">
                                                                                    <input id="ch2" type="checkbox" />
                                                                                    <div className="transition"></div>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="option-field">
                                                                            <p className="text-align-center"> <img src="\assets\solana.svg" alt="img" className="img-fluid" /> Solana</p>
                                                                            {/* <Form.Check /> */}
                                                                            <div className="content">
                                                                                <label className="checkBox">
                                                                                    <input id="ch2" type="checkbox" />
                                                                                    <div className="transition"></div>
                                                                                </label>
                                                                            </div>
                                                                        </div>



                                                                    </Accordion.Body>
                                                                </Accordion.Item>
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={showBtn ? "col-xl-9 col-lg-9 col-12" : "col-xl-12 col-12"}>
                        <div className='chartsbtn'>
                            {filts?.map((item, index) => {
                                return (
                                    <button onClick={() => SetFilters(item)} key={index} className='fst_chartbtn'>
                                        {item}   <img src="\crosss.png" alt="img" className="img-fluid ml-4" />
                                    </button>
                                )
                            })}
                            {/* <button className='secchatrbtnn'>
                                Clear all
                            </button> */}
                        </div>
                        {/* <div className='chart_im mb-5'>
                            <Treasure />

                        </div> */}
                        {nfts?.length > 0 ?
                            <>
                                <div className="table-responsive border-bottom-0 tabble_activity mt-5">
                                    <table className="table bashbord_table">
                                        <thead className="bashbord_table_head">
                                            <tr className="tablerow">
                                                <th scope="col"></th>
                                                <th scope="col">Item</th>
                                                <th scope="col">Price</th>
                                                {/* <th scope="col">Rarity</th> */}
                                                <th scope="col">Quantity</th>
                                                <th scope="col">From</th>
                                                <th scope="col">To</th>
                                                <th scope="col">Time</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {nfts?.map((item, index) => {
                                                return (
                                                    <>
                                                        {filts.find((i) => item?.type === i) && index < showMore ?
                                                            <tr key={index} className="fstrowtable">
                                                                <td>
                                                                    <div className="leftimg d-flex align-items-center">
                                                                        <img src="\assets\Frame1002.svg" alt="img" className="img-fluid" />
                                                                        <p style={{ fontSize: 14, color: "#000", marginLeft: -32 }}>{item?.type}</p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="mainheading">

                                                                        <div className="midimg">
                                                                            <img style={{ width: 45, height: 45, borderRadius: 15 }} src={item?.tokens[0]?.image} alt="img" className="img-fluid" />
                                                                        </div>
                                                                        <div className="rightext">
                                                                            <h4 className='boredap'>{item?.tokens[0]?.nftName}</h4>
                                                                            <p className='paraadig'><span style={{ fontSize: 12 }}>TokenId: </span>{item?.tokens[0]?.tokenID}</p>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td className='text-left'>{item?.price ? <><h4 className="black">{item?.price} WETH</h4>
                                                                    {/* <p className="gray">$13,892.32</p> */}
                                                                    </>
                                                                    : <span className='pl-5'>---</span> 
                                                                    }
                                                                </td>
                                                                {/* <td><h4 className="black">--</h4>
                                                                </td> */}
                                                                <td className='pl-5'>
                                                                    <h4 className="one pl-5">1</h4>
                                                                </td>
                                                                <td className='pl-5'> {item?.buyer?.length > 0 && <p className="blue">{item?.buyer[0]?.userFullName ? item?.buyer[0]?.userFullName : item?.buyer[0]?.walletAddress?.slice(item?.buyer[0]?.walletAddress?.length - 5, item?.buyer[0]?.walletAddress?.length)}</p>}</td>
                                                                <td>{item?.seller?.length > 0 && <p className="blue">{item?.seller[0]?.userFullName ? item?.seller[0]?.userFullName : item?.seller[0]?.walletAddress?.slice(item?.seller[0]?.walletAddress?.length - 5, item?.seller[0]?.walletAddress?.length)}</p>}</td>
                                                                <td><p className="blue">{moment(item?.createdAt).fromNow()}</p></td>
                                                            </tr>
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                                {showMore < nfts.length &&
                                    <div className='w-100 mb-3 mt-5  d-flex justify-content-center align-items-center'>
                                        <button onClick={() => setShowMore(showMore + 3)} className=''>
                                            Load More
                                        </button>
                                    </div>}

                            </>
                            :
                            <div className='main-data mt-5'>
                                <div className='main-imgcard'>
                                    <div className='main-imagescard1 d-flex align-items-center justify-content-center'>
                                        <h3 className='main-paragraph mt-5 ml-3'>No activities found.</h3>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default ActivitiesTab;