

import ReactApexChart from "react-apexcharts";
import React, { useState, useEffect } from "react";
import './stabl3graph.scss';
function Zoomable({ charts }) {
  const [data, setData] = useState(null);

  useEffect(() => {
    if (charts?.length > 0) {
      let datas = [];
      // datas = [0.5, 0.01, 0.01];
      datas = charts[0].floorPrice;
      let state = {
        series: [{
          name: 'Floor chart',
          data: datas
        }],
        options: {
          chart: {
            type: 'area',
            stacked: false,
            height: 278,
            zoom: {
              type: 'x',
              enabled: true,
              autoScaleYaxis: true
            },
            toolbar: {
              autoSelected: 'zoom'
            }
          },
          dataLabels: {
            enabled: false
          },
          markers: {
            size: 0,
          },
          title: {
            text: 'Stock Price Movement',
            align: 'left'
          },
          fill: {
            type: 'gradient',
            gradient: {
              shadeIntensity: 1,
              inverseColors: false,
              opacityFrom: 0.5,
              opacityTo: 0,
              stops: [0, 90, 100]
            },
          },
          yaxis: {
            labels: ["a", "b", "c", "d", "e"],
          },
          title: {
            text: 'Price'
          },
        },
        xaxis: {
          type: 'datetime',
        },
      }
      setData(state)
    }
  }, [charts])

  console.log("data", data)

  return (
    <div className="row">
      <div className="col-11 col-sm-11 m-auto p-0" >
        {data &&
          <div id="chart" className="stabl3graph Zoomable-graph">
            <ReactApexChart options={data.options} series={data.series} type="area" height={278} />
          </div>
        }
      </div>
    </div>


  );
}
export default Zoomable;
