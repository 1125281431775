import React from "react";
import { useState } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./CreateNft.scss";
import dummyImg from "../../assets/dummyImg.png";
import { useWeb3React } from "@web3-react/core";
import { Link } from "react-router-dom";
import AddProperties from "../../components/Modals/addProperties";
import Environment from "../../utils/Environment";
import { useEffect } from "react";
import { Api_URL } from "../../utils/ApiURL";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import Loader from "../../hooks/loader";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import Category from "../Create-Collection/Listarray";
import { setupNetwork } from "../../utils/wallet";

const CreateNft = () => {

  let navigate = useNavigate();

  const [logoPicture, setLogoPicture] = useState(null);
  const [nftName, setnftName] = useState("");
  const [description, setDescription] = useState();
  const { account } = useWeb3React();
  const [token, setToken] = useState();
  const [collection, setCollection] = useState(null);
  const [selCol, setSelCol] = useState(null);
  const [colCont, setColCont] = useState(null);
  const [getUserData, setGetUserData] = useState([]); // use when api getCollectionByCreatorAddress get ready
  const [explicitChk, setExplicitChk] = useState(false);
  const [allCo, setAllCo] = useState([]);
  const [loader, setLoader] = useState(false);
  const [checkState, setCheckState] = useState(false);
  const [properties, setProperties] = useState([
    {
      name: "",
      value: ""
    }
  ]);
  const context = useWeb3React();
  const { chainId } = context;
  const Category = [
    {
      item: "Ethereum",
      url: "https://res.cloudinary.com/quecko012356/image/upload/v1658760197/ethereum-eth_1_u53rnu.png",
    },
    // {
    //   item: "Avalanche",
    //   url: "https://res.cloudinary.com/quecko012356/image/upload/v1658760197/avalanche-avax-logo_1_x7xlmj.png",
    // },
    {
      item: "Binance Smart Chain",
      url: "https://res.cloudinary.com/quecko012356/image/upload/v1658760197/Frame_9416_jazirr.png",
    },
    {
      item: "Polygon",
      url: "https://res.cloudinary.com/quecko012356/image/upload/v1658760197/Frame_1357_wmxgo7.png",
    },
    // {
    //   item: "Phantum",
    //   url: "https://res.cloudinary.com/hamza1234/image/upload/v1653662264/drop-in_wkkhmu.png",
    // },
  ];

  useEffect(() => {
    if (account) {
      setToken(localStorage.getItem("accessToken"));
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      const data = {
        creator: account?.toLowerCase()?.toString(),
        chainId: chainId?.toString()
      }
      axios.post(`${Api_URL}/collection/getCollectionByCreatorAddressAndChainId`, data, {
        header: {
          Authorization: "Bearer " + token
        }
      })
        .then((res) => {
          setAllCo(res?.data);
        })
        .catch((err) => {
        })
    }
  }, [account, chainId]);

  const [category, setcategory] = useState("Binance Smart Chain");
  const [img, setimg] = useState(
    "https://res.cloudinary.com/quecko012356/image/upload/v1658760197/Frame_9416_jazirr.png"
  );

  const categoriess = async (a, b) => {
    setcategory(a);
    setimg(b);
    setLoader(true);
    if (a === "Ethereum") {
      const res = await setupNetwork(5, setLoader);
      await setLoader(false);
    } else if (a === "Avalanche") {
      const res = await setupNetwork(43114, setLoader);
      await setLoader(false);
    } else if (a === "Binance Smart Chain") {
      const res = await setupNetwork(97, setLoader);
      await setLoader(false);
    } else if (a === "Polygon") {
      const res = await setupNetwork(80001, setLoader);
      await setLoader(false);
    }
  };

  const GetUser = async () => {
    const data = { walletAddress: account }
    let tok = localStorage.getItem("accessToken");
    await axios
      .post(`${Api_URL}/user/getUser`, data, {
        headers: {
          Authorization: "Bearer " + tok,
        }
      })
      .then((res) => {
        console.log('user data', res)
        setGetUserData(res?.data?.resultData)
      })
      .catch((err) => {
      })
    // };
  }

  useEffect(() => {
    if (account) {
      GetUser();
    }
  }, [account])

  const logoPicHandle = (evt) => {
    let image = evt.target.files[0];
    if (image.size > 19999328) {
      toast.error("Max file size 200mb!")
    } else {
      setLogoPicture(evt.target.files[0]);
    }
  };

  const CreateItem = (e) => {
    if (!account) {
      toast.info('Connect your wallet')
      return
    }
    if (logoPicture) {
      if (nftName) {
        if (colCont) {
          var data = new FormData();
          let tok = localStorage?.getItem("accessToken");
          data.append("creatorAddress", account);
          // data.append("contractAddress", collecCont);
          data.append("contractAddress", colCont);
          data.append("ownerAddress", account);
          data.append("expilicitAndSensitiveContent", explicitChk);
          data.append("nftName", nftName);
          data.append("description", description);
          data.append("chainId", chainId?.toString());
          data.append("chain", "BSC");
          data.append("category", selCol?.category);
          data.append("image", logoPicture);
          data.append("properties", JSON.stringify(properties));
          if (account) {
            if (logoPicture == null && nftName == "") {
              setCheckState(true);
            } else {
              e.preventDefault();
              setLoader(true);
              var config = {
                method: "post",
                url: `${Api_URL}/token/addToken`,
                headers: {
                  Authorization:
                    "Bearer " + tok,
                  "Content-Type": "application/x-www-form-urlencoded"
                },

                data: data
              };
              axios(config)
                .then(function (response) {
                  setLoader(false)
                  toast.success("NFT Created Successfully");
                  navigate('/myprofile/created');
                })
                .catch(function (error) {
                  setLoader(false)
                  toast.error(error)
                  // console.log(error);
                });

            }
          } else {
            toast.error("Connect your wallet")
            setLoader(false);
          }
        } else {
          toast.error("Please choose collection.")
        }
      } else {
        toast.error("Nft name required.")
      }
    } else {
      toast.error("Nft image required.")
    }
  };

  const OnCollection = (elem) => {
    setCollection(elem?.name);
    setColCont(elem?.contractAddress);
    setSelCol(elem);
  }

  return (
    <>
      {loader &&
        <Loader />
      }
      <Header />
      <section className="competitionform">
        <div className="container">
          <div className="row direction">
            <div className="col-sm-12 col-lg-8">
              <div className="upload">
                <h6>Upload file</h6>
                <div className={checkState ? "border-danger___email piciploddiv1 create____nft___img" : "piciploddiv1 create____nft___img"}>
                  {logoPicture ? (
                    <>
                      <label
                        for="upload1"
                        className="upload-btn piciploddiv1 border-0 p-0"
                      >{
                          logoPicture?.slice(0, 5) === "https" ?
                            <img
                              src={logoPicture}
                              className="logo___image___uploded"
                            />
                            :
                            <img
                              src={logoPicture ? URL?.createObjectURL(logoPicture) : 'no ing'}
                              className="logo___image___uploded"
                            />
                        }

                      </label>
                    </>
                  ) : (
                    <>
                      <p className="inerpicpara m-0">
                        PNG, JPG, GIF, WEBP or MP4. Max 200mb.
                      </p>
                      <label for="upload1" className="upload-btn">
                        Upload file
                      </label>
                    </>
                  )}
                  <input
                    id="upload1"
                    type="file"
                    className="d-none"
                    onChange={(evt) => logoPicHandle(evt)}
                  />
                </div>
              </div>
              <div className="inputss">
                <div>
                  <p>Title</p>
                  <input
                    type="text"
                    className={checkState ? "border-danger___email" : "forminput"}
                    placeholder="Item Name"
                    onChange={(e) => setnftName(e.target.value)}
                  />
                </div>
                <div>
                  <p>Description</p>
                  <textarea
                    className="forminput"
                    placeholder="e.g. “This is very limited item”"
                    onChange={(e) => setDescription(e.target.value)}
                  ></textarea>
                </div>
                <div className="input-field">
                  <h6>Blockchain</h6>
                  <div className="button-list">
                    <div className="dropdown buttons-list-all">
                      <button
                        className="button-listing drop-style-font"
                        type="button"
                        style={{ height: 50 }}
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <div className="left d-flex justify-content-center align-items-center">
                          <img src={img} alt="img" className="img-fluid " />
                          <p className="m-0">{category}</p>
                        </div>
                        <img
                          src="bluemoon-nft\bbarrow.png"
                          alt="img"
                          className="img-fluid dropdown-toggle-animate-specss"
                        />
                      </button>

                      <div
                        className="dropdown-menu gfghfhgfv"
                        aria-labelledby="dropdownMenuButton"
                      >
                        {Category.map((elem) => {
                          return (
                            <a
                              className="dropdown-item"
                              onClick={() => categoriess(elem.item, elem.url)}
                            >
                              <img
                                src={elem?.url}
                                alt="img"
                                className="img-fluid "
                              />
                              &nbsp;&nbsp;
                              {elem?.item}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-field">
                  {console.log('collection', allCo)}
                  {allCo &&
                    <h6>Collection</h6>
                  }
                  <div className="button-list">
                    {allCo ?
                      <div className="dropdown buttons-list-all">
                        <button
                          className="button-listing drop-style-font"
                          style={{ height: 50 }}
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="left d-flex justify-content-center align-items-center">
                            <p className="m-0">{collection}</p>
                          </div>
                          <img
                            src="bluemoon-nft\bbarrow.png"
                            alt="img"
                            className="img-fluid dropdown-toggle-animate-specss"
                          />
                        </button>

                        <div
                          className="dropdown-menu gfghfhgfv"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <a
                            className="dropdown-item"
                          // onClick={() => {
                          //   setCollection("Bluemoon");
                          //   setCollecCont(Environment.CollectionContract);
                          // }}
                          />
                          {/* <a
                          className="dropdown-item"
                          onClick={() => {
                            setCollection("Bluemoon");
                            DefaultCont();
                          }}
                        >
                          <img src="\assets\slice-logo.png" alt="img" className="img-fluid" style={{ width: '40px',height:"40px", borderRadius: "50%", objectFit: "cover" }} />
                          &nbsp;&nbsp;
                          {"Bluemoon Default Collection"}
                        </a> */}
                          {allCo?.defaultCollection?.map((elem) => {
                            return (
                              <a
                                className="dropdown-item"
                                onClick={() =>
                                  OnCollection(elem)
                                }
                              >
                                <img
                                  src={`https://bluemoon.quecko.com/banner-globe-combo.png`}
                                  style={{ width: '40px', height: "40px", objectFit: "cover", borderRadius: "50%" }}
                                  alt="img"
                                />
                                &nbsp;&nbsp;
                                {elem?.name}
                              </a>
                            );
                          })}
                          {allCo?.result?.map((elem) => {
                            return (
                              <a
                                className="dropdown-item"
                                onClick={() =>
                                  OnCollection(elem)
                                }
                              >
                                <img
                                  src={elem?.logoImage}
                                  style={{ width: '40px', height: "40px", objectFit: "cover", borderRadius: "50%" }}
                                  alt="img"
                                />
                                &nbsp;&nbsp;
                                {elem?.name}
                              </a>
                            );
                          })}
                        </div>
                      </div>
                      :
                      <Link to="/createcollection" >
                        <div className="formbtn">
                          <button>Create Collection</button>
                        </div>
                      </Link>
                    }
                  </div>
                </div>
                <div className="main1">
                  <div className="card2">
                    <img
                      src="\asssests\threelines.svg"
                      className="img-fluid img33"
                      alt=""
                    />
                    <div className="inner-card">
                      <h5 className="main-heading">Properties</h5>
                      <p className="main-para">
                        Textual traits that show up as rectangles
                      </p>
                    </div>
                  </div>
                  <div className="outer-card">
                    <a data-toggle="modal" data-target="#exampleModal">
                      <img
                        src="\asssests\plussign.svg"
                        className="img-fluid img22"
                        alt=""
                      />
                    </a>
                  </div>
                </div>
                <div className="tittle tittle-bbb">
                  <div className="text-p">
                    <p className="offersreciv m-0">
                      Explicit & sensitive content
                    </p>
                    <p className="prop-para">
                      Set this collection as explicit and sensitive content.
                    </p>
                  </div>
                  <div className="my-item">
                    <label className="toggle">
                      <input
                        className="toggle-checkbox"
                        type="checkbox"
                        onChange={() => setExplicitChk(!explicitChk)}
                      />
                      <div className="toggle-switch"></div>
                    </label>
                  </div>
                </div>
              </div>
              <div className="formbtn" type="submit">
                <button onClick={(e) => CreateItem(e)}>Create</button>
              </div>
            </div>

            <div className="col-sm-12 col-lg-4">
              <h2 className="previewiteeemmsss">Preview item</h2>
              <div className="card ">
                {logoPicture?.slice(0, 5) === "https" ?
                  <img
                    src={logoPicture ? logoPicture : dummyImg}
                    className="cardimg"
                    alt="img"
                  />
                  :
                  <img
                    src={logoPicture ? URL?.createObjectURL(logoPicture) : dummyImg}
                    className="cardimg"
                    alt="img"
                  />
                }
                <div className="forfl">
                  <h6 className="cardhead">{nftName ? nftName : "Item Name"}</h6>
                  <span className="cardbtn">BSC</span>
                </div>
                <div className="forfl">
                  <div className="creator">
                    <img
                      // src="\create\Rectangle.svg"
                      src={getUserData?.picture}
                      alt="img"
                      className="creatorimg"
                    />
                    <div>
                      <p className="creatorhead" style={{ marginTop: 'initial' , marginBottom: 'initial' }}>Creator</p>
                      <p className="creatopara" style={{ marginTop: 'initial' , marginBottom: 'initial' ,lineHeight:'initial' }}>
                        {getUserData?.userFullName ? getUserData?.userFullName : account?.slice(0, 11) + "..."}
                      </p>
                    </div>
                  </div>
                  {/* <div className="fcol">
                    <p className="high">Highest Bid</p>
                    <h6 className="highhead">4.89 eTH</h6>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-modal">
        <div
          className="modal fade property121 p-0"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <AddProperties
            properties={properties}
            setProperties={setProperties}
          />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CreateNft;
