import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getCollectionContract } from "../../utils/contractHelpers";

const RentNft = () => {
    const web3 = useWeb3();

    const rentNfts = useCallback(
        async (rentNFT, nftContract, tokenId, expires, account) => {
            const tokenAddress = nftContract;
            const contract = getCollectionContract(tokenAddress, web3);
            let nd = parseFloat(rentNFT)?.toFixed(6);
            nd = web3.utils.toWei(nd.toString(), "ether");
            console.log("borrow", nd, nftContract, tokenId, parseInt(expires))
            try {
                const gas = await contract.methods
                    .rentNFT(tokenId, parseInt(expires))
                    .estimateGas({ value: nd, from: account });
                let gasPrice = await web3.eth.getGasPrice();
                const details = await contract.methods
                    .rentNFT(tokenId, parseInt(expires))
                    .send({
                        value: nd,
                        from: account,
                        gas,
                        gasPrice: gasPrice
                    })
                return details;
            } catch (error) {
                console.log("borrow", error)
                throw (error)
            }
        },
        [web3]
    );
    return { rentNfts: rentNfts };
};
export default RentNft;