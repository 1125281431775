import React from "react";
import "./filter.scss";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Api_URL } from "../../utils/ApiURL";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import { useNavigate } from "react-router-dom";
const Filter = ({
  forRent,
  setForRent,
  showBtn,
  categories,
  setLoader,
  callAPI,
  setRecentAddTokens,
  setUsd,
  setCategories,
  isBuyNowChecked,
  isOnAction,
  clickFiltrerHandl,
  clickFiltrerHandlAuc,
  setEnterP, 
  enterP,
  setLprice,
  setSprice,
  setBuyNowChecked,
  setOnAction
}) => {
  let navigate = useNavigate();
  const Filters = async (val) => {
    let dumArr = categories;
    let dumObj = categories.find((i) => {
      return i === val;
    });
    if (dumObj) {
      dumArr = dumArr.filter((i) => {
        return i !== val;
      });
      setCategories(dumArr)
    } else {
      dumArr.push(val);
      setCategories(dumArr);
    }
    setLoader(true);
    const data = {
      category: dumArr
    };
    setLoader(true);
    if (dumArr.length > 0) {
      try {
        const res = await axios.post(
          `${Api_URL}/token/getNftByCategory`,
          data,
          {
            headers: {}
          }
        );
        setLoader(false);
        setRecentAddTokens(res?.data?.data?.reverse());
      } catch (error) {
        setLoader(false);
      }
    } else {
      callAPI();
    }
  };

  // 99999999999

  const UpdateCats = (val) => {
    // console.log("call", val)
    let dumArr = categories;
    let dumObj = categories.find((i) => {
      return i === val;
    });
    if (dumObj) {
      dumArr = dumArr.filter((i) => {
        return i !== val;
      });
      // callAPI(dumArr)
      setCategories(dumArr)
    } else {
      dumArr.push(val);
      callAPI(dumArr);
      // setCategories(dumArr)
    }
  }
  function forRentFunc(e) {
    const newState = determineNewState(e);

    // Update the URL state without triggering a full page reload
    if(forRent){
      window.history.replaceState(null, null, `/explore`);
    }else{
      window.history.replaceState(null, null, `/explore?state=${newState}`);
    }
   

    // Update your component state if needed
    setForRent(e.target.checked);
  }

  function determineNewState(e) {
   setBuyNowChecked(false)
   setOnAction(false)
    return 'forRent';
    
  }

//   function forRentFunc(e) {
//     if ( isOnAction && isBuyNowChecked){
//       navigate('/explore', { state: 'both' })
//       // clickFiltrerHandlAuc()
//       // clickFiltrerHandl()
//       setForRent(e.target.checked)
//     } else if (isOnAction){
//       navigate('/explore', { state: 'auction' })
//       // clickFiltrerHandlAuc()
//       setForRent(e.target.checked)
//     } else if (isBuyNowChecked){
//       navigate('/explore', { state: 'fixedPrice' })
//       // clickFiltrerHandl()
//       setForRent(e.target.checked)
//     } else if (isBuyNowChecked && forRent) {
//       navigate('/explore', { state: 'fixedPrice' })
//       // clickFiltrerHandl()
//       setForRent(false)
//     } else if (isOnAction && forRent) {
//       navigate('/explore', { state: 'auction' })
//       // clickFiltrerHandlAuc()
//       setForRent(false)
//     } else {
//       navigate('/explore', { state: 'forRent' })
//       setForRent(e.target.checked)
//     }
   
// }
  return (
    <>
      {showBtn && (
        <section className="filter">
          <header className="header">
            <h3>Filters</h3>
            {/* <a href="" className="reset">
              Reset
            </a> */}
          </header>
          <div className="main">
            <Accordion defaultActiveKey={["0"]} alwaysOpen>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Status</Accordion.Header>
                <Accordion.Body>
                  <div className="option-field">
                    <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                      <label
                        className="filter form-check-label p-0"
                        for="defaultCheck1"
                      >
                        <p>Buy Now</p>
                      </label>
                      <input
                        className="p-0 m-0"
                        type="checkbox"
                        value=""
                        // disabled={forRent}
                        id="defaultCheck1"
                        checked={isBuyNowChecked}
                        onChange={() => { clickFiltrerHandl(); setForRent(false) }}
                      />
                    </div>
                  </div>
                  <div className="option-field">
                    {/* <p>On Auction</p>
                    <Form.Check /> */}
                    <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                      <label
                        className="filter form-check-label p-0"
                        for="defaultCheck2"
                      >
                        <p>On Auction</p>
                      </label>
                      <input
                        className="p-0 m-0"
                        type="checkbox"
                        value=""
                        // disabled={forRent}
                        id="defaultCheck2"
                        checked={isOnAction}
                        onChange={() => {clickFiltrerHandlAuc(); setForRent(false) }}
                      />
                    </div>
                  </div>
                  <div className="option-field">
                    <p>For Rent</p>
                    <input
                    // disabled={isOnAction || isBuyNowChecked}
                        className="p-0 m-0"
                        type="checkbox"
                        id="defaultCheck3"
                      checked={forRent}
                        onChange={forRentFunc}
                      />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>Price</Accordion.Header>
                <Accordion.Body>
                  <div className="price">
                    <div className="set-arrow">
                      {/* <img
                        src="\assets\arrow-down.png"
                        alt="img"
                        className="img-fluid arrow-down-set"
                      /> */}
                      <select disabled className="custom-select">
                        <option value="1">BNB</option>
                      </select>
                      {/* <p>BNB</p> */}
                    </div>
                    <input
                      onChange={(e) => setSprice(e.target.value)}
                      type="number"
                      placeholder="100"
                    />
                    <p>to</p>
                    <input
                      onChange={(e) => setLprice(e.target.value)}
                      type="number"
                      placeholder="10000"
                    />
                  </div>
                  <div className="apply-btn">
                    {" "}
                    <button
                      onClick={callAPI}
                      className="apply-btn-btn"
                    >
                      Apply
                    </button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              {/* <Accordion.Item eventKey="2">
                <Accordion.Header>Chains</Accordion.Header>
                <Accordion.Body>
                  <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                    <label
                      className="filter form-check-label p-0"
                      for="defaultCheck2"
                    >
                      <p>
                        <img
                          src="\assets\icon\eth.svg"
                          alt="img"
                          className="img-fluid mr-2"
                        />
                        Ethereum
                      </p>
                    </label>
                    <input
                      className="p-0 m-0"
                      type="checkbox"
                      value=""
                      id="defaultCheck202"
                      checked={isEthChecked}
                      onChange={() => setEthChecked(!isEthChecked)}
                    />
                  </div>
                  <div className="form-check inplabel d-flex align-items-center justify-content-between w-100">
                    <label
                      className="filter form-check-label p-0"
                      for="defaultCheck2"
                    >
                      <p>
                        <img
                          src="\assets\icon\bsc.svg"
                          alt="img"
                          className="img-fluid mr-2"
                        />
                        BSC
                      </p>
                    </label>
                    <input
                      className="p-0 m-0"
                      type="checkbox"
                      value=""
                      id="defaultCheck204"
                      checked={isBscChecked}
                      onChange={() => setBscChecked(!isBscChecked)}
                    />
                  </div>
                </Accordion.Body>
              </Accordion.Item> */}
              <Accordion.Item eventKey="2">
                <Accordion.Header>Categories</Accordion.Header>
                <Accordion.Body>
                  <div className="option-field">
                    <p>Art</p>
                    <Form.Check checked={categories.find((i) => i === "Art")} onChange={() => UpdateCats("Art")} />
                  </div>
                  <div className="option-field">
                    <p>Music</p>
                    <Form.Check checked={categories.find((i) => i === "Music")} onChange={() => UpdateCats("Music")} />
                  </div>
                  <div className="option-field">
                    <p>Domain Names</p>
                    <Form.Check checked={categories.find((i) => i === "Domain Names")} onChange={() => UpdateCats("Domain Names")} />
                  </div>
                  <div className="option-field">
                    <p>Virtual Worlds</p>
                    <Form.Check checked={categories.find((i) => i === "Virtual Worlds")} onChange={() => UpdateCats("Virtual Worlds")} />
                  </div>
                  <div className="option-field">
                    <p>Trading Cards</p>
                    <Form.Check checked={categories.find((i) => i === "Trading Cards")} onChange={() => UpdateCats("Trading Cards")} />
                  </div>
                  <div className="option-field">
                    <p>Collectibles</p>
                    <Form.Check checked={categories.find((i) => i === "Collectibles")} onChange={() => UpdateCats("Collectibles")} />
                  </div>
                  <div className="option-field">
                    <p>Sports</p>
                    <Form.Check checked={categories.find((i) => i === "Sports")} onChange={() => UpdateCats("Sports")} />
                  </div>
                  <div className="option-field">
                    <p>Photography</p>
                    <Form.Check checked={categories.find((i) => i === "Photography")} onChange={() => UpdateCats("Photography")} />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </section>
      )}
      <section className="filter-mbl d-none">
        <div
          className="modal fade"
          id="staticBackdrop"
          data-backdrop="static"
          data-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body">
                <header className="header">
                  <h3>Filters</h3>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">
                      <img
                        src="\bluemoon-nft\close.png"
                        alt="img"
                        className="img-fluid"
                      />
                    </span>
                  </button>
                </header>
                <div className="main">
                  <Accordion defaultActiveKey={["0"]} alwaysOpen>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Status</Accordion.Header>
                      <Accordion.Body>
                        <div className="option-field">
                          <p>Buy Now</p>
                          <Form.Check
                            checked={isBuyNowChecked}
                            onChange={() => clickFiltrerHandl()}
                          />
                        </div>
                        <div className="option-field">
                          <p>On Auction</p>
                          <Form.Check
                            checked={isOnAction}
                            onChange={() => clickFiltrerHandlAuc()}
                          />
                        </div>
                        {/* <div className="option-field">
                          <p>Rent</p>
                          <Form.Check />
                        </div>
                        <div className="option-field">
                          <p>Swap</p>
                          <Form.Check />
                        </div> */}
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Price</Accordion.Header>
                      <Accordion.Body>
                        <div className="price">
                          <div className="set-arrow">
                            {/* <img
                              src="\assets\arrow-down.png"
                              alt="img"
                              className="img-fluid arrow-down-set"
                            /> */}
                            <select disabled className="custom-select">
                              {/* <option onChange={() => setUsd(true)} selected>
                                USD
                              </option> */}
                              <option onChange={() => setUsd(false)} value="1">
                                BNB
                              </option>
                              {/* <option value="2">Two</option>
                            <option value="3">Three</option> */}
                            </select>
                          </div>
                          <input
                            type="number"
                            min={0}
                            onChange={(e) => setSprice(e.target.value)}
                            placeholder="100"
                          />
                          <p>to</p>
                          <input
                            type="number"
                            min={0}
                            onChange={(e) => setLprice(e.target.value)}
                            placeholder="10000"
                          />
                        </div>
                        <div className="apply-btn">
                          {/* <Link to="/timeauction"> */}{" "}
                          <button
                            onClick={callAPI}
                            className="apply-btn-btn"
                          >
                            Apply
                          </button>
                          {/* </Link> */}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="2">
                      <Accordion.Header>Chains</Accordion.Header>
                      <Accordion.Body>
                        <div className="option-field">
                          <p>
                            <img
                              src="\assets\icon\eth.svg"
                              alt="img"
                              className="img-fluid mr-2"
                            />
                            Ethereum
                          </p>
                          <Form.Check />
                        </div>
                        <div className="option-field">
                          <p>
                            <img
                              src="\assets\icon\bsc.svg"
                              alt="img"
                              className="img-fluid mr-2"
                            />
                            BSC
                          </p>
                          <Form.Check />
                        </div>
                      </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Categories</Accordion.Header>
                      <Accordion.Body>
                        <div className="option-field">
                          <p>Art</p>
                          <Form.Check onChange={() => UpdateCats("Art")} />
                        </div>
                        <div className="option-field">
                          <p>Music</p>
                          <Form.Check onChange={() => UpdateCats("Music")} />
                        </div>
                        <div className="option-field">
                          <p>Domain Names</p>
                          <Form.Check
                            onChange={() => UpdateCats("Domain Names")}
                          />
                        </div>
                        <div className="option-field">
                          <p>Virtual Worlds</p>
                          <Form.Check
                            onChange={() => UpdateCats("Virtual Worlds")}
                          />
                        </div>
                        <div className="option-field">
                          <p>Trading Cards</p>
                          <Form.Check
                            onChange={() => UpdateCats("Trading Cards")}
                          />
                        </div>
                        <div className="option-field">
                          <p>Collectibles</p>
                          <Form.Check
                            onChange={() => UpdateCats("Collectibles")}
                          />
                        </div>
                        <div className="option-field">
                          <p>Sports</p>
                          <Form.Check onChange={() => UpdateCats("Sports")} />
                        </div>
                        <div className="option-field">
                          <p>Photography</p>
                          <Form.Check onChange={() => UpdateCats("Photography")} />
                        </div>
                        {/* <div className="bottom-btn">
                          <button className="clear">Clear all</button>
                          <button className="done">Done</button>
                        </div> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Filter;
