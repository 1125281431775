import { useCallback, useState, useEffect } from "react";
import useWeb3 from "../useWeb3";
import { getFactContract } from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";
import { useWeb3React } from "@web3-react/core";

export const TokenApprBulk = () => {
    const web3 = useWeb3();
    const context = useWeb3React();
    const { chainId } = context;
    const [marketAddress, setMarketAddress] = useState(null);

    useEffect(() => {
        if (chainId === 97) {
            setMarketAddress(Environment.bulkTransfer);
        } else if (chainId === 4) {
            setMarketAddress(Environment.Eth.bulkTransfer);
        } else if (chainId === 43114) {
            setMarketAddress(Environment.Eth.bulkTransfer);
        } else if (chainId === 80001) {
            setMarketAddress(Environment.Polygon.bulkTransfer);
        }
    }, [chainId])

    const ApproveToken = useCallback(async (owner, address) => {
        if (marketAddress) {
            try {

                const contract = getFactContract(address, web3);

                const approved = await contract.methods.isApprovedForAll(owner, marketAddress).call();
                return approved;
            } catch (e) {
                throw e;
            }
        }
    }, [marketAddress]);

    return { ApproveTokenBulk: ApproveToken };
};

export default TokenApprBulk;