

import ReactApexChart from "react-apexcharts";
import React, { useState, useEffect } from "react";
import './stabl3graph.scss';
function Columnchart({ charts }) {

  const [chartData, setChartData] = useState(null);

  // const state = {

  //   series: [
  //     {
  //       name: 'Orders',
  //       type: 'column',
  //       data: [440, 505, 414, 671, 227, 413, 201, 352, 752, 320, 257, 160]
  //     },
  //     {
  //       name: 'Offers',
  //       type: 'column',
  //       data: [440, 505]
  //     }
  //   ],
  //   options: {
  //     chart: {
  //       height: 278,
  //       type: 'line',
  //     },
  //     stroke: {
  //       width: [0, 4]
  //     },
  //     title: {
  //       text: 'Price Distribution'
  //     },
  //     dataLabels: {
  //       enabled: true,
  //       enabledOnSeries: [1]
  //     },
  //     labels: ['01 Jan 2001', '02 Jan 2001', '03 Jan 2001', '04 Jan 2001', '05 Jan 2001', '06 Jan 2001', '07 Jan 2001', '08 Jan 2001', '09 Jan 2001', '10 Jan 2001', '11 Jan 2001', '12 Jan 2001'],
  //     xaxis: {
  //       type: 'datetime'
  //     },
  //     yaxis: [{
  //       title: {
  //         text: 'Orders',
  //       },

  //     }, {
  //       opposite: true,
  //       title: {
  //         text: 'Offers'
  //       }
  //     }
  //     ]
  //   },


  // };

  useEffect(() => {
    if (charts) {
      let order = [];
      let offers = [];
      let datt = [];
      if (charts?.totalOffers?.length > charts?.totalOrders?.length) {
        for (var i = 0; i < charts?.totalOffers?.length; i++) {
          offers?.push(charts?.totalOffers[i].price);
          if (charts?.totalOrders[i]) {
            order.push(charts?.totalOrders[i])
          } else {
            order.push(0);
          }
          let datee = [];
          if (charts?.totalOffers?.length > 0) {
            datee = charts?.totalOffers[i]?._id;
            datee = datee?.day + "/" + datee?.month + "/" + datee?.year;
            var dateParts = datee.split("/");
            var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            dateObject = dateObject.toString();
            datt.push(dateObject?.slice(0, 15));
          }
        }
      } else {
        for (var i = 0; i < charts?.totalOrders?.length; i++) {
          order?.push(charts?.totalOrders[i].price);
          if (charts?.totalOffers[i]) {
            order.push(charts?.totalOffers[i])
          } else {
            order.push(0);
          }
        }
        let datee = [];
        if (charts?.totalOrders?.length > 0) {
          datee = charts?.totalOrders[i]?._id;
          datee = datee?.day + "/" + datee?.month + "/" + datee?.year;
          var dateParts = datee.split("/");
          var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
          dateObject = dateObject.toString();
          datt.push(dateObject?.slice(0, 15));
        }
      }

      let state0 = {
        series: [
          {
            name: 'Orders',
            type: 'column',
            data: order
          },
          {
            name: 'Offers',
            type: 'column',
            data: offers
          }
        ],
        options: {
          chart: {
            height: 278,
            type: 'line',
          },
          stroke: {
            width: [0, 4]
          },
          title: {
            text: 'Price Distribution'
          },
          dataLabels: {
            enabled: true,
            enabledOnSeries: [1]
          },
          labels: datt,
          xaxis: {
            type: 'datetime'
          },
          yaxis: [{
            title: {
              text: 'Orders',
            },

          }, {
            opposite: true,
            title: {
              text: 'Offers'
            }
          }
          ]
        },


      };
      setChartData(state0);
    }
  }, [charts])

  return (
    <>
      {chartData?.series &&
        <div id="chart" className="stabl3graph Columnchart-graph">
          <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={278} />
        </div>
      }
    </>
  );
}
export default Columnchart;
