import web3NoAccount from './web3'
import factoryAbi from "./factoryAbi.json"
import collection from "./collectionAbi.json"
import wethAbi from "./wethAbi.json"
import marketPlace from "./marketPlace.json"
import factAbi from "./factAbi.json"
import bulkAbi from "./bulkAbi.json"

import erc20 from './erc20.json'
import swap from './swapAbi.json'
import rent from './rentingAbi.json'
const getContract = (abi, address, web3) => {
    const _web3 = web3 ?? web3NoAccount;
    // // console.log('_web3',_web3);
    return new _web3.eth.Contract(abi, address)
}

export const getCollectionContract = (address, web3) => {
    return getContract(collection, address, web3)
}
export const getFactoryContract = (address, web3) => {
    return getContract(factoryAbi, address, web3)
}
export const getMarketContract = (address, web3) => {
    return getContract(marketPlace, address, web3)
}

export const getFactContract = (address, web3) => {
    return getContract(factAbi, address, web3)
}

export const getBulkTansferContract = (address, web3) => {
    return getContract(bulkAbi, address, web3)
}

// export const getUSDTContract = (address, web3) => {
//     return getContract(USDTAbi, address, web3)
// }

export const getWethContract = (address, web3) => {
    return getContract(wethAbi, address, web3)
}
export const getErc20TokenContract = (address, web3) => {
    return getContract(erc20, address, web3)
}
export const getSwapContract = (address, web3) => {
    return getContract(swap, address, web3)
}
export const getRentContract = (address, web3) => {
    return getContract(rent, address, web3)
}
// export const getUSDTMainContract = (address, web3) => {
//     return getContract(USDTMainAbi, address, web3)
// }