import { useCallback, useEffect, useState } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

const OffMint = () => {
    const web3 = useWeb3();
    // const contractAddress = environment.MarketContract;
    const [contractAddress, setContractAddress] = useState(null);
    const context = useWeb3React();
    const { chainId } = context;
console.log('chainIdeeeeeee', chainId)
    useEffect(() => {
        if (chainId === 97) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 4) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 43114) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 80001) {
            setContractAddress(environment.marketPlaceContract);
        }
    }, [chainId])

    const contract = getMarketContract(contractAddress, web3);
    const OfferMint = useCallback(
        async (account, values, image, sellerSign, buyerSign, contractAddress, timeNow) => {
            try {
                console.log("seller, buyer, amount, uri, tokenId, deadline, v, r, s, contaddress", values, image, sellerSign, buyerSign, contractAddress, timeNow)
                const gas = await contract.methods
                    .OfferAndMint(values, image, sellerSign, buyerSign, contractAddress, timeNow)
                    .estimateGas({ from: account });
                let gasPrice = await web3.eth.getGasPrice();
                const details = await contract.methods
                    .OfferAndMint(values, image, sellerSign, buyerSign, contractAddress, timeNow)
                    .send({
                        from: account,
                        gas,
                        gasPrice: gasPrice
                    })
                return details;
            } catch (error) {
                console.log("error",error)
                throw (error)
            }
        },
        [contract]
    );
    return { OfferMint: OfferMint };
};

export default OffMint;