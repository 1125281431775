import React from "react";
import "./creatform.scss";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
const Creatform = () => {
  return (
    <>
      <Header />
      <section className="main-Creatform ">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-12 m-auto">
              <div className="row">
                <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                  <div className="maininnerfomdiv">
                    <div className="maininnerform">
                      <h5 className="offersreciv">Upload file</h5>
                      <div className="piciploddiv">
                        <p className="inerpicpara">
                          PNG, JPG, GIF, WEBP or MP4. Max 200mb.
                        </p>
                        <label htmlFor="upload-btn" className="uplodebtn">Upload file</label>
                        <input id="upload-btn" type="file" className="d-none" />
                      </div>
                    </div>
                    <div className="tittle">
                      <h5 className="offersreciv">Tittle</h5>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Item Name"
                      ></input>
                    </div>
                    <div className="tittle">
                      <h5 className="offersreciv">Description</h5>
                      <div className="input-group">
                        <textarea
                          className="form-control dddescription"
                          placeholder="E.G. “This is Very Limited Item”"
                          aria-label="With textarea"
                        ></textarea>
                      </div>
                    </div>
                    {/* <div className="tittle">
                      <h5 className="offersreciv">Collection</h5>
                      <div className="form-group cccollection">
                        <a href="">
                          <img
                            src="\asssests\arrow11.svg"
                            className="img-fluid fvvfv"
                            alt="img"
                          />
                        </a>
                        <select
                          className="form-control efwuiy78ef"
                          id="exampleFormControlSelect1"
                        >
                          <option className="change-color">Art</option>
                          <option className="change-color">Music</option>
                          <option className="change-color">Domain Names</option>
                          <option className="change-color">Trading Cards</option>
                          <option className="change-color">Sports</option>
                        </select>
                      </div>
                    </div> */}
                    {/* <div className="main1">
                      <div className="card2">
                        <img
                          src="\asssests\threelines.svg"
                          className="img-fluid img33"
                          alt=""
                        />
                        <div className="inner-card">
                          <h5 className="main-heading">Properties</h5>
                          <p className="main-para">
                            Textual traits that show up as rectangles
                          </p>
                        </div>
                      </div>
                      <div className="outer-card">
                        <a data-toggle="modal" data-target="#exampleModal">
                          <img
                            src="\asssests\plussign.svg"
                            className="img-fluid img22"
                            alt=""
                          />
                        </a>
                      </div>
                    </div> */}
                    {/* <div className="tittle tittle-bbb">
                      <div className="text-p">
                      <h5 className="offersreciv">
                        Explicit & sensitive content
                      </h5>

                      <p className="inerpicpara">
                        Set this collection as explicit and sensitive content.
                      </p>
                      </div>
                      <div className="my-item">
                        <label className="toggle">
                          <input className="toggle-checkbox" type="checkbox" />
                          <div className="toggle-switch"></div>
                        </label>
                      </div>
                    </div> */}
                  </div>
                </div>

                <div className="col-xl-4 col-lg-4 col-md-8 col-sm-12">
                  <div className="cardoffersreciv">
                    <h5 className="offersreciv">Preview item</h5>
                  </div>
                  <div className="ccaarrdd">
                    {/* <div className="heart"><img src="\bluemoon-nft\heart.png" alt="img" className="img-fluid" />100</div> */}
                    {/* <button className="placebid"><img src="\bluemoon-nft\btn-inner.png" alt="img" className="img-fluid" />Place Bid</button> */}
                    <img
                      src="\bluemoon-nft\the-blue-moon\livecard.png"
                      alt="img"
                      className="img-fluid w-100"
                    />
                    {/* <div className="timer">
                      <img src="\bluemoon-nft\timer.png" alt="img" className="img-fluid" />
                      <p>05 : 12 : 07 : 45</p>
                    </div> */}
                    <div className="inerfst">
                      <h6 className="crdhngg ">"Hamlet Contemplates ...</h6>
                      <Link to="explore" className="cardbtn">
                        BSC
                      </Link>
                    </div>
                    <div className="fitcont">
                      <ul className="list-inline ">
                        <div className="mmaaaiin d-flex">
                          <div className="maaiinnoonnee">
                            <li className="list-inline-item">
                              <img
                                src="\bluemoon-nft\the-blue-moon\bbaalleck.png"
                                className="img-fluid sfgsdfsdsdgsdg"
                                alt=""
                              />
                            </li>
                            <li className="list-inline-item">
                              <div className="history-heads">
                                <h3 className="hhiigghh"> Creator </h3>
                                <p className="hours">SalvadorDali</p>
                              </div>
                            </li>
                          </div>
                          <div className="mainncccxxxx">
                            <li className="list-inline-item">
                              <div className="history-heads">
                                <h3 className="hhiigghh"> Highest Bid</h3>
                                <p className="hoursss">4.89 eTH</p>
                              </div>
                            </li>
                          </div>
                        </div>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lodebuttonns1">
                <button className="loadmore1">Create</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main-modal">
        <div
          className="modal fade property121 p-0"
          id="exampleModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header mainheading">
                <h5 className="text-center">Add Properties</h5>
                <button
                  type="button"
                  className="close btn-close-header"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <img
                    src="\bluemoon-nft\create\layer1.svg"
                    className="img-fluid cross"
                  />
                </button>
              </div>
              <div className="modal-body addproprty">
                <div className="paramodal">
                  <p className="">
                    Properties show up underneath your item, are clickable, and
                    can be filtered in your collection's sidebar.
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-5 col-5">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Type</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Character"
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-5">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Name</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Male"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-2 m-auto">
                    <div className="closecross">
                      <img
                        src="\asssests\close-circle.svg"
                        className="img-fluid crosimg"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 col-5">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Type</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Character"
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-5">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Name</label>
                      <input
                        type="email"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Male"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-2 m-auto">
                    <div className="closecross">
                      <img
                        src="\asssests\close-circle1.svg"
                        className="img-fluid crosimg"
                      />
                    </div>
                  </div>
                </div>
                <div className="addmre">
                  <button className="heelo-btn">add more</button>
                </div>
                <div className="savebutton">
                  <button className="">Save</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Creatform;
