import { useCallback, useState, useEffect } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

const AcceptBidAg = () => {
    const web3 = useWeb3();
    // const contractAddress = environment.MarketContract;
    const [contractAddress, setContractAddress] = useState(null);
    const context = useWeb3React();
    const { chainId } = context;

    useEffect(() => {
        if (chainId === 97) {
            setContractAddress(environment.marketPlaceContract);
        }
        // else if (chainId === 4) {
        //     setContractAddress(environment.Eth.MarketContract);
        // } else if (chainId === 43114) {
        //     setContractAddress(environment.Eth.MarketContract);
        // } else if (chainId === 80001) {
        //     setContractAddress(environment.Polygon.MarketContract);
        // }
    }, [chainId])

    const contract = getMarketContract(contractAddress, web3);

    const BidAccept2 = useCallback(
        async (account, values, unix_seconds, values1, values2, contractAddress, royaltiesAddress, percentageFee) => {
            // account, values, unix_seconds, values1, values2, nft?.contractAddress, collection?.royaltiesAddress, collection?.percentageFee
            if (contractAddress) {
                try {
                    const gas = await contract.methods
                        .AcceptBidAndTransfer(values, unix_seconds, values1, values2, contractAddress, royaltiesAddress, percentageFee)
                        .estimateGas({ from: account });
                    let gasPrice = await web3.eth.getGasPrice();
                    const details = await contract.methods
                        .AcceptBidAndTransfer(values, unix_seconds, values1, values2, contractAddress, royaltiesAddress, percentageFee)
                        .send({
                            from: account,
                            gas,
                            gasPrice: gasPrice
                        })
                    return details;
                } catch (error) {
                    // console.log(error)
                    throw (error)
                }
            }
        },
        [contract]
    );
    return { BidAccept2: BidAccept2 };
};

export default AcceptBidAg;