import React, { useState } from "react";
import "./collectioncard.scss";
import { Link } from "react-router-dom";
import { useWeb3React } from "@web3-react/core";

const CollectionCard = ({ data, owner }) => {
  const [show, setShow] = useState(12);
  const { account } = useWeb3React();

  const ToShow = () => {
    let sho = show + 3;
    if (sho > data.length) {
      setShow(data.length);
    } else {
      setShow(sho);
    }
  };

  return (
    <section className="collectioncard">
      <div className="container1920">
        {data?.length >= 1 ? (
          <div className="row">
            {data?.slice(0, show)?.map((item, index) => {
              console.log('sdfasdfasdf', data)
              return (
                <div
                  key={index}
                  className="col-xl-3 col-lg-4 col-md-6 col-6 padd-sm"
                >
                  <div className="cards">
                    <div className="cardsimg">
                      <Link to={"/collections?id=" + item?._id}>
                        <img
                          src={item?.BannerImage}
                          alt="img"
                          className="cardinnerimg img-fluid"
                        />
                      </Link>
                    </div>
                    <div className="cardsouter">
                      <img
                        src={item?.logoImage}
                        alt="img"
                        className="maninnerimg img-fluid"
                      />
                      {item?.creator && item.creator.length > 0 && (
                        <>
                          {item?.creator[0]?.verified ? (
                            <div className="badge">
                              <i className="ripple"></i>
                            </div>
                          ) : null}
                        </>
                      )}
                      <div className="innercards">
                        <h6 className="cardhead">
                          <Link to={"/collections?id=" + item?._id}>
                            {item?.name}
                          </Link>
                        </h6>
                        <p className="cardpara">
                          Created by
                          {item?.creatorDetails && item.creatorDetails[0]?.userFullName ? (
                            <span className="darktext pl-1">
                              {item?.creatorDetails[0]?.userFullName}
                            </span>
                          ) : (
                            <>
                              {typeof item?.creator === "string" ? (
                                <Link to={"/user?id=" + item?.creator}>
                                  <span className="darktext">
                                    {" "}
                                    {item?.creator?.slice(0, 12)}
                                  </span>
                                </Link>
                              ) : (
                                <Link
                                  to={
                                    "/user?id=" +
                                    item?.creator[0]?.walletAddress
                                  }
                                >
                                  <span className="darktext">
                                    {" "}
                                    {item?.creator[0]?.userFullName
                                      ? item?.creator[0]?.userFullName
                                      : item?.creator[0]?.walletAddress?.slice(
                                        0,
                                        12
                                      )}
                                  </span>
                                </Link>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {show === data?.length || 
            <div className="col-md-12 wrap-inner load-more text-center">
              <button
                onClick={() => ToShow()}
                className="sc-button loadmore fl-button pri-3"
              >
                <span>
                  {show === data?.length ? "NO MORE TO SHOW" : "LOAD MORE"}
                </span>
              </button>
            </div>
}
          </div>
        ) : (
          <div className="no_item_display">
            <img src="\assets\no-display.png" alt="img" className="img-fluid" />
            <h5>No Items To Display</h5>
          </div>
        )}
      </div>
    </section>
  );
};

export default CollectionCard;
