import React from "react";
import { Accordion } from "react-bootstrap-accordion";
import ExploreItem from "./ExploreItem";
import todayPickData from "../../../assets/fake-data/data-today-pick";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

const Explore = (props) => {
  const data = props.data;
  return (
    <section className="tf-explore tf-section ">
      <div className="themesflat-container">
        <div className="filter-items">
          <div className="left-filter-icon">
            <button className="filter-btn">
              <img
                src="\assets\images\filter.png"
                alt="img"
                className="img-fluid"
              />
            </button>
            <button
              className="filter-left-btn-mbl d-none"
              data-toggle="modal"
              data-target="#staticBackdrop"
            >
              {" "}
              Filters{" "}
              <img
                src="\bluemoon-nft\inner-btn-filter-icon.png"
                alt="img"
                className="img-fluid"
              />
            </button>
          </div>
          <div className="right-filter-drop">
            <DropdownButton id="dropdown-basic-button" title="  Recently added">
              <Dropdown.Item href="#/action-1">
                Price: Low to High
              </Dropdown.Item>
              <Dropdown.Item href="#/action-2">
                {" "}
                Price: High to Low
              </Dropdown.Item>
              <Dropdown.Item href="#/action-3">
                Auction ending soon
              </Dropdown.Item>
            </DropdownButton>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-3 col-12">
            <div id="side-bar" className="side-bar style-3">
              {data.map((item, index) => (
                <div
                  className="widget widget-category mgbt-24 boder-bt"
                  key={index}
                >
                  <div className="content-wg-category">
                    <Accordion title={item.title} show={true}>
                      <form action="#">
                        {item.content.map((itemm, index) => (
                          <div key={index}>
                            <label>
                              {itemm.field}
                              <input
                                type="checkbox"
                                defaultChecked={itemm.checked}
                              />
                              <span className="btn-checkbox"></span>
                            </label>
                            <br />
                          </div>
                        ))}
                      </form>
                    </Accordion>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="col-xl-9 col-lg-9 col-md-12">
            <ExploreItem data={todayPickData} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Explore;
