import React, { useEffect, useState } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { Link } from "react-router-dom";
import Countdown from "react-countdown";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { getErc20TokenContract, getSwapContract } from '../utils/contractHelpers'
import useWeb3 from "../hooks/useWeb3";
import "react-tabs/style/react-tabs.css";
import liveAuctionData from "../assets/fake-data/data-live-auction";
import LiveAuction from "../components/layouts/LiveAuction";
import img1 from "../assets/images/avatar/avt-3.jpg";
import img2 from "../assets/images/avatar/avt-11.jpg";
import img3 from "../assets/images/avatar/avt-1.jpg";
import img4 from "../assets/images/avatar/avt-5.jpg";
import img5 from "../assets/images/avatar/avt-7.jpg";
import img6 from "../assets/images/avatar/avt-8.jpg";
import img7 from "../assets/images/avatar/avt-2.jpg";
import imgdetail1 from "../assets/images/box-item/images-item-details.jpg";
import axios from "axios";
import { soliditySha3 } from "web3-utils";
import { Api_URL } from "../utils/ApiURL";
import { useWeb3React } from "@web3-react/core";
import Loader from "../hooks/loader";
import Environment from "../utils/Environment";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const ItemDetails03 = () => {
  const [ids, setIds] = useState()
  const [loader, setLoader] = useState(false);
  const [swapState, setSwapData] = useState([])
  const [swapStateDetail, setSwapDataDetail] = useState()
  const [token, setToken] = useState(null);
  const [status, setStatus] = useState('swapItems')
  const { account } = useWeb3React();
  const [refAddressStatic, setRefAddress] = ('0x324f5Ba3dB0DDc0424F519ECD032C398CBd274ec')
  const context = useWeb3React();
  let navigate = useNavigate();
  const { chainId } = context;
  const web3 = useWeb3()
  const [meta, setMeta] = useState({
    price: "0",
    startTime: new Date().getTime(),
    endTime: null,
    method: "fixed"
  })
  let onsale = [
    {
      "_id": "63a1adc31d0ea6b7d0e177a9",
      "creatorAddress": "0x268b9dbe1ff41904310c1b83cdf1be7ee6d3e009",
      "contractAddress": "0x0f670907fca7d8eb80d868fe3ace4803fa0712d0",
      "ownerAddress": "0x009c4e059c8c79b9228337b622d0b2db6159f7bb",
      "tokenID": 2,
      "isMinted": true,
      "nftName": "small #2",
      "description": "fjvnj",
      "image": "https://bluemoon-s3.s3.us-west-2.amazonaws.com/1671540163607-small%202.avif",
      "chainId": "97",
      "category": "Music",
      "attributes": [],
      "numberOfLikes": 0,
      "priceforsale": 0,
      "sale": 0,
      "salePrice": 6,
      "instantSalePrice": 0,
      "likedImage": "https://kashmiri-s3.s3.ap-southeast-1.amazonaws.com/heart.png",
      "unLikedImage": "https://kashmiri-s3.s3.ap-southeast-1.amazonaws.com/heart-outline-icon.png",
      "views": 8,
      "blockStatus": false,
      "verified": true,
      "tokenSwapBtnStatus": false,
      "isRent": false,
      "chain": "BSC",
      "properties": [
        {
          "name": "",
          "value": ""
        }
      ],
      "levels": [],
      "stats": [],
      "bulk": false,
      "metaDataFiles": [],
      "expilicitAndSensitiveContent": false,
      "creators": [
        {
          "_id": "63a1ab021d0ea6b7d0e17430",
          "role": "user",
          "status": "active",
          "verified": true,
          "picture": "https://bluemoon-s3.s3.us-west-2.amazonaws.com/pictures/placeholder.png",
          "coverPicture": "https://bluemoon-s3.s3.us-west-2.amazonaws.com/pictures/cover-placeholder.png",
          "followersCount": 0,
          "followingCount": 0,
          "numberOfLikedTokens": 0,
          "numberOfLikedCollections": 0,
          "tokenCreated": 0,
          "tokenSold": 0,
          "likedImage": "https://kashmiri-s3.s3.ap-southeast-1.amazonaws.com/heart.png",
          "unLikedImage": "https://kashmiri-s3.s3.ap-southeast-1.amazonaws.com/heart-outline-icon.png",
          "Permissions": [],
          "followers": [],
          "following": [],
          "likedTokens": [],
          "likedCollections": [],
          "walletAddress": "0x268b9dbe1ff41904310c1b83cdf1be7ee6d3e009",
          "createdAt": "2022-12-20T12:30:58.199Z",
          "updatedAt": "2022-12-20T12:30:58.199Z",
          "__v": 0
        }
      ],
      "onSale": false,
      "isAuctionLive": false,
      "reveal": false,
      "likedBy": [],
      "time": "2022-12-20T12:42:43.825Z",
      "createdAt": "2022-12-20T12:42:43.826Z",
      "updatedAt": "2023-01-31T09:47:45.152Z",
      "__v": 0,
      "metaData": {
        "price": "6",
        "startTime": "1671540197",
        "endTime": "2023-01-07T17:43:00.000Z",
        "method": "auction"
      },
      "lastPrice": "9",
      "orders": [],
      "users": [
        {
          "_id": "64342c3960ed4758c9cc3039",
          "role": "user",
          "totalRefferal": 0,
          "status": "active",
          "verified": true,
          "picture": "https://bluemoon-s3.s3.us-west-2.amazonaws.com/pictures/placeholder.png",
          "coverPicture": "https://bluemoon-s3.s3.us-west-2.amazonaws.com/pictures/cover-placeholder.png",
          "followersCount": 0,
          "followingCount": 0,
          "numberOfLikedTokens": 0,
          "numberOfLikedCollections": 0,
          "tokenCreated": 0,
          "tokenSold": 0,
          "likedImage": "https://kashmiri-s3.s3.ap-southeast-1.amazonaws.com/heart.png",
          "unLikedImage": "https://kashmiri-s3.s3.ap-southeast-1.amazonaws.com/heart-outline-icon.png",
          "Permissions": [],
          "followers": [],
          "following": [],
          "likedTokens": [],
          "likedCollections": [],
          "userEmail": "abcd12@xyz.com",
          "password": "$2a$10$Jy6zRaEDPSFEBwsG46rQ3eVxbEB98td0dF9VjKwnb4ubuGk8Yjicq",
          "createdAt": "2023-04-10T15:33:13.067Z",
          "updatedAt": "2023-04-10T15:33:13.067Z",
          "__v": 0
        },
        {
          "_id": "6434300f35e18ffc09b926be",
          "role": "user",
          "totalRefferal": 0,
          "status": "active",
          "verified": true,
          "picture": "https://bluemoon-s3.s3.us-west-2.amazonaws.com/pictures/placeholder.png",
          "coverPicture": "https://bluemoon-s3.s3.us-west-2.amazonaws.com/pictures/cover-placeholder.png",
          "followersCount": 0,
          "followingCount": 0,
          "numberOfLikedTokens": 0,
          "numberOfLikedCollections": 0,
          "tokenCreated": 0,
          "tokenSold": 0,
          "likedImage": "https://kashmiri-s3.s3.ap-southeast-1.amazonaws.com/heart.png",
          "unLikedImage": "https://kashmiri-s3.s3.ap-southeast-1.amazonaws.com/heart-outline-icon.png",
          "Permissions": [],
          "followers": [],
          "following": [],
          "likedTokens": [],
          "likedCollections": [],
          "userEmail": "abcddd12@xyz.com",
          "password": "$2a$10$Sq//z26vwndHZIgXwQBTfeckmHaco8YRyddfYhHXB2V.gJcBoEVuu",
          "createdAt": "2023-04-10T15:49:35.152Z",
          "updatedAt": "2023-04-10T15:49:35.152Z",
          "__v": 0
        }
      ],
      "bids": []
    }]
  const [dataHistory] = useState([
    {
      img: img1,
      name: "Mason Woodward",
      time: "8 hours ago",
      price: "4.89 BNB",
      priceChange: "$12.246",
    },
  ]);
  const getSwapItemDetail = async () => {
    setLoader(true);
    var data = JSON.stringify({
      "tokenId": ids
    });
    // console.log("okokok", token)
    var config = {
      method: "post",
      url: `${Api_URL}/swapOffer/getOfferDetails`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setLoader(false);
        // console.log('ldfa;', response)
        setSwapData(response?.data)
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  }
  const detailOfSingle = async (ids) => {
    setSwapDataDetail()
    setLoader(true);
    var data = JSON.stringify({
      "swapOfferId": ids
    });
    // console.log("okokok", token)
    var config = {
      method: "post",
      url: `${Api_URL}/swapOffer/getOfferData`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setLoader(false);
        // console.log('ldfa;dfd', response?.data)
        setSwapDataDetail(response?.data?.offers[0] || [])
        window.$('#exampleModal').modal('show')
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  }
  // const AcceptOffer = async () => {}
  const AcceptOffer = async () => {
    setLoader(true)
    let bidItems = swapStateDetail?.bidItems
    let item = swapStateDetail
    let swapItems = swapStateDetail?.swapItems
    // console.log('first', item, bidItems, swapItems)
    let appStatus = false;
    if (account) {
      if (true) {
        try {
          setLoader(true)
          for (let i = 0; i < swapItems?.length; i++) {
            const contract = getErc20TokenContract(swapItems[i]?.tokens[0]?.contractAddress, web3);
            if (swapItems[i]?.tokens[0].isMinted) {
              let res = await contract.methods.isApprovedForAll(account, Environment?.swap).call()
              // console.log('first 11111', swapItems?.map((item) => item.tokens[0].isMinted == true && item.tokens[0].contractAddress))
              if (!res) {
                try {
                  var gasFunPrice;
                  web3.eth.getGasPrice().then((result) => {
                    var result2 = parseInt(result) + 5000000000
                    // // console.log("gasfun", typeof result2, result2)
                    gasFunPrice = result2.toString()
                  })
                  let gas = await contract.methods.setApprovalForAll(Environment?.swap, true).estimateGas({ from: account })

                  let res2 = await contract.methods.setApprovalForAll(Environment?.swap, true).send({
                    gas: gas,
                    gasPrice: gasFunPrice, from: account })
                  appStatus = false
                } catch (error) {
                  toast.error('Use denied transaction')
                  appStatus = true
                }
              }
            }

          }

          if (appStatus === false) {

            var nftArr = [];
            var tokenIds = [];
            var amounts = [];
            var ReqnftArr = [];
            var ReqtokenIds = [];
            var Reqamounts = [];
             
            for (let item of bidItems) {
              if (item.tokens[0].isMinted) {
                // console.log('dafdasdf', item.tokens[0].contractAddress)
                nftArr.push(item.tokens[0].contractAddress)
                tokenIds.push(item.tokens[0].tokenID)
                amounts.push('0')
              }
            }
            for (let item of swapItems) {
              if (item.tokens[0].isMinted) {
                ReqnftArr.push(item.tokens[0].contractAddress)
                ReqtokenIds.push(item.tokens[0].tokenID)
                Reqamounts.push('0')
              }
            }
            // console.log('dafdasdf', swapItems, bidItems)
            let so = item?.signature;
            so = so.substring(2)
            let br = '0x' + so.slice(0, 64);
            let bs = '0x' + so.slice(64, 128);
            let bv = '0x' + so.slice(128, 130);
            var ts = item?.time;
            var unix_seconds = ts // ((new Date(ts)).getTime()) / 1000;
            let soliditySha3Expected = null;
            let dumArr = null;
            let dumArr2 = null;
            
            if (tokenIds?.length === 0) {
              dumArr = ['']
            } else {
              dumArr = tokenIds;
            }
            if (ReqtokenIds?.length === 0) {
              dumArr2 = ['']
            } else {
              dumArr2 = tokenIds;
            }
            // console.log("accept offer")
            // console.log("contract", Environment?.swap)
            // console.log("account", account)
            // console.log("buyer", item?.bidderAddress)
            // console.log("start time", parseInt(unix_seconds))

            if (true) {
              soliditySha3Expected = soliditySha3(
                Environment?.swap,
                account?.toLowerCase(),
                item?.bidderAddress?.toLowerCase(),
                unix_seconds,
              );
            }
            // else if (chainId === 4) {
            //   soliditySha3Expected = soliditySha3(
            //     Environment?.Eth.MarketContract,
            //     account?.toLowerCase(),
            //     web3.utils.toWei(nft?.metaData?.price.toString()),
            //     nft?.metaData?.startTime
            //   );
            // } else if (chainId === 80001) {
            //   soliditySha3Expected = soliditySha3(
            //     Environment?.Polygon.MarketContract,
            //     account?.toLowerCase(),
            //     web3.utils.toWei(nft?.metaData?.price.toString()),
            //     nft?.metaData?.startTime
            //   );
            // } else if (chainId === 43114) {
            //   soliditySha3Expected = soliditySha3(
            //     Environment?.Avax.MarketContract,
            //     account?.toLowerCase(),
            //     web3.utils.toWei(nft?.metaData?.price.toString()),
            //     nft?.metaData?.startTime
            //   );
            // }
            let signature = null;
            await web3.eth.personal.sign(soliditySha3Expected, account).then(async (res) => {
              signature = res;
              signature = signature.substring(2)
              let r = '0x' + signature.slice(0, 64);
              let s = '0x' + signature.slice(64, 128);
              let v = '0x' + signature.slice(128, 130);
              setLoader(true);
              // const minALLocation1 = new BigNumber(nft?.metaData?.price).multiply(new BigNumber(10).pow(18));

              let bidderNfts = {
                nft: nftArr,
                tokenIds: tokenIds,
                amounts: amounts,
                reqNft: ReqnftArr,
                reqTokenIds: ReqtokenIds,
                reqTokenAmouts: Reqamounts
              }
              let signSeller = {
                v,
                r,
                s,
              }
              let signBidder = {
                v: bv,
                r: br,
                s: bs,
              }
              if (true) {
                // console.log('first 222', 'bidderAddress', item?.bidderAddress, 'bidder nft tupple', bidderNfts, 'sign Seller', signSeller, 'signbidder', signBidder)
                // console.log('first 333', bidderNfts?.nft?.length < 1 && bidderNfts?.tokenIds?.length < 1 && bidderNfts?.amounts?.length < 1 && bidderNfts?.reqNft?.length < 1 && bidderNfts?.reqTokenIds?.length < 1 && bidderNfts?.reqTokenAmouts?.length < 1)
                const contract2 = getSwapContract(Environment?.swap, web3);
                try {
                  if (bidderNfts?.nft?.length < 1 && bidderNfts?.tokenIds?.length < 1 && bidderNfts?.amounts?.length < 1 && bidderNfts?.reqNft?.length < 1 && bidderNfts?.reqTokenIds?.length < 1 && bidderNfts?.reqTokenAmouts?.length < 1) {
                    var data = JSON.stringify({
                      "bidderAddress": item?.bidderAddress,
                      "sellerAddress": item?.sellerAddress,
                      "id": item?._id
                    });
                    // console.log("okokok", token)
                    var config = {
                      method: "post",
                      url: `${Api_URL}/swapOffer/acceptSwapOffer`,
                      headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json"
                      },
                      data: data
                    };
                    await axios(config)
                      .then(function (response) {
                        toast.success('Offer Accepted')
                        window.$('#exampleModal').modal('hide')
                        navigate('/myprofile')
                        setLoader(false);
                        // console.log('ldfa;dfddddddddd', response)
                        setSwapData(response?.data)
                      })
                  } else {
                    toast.info('Offer Accepted');
                    console.log('gggggjgjgjgjg', item?.bidderAddress, account, bidderNfts, signSeller, signBidder, unix_seconds)
                    var gasPrices;
                    web3.eth.getGasPrice().then((result) => {
                      var result2 = parseInt(result) + 5000000000
                      // // console.log("gasfun", typeof result2, result2)
                      gasPrices = result2.toString()
                    })
                    let gas = await contract2.methods.acceptSwapOfferWor(item?.bidderAddress, account, bidderNfts, signSeller, signBidder, unix_seconds).estimateGas({ from: account })

                    await contract2.methods.acceptSwapOfferWor(item?.bidderAddress?.toLowerCase(), account?.toLowerCase(), bidderNfts, signSeller, signBidder, unix_seconds).send({
                      gas: gas,
                      gasPrice: gasPrices, from: account })
                    var data = JSON.stringify({
                      "bidderAddress": item?.bidderAddress,
                      "sellerAddress": item?.sellerAddress,
                      "id": item?._id
                    });
                    // console.log("okokok", token)
                    var config = {
                      method: "post",
                      url: `${Api_URL}/swapOffer/acceptSwapOffer`,
                      headers: {
                        Authorization: "Bearer " + token,
                        "Content-Type": "application/json"
                      },
                      data: data
                    };
                    await axios(config)
                      .then(function (response) {
                        toast.success('Offer Accepted')
                        window.$('#exampleModal').modal('hide')
                        navigate('/myprofile')
                        setLoader(false);
                        // console.log('ldfa;dfddddddddd', response)
                        setSwapData(response?.data)
                      })
                    // let bidder = await contract2.methods.verifyBidderWoAr(item?.bidderAddress,  account,  signBidder, unix_seconds).call()
                    // let seller = await contract2.methods.verifySellerWoAr(item?.bidderAddress, '0x009c4E059C8c79B9228337b622D0b2Db6159f7bB',  signBidder, unix_seconds).call()
                    // // console.log('values===> read res', bidder,seller)
                  }
                  // toast.success('Offer Accepted')
                  window.$('#exampleModal').modal('hide')
                } catch (error) {
                  // console.log(error)
                }
                // try {

                //   const res = await BuyMinte(account, values, image1, signBidder, signSeller, nft?.contractAddress, nft?.metaData?.startTime);
                //   if (res) {
                //     var data = JSON.stringify({
                //       "bidderAddress": account?.toLowerCase(),
                //       "sellerAddress": nft?.ownerAddress?.toLowerCase(),
                //       "contractAddress": nft?.contractAddress?.toString()?.toLowerCase(),
                //       "tokenID": nft?.tokenID?.toString(),
                //       "amount": nft?.metaData?.price,
                //       "chainId": nft?.chainId
                //     });

                //     var config = {
                //       method: 'post',
                //       url: `${Api_URL}/bid/acceptBid`,
                //       headers: {
                //         Authorization: "Bearer " + auth,
                //         'Content-Type': 'application/json'
                //       },
                //       data: data
                //     };

                //     axios(config)
                //       .then(function (response) {
                //         GetNft();
                //         GetTokenHistory();
                //         toast.success("Nft purchased successfully")
                //         setLoader(false);
                //         window.$("#buymodal").modal("hide")
                //         // setBids(response.data.bid);
                //       })
                //       .catch(function (error) {
                //         setLoader(false);
                //         // console.log(error);
                //       });
                //   }
                // } catch (err) {
                //   toast.error(err);
                //   setLoader(false);
                // }
              }
              
            }).catch((err) => {
              setLoader(false);
              throw err;
            });
          }
          setLoader(false)
        } catch (error) {
          // console.log(error)
          toast.error(error.message);
          setLoader(false);
        }
      }
    } else {
      toast.error("Connect your wallet")
    }
  }


  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("id");
    setIds(id)
    // console.log('id', id)
  }, [])
  useEffect(() => {
    if (ids && token) {
      getSwapItemDetail()
    }
  }, [ids, token])

  useEffect(() => {
    if (account) {
      setToken(localStorage.getItem("accessToken"));

    }
  }, [account]);
  let formatTimeDifference = function (epochTime) {
    if (isNaN(epochTime) || epochTime <= 0) {
      return 'Invalid timestamp';
    }

    const dt = new Date(epochTime);
    const now = new Date();
    const delta = Math.round((now - dt) / 1000); // Time difference in seconds

    const minute = 60;
    const hour = 60 * minute;
    const day = 24 * hour;
    const month = 30 * day;
    const year = 365 * day;

    if (delta < minute) {
      return 'Just now';
    } else if (delta < hour) {
      const minutes = Math.floor(delta / minute);
      return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
    } else if (delta < day) {
      const hours = Math.floor(delta / hour);
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else if (delta < month) {
      const days = Math.floor(delta / day);
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    } else if (delta < year) {
      const months = Math.floor(delta / month);
      return `${months} month${months !== 1 ? 's' : ''} ago`;
    } else {
      const years = Math.floor(delta / year);
      return `${years} year${years !== 1 ? 's' : ''} ago`;
    }
  };

  // Example usage
  // const epochTime = 1701152569784;
  // const formattedTime = formatTimeDifference(epochTime);
  // console.log(formattedTime);

  return (
    <>
      {loader && <Loader />}
      <div className="item-details">
        <Header />
        {/* <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Item Details 1</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Explore</Link></li>
                                    <li>Item Details 1</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section> */}
        <div className="tf-section tf-item-details">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-md-12">
                <div className="content-left">
                  <div className="media">
                    <img src={Object.keys(swapState).length != 0 && swapState?.tokenDetail[0]?.image} alt="Axies" />
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-md-12">
                <div className="content-right">
                  <div className="sc-item-details">
                    <h2 className="style2">
                      “{Object.keys(swapState).length != 0 && swapState?.tokenDetail[0]?.nftName}”{" "}
                    </h2>
                    <div className="meta-item">
                      <div className="left">
                        <span className="viewed eye">{Object.keys(swapState).length != 0 && swapState?.tokenDetail[0]?.views}</span>
                        <span
                          to=""
                          className="liked heart wishlist-button mg-l-8"
                        >
                          <span className="number-like">{Object.keys(swapState).length != 0 && swapState?.tokenDetail[0]?.likedBy?.length}</span>
                        </span>
                      </div>
                      {/* <div className="right">
                        <Link to="#" className="share"></Link>
                        <Link to="#" className="option"></Link>
                      </div> */}
                    </div>
                    <div className="client-infor sc-card-product">
                      <div className="meta-info">
                        <div className="author">
                          <div className="avatar">
                            <img src={Object.keys(swapState).length != 0 && swapState?.tokenDetail[0]?.ownerDetails[0]?.picture} alt="Axies" />
                          </div>
                          <div className="info">
                            <span>Owned By</span>
                            <h6>
                              {" "}
                              <Link to="/author">{Object.keys(swapState).length != 0 && swapState?.tokenDetail[0]?.ownerDetails[0]?.userFullName}</Link>{" "}
                            </h6>
                          </div>
                        </div>
                      </div>
                      <div className="meta-info">
                        <div className="author">
                          <div className="avatar">
                            <img src={ Object.keys(swapState).length != 0 && swapState?.tokenDetail[0]?.creatorDetails[0]?.picture} alt="Axies" />
                          </div>
                          <div className="info">
                            <span>Create By</span>
                            <h6>
                              {" "}
                              <Link to="/author">{Object.keys(swapState).length != 0 && swapState?.tokenDetail[0]?.creatorDetails[0]?.userFullName}</Link>{" "}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p>
                      {Object.keys(swapState).length != 0 && swapState?.tokenDetail[0]?.description}
                    </p>
                    <div className="meta-item-details style2">
                      <div className="item meta-price">
                        <span className="heading">Current Price</span>
                        <div className="price">
                          <div className="price-box">
                            <h5>{Object.keys(swapState).length != 0 && swapState?.tokenDetail[0]?.metaData?.price || 0} BNB</h5>
                          </div>
                        </div>
                      </div>
                      <div className="item count-down">
                        <span className="heading style-2">Total Offer</span>
                        <Countdown >
                          <span>{swapState?.offers?.length}</span>
                        </Countdown>
                      </div>
                    </div>
                    {/* <div className="d-flex item-03-btn">
                    <a
                      className="sc-button loadmore style fl-button pri-3 w-100 mr-4"
                      data-toggle="modal"
                      data-target="#offermodal"
                    >
                      <span className="d-flex justify-content-center">
                        {" "}
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="change-my-color mr-2"
                        >
                          <path
                            d="M17.1166 15.8677C16.9916 15.9927 16.8333 16.051 16.6749 16.051C16.5166 16.051 16.3583 15.9927 16.2333 15.8677L12.1083 11.7427L12.5499 11.301L12.9916 10.8594L17.1166 14.9844C17.3583 15.226 17.3583 15.626 17.1166 15.8677Z"
                            fill="white"
                          />
                          <path
                            d="M5.39172 7.73308L10.225 12.5664C10.55 12.8914 10.55 13.4164 10.225 13.7414L9.47505 14.4997C8.80005 15.1664 7.73338 15.1664 7.06672 14.4997L3.45005 10.8831C2.79172 10.2247 2.79172 9.14975 3.45005 8.48308L4.20838 7.72475C4.53338 7.40808 5.06672 7.40808 5.39172 7.73308Z"
                            fill="white"
                          />
                          <path
                            d="M15.4917 8.49141L12.3167 11.6581C11.9834 11.9914 11.45 11.9914 11.1167 11.6581L6.30005 6.84141C5.96672 6.50807 5.96672 5.97474 6.30005 5.64141L9.47505 2.46641C10.1334 1.80807 11.2084 1.80807 11.875 2.46641L15.4917 6.08307C16.15 6.74974 16.15 7.81641 15.4917 8.49141Z"
                            fill="white"
                          />
                          <path
                            d="M6.66663 18.125H1.66663C1.32496 18.125 1.04163 17.8417 1.04163 17.5C1.04163 17.1583 1.32496 16.875 1.66663 16.875H6.66663C7.00829 16.875 7.29163 17.1583 7.29163 17.5C7.29163 17.8417 7.00829 18.125 6.66663 18.125Z"
                            fill="white"
                          />
                        </svg>
                        Make an offer
                      </span>
                    </a>
                    <a
                      className="sc-button loadmore style fl-button pri-3 w-100"
                      data-toggle="modal"
                      data-target="#buymodal"
                    >
                      <span className="d-flex justify-content-center">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="change-my-color mr-2"
                        >
                          <path
                            d="M16.0333 4.64948H15.7L12.8833 1.83281C12.6583 1.60781 12.2916 1.60781 12.0583 1.83281C11.8333 2.05781 11.8333 2.42448 12.0583 2.65781L14.05 4.64948H5.94996L7.94163 2.65781C8.16663 2.43281 8.16663 2.06615 7.94163 1.83281C7.71663 1.60781 7.34996 1.60781 7.11663 1.83281L4.30829 4.64948H3.97496C3.22496 4.64948 1.66663 4.64948 1.66663 6.78281C1.66663 7.59115 1.83329 8.12448 2.18329 8.47448C2.38329 8.68281 2.62496 8.79115 2.88329 8.84948C3.12496 8.90781 3.38329 8.91615 3.63329 8.91615H16.3666C16.625 8.91615 16.8666 8.89948 17.1 8.84948C17.8 8.68281 18.3333 8.18281 18.3333 6.78281C18.3333 4.64948 16.775 4.64948 16.0333 4.64948Z"
                            fill="white"
                          />
                          <path
                            d="M15.875 10H4.05838C3.54171 10 3.15005 10.4583 3.23338 10.9667L3.93338 15.25C4.16671 16.6833 4.79171 18.3333 7.56671 18.3333H12.2417C15.05 18.3333 15.55 16.925 15.85 15.35L16.6917 10.9917C16.7917 10.475 16.4 10 15.875 10ZM8.84171 15.375C8.84171 15.7 8.58338 15.9583 8.26671 15.9583C7.94171 15.9583 7.68338 15.7 7.68338 15.375V12.625C7.68338 12.3083 7.94171 12.0417 8.26671 12.0417C8.58338 12.0417 8.84171 12.3083 8.84171 12.625V15.375ZM12.4084 15.375C12.4084 15.7 12.15 15.9583 11.825 15.9583C11.5084 15.9583 11.2417 15.7 11.2417 15.375V12.625C11.2417 12.3083 11.5084 12.0417 11.825 12.0417C12.15 12.0417 12.4084 12.3083 12.4084 12.625V15.375Z"
                            fill="white"
                          />
                        </svg>
                        Buy Now for 4.5 BNB
                      </span>
                    </a>
                  </div> */}
                    <div className="flat-tabs themesflat-tabs mt-5">
                      <Tabs>
                        {/* <TabList>
                        <Tab>Bid History</Tab>
                        <Tab>Details</Tab>
                        <Tab>History</Tab>
                      </TabList> */}

                        <TabPanel>
                          <ul className="bid-history-list">
                            {swapState?.offers?.reverse()?.map((item, index) => (
                              item?.swapoffer?.length > 0 &&
                              <li key={index} item={item}>
                                <div className="content">
                                  <div className="client">
                                    <div className="sc-author-box style-2">
                                      <div className="author-avatar">
                                        <Link to="#">
                                          <img
                                            src={item?.swapoffer[0]?.user[0]?.picture}
                                            alt="Axies"
                                            className="avatar"
                                          />
                                        </Link>
                                        <div className="badge"></div>
                                      </div>
                                      <div className="author-infor">
                                        <div className="name">
                                          <h6 className="text-truncate addadk">
                                              <Link to="/author">{item?.swapoffer[0]?.user[0]?.userFullName || item?.swapoffer[0]?.user[0]?.walletAddress} </Link>
                                          </h6>{" "}
                                        </div>
                                          <span className="time">{formatTimeDifference(parseFloat(item?.swapoffer[0]?.time)) }</span>
                                      </div>
                                      <div className="imgsDiv">
                                      {/* {item?.swapoffer[0]?.swapItems?.tokens?.slice(0, 2)?.map((item) => { offers[0].swapoffer[0].swapItems.tokens[0].image */}
                                        {item?.swapoffer.slice(0, 2)?.map((item,id) => {
                                          return (
                                            <img src={item.swapItems?.tokens[0]?.image} alt="" />
                                          )
                                        })}
                                        {item?.swapoffer?.length > 2 &&
                                          <span className="emptySpan">+{item?.swapoffer?.length - 2}</span>
                                        }
                                      </div>
                                    </div>

                                  </div>

                                  <div className="price">
                                    
                                    <button onClick={() => detailOfSingle(item?.swapoffer[0]?._id)} className="btnOut">Details</button>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </TabPanel>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <LiveAuction data={liveAuctionData} /> */}
        <Footer />
        <div className="modal-offer">
          <div
            className="modal fade offeereed1 p-0"
            id="offermodal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content gcyctycytc">
                <div className="modal-header unknown-header">
                  <h5 className="modal-title uk111u" id="exampleModalLabel">
                    Make an Offer
                  </h5>
                  <button
                    type="button"
                    className="unknown-button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src="\asssests\layer122.png"
                      alt="img"
                      className="unknown-image"
                    />
                  </button>
                </div>
                <div className="modal-body unknown-body">
                  <p className="unknown-parag">
                    you are about to make an offer for{" "}
                    <span className="unknown-graph">#03 The Destroyer</span> From{" "}
                    <span className="unknown-graph">Monsters</span> collection.
                  </p>
                  <div>
                    <label for="efkehakf" className="form-label unknown-label">
                      Offer amount
                    </label>
                    <div className="input-group efkehakf">
                      <input
                        type="text"
                        className="form-control unknown-input"
                        placeholder="Amount"
                        aria-describedby="button-addon2"
                      />
                      <div>
                        <span
                          className="unknown-eth"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-expanded="false"
                        >
                          BNB &nbsp;&nbsp;
                          <img
                            src="\asssests\Icon11.svg"
                            className="img-fluid w200"
                            alt=""
                          />
                        </span>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <button className="dropdown-item" type="button">
                            BTC
                          </button>
                          <button className="dropdown-item" type="button">
                            BNB
                          </button>
                          <button className="dropdown-item" type="button">
                            BUSD
                          </button>
                        </div>
                      </div>
                    </div>
                    <label
                      for="input-group eafrfeze3r"
                      className="form-label unknown-label"
                    >
                      Offer expiration
                    </label>
                    <div className="input-group known-input">
                      <select
                        className="form-select unknown-select"
                        id="inputGroupSelect04"
                        aria-label="Example select with button addon"
                      >
                        <option selected>1 Day</option>
                        <option value="1">3 Days</option>
                        <option value="2">7 Days</option>
                        <option value="3">1 Month</option>
                      </select>
                    </div>
                  </div>
                  <div className="worry22">
                    <a href="" className="btn21btn61">
                      Make Offer
                    </a>
                    <a href="" className="btn21btn61">
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-buy">
          <div
            className="modal fade buyed11 p-0"
            id="buymodal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content gcyctycytc">
                <div className="modal-header unknown-header">
                  <h5 className="modal-title uk111u" id="exampleModalLabel">
                    Buy Now
                  </h5>
                  <button
                    type="button"
                    className="unknown-button"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <img
                      src="\asssests\layer122.png"
                      alt="img"
                      className="unknown-image"
                    />
                  </button>
                </div>
                <div className="modal-body unknown-body">
                  <p className="unknown-parag">
                    you are about to buy{" "}
                    <span className="unknown-graph">#03 The Destroyer</span> From{" "}
                    <span className="unknown-graph">Monsters</span> collection.
                  </p>
                  <div className="worry11">
                    <h6 className="uuu111pp111">Item Price</h6>
                    <p className="uuu111pp">
                      <img
                        src="\asssests\ETHEREUM 4.svg"
                        className="img-fluid"
                        alt="img"
                      />{" "}
                      3.20 BNB{" "}
                    </p>
                  </div>

                  <div className="worry">
                    <p className="xdtrg4rs">Service fee</p>
                    <p className="uuu111pp">0.025 BNB</p>
                  </div>
                  <div className="worry">
                    <p className="xdtrg4rs">Total Bid amount</p>
                    <p className="uuu111pp">3.225 BNB</p>
                  </div>
                  <div className="worry22">
                    <a href="" className="btn21btn61">
                      Buy Now
                    </a>
                    <a href="" className="btn21btn61">
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='main-swapnft'>
        <div className="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Swap Offer Detail</h5>
                <a type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <img src='\bluemoon-nft\create\close.png' className='img-fluid' />
                </a>
              </div>
              <div className="modal-body">
                <ul className="nav nav-pills mb-3 d-flex justify-content-center" id="pills-tab" role="tablist">
                  <li className="nav-item">
                    <a onClick={() => setStatus('swapItems')} className="samebtntabsss  nav-link py-3 px-4 active" id="pills-home-tab" data-toggle="pill" href="#pills-home" role="tab" aria-controls="pills-home" aria-selected="true">Your Nft</a>
                  </li>
                  <li className="nav-item">
                    <a  onClick={() => setStatus('bidItems')} className=" samebtntabsss nav-link py-3 px-4" id="pills-profile-tab" data-toggle="pill" href="#pills-profile" role="tab" aria-controls="pills-profile" aria-selected="false">Offered Nft</a>
                  </li>
                </ul>
                {/* <button onClick={() => setStatus('swapItems')}>Your Nft</button> <button onClick={() => setStatus('bidItems')}>Offered Nft</button> */}
                <div className='row'>
                  {(status === 'swapItems' ? swapStateDetail?.swapItems : swapStateDetail?.bidItems)?.map((item) => {
                    
                    return (
                      <div className='col-xl-4 col-12'>
                        <div className="ccaarrdd">
                          <div className="form-check">
                            {/* <input onClick={() => myNftAddFunc(item)} className="form-check-input" type="radio" name={item?.nftName} id="exampleRadios1" value="option1" /> */}
                            {/* <a className=''><img src='\asssests\Frame1120.svg' className='img-fluid' /></a> */}
                          </div>
                          <img
                            src={item?.tokens[0].image}
                            alt="img"
                            className="img-fluid w-100 forh setcustomheightforswapcard"
                          />
                          <div className="inerfst">
                            <h6 className="crdhngg ">{item?.tokens[0].nftName}</h6>
                            <Link to="explore" className="cardbtn">
                              {item?.tokens[0].chain}
                            </Link>
                          </div>
                          <div className="fitcont">
                            <ul className="list-inline ">
                              <div className="mmaaaiin d-flex">
                                <div className="maaiinnoonnee">
                                  <li className="list-inline-item">
                                    <img
                                      src={item?.tokens[0]?.creatorDetails[0]?.picture || ''}
                                      className="img-fluid sfgsdfsdsdgsdg "
                                      alt=""
                                    />
                                  </li>
                                  <li className="list-inline-item">
                                    <div className="history-heads">
                                      <h3 className="hhiigghh"> Creator </h3>
                                      <p className="hours"><a href={"/user?id=" + item?.tokens[0]?.ownerAddress}>{item?.tokens[0]?.creatorDetails[0]?.userFullName || ''}</a>{" "}</p>
                                    </div>
                                  </li>
                                </div>
                                <div className="mainncccxxxx">
                                  <li className="list-inline-item">
                                    <div className="history-heads">
                                      <h3 className="hhiigghh"> Current Price</h3>
                                      <p className="hoursss">{item?.tokens[0]?.metaData?.price || 0} BNB</p>
                                    </div>
                                  </li>
                                </div>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    )

                  })}

                </div>
                <div className='footer'>
                  <div className='modal-button'>
                    <div className='button1' onClick={() => AcceptOffer()}>Accept Offer</div>
                    <div className='button2' onClick={() => window.$('#exampleModal').modal('hide')}>Close</div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ItemDetails03;
