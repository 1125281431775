import React, { useState, useEffect } from "react";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import "./createcollection.scss";
import Category from "./Listarray";
import Category1 from "./Category1";
import FactoryContractMethod from "../../hooks/factoryContractMethod";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../../hooks/useWeb3";
import { Api_URL } from '../../utils/ApiURL';
import Loader from "../../hooks/loader";
import { setupNetwork } from "../../utils/wallet";
import { useNavigate } from "react-router-dom";
const CreateCollection = () => {
  let navigate = useNavigate();
  const [img2, setimg2] = useState(null);
  const [img3, setimg3] = useState(null)
  const [links, setLinks] = useState("");
  const [insta, setInsta] = useState("");
  const [discord, setDiscord] = useState("");
  const [medium, setMedium] = useState("");
  const [twitter, setTwitter] = useState("");
  const [telegram, setTelegram] = useState("");
  const [loader, setLoader] = useState(false);
  const [category, setcategory] = useState("Binance Smart Chain");
  const [rAddress, setrAddress] = useState("");
  const [category1, setcategory1] = useState("Art");
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [contract, setContract] = useState("");
  const [chainId1, setChainId1] = useState(97)
  const [id, setId] = useState(null)
  const [perFee, setPerFee] = useState("");
  const [explicit, setExplicit] = useState(false);
  const [avname, setAvname] = useState(null);
  const [avurl, setAvurl] = useState(null);
  const [address, setAddress] = useState(null)
  const [feeErr, setFeeErr] = useState(false);
  const [bool, setbool] = useState(false)
  const [originalData, setOriginalData] = useState({});
  console.log('originalData', originalData)
  const [url, setUrl] = useState("https://bluemoon.io/collection/");
  const [img, setimg] = useState(
    "https://res.cloudinary.com/quecko012356/image/upload/v1658760197/Frame_9416_jazirr.png"
  );
  const [network, setNetwork] = useState({ name: "Ethereum", img: 'https://res.cloudinary.com/hamza1234/image/upload/v1653662264/drop-in_wkkhmu.png' });
  const categoriess = async (a, b) => {
    setcategory(a);
    setimg(b);
    // console.log("xxxx", a, b)
    setLoader(true);
    let chain = null;
    if (a === "Ethereum") {
      chain = 5;
    } else if (a === "Avalanche") {
      chain = 43114;
    } else if (a === "Binance Smart Chain") {
      chain = 97;
    } else if (a === "Polygon") {
      chain = 80001;
    }
    const res = await setupNetwork(chain, setLoader);
    // console.log("rrrrrrrrrr", res);
    await setLoader(false);
    // console.log("rrrrrrrr", loader)
  };
  const categoriess1 = (a, b) => {
    setcategory1(a);

  };
  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("id");

    if (id !== null) {
      setId(id);
      setAddress(id);
    }
  }, []);
  const [token, setToken] = useState(null);
  const { account } = useWeb3React();
  const context = useWeb3React();
  const { chainId } = context;
  useEffect(() => {
    if (account) {
      setToken(localStorage.getItem("accessToken"));
    }
  }, [account]);
  useEffect(() => {
    if (chainId === 97) {
      setNetwork({ name: "Binance Smart Chain", img: "https://res.cloudinary.com/quecko012356/image/upload/v1658760197/Frame_9416_jazirr.png" });
    } else if (chainId === 5) {
      setNetwork({ name: "Ethereum", img: 'https://res.cloudinary.com/hamza1234/image/upload/v1653662264/drop-in_wkkhmu.png' });
    } else if (chainId === 43114) {
      setNetwork({ name: "Avalanche", img: 'https://res.cloudinary.com/quecko012356/image/upload/v1658760197/avalanche-avax-logo_1_x7xlmj.png' });
    } else if (chainId === 80001) {
      setNetwork({ name: "Polygon", img: "https://res.cloudinary.com/quecko012356/image/upload/v1658760197/Frame_1357_wmxgo7.png" });
    }
  }, [chainId])
  const web3 = useWeb3();
  const [block, setBlock] = useState("block");
  const { factoryContractMethod } = FactoryContractMethod();
  const [logoPicture, setLogoPicture] = useState(null);
  const [featuredPicture, setFeaturedPicture] = useState(null);
  const [bannerPicture, setBannerPicture] = useState(null);
  const [inputs, setInputs] = useState({})
  const [checkBoxx, setCheckBoxx] = useState(false)
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  const handleChange3 = (e) => {
    e.target.value = parseInt(e.target.value, 10).toFixed(0);
    const { name, value } = e.target;
    setInputs(inputs => ({ ...inputs, [name]: value }));
  }
  const handleChange2 = (e) => {
    // console.log(e.target.checked, 'e.target.checked');
    setCheckBoxx(e.target.checked)
  }
  const logoPicHandle = (evt) => {
    setLogoPicture(evt.target.files[0]);
  };
  const featuredPicHandle = (evt) => {
    setFeaturedPicture(evt.target.files[0]);
  };
  const bannerPictureHandle = (evt) => {
    setBannerPicture(evt.target.files[0]);
  }
  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("id");

    if (id !== null) {
      setId(id);
      var qs = require("qs");
      var data = qs.stringify({
        _id: id
      });
      var config = {
        method: "post",
        url: `${Api_URL}/collection/getCollectionById`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: data
      };
      axios(config)
        .then(function (response) {
          let data = response.data.data[0];
          setOriginalData({
            ...data,
          });
          setcategory(data?.category);
          setimg(data?.logoImage);
          if (data?.publicUrl !== "undefined") {
            setUrl(data?.publicUrl)
          }
          setDesc(data?.description);
          setimg2(data?.BannerImage);
          setName(data?.name);
          setimg3(data?.featuredImage);
          setrAddress(data?.royaltiesAddress);
          setLinks(data?.collectionWebLink);
          setPerFee(data?.percentageFee);
          setContract(data?.contractAddress);
          setDiscord(data?.collectionDiscordLink);
          setTelegram(data?.collectionTelegramLink);
          setExplicit(data?.expilicitAndSensitiveContent);
          setMedium(data?.collectionMediumLink);
          setInsta(data?.collectionInstagramLink);
          setTwitter(data?.collectionTelegramLink);
        })
        .catch(function (error) {
          // console.log(error);
        });
    }
  }, [bool]);
  const CheckName = (val) => {
    var qs = require("qs");
    var data = null;
    if (val) {
      data = qs.stringify({
        name: val
      });
    } else {
      data = qs.stringify({
        name
      });
    }
    var config = {
      method: "post",
      url: `${Api_URL}/collection/nameExists`,
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        setLoader(false);
        if (response.data.msg === "Collection name available") {
          setAvname(true);
        } else {
          setAvname(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
        setAvname(false);
        // console.log(error);
      });
  }
  useEffect(() => {
    CheckUrl();
  }, [url])
  const CheckUrl = (val) => {
    var qs = require("qs");
    var data = null;
    if (val) {
      data = qs.stringify({
        publicUrl: val,
      });
    } else {
      data = qs.stringify({
        publicUrl: url,
      });
    }
    var config = {
      method: "post",
      url: `${Api_URL}/collection/urlExists`,
      headers: {
        'Authorization': `Bearer ${token}`,
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        setAvurl(true)
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        setAvurl(false);
        // console.log(error);
      });
  }
  useEffect(() => {
    if (chainId === 97) {
      setNetwork({ name: "Binance Smart Chain", img: "https://res.cloudinary.com/quecko012356/image/upload/v1658760197/Frame_9416_jazirr.png" });
    } else if (chainId === 5) {
      setNetwork({ name: "Ethereum", img: 'https://res.cloudinary.com/quecko012356/image/upload/v1658760197/ethereum-eth_1_u53rnu.png' });
    } else if (chainId === 43114) {
      setNetwork({ name: "Avalanche", img: 'https://res.cloudinary.com/quecko012356/image/upload/v1658760197/avalanche-avax-logo_1_x7xlmj.png' });
    } else if (chainId === 80001) {
      setNetwork({ name: "Polygon", img: "https://res.cloudinary.com/quecko012356/image/upload/v1658760197/Frame_9416_jazirr.png" });
    }
  }, [chainId])
  const creatCollection = async () => {
    if (account) {
      var result = web3.utils.isAddress(inputs?.walletAdress);
      if (result) {
        if (inputs?.fee == undefined || inputs?.fee <= 0.0 || inputs?.fee > 10) {
          toast.error("Enter fee between 1-10 ");
          return;
        }
        if (block == 'block') {
          setLoader(true);
          try {
            if (logoPicture != null || bannerPicture != null || featuredPicture != null) {
              if (inputs?.fee >= 1 && category) {
                if (inputs?.url != undefined || inputs?.url != '') {
                  let res = null;
                  if (id) {
                    res = true;
                  } else {
                    res = await factoryContractMethod(account, inputs?.name, "ETH");
                  }
                  let ownerAdree = res?.events?.newCollection?.returnValues?.contractAddress
                  var data = new FormData();
                  data.append("creator", account);
                  data.append("contractAddress", ownerAdree)
                  data.append("name", inputs?.name);
                  data.append("description", inputs?.description);
                  data.append("publicUrl", url);
                  data.append("collectionWebLink", inputs?.site);
                  data.append("collectionDiscordLink", inputs.discord);
                  data.append("collectionMediumLink", inputs?.medium);
                  data.append("collectionTelegramLink", inputs?.telegram);
                  data.append("collectionInstagramLink", inputs?.insta);
                  data.append("collectionTwitterLink", inputs?.twitter);
                  data.append("expilicitAndSensitiveContent", checkBoxx);
                  data.append("blockchain", category);
                  data.append("category", category1);
                  data.append("chainId", chainId);
                  data.append("logoImage", logoPicture);
                  data.append("BannerImage", bannerPicture);
                  data.append("featuredImage", featuredPicture);
                  data.append("percentageFee", inputs?.fee);
                  data.append("royaltiesAddress", inputs?.walletAdress);
                  await axios
                    .post(`${Api_URL}/collection/addCollection`, data, {
                      headers: {
                        Authorization: "Bearer " + token
                      }
                    })
                    .then((res) => {
                      // console.log(res, 'res');
                      setLoader(false);
                      toast.success("Collection Created Successfully");
                      navigate('/explore-collections')
                    })
                    .catch((err) => {
                      setLoader(false);
                      // console.log(err.message, 'errerrerr')
                      toast.error(err?.response?.data.msg, {
                        position: "top-right",
                        autoClose: 2000
                      });
                    })
                } else {
                  toast.error("please provide correct url required");
                }
              } else {
                if (inputs?.fee < 1) {
                  toast.error("Fee must be between 1-8")
                  setFeeErr(true)
                } else {
                  toast.error("Category is required")
                }
                setLoader(false);
              }
            } else {
              toast.error("image and cover image is required");
              setLoader(false);
            }
          } catch (err) {
            if (inputs?.name == undefined) {
              toast.error('please provide name');
            } else {
              toast.error(err.message);
            }

            setLoader(false);
          }
        } else {
          toast.error("You are blocked from admin");
        }
      } else {
        toast.error("Incorrect Wallet address")
      }
    } else {
      toast.error("Please Connect your wallet");
    }
  }
  // Utility function to check if two objects are deeply equal
  // Utility function to check if two objects are deeply equal
  function deepEqual(obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }

  const EditCollection = async () => {
    if (account) {
      if (block === 'block') {
        setLoader(true);

        try {
          if (img != null || img2 != null || img3 != null) {
            if (url || url !== '') {
              // Check if any data has been changed
              console.log('dddddddddd', {
                name,
                desc,
                url,
                links,
                discord,
                medium,
                telegram,
                insta,
                twitter,
                checkBoxx,
                category,
                category1,
                chainId1,
                img,
                img2,
                img3
              }, {
                name: originalData.name,
                desc: originalData.description,
                url: originalData.publicUrl,
                links: originalData.collectionWebLink,
                discord: originalData.collectionDiscordLink,
                medium: originalData.collectionMediumLink,
                telegram: originalData.collectionTelegramLink,
                insta: originalData.collectionInstagramLink,
                twitter: originalData.collectionTwitterLink,
                checkBoxx: originalData.expilicitAndSensitiveContent,
                category: originalData.blockchain,
                category1: originalData.category,
                chainId1: originalData.chainId,
                img: originalData.logoImage,
                img2: originalData.featuredImage,
                img3: originalData.BannerImage
              })
              const isDataChanged =
                category !== originalData.category ||
                img !== originalData.logoImage ||
                url !== (originalData.publicUrl !== "undefined" ? originalData.publicUrl : '') ||
                desc !== originalData.description ||
                img2 !== originalData.BannerImage ||
                name !== originalData.name ||
                links !== originalData.collectionWebLink ||
                contract !== originalData.contractAddress ||
                discord !== originalData.collectionDiscordLink ||
                telegram !== originalData.collectionTelegramLink ||
                checkBoxx !== originalData.expilicitAndSensitiveContent ||
                medium !== originalData.collectionMediumLink ||
                insta !== originalData.collectionInstagramLink ||
                twitter !== originalData.collectionTwitterLink;


              if (isDataChanged) {
                var data = new FormData();
                var data = new FormData();
            data.append("creator", account);
            data.append("contractAddress", contract)
            data.append("name", name);
            data.append("description", desc);
            data.append("publicUrl", url);
            data.append("collectionWebLink", links);
            data.append("collectionDiscordLink", discord);
            data.append("collectionMediumLink", medium);
            data.append("collectionTelegramLink", telegram);
            data.append("collectionInstagramLink", insta);
            data.append("collectionTwitterLink", twitter);
            data.append("expilicitAndSensitiveContent", checkBoxx);
            data.append("blockchain", category);
            data.append("category", category1);
            data.append("chainId", chainId1);
            data.append("logoImage", img);
            data.append("BannerImage", img3);
            data.append("featuredImage", img2);
            await axios
                  .post(`${Api_URL}/collection/editCollection`, data, {
                    headers: {
                      Authorization: "Bearer " + token
                    }
                  })
                  .then((res) => {
                    setbool(!bool)
                    setLoader(false);
                    toast.success("Collection Updated Successfully");
                    // navigate('/explore-collections')
                  })
                  .catch((err) => {
                    setLoader(false);
                    toast.error(err?.response?.data.msg, {
                      position: "top-right",
                      autoClose: 2000
                    });
                  });
              } else {
                // No changes, show toast message
                toast.warning("Please make changes before updating the collection");
                setLoader(false);
              }
            } else {
              toast.error("Please provide a correct URL");
            }
          } else {
            toast.error("Image and cover image are required");
            setLoader(false);
          }
        } catch (err) {
          if (name === undefined) {
            toast.error('Please provide a name');
          } else {
            toast.error(err.message);
          }
          setLoader(false);
        }
      } else {
        toast.error("You are blocked from admin");
      }
    } else {
      toast.error("Please connect your wallet");
    }
  };


  // const EditCollection = async () => {
  //   if (account) {
  //     if (block == 'block') {
  //       setLoader(true);
  //       try {
  //         if (img != null || img2 != null || img3 != null) {
  //           if (url || url != '') {
  //             let res = null;
  //             if (id) {
  //               res = true;
  //             } else {
  //               res = false;
  //             }
  //             // let ownerAdree = res?.events?.newCollection?.returnValues?.contractAddress
  //             var data = new FormData();
  //             data.append("creator", account);
  //             data.append("contractAddress", contract)
  //             data.append("name", name);
  //             data.append("description", desc);
  //             data.append("publicUrl", url);
  //             data.append("collectionWebLink", links);
  //             data.append("collectionDiscordLink", discord);
  //             data.append("collectionMediumLink", medium);
  //             data.append("collectionTelegramLink", telegram);
  //             data.append("collectionInstagramLink", insta);
  //             data.append("collectionTwitterLink", twitter);
  //             data.append("expilicitAndSensitiveContent", checkBoxx);
  //             data.append("blockchain", category);
  //             data.append("category", category1);
  //             data.append("chainId", chainId1);
  //             data.append("logoImage", img);
  //             data.append("BannerImage", img3);
  //             data.append("featuredImage", img2);
  //             await axios
  //               .post(`${Api_URL}/collection/editCollection`, data, {
  //                 headers: {
  //                   Authorization: "Bearer " + token
  //                 }
  //               })
  //               .then((res) => {
  //                 // console.log(res, 'res');
  //                 setLoader(false);
  //                 toast.success("Collection Updated Successfully");
  //                 // navigate('/explore-collections')
  //               })
  //               .catch((err) => {
  //                 setLoader(false);
  //                 // console.log(err.message, 'errerrerr')
  //                 toast.error(err?.response?.data.msg, {
  //                   position: "top-right",
  //                   autoClose: 2000
  //                 });
  //               })
  //           } else {
  //             toast.error("please provide correct url required");
  //           }
  //         } else {
  //           toast.error("image and cover image is required");
  //           setLoader(false);
  //         }
  //       } catch (err) {
  //         if (inputs?.name == undefined) {
  //           toast.error('please provide name');
  //         } else {
  //           toast.error(err.message);
  //         }

  //         setLoader(false);
  //       }
  //     } else {
  //       toast.error("You are blocked from admin");
  //     }
  //   } else {
  //     toast.error("Please Connect your wallet");
  //   }
  // }
  const SETURL = (e) => {
    if (e.length > 30 && e.slice(0, 31) === "https://bluemoon.io/collection/") {
      let str = e.replace(/\s/g, '');
      setUrl(str)
    } else {
      setUrl(url)
    }
  }
  useEffect(() => {
    if (chainId === 5) {
      setcategory("Ethereum")
      setimg('https://res.cloudinary.com/quecko012356/image/upload/v1658760197/ethereum-eth_1_u53rnu.png')
    } else if (chainId === 97) {
      setcategory("Binance Smart Chain");
      setimg('https://res.cloudinary.com/quecko012356/image/upload/v1658760197/Frame_9416_jazirr.png')
    } else if (chainId === 80001) {
      setcategory("Polygon");
      setimg('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAfCAMAAAAocOYLAAAASFBMVEVHcEyJN9mJN9qIN9qSMtKTMdGOM9WJN9qKNtmAPOGEOd1+JtuCFtWcYd/l2fatgeSAPODOte////+RTN28muny6/p9PuN7P+TBeD9sAAAACnRSTlMAFWuv2v//Sf/WjY7C1AAAAT5JREFUeAF90wdyAyEUA1DaFrq33/+mkQAnkKYZT3uIv8BYfEUqbaZ5NlpJ8TOLmZC5ZDWLGCOhSFXGyqEMI0/FywrXbaEGRsCrU/+0we69g4QN031zJ4ubjtEOMeVAfTnb7U5G/Lrt+54OzwWvhfV+uj+p+G3FsYEc6ihfMRwJi/wLkf3Zwuy3fQv4tHBWv5XQzdk9A9zDfW59/R6PBnLSebbmjxW1HS8MPpt/9u/7rh7AZ/BrhEfneYrR054D7sbDsQs3i1CE8+fiuNnmyFnbmK85vvdw4hvIdC0UmH4GeNho1Fp/lJBgOO888BT8fmhziftvF1vv/RzY8v2Kh6O+Ctg1hi/wsgFn5/qqYx2RZMRDB+Z0ZqEz1M4X0aIajnXVFFnKgtYf2i3SrmPdSjFmsR3brtz/fy0W2OH/+wFaECNQAFFFYgAAAABJRU5ErkJggg==')
    }
    
  }, [chainId])
  return (
    <>
      {loader &&
        <Loader />
      }
      <Header />
      <section className="createcollection ptb">
        <div className="container text-left">
          {id ?
            <div className="row">
              <div className="col-lg-12 col-12 m-auto">
                <div className="row">
                  <div className="col-xl-9 col-12">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="upload">
                          <h6>Logo</h6>
                          <p>PNG, JPG, GIF, WEBP or MP4. 350 x 350 recommended.</p>
                          <div className="piciploddiv piciploddiv1">
                            {typeof img === "string" ?
                              <>
                                <label for="upload1" className="upload-btn piciploddiv1 border-0 p-0">
                                  <img src={img ? img : "./upload.png"} className="logo___image___uploded" />
                                </label>
                              </>
                              :
                              <>
                                <label for="upload1" className="upload-btn piciploddiv1 border-0 p-0">
                                  <img src={
                                    img ? URL?.createObjectURL(img) : "./upload.png"
                                  } className="logo___image___uploded" />
                                </label>
                              </>
                            }
                            <input id="upload1" type="file" accept='image/*' className="d-none" onChange={(e) => setimg(e.target.files[0])} />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="upload">
                          <h6>Featured image</h6>
                          <p>PNG, JPG, GIF, WEBP or MP4. 600 x 400 recommended.</p>
                          <div className="piciploddiv1">
                            {typeof img2 === "string" ?
                              <>
                                <label for="upload" className="upload-btn piciploddiv1 border-0 p-0">
                                  <img src={img2 ? img2 : "./upload.png"} className="logo___image___uploded" />
                                </label>
                              </>
                              :
                              <label for="upload" className="upload-btn piciploddiv1 border-0 p-0">
                                <img src={
                                  img2
                                    ? URL?.createObjectURL(img2)
                                    : "./upload.png"
                                } className="logo___image___uploded" />
                              </label>
                            }
                            <input id="upload" accept='image/*' type="file" className="d-none" onChange={(e) => setimg2(e.target.files[0])} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="upload">
                      <h6>Banner image</h6>
                      <p>PNG, JPG, GIF, WEBP or MP4.</p>
                      <div className="piciploddiv piciploddiv1 w-100" style={{ justifyContent: "space-around", height: "310px" }}>
                        {typeof img3 === "string" ?
                          <label for="upload2" className={img3 ? "upload-btn piciploddiv1 border-0 p-0 h-100" : "upload-btn piciploddiv1 border-0 p-0"}>
                            <img src={img3 ? img3 : "./upload.png"} className="logo___image___uploded" />
                          </label>
                          :
                          <label for="upload2" className="upload-btn piciploddiv1 border-0 p-0">
                            <img src={
                              img3
                                ? URL?.createObjectURL(img3)
                                : "./upload.png"
                            } className="logo___image___uploded" />
                          </label>
                        }
                        <input id="upload2" accept='image/*' type="file" className="d-none" onChange={(e) => setimg3(e.target.files[0])} />
                      </div>
                    </div>
                    <div className="description">
                      <div className="input-field">
                        <h6>Name</h6>
                        <input
                          name="name"
                          type="text"
                          value={name}
                          placeholder="Example: treasures of moon"
                          className="input-create"
                          onChange={(e) => { setName(e.target.value); CheckName() }}
                        />
                        {avname === true && <h6 className="text-success pt-2">Available</h6>}
                        {avname === false && <h6 className="text-danger pt-2">Unavailable</h6>}
                      </div>
                      <div className="input-field">
                        <h6 className="mb-0">URL</h6>
                        <p>Customize your URL on Bluemoon. Must only contain lowercase letters, numbers, and hyphens.</p>
                        <input
                          name="url"
                          type="text"
                          value={url}
                          placeholder="https://bluemoon.io/collection/treasures-of-moon"
                          className="input-create"
                          onChange={(e) => SETURL(e.target.value)}
                        />
                        {avurl === true && <h6 className="text-success pt-2">Available</h6>}
                        {avurl === false && <h6 className="text-danger pt-2">Unavailable</h6>}
                      </div>
                      <div className="input-field">
                        <h6>Description</h6>
                        <textarea
                          name="description"
                          type="text"
                          value={desc}
                          placeholder="e.g. “This is very limited item”"
                          className="input-create"
                          onChange={(e) => setDesc(e.target.value)}
                        />
                      </div>
                      <div className="input-field">
                        <h6>Links</h6>
                        <div className="inner-input">
                          <img
                            src="\bluemoon-nft\global.png"
                            alt="img"
                            className="img-fluid"
                          />
                          <input name="site" value={links === "undefined" ? "" : links} type="text" className="upperinput" placeholder="yoursite.io"
                            onChange={(e) => setLinks(e.target.value)}
                          />
                        </div>
                        <div className="inner-input">
                          <img
                            src=".\create\discord.png"
                            alt="img"
                            className="img-fluid"
                          />
                          <input type="text" className="linkinput" placeholder="https://discord.gg/abscdef"
                            name="discord"
                            value={discord === "undefined" ? "" : discord}
                            onChange={(e) => setDiscord(e.target.value)}
                          />
                        </div>
                        <div className="inner-input">
                          <img
                            src=".\create\insta.png"
                            alt="img"
                            className="img-fluid"
                          />
                          <input type="text" className="linkinput" placeholder="https://instagram.com/YourinstagramHandle"
                            name="insta"
                            value={insta === "undefined" ? "" : insta}
                            onChange={(e) => setInsta(e.target.value)}
                          />
                        </div>
                        <div className="inner-input">
                          <img
                            src="\create\mediumShape.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <input type="text" className="linkinput" placeholder="https://medium.com/@YourmediumHandle"
                            name="medium"
                            value={medium === "undefined" ? "" : medium}
                            onChange={(e) => setMedium(e.target.value)}
                          />
                        </div>
                        <div className="inner-input">
                          <img
                            src=".\create\telegram.png"
                            alt="img"
                            className="img-fluid"
                          />
                          <input type="text" value={telegram === "undefined" ? "" : telegram} className="linkinput" placeholder="https://t.me/abcdef"
                            onChange={(e) => setTelegram(e.target.value)}
                            name="telegram"
                          />
                        </div>
                        <div className="inner-input">
                          <img
                            src="\create\twitter.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <input value={twitter === "undefined" ? "" : twitter} type="text" className="lowerinput" placeholder="https://twitter.com/abcdef"
                            onChange={(e) => setTwitter(e.target.value)}
                            name="twitter"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="collection-items">
                      <div className="parent">
                        <div className="first">
                          <div className="inner-text">
                            <h6>Explicit & Sensitive Content</h6>
                            <p>
                              Set this collection as explicit and sensitive content.
                            </p>
                          </div>
                        </div>
                        <div className="second">
                          <div className="my-item">
                            <label className="toggle">
                              <input name={checkBoxx} checked={explicit}
                                onChange={() => setExplicit(!explicit)} className="toggle-checkbox" type="checkbox" />
                              <div className="toggle-switch"></div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottommmmmmmmm">
                      <button className="btn-blue" onClick={EditCollection}>{id ? "Edit Collection" : "Create Collection"}</button>
                      <button onClick={() => navigate(-1)} className="ml-4">Cancel</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            :
            <div className="row">
              <div className="col-lg-12 col-12 m-auto">
                <div className="row">
                  <div className="col-xl-9 col-12">
                    <div className="row">
                      <div className="col-xl-6">
                        <div className="upload">
                          <h6>Logo</h6>
                          <p>PNG, JPG, GIF, WEBP or MP4. 350 x 350 recommended.</p>
                          <div className="piciploddiv piciploddiv1">
                            {logoPicture ?
                              <>
                                <label for="upload1" className="upload-btn piciploddiv1 border-0 p-0">
                                  {logoPicture?.slice(0, 5) === "https" ?
                                    <img src={logoPicture} className="logo___image___uploded" />
                                    :
                                    <img src={logoPicture ? URL?.createObjectURL(logoPicture) : ""} className="logo___image___uploded" />
                                  }
                                </label>
                              </>
                              :
                              <label for="upload1" className="upload-btn curp">Upload file</label>
                            }
                            <input id="upload1" accept='image/*' type="file" className="d-none" onChange={(evt) => logoPicHandle(evt)} />
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6">
                        <div className="upload">
                          <h6>Featured image</h6>
                          <p>PNG, JPG, GIF, WEBP or MP4. 600 x 400 recommended.</p>
                          <div className="piciploddiv1">
                            {
                              featuredPicture ?
                                <>
                                  <label for="upload" className="upload-btn piciploddiv1 border-0 p-0">
                                    {
                                      featuredPicture?.slice(0, 5) === "https" ?
                                        <img src={featuredPicture} className="logo___image___uploded" />
                                        :
                                        <img src={featuredPicture ? URL?.createObjectURL(featuredPicture) : ""} className="logo___image___uploded" />
                                    }
                                  </label>
                                </>
                                :
                                <label for="upload" className="upload-btn curp">Upload file</label>
                            }
                            <input id="upload" accept='image/*' type="file" className="d-none" onChange={(evt) => featuredPicHandle(evt)} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="upload">
                      <h6>Banner image</h6>
                      <p>PNG, JPG, GIF, WEBP or MP4.</p>
                      <div className="piciploddiv piciploddiv1 w-100" style={{ justifyContent: "space-around", height: "310px" }}>
                        {bannerPicture ?
                          <label for="upload2" className="upload-btn piciploddiv1 border-0 p-0 h-100">
                            {
                              bannerPicture?.slice(0, 5) === 'https' ?
                                <img src={bannerPicture} className="logo___image___uploded" />
                                :
                                <img src={bannerPicture ? URL.createObjectURL(bannerPicture) : ''} className="logo___image___uploded" />
                            }
                          </label>
                          :
                          <>
                            <p className="inerpicpara">
                              1400 x 400 recommended.
                            </p>
                            <label for="upload2" className="upload-btn curp">Upload file</label>
                          </>
                        }
                        <input id="upload2" accept='image/*' type="file" className="d-none" onChange={(evt) => bannerPictureHandle(evt)} />
                      </div>
                    </div>
                    <div className="description">
                      <div className="input-field">
                        <h6>Name</h6>
                        <input
                          name="name"
                          type="text"
                          placeholder="Example: treasures of moon"
                          className="input-create"
                          onChange={(e) => { handleChange1(e); CheckName(e.target.value) }}
                        />
                        {avname === true && <h6 className="text-success pt-2">Available</h6>}
                        {avname === false && <h6 className="text-danger pt-2">Unavailable</h6>}
                      </div>
                      <div className="input-field">
                        <h6 className="mb-0">URL</h6>
                        <p>Customize your URL on Bluemoon. Must only contain lowercase letters, numbers, and hyphens.</p>
                        <input
                          name="url"
                          type="text"
                          value={url}
                          placeholder="https://bluemoon.io/collection/treasures-of-moon"
                          className="input-create"
                          onChange={(e) => SETURL(e.target.value)}
                        />
                        {avurl === true && <h6 className="text-success pt-2">Available</h6>}
                        {avurl === false && <h6 className="text-danger pt-2">Unavailable</h6>}
                      </div>
                      <div className="input-field">
                        <h6>Description</h6>
                        <textarea
                          name="description"
                          type="text"
                          placeholder="e.g. “This is very limited item”"
                          className="input-create"
                          onChange={(e) => { handleChange1(e) }}
                        />
                      </div>
                      <div className="input-field">
                        <h6>Category</h6>
                        <div className="button-list">
                          <div className="dropdown buttons-list-all">
                            <button
                              className="button-listing drop-style-font"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                              style={{ paddingLeft: "22px" }}
                            >
                              <div className="left d-flex justify-content-center align-items-center">
                                <p className="m-0">{category1}</p>
                              </div>
                              <img
                                src="bluemoon-nft\bbarrow.png"
                                alt="img"
                                className="img-fluid dropdown-toggle-animate-specss"
                              />
                            </button>

                            <div
                              className="dropdown-menu categoy-drop-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              {Category1.map((elem) => {
                                return (
                                  <a
                                    className="dropdown-item"
                                    onClick={() => categoriess1(elem.item)}
                                  >
                                    &nbsp;&nbsp;
                                    {elem?.item}
                                  </a>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="input-field">
                        <h6>Links</h6>
                        <div className="inner-input">
                          <img
                            src="\bluemoon-nft\global.png"
                            alt="img"
                            className="img-fluid"
                          />
                          <input name="site" type="text" className="upperinput" placeholder="yoursite.io"
                            onChange={(e) => { handleChange1(e) }}
                          />
                        </div>
                        <div className="inner-input">
                          <img
                            src=".\create\discord.png"
                            alt="img"
                            className="img-fluid"
                          />
                          <input type="text" className="linkinput" placeholder="https://discord.gg/abscdef"
                            name="discord"
                            onChange={(e) => { handleChange1(e) }}
                          />
                        </div>
                        <div className="inner-input">
                          <img
                            src=".\create\insta.png"
                            alt="img"
                            className="img-fluid"
                          />
                          <input type="text" className="linkinput" placeholder="https://instagram.com/YourinstagramHandle"
                            name="insta"
                            onChange={(e) => { handleChange1(e) }}
                          />
                        </div>
                        <div className="inner-input">
                          <img
                            src="\create\mediumShape.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <input type="text" className="linkinput" placeholder="https://medium.com/@YourmediumHandle"
                            name="medium"
                            onChange={(e) => { handleChange1(e) }}
                          />
                        </div>
                        <div className="inner-input">
                          <img
                            src=".\create\telegram.png"
                            alt="img"
                            className="img-fluid"
                          />
                          <input type="text" className="linkinput" placeholder="https://t.me/abcdef"
                            name="telegram"
                            onChange={(e) => { handleChange1(e) }}
                          />
                        </div>
                        <div className="inner-input">
                          <img
                            src="\create\twitter.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <input type="text" className="lowerinput" placeholder="https://twitter.com/abcdef"
                            onChange={(e) => { handleChange1(e) }}
                            name="twitter"
                          />
                        </div>
                      </div>
                      <div className="input-field">
                        <h6 className="m-0">Creator Earnings</h6>
                        <p>
                          Collect a fee when a user re-sells an item you originally created.
                        </p>
                        <input
                          name="walletAdress"
                          type="text"
                          placeholder="0xe770CBc62A524898C16AF40CeBc8c0E439477245"
                          className="input-create"
                          onChange={(e) => { handleChange1(e) }}
                        />
                        <div className="add-percent">
                          <input
                            name="fee"
                            type="number"
                            step={1}
                            min="1"
                            max="10"
                            placeholder="eg: 2"
                            className="input-create mt-3"
                            onChange={(e) => { handleChange3(e) }}
                          />
                          <span>%</span>
                        </div>
                        {inputs?.fee < 1 || inputs?.fee > 10 && <h6 className="text-danger pt-2">Enter fee between 1-10</h6>}
                      </div>
                    </div>
                    <div className="input-field">
                      <h6>Blockchain</h6>

                      <div className="button-list">
                        <div className="dropdown buttons-list-all">
                          <button
                            className="button-listing drop-style-font"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            style={{ paddingLeft: "22px" }}
                          >
                            <div className="left d-flex justify-content-center align-items-center">
                              <img src={img} alt="img" className="img-fluid " />
                              <p className="m-0">{category}</p>
                            </div>
                            <img
                              src="bluemoon-nft\bbarrow.png"
                              alt="img"
                              className="img-fluid dropdown-toggle-animate-specss"
                            />
                          </button>

                          <div
                            className="dropdown-menu categoy-drop-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            {Category.map((elem) => {
                              return (
                                <a
                                  className="dropdown-item"
                                  onClick={() => categoriess(elem.item, elem.url)}
                                >
                                  <img
                                    src={elem?.url}
                                    alt="img"
                                    className="img-fluid"
                                  />
                                  &nbsp;&nbsp;
                                  {elem?.item}
                                </a>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="collection-items">
                      <div className="parent">
                        <div className="first">
                          <div className="inner-text">
                            <h6>Explicit & Sensitive Content</h6>
                            <p>
                              Set this collection as explicit and sensitive content.
                            </p>
                          </div>
                        </div>
                        <div className="second">
                          <div className="my-item">
                            <label className="toggle">
                              <input name={checkBoxx} className="toggle-checkbox" type="checkbox" onChange={(e) => { handleChange2(e) }} />
                              <div className="toggle-switch"></div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bottommmmmmmmm">
                      <button disabled={!avname || !avurl} className="btn-blue" onClick={() => creatCollection()}>{id ? "Edit Collection" : "Create Collection"}</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </section>
      <Footer />
    </>
  );
};

export default CreateCollection;