import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import CardModal from './CardModal'
import { useWeb3React } from '@web3-react/core'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import { Api_URL } from '../../utils/ApiURL';
import axios from 'axios';
import TrendingCard from '../../pages/TrendingNowMobile/TrendingCard';

const TrendingNow = props => {
    const [modalShow, setModalShow] = useState(false);
    const [data, setAucData] = useState(null);
    const { account } = useWeb3React();


    const getLiveAuction = async () => {
        await axios.post(`${Api_URL}/collection/getTrendingCollections`)
            .then((res) => {
                setAucData(res?.data?.result);
            })
            .catch((err) => {

            })
    }

    useEffect(() => {
        getLiveAuction()
    }, [])




    return (
        <Fragment>
            <section className="trending-collections desktop-padding-manual">
                <div className="container">
                    {data?.length >= 1 ?
                        <div className="row">
                            <div className="col-md-12">
                                <div className="heading-live-auctions">
                                    <h2 className="tf-title">Trending Now</h2>
                                    <Link to="/explore-collections?state=trending" className="exp style2">EXPLORE MORE</Link>
                                </div>
                            </div>
                            <div className="col-md-12 none-mobile-sm">
                                <Swiper
                                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                                    spaceBetween={20}

                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                            slidesOffsetBefore: 0
                                        },
                                        767: {
                                            slidesPerView: 2
                                        },
                                        991: {
                                            slidesPerView: 3
                                        },
                                        1300: {
                                            slidesPerView: 4
                                        }
                                    }}
                                    navigation
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                >
                                    {
                                        data?.slice(0, 7)?.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <div className="swiper-container show-shadow carousel auctions">
                                                    <div className="swiper-wrapper">
                                                        <div className="swiper-slide">
                                                            <div className="slider-item">
                                                                <div className="sc-card-product">
                                                                    <div className="card-media">
                                                                        <Link to={"/collections?id=" + item?._id}><img src={item?.logoImage} alt="axies" /></Link>
                                                                    </div>
                                                                    <div className="meta-info">
                                                                        <div className="author">
                                                                            <Link to={"/user?id=" + item?.ownerAddress}>
                                                                                <div className="avatar">
                                                                                    <img src={item?.creator[0]?.picture} alt="axies" />
                                                                                </div>
                                                                            </Link>
                                                                            <div className="info">
                                                                                <h5>{item?.name} </h5>
                                                                                <Link to={"/user?id=" + item?.ownerAddress}>
                                                                                    <h6 className='w-100'><span>Created by</span> {item?.creator[0]?.userFullName ? item?.creator[0]?.userFullName : item?.creator[0]?.walletAddress?.slice(0, 3) + '...' + item?.creator[0]?.walletAddress?.slice(item?.creator[0]?.walletAddress.length - 3, item?.creator[0]?.walletAddress.length)}
                                                                                    </h6>
                                                                                </Link>
                                                                            </div>
                                                                          
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </SwiperSlide>
                                        ))
                                    }
                                </Swiper>
                            </div>
                            <div className="col-md-12 d-none show-mobile-sm">
                                <TrendingCard data={data} />
                            </div>
                        </div>
                        :
                        <div className='py-5 my-5 d-flex align-items-center justify-content-center w-100'>
                            <h2 className='py-5 my-5'>No NFT in Trending</h2>
                        </div>
                    }
                </div>
            </section>
            <CardModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </Fragment>

    );
}

TrendingNow.propTypes = {
    data: PropTypes?.array?.isRequired,
}


export default TrendingNow;
