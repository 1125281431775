import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import { Link } from "react-router-dom";
import ExploreItem from "../../components/layouts/explore-04/ExploreItem";
import dummyProfile from "../../assets/dummyProfile.png";
import dummyImg from "../../assets/dummyImg.png";
import { useNavigate } from "react-router-dom";
import { Api_URL } from "../../utils/ApiURL";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { toast } from "react-toastify";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Loader from "../../hooks/loader";
import CollectionCard from "../CollectionCardNew/CollectionCard";
import Activity01 from "../Activity01";

const Myprofille = () => {

  let navigate = useNavigate();
  const { account } = useWeb3React();
  const [data, setData] = useState(null);
  const [token, setToken] = useState(null);
  const [copied, setCopied] = useState(false);
  const [loader, setLoader] = useState(false);
  const [collectionByCreatorAddress, setCollectionByCreatorAddress] = useState(null);
  const [onsale, setOnsale] = useState([]);
  const [followers, getFollowers] = useState();
  const [createdCollection, setCreatedCollection] = useState([]);
  const context = useWeb3React();
  const { chainId } = context;
  const [ownedCollection, setOwnedCollection] = useState([]);
  const [dumArray2, setdumArray2] = useState([]);
  const [following, getFollowing] = useState();
  const [offerRecieved, setOfferRecieved] = useState();
  const [ethUsd, setEthUsd] = useState(null);
  const [offerSent, setOfferSent] = useState();
  const [id, setId] = useState(null);
  const [address, setAddress] = useState();
  const [follow, setFollow] = useState(false);

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("id");

    if (id !== null) {
      setId(id);
      setAddress(id);
    }
  }, []);

  useEffect(() => {
    if (id && token) {
      getFollowingDetails();
      getFollowersDetails();
    }
  }, [id, token])

  useEffect(() => {
    axios.get('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD').then((res) => {
      setEthUsd(res.data);
    })
  }, [offerRecieved])

  const getOfferSent = async () => {
    let tok = localStorage.getItem("accessTok");
    setLoader(true);

    var data = JSON.stringify({
      bidderAddress: id
    });
    var config = {
      method: "post",
      url: `${Api_URL}/bid/getBidsByBidder`,
      headers: {
        Authorization: "Bearer " + tok,
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setOfferSent(response?.data?.bid?.reverse())
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  };

  const getFollowingDetails = async () => {
    setLoader(true);
    await axios
      .post(
        `${Api_URL}/user/getFollowingAndDetails`,
        {
          walletAddress: id
        },
        {
          Authorization: `Bearer ${token}`
        }
      )
      .then((res) => {
        let data2 = res?.data?.data?.detailsOfFollowing;
        getFollowing(data2);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        // console.log("getApi error", err);
      });
  };

  const getFollowersDetails = async () => {
    setLoader(true);
    await axios
      .post(
        `${Api_URL}/user/getFollowersAndDetails`,
        {
          walletAddress: id
        },
        {
          Authorization: `Bearer ${token}`
        }
      )
      .then((res) => {
        let data1 = res?.data?.data.detailsOfFollowers;
        getFollowers(data1);
        var dumArrey = [];
        for (let i of data1) {
          dumArrey.push(i.walletAddress);
        }
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        // console.log("getApi error", err);
      });
  };

  const getOwnedNft = async () => {
    var axios = require("axios");
    setLoader(true);
    var data = JSON.stringify({
      walletAddress: id,
      chainId: chainId?.toString()
    });
    var config = {
      method: "post",
      url: `${Api_URL}/token/getOwnedTokensOfUserAndDetails`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        setOwnedCollection(response?.data?.data);
        let dumArr = response?.data?.data?.filter((i) => {
          return i?.metaData && i?.onSale;
        })
        setOnsale(dumArr);
        // localStorage.setItem("accessTok", response?.data?.token?.accessToken);
        // setAuth(response?.data?.token?.accessToken);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  };

  useEffect(() => {
    if (id) {
      setToken(localStorage.getItem("accessToken"));
    }
  }, [id]);

  useEffect(() => {
    if (id && chainId && token) {
      getCreatedNft();
      getOwnedNft();
    }
  }, [id, chainId, token]);

  const getCreatedNft = async () => {
    setLoader(true);
    await axios
      .post(
        `${Api_URL}/token/getCreatedTokensOfUserAndDetails`,
        {
          walletAddress: id,
          chainId: chainId?.toString()
        }
      )
      .then((res) => {
        setLoader(false);
        setCreatedCollection(res?.data?.data);
      })
      .catch((err) => {
        setLoader(false);
        // console.log("getApi error", err);
      });
  };

  const getUser = async () => {
    let data = null;
    if (id) {
      data = { walletAddress: id }
    } else {
      data = { walletAddress: id }
    }
    await axios
      .post(`${Api_URL}/user/getUser`, data, {
        headers: {
          Authorization: "Bearer " + token,
        }
      })
      .then((response) => {
        let find = response?.data?.user?.followers?.find((r) => {
          return r.address?.toLowerCase() === account?.toLowerCase()
        })
        if (find) {
          setFollow(true);
        }
        setData(response.data);
      })
      .catch((err) => {
        toast.error(err?.response?.data.msg, {
          position: "top-right",
          autoClose: 2000,
        });
        console?.log(err?.response?.data.msg)
      })
  }

  const getCollectionByUser = async () => {
    const data = {
      creator: id?.toLowerCase()?.toString()
    }
    await axios
      .post(`${Api_URL}/collection/getCollectionByCreatorAddress`, data, {
        headers: {
          Authorization: "Bearer " + token,
        }
      })
      .then((res) => {
        setCollectionByCreatorAddress(res?.data?.result);
      })
      .catch((err) => {
        toast.error(err?.response?.data.msg, {
          position: "top-right",
          autoClose: 2000,
        });
        console?.log(err?.response?.data.msg)
      })
  }

  useEffect(() => {
    if (id) {
      getUser();
    }
  }, [id, account])


  useEffect(() => {
    if (token) {
      getUser();
      getCollectionByUser();
    }
  }, [token, id]);

  const copiedHandle = () => {
    setCopied(true);
    setTimeout(
      function () {
        setCopied(false);
      }.bind(this),
      1500
    );
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
    <p className="dtyuguihikguyj">  {copied ? "copied" : "copy"}</p>
    </Tooltip>
  );

  const Unfollow = async (e, id) => {
    setLoader(true);
    var data = JSON.stringify({
      "walletAddress": id?.toLowerCase(),
      "toFollow": e?.toLowerCase()
    });
    var config = {
      method: 'post',
      url: `${Api_URL}/users/removeFollower`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        let dumArr = following;
        let data2;
        data2 = dumArr?.filter((item) => {
          return item?._id !== id;
        });
        getFollowing(data2);
        getFollowersDetails();
        setLoader(false);
      })
      .catch(function (err) {
        toast.error(err?.response?.data.msg, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
  };

  const followAddress = async () => {
    setLoader(true);
    var data = JSON.stringify({
      "walletAddress": account?.toLowerCase(),
      "toFollow": address?.toLowerCase()
    });
    var config = {
      method: 'post',
      url: follow === false ? `${Api_URL}/user/addFollower` : `${Api_URL}/user/removeFollower`,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        setLoader(false);
        getUser();
        if (follow === true) {
          setFollow(false);
        } else {
          setFollow(true);
        }
      })
      .catch(function (err) {
        toast.error(err?.response?.data.msg, {
          position: "top-right",
          autoClose: 2000,
        });
        setLoader(false);
      });
  };

  const getOfferRecieved = async () => {
    setLoader(true);

    var data = JSON.stringify({
      walletAddress: id
    });
    var config = {
      method: "post",
      url: `${Api_URL}/bid/getBidByProfile`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        // console.log("response?.data?.offersReceivedresponse?.data?.offersReceived", response?.data?.offersReceived)
        let filts = response?.data?.offersReceived?.filter((i) => {
          return i?.bids?.length > 0;
        })
        setOfferRecieved(filts?.reverse())
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  };

  const getMax = (val) => {
    if (val) {
      let v = val;
      v = v.sort(function (a, b) {
        return parseFloat(b.amount) - parseFloat(a.amount);
      });
      return v[0]?.amount?.toFixed(4);
    }
  }

  const getMaxUsd = (val) => {
    if (val) {
      let v = val;
      v = v.sort(function (a, b) {
        return parseFloat(b.amount) - parseFloat(a.amount);
      });
      return (parseFloat(v[0]?.amount) * ethUsd?.USD).toFixed(2)
    };
  }

  return (
    <>
      <Header />
      {loader && <Loader />}
      <section className="main-Authoronsale">
        <div className="bg-img-author">
          <img src={data?.resultData?.coverPicture ? data?.resultData?.coverPicture : dummyImg} alt="img" className="img-fluid" />
        </div>
        <div className="container1920">
          <div className="row">
            <div className="col-sm-12 m-auto ">
              <div className="main_prodiv ">
                <div className="">
                  <img style={{ border: '10px solid white', borderRadius: 30 }} src={data?.resultData?.picture ? data?.resultData?.picture : dummyProfile} alt="img" className='img-fluid profileavtt' />
                </div>
                <div className="icondiv">
                  <ul className="list-inline">
                    {
                      data?.resultData?.telegramLink !== "undefined" ?
                        <a className="list-inline-item iconbtnnnn" href={"https://www.telegram.com/" + data?.resultData?.telegramLink} target="_blank">
                          <li className="list-inline-item iconbtnnnn"><img src="\fst.svg" alt="img" className='' /></li>
                        </a>
                        : null
                    }
                    {
                      data?.resultData?.redditLink !== "undefined" ?
                        <a className="list-inline-item iconbtnnnn" href={"https://www.reddit.gg/" + data?.resultData?.redditLink} target="_blank">
                          <li className="list-inline-item iconbtnnnn"><img src="\sseenndd.svg" alt="img" className='' /></li>
                        </a>
                        : null
                    }
                    {
                      data?.resultData?.discordLink !== "undefined" ?

                        <a className="list-inline-item iconbtnnnn" href={"https://www.discord.gg/" + data?.resultData?.discordLink} target="_blank">
                          <li className="list-inline-item iconbtnnnn"><img src="\thirdds.svg" alt="img" className='' /></li>
                        </a>
                        : null
                    }
                    {
                      data?.resultData?.twitterUserName !== "undefined" ?

                        <a className="list-inline-item iconbtnnnn" href={"https://www.twitter.com/" + data?.resultData?.twitterUserName} target="_blank">
                          <li className="list-inline-item iconbtnnnn"><img src="\forrh.svg" alt="img" className='' /></li>
                        </a>
                        : null
                    }
                    {
                      data?.resultData?.instagramLink !== "undefined" ?

                        <a className="list-inline-item iconbtnnnn" href={"https://www.instagram.com/" + data?.resultData?.instagramLink} target="_blank">
                          <li className="list-inline-item iconbtnnnn"><img src="\fifth.svg" alt="img" className='' /></li>
                        </a>
                        : null
                    }
                    {
                      data?.resultData?.meduimLink !== "undefined" ?

                        <a className="list-inline-item iconbtnnnn" href={"https://www.medium.com/" + data?.resultData?.meduimLink} target="_blank">
                          <li className="list-inline-item iconbtnnnn"><img src="\sixx.svg" alt="img" className='' /></li>
                        </a>
                        : null
                    }
                  </ul>

                </div>
              </div>
              <div className="avatar__row">
                <h3 className="jekkee">{data?.resultData?.userFullName}</h3>
                {!id ?
                  <button className='editprofilebtnmm' onClick={() => navigate('/EditProfile')}>Edit Profile</button>
                  :
                  <>
                    {account !== address && (
                      <button className="btn-common" onClick={followAddress}>
                        {follow ? "Following" : "Follow"}
                      </button>
                    )}
                  </>
                }
                <div className="followerss gvvggcg d-flex align-items-start mt-4">
                  <p className="folowdig ">{data?.resultData?.followersCount > 0 ? data?.resultData?.followersCount : 0} <span className="foll">Followers</span></p>
                  <p className="folowdig">{data?.resultData?.followingCount > 0 ? data?.resultData?.followingCount : 0} <span className="foll">Following</span></p>
                </div>
                <p className='ttteexxxtt'>{(data?.resultData?.bio && data?.resultData?.bio !== 'undefined') && data?.resultData?.bio}</p>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderTooltip}
                  className="copycoopppyy"
                >
                  <CopyToClipboard
                    text={data?.resultData?.walletAddress}
                    onCopy={() => copiedHandle()}
                  >
                    <div className="copycoopppyy curp">
                      <p className='coppytestcc'>{data?.resultData?.walletAddress}</p>
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-copy" width="22" height="22" viewBox="0 0 24 24" strokeWidth="1" stroke="#7a798a" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="8" y="8" width="12" height="12" rx="2" />
                        <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                      </svg>
                    </div>
                  </CopyToClipboard>
                </OverlayTrigger>
              </div>
            </div>
          </div>

          <div className='mainpills landingpills '>

            <div className="row">
              <div className=" col-sm-12  m-auto ">
                <ul className="nav nav-tabs " role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-toggle="tab"
                      href="#sale"
                      role="tab"
                      aria-controls="sale"
                      aria-selected="true"
                    >
                      On Sale
                    </a>
                  </li>
                  {/* {!id && */}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#owned"
                        role="tab"
                        aria-controls="owned"
                        onClick={getOwnedNft}
                        aria-selected="false"
                      >
                        Owned
                      </a>
                    </li>
                  {/* } */}
                  {/* {!id && */}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#created"
                        role="tab"
                        aria-controls="created"
                        aria-selected="false"
                        onClick={getCreatedNft}
                      >
                        Created
                      </a>
                    </li>
                  {/* } */}
                  <li className="nav-item">
                    <a
                      className="nav-link "
                      data-toggle="tab"
                      href="#collections"
                      role="tab"
                      aria-controls="collections"
                      aria-selected="false"
                    >
                      Collections
                    </a>
                  </li>
                  {/* {!id && */}
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#followers"
                        role="tab"
                        aria-controls="followers"
                        aria-selected="true"
                      >
                        {" "}
                        Followers
                      </a>
                    </li>
                  {/* } */}
                  {/* {!id && */}
                    <li className="nav-item">
                      <a
                        className="nav-link "
                        data-toggle="tab"
                        href="#following"
                        role="tab"
                        aria-controls="following"
                        aria-selected="false"
                      >
                        Following
                      </a>
                    </li>
                  {/* } */}
                  {!id &&
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#offers"
                        role="tab"
                        aria-controls="offers"
                        onClick={getOfferRecieved}
                        aria-selected="false"
                      >
                        Offers
                      </a>
                    </li>
                  }
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-toggle="tab"
                      href="#activity"
                      role="tab"
                      aria-controls="offers"
                      onClick={getOfferRecieved}
                      aria-selected="false"
                    >
                      Activity
                    </a>
                  </li>
                </ul>
                <div className="tab-content mt-3">
                  <div
                    className="tab-pane active"
                    id="sale"
                    role="tabpanel"
                    aria-labelledby="sale-tab"
                  >
                    <div className="row ">
                      <div className="col-xl-12 col-lg-11 col-md-12 col-12 m-auto ">
                        <ExploreItem recentAddTokens={onsale} />
                      </div>{" "}
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="owned"
                    role="tabpanel"
                    aria-labelledby="owned-tab"
                  >
                    <ExploreItem recentAddTokens={ownedCollection} />
                  </div>
                  <div
                    className="tab-pane"
                    id="created"
                    role="tabpanel"
                    aria-labelledby="created-tab"
                  >
                    <ExploreItem recentAddTokens={createdCollection} />
                  </div>
                  <div
                    className="tab-pane"
                    id="collections"
                    role="tabpanel"
                    aria-labelledby="collections-tab"
                  >
                    <CollectionCard owner={true} data={collectionByCreatorAddress} />
                  </div>
                  <div
                    className="tab-pane followerstaabb"
                    id="followers"
                    role="tabpanel"
                    aria-labelledby="followers-tab"
                  >
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12  m-auto ">
                        <div className="followersmain">
                          {followers?.length > 0 ?
                            <div className="row">
                              {followers?.map((item, index) => {
                                return (
                                  <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-6">
                                    <div className="folowcardd ">
                                      <div className="flpic">
                                        <img
                                          src={
                                            item?.picture
                                              ? item?.picture
                                              : "./upload.png"
                                          }
                                          alt=""
                                          className=" img-fluid"
                                        />
                                      </div>
                                      <div className="writing">
                                        <p className="crispin">{item?.userFullName}</p>
                                        <p className="crispineth">{item?.followersCount + " "}
                                          Followers</p>
                                      </div>
                                      <div className="fcardbtn">
                                        <>
                                          {dumArray2[index] == true ? (
                                            <button
                                              className="fcardbtn"
                                              onClick={() =>
                                                Unfollow(item?.walletAddress, item?._id)
                                              }
                                            >
                                              UnFollow
                                            </button>
                                          ) : (
                                            <button
                                              className="nfollowcard"
                                              onClick={() =>
                                                followAddress(item?.walletAddress)
                                              }
                                            >
                                              Follow
                                            </button>
                                          )}
                                        </>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            :
                            <div className="no____nft">
                              <h3>No Followers Found</h3>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="following"
                    role="tabpanel"
                    aria-labelledby="following-tab"
                  >
                    <div className="row">
                      <div className="col-xl-12 col-lg-12 col-md-12 col-12 m-auto ">
                        <div className="followersmain">
                          {following?.length > 0 ?
                            <div className="row">
                              {following?.map((item, index) => {
                                return (
                                  <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-6">
                                    <div className="folowcardd ">
                                      <div className="flpic">
                                        <img
                                          src={
                                            item?.picture
                                              ? item?.picture
                                              : "./upload.png"
                                          }
                                          alt=""
                                          className=" img-fluid"
                                        />
                                      </div>
                                      <div className="writing">
                                        <p className="crispin">{item?.userFullName}</p>
                                        <p className="crispineth">{item?.followersCount + " "}
                                          Followers</p>
                                      </div>
                                      <div className="fcardbtn">
                                        <>
                                          {dumArray2[index] == true ? (
                                            <button
                                              className="fcardbtn"
                                              onClick={() =>
                                                Unfollow(item?.walletAddress, item?._id)
                                              }
                                            >
                                              UnFollow
                                            </button>
                                          ) : (
                                            <button
                                              className="nfollowcard"
                                              onClick={() =>
                                                followAddress(item?.walletAddress)
                                              }
                                            >
                                              Follow
                                            </button>
                                          )}
                                        </>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                              )}
                            </div>
                            :
                            <div className="no____nft">
                              <h3>No Following Found</h3>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="offers"
                    role="tabpanel"
                    aria-labelledby="offers-tab"
                  >
                    <div className="pillsbtnns">
                      <ul
                        className="nav nav-pills sentresive"
                        id="pills-tab"
                        role="tablist"
                      >
                        <li className="nav-item maintabbtnssf" role="presentation">
                          <a
                            className="nav-link recieved"
                            id="pills-recieved-tab"
                            data-toggle="pill"
                            href="#pills-recieved"
                            role="tab"
                            aria-controls="pills-recieved"
                            aria-selected="true"
                          >
                            Offers Recieved
                          </a>
                        </li>
                        <li className="nav-item maintabbtnssse" role="presentation">
                          <a
                            className="nav-link ssent "
                            id="pills-ssent-tab"
                            data-toggle="pill"
                            href="#pills-ssent"
                            role="tab"
                            aria-controls="pills-ssent"
                            aria-selected="false"
                            onClick={() => { getOfferSent() }}
                          >
                            Offers Sent
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="Bidoffers">
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade "
                          id="pills-recieved"
                          role="tabpanel"
                          aria-labelledby="pills-recieved-tab"
                        >
                          <div className="row">
                            <div className="col-xl-11 col-12 threepillstabs">
                              <div
                                className="nav nav-tabs  oofferssser "
                                id="nav-tab"
                                role="tablist"
                              >
                                <a
                                  className="nav-link oferiner"
                                  id="nav-bid-tab"
                                  data-toggle="tab"
                                  href="#nav-bid"
                                  role="tab"
                                  aria-controls="nav-bid"
                                  aria-selected="true"
                                >
                                  Bid Offers
                                </a>
                                <a
                                  className="nav-link oferiner"
                                  id="nav-swap-tab"
                                  data-toggle="tab"
                                  href="#nav-swap"
                                  role="tab"
                                  aria-controls="nav-swap"
                                  aria-selected="false"
                                >
                                  Swap Offers
                                </a>
                                <a
                                  className="nav-link oferiner"
                                  id="nav-rent-tab"
                                  data-toggle="tab"
                                  href="#nav-rent"
                                  role="tab"
                                  aria-controls="nav-rent"
                                  aria-selected="false"
                                >
                                  Rent Offers
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="tab-content bidssii" id="nav-tabContent">
                            <div
                              className="tab-pane fade show active"
                              id="nav-bid"
                              role="tabpanel"
                              aria-labelledby="nav-bid-tab"
                            >
                              <div className="newtable">
                                <div className="row">
                                  <div className="col-xl-11 col-12 table-responsive-lg">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="uhvvvv">
                                            <h3 className="headdingrow">Items</h3>
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (ETH)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (USD)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Total Offers
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Details
                                            </h3>
                                          </th>
                                        </tr>
                                      </thead>
                                      {offerRecieved?.length > 0 ?
                                        <tbody>
                                          {offerRecieved?.map((item, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <div className="img-table d-flex">
                                                    <img
                                                      style={{ width: 60 }}
                                                      src={item?.image}
                                                      className="img-fluid"
                                                    />
                                                    <h6 className="">
                                                      {item?.nftName} #{item?.tokenID}
                                                    </h6>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="ethicon">
                                                    <img
                                                      src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                      className="img-fluid"
                                                    />
                                                    <h6 className="">{getMax(item?.bids)}</h6>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="paratable">
                                                    <p className="">{getMaxUsd(item?.bids)}</p>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="paratable">
                                                    <p className="">{item?.bids?.length}</p>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="btn-table">
                                                    <Link to={'/item-details?id=' + item?._id}>
                                                      <button className="viewdetails">
                                                        View Details
                                                      </button>
                                                    </Link>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                        :
                                        <div className="no____nft">
                                          <h3>No Offers Found</h3>
                                        </div>
                                      }
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-swap"
                              role="tabpanel"
                              aria-labelledby="nav-swap-tab"
                            >
                              <div className="newtable">
                                <div className="row">
                                  <div className="col-xl-11 col-12 table-responsive-lg">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="uhvvvv">
                                            <h3 className="headdingrow">Items</h3>
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (ETH)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (USD)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Total Offers
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Details
                                            </h3>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div className="img-table d-flex">
                                              <img
                                                src="\bluemoon-nft\sales\monkey.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">
                                                BoredApe #13963
                                              </h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="ethicon">
                                              <img
                                                src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">0.022 ETH</h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">$72.24</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">20</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="btn-table">
                                              <Link to="/item-details-03">
                                                <button className="viewdetails">
                                                  View Details
                                                </button>
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="img-table d-flex">
                                              <img
                                                src="\bluemoon-nft\sales\monkey.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">
                                                BoredApe #13963
                                              </h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="ethicon">
                                              <img
                                                src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">0.022 ETH</h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">$72.24</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">20</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="btn-table">
                                              <button className="viewdetails">
                                                View Details
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="img-table d-flex">
                                              <img
                                                src="\bluemoon-nft\sales\monkey.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">
                                                BoredApe #13963
                                              </h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="ethicon">
                                              <img
                                                src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">0.022 ETH</h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">$72.24</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">20</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="btn-table">
                                              <button className="viewdetails">
                                                View Details
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="img-table d-flex">
                                              <img
                                                src="\bluemoon-nft\sales\monkey.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">
                                                BoredApe #13963
                                              </h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="ethicon">
                                              <img
                                                src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">0.022 ETH</h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">$72.24</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">20</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="btn-table">
                                              <button className="viewdetails">
                                                View Details
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="img-table d-flex">
                                              <img
                                                src="\bluemoon-nft\sales\monkey.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">
                                                BoredApe #13963
                                              </h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="ethicon">
                                              <img
                                                src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">0.022 ETH</h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">$72.24</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">20</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="btn-table">
                                              <button className="viewdetails">
                                                View Details
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>
                                            <div className="img-table d-flex">
                                              <img
                                                src="\bluemoon-nft\sales\monkey.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">
                                                BoredApe #13963
                                              </h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="ethicon">
                                              <img
                                                src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">0.022 ETH</h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">$72.24</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">20</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="btn-table">
                                              <button className="viewdetails">
                                                View Details
                                              </button>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-rent"
                              role="tabpanel"
                              aria-labelledby="nav-rent-tab"
                            >
                              <div className="newtable">
                                <div className="row">
                                  <div className="col-xl-11 col-12 table-responsive-lg">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="uhvvvv">
                                            <h3 className="headdingrow">Item</h3>
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (ETH)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (USD)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Total Offers
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Details
                                            </h3>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div className="img-table d-flex">
                                              <img
                                                src="\bluemoon-nft\sales\monkey.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">
                                                BoredApe #13963
                                              </h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="ethicon">
                                              <img
                                                src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">0.022 ETH</h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">$72.24</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">20</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="btn-table">
                                              <Link to="/item-details-03">
                                                <button className="viewdetails">
                                                  View Details
                                                </button>
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-ssent"
                          role="tabpanel"
                          aria-labelledby="pills-ssent-tab"
                        >
                          <div className="newtable">
                            <div className="row">
                              <div className="col-xl-11 col-12 table-responsive-lg">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th className="uhvvvv">
                                        <h3 className="headdingrow">Item</h3>
                                      </th>
                                      <th className="uhvvvv2">
                                        {" "}
                                        <h3 className="headdingrow">
                                          Highest Bid (ETH)
                                        </h3>{" "}
                                      </th>
                                      <th className="uhvvvv2">
                                        {" "}
                                        <h3 className="headdingrow">
                                          Total Offers
                                        </h3>{" "}
                                      </th>
                                      <th className="uhvvvv2">
                                        {" "}
                                        <h3 className="headdingrow">
                                          Details
                                        </h3>
                                      </th>
                                    </tr>
                                  </thead>
                                  {offerSent?.length > 0 ?
                                    <tbody>
                                      {offerSent?.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <div className="img-table d-flex">
                                                <img
                                                  style={{ width: 60 }}
                                                  src={item?.tokens?.length > 0 && item?.tokens[0].image}
                                                  className="img-fluid"
                                                />
                                                <h6 className="">
                                                  {item?.tokens.map(tok => (
                                                    tok?.nftName
                                                  ))}
                                                </h6>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="ethicon">
                                                <img
                                                  src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                  className="img-fluid"
                                                />
                                                <h6 className="">{item?.amount ? item?.amount : 0}</h6>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="paratable">
                                                <p className="">{item?.bids?.length}</p>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="btn-table">
                                                <Link to={'/item-details?id=' + item?._id}>
                                                  <button className="viewdetails">
                                                    View Details
                                                  </button>
                                                </Link>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                    :
                                    <div className="no____nft">
                                      <h3>No Offers Found</h3>
                                    </div>
                                  }
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="Bidoffers">
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade "
                          id="pills-ssent"
                          role="tabpanel"
                          aria-labelledby="pills-ssent-tab"
                        >
                          <div className="row">
                            <div className="col-xl-11 col-12 threepillstabs">
                              <div
                                className="nav nav-tabs  oofferssser "
                                id="nav-tab"
                                role="tablist"
                              >
                                <a
                                  className="nav-link oferiner"
                                  id="nav-bid-tab"
                                  data-toggle="tab"
                                  href="#nav-bid"
                                  role="tab"
                                  aria-controls="nav-bid"
                                  aria-selected="true"
                                >
                                  Bid Offers
                                </a>
                                <a
                                  className="nav-link oferiner"
                                  id="nav-swap-tab"
                                  data-toggle="tab"
                                  href="#nav-swap"
                                  role="tab"
                                  aria-controls="nav-swap"
                                  aria-selected="false"
                                >
                                  Swap Offers
                                </a>
                                <a
                                  className="nav-link oferiner"
                                  id="nav-rent-tab"
                                  data-toggle="tab"
                                  href="#nav-rent"
                                  role="tab"
                                  aria-controls="nav-rent"
                                  aria-selected="false"
                                >
                                  Rent Offers
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="tab-content bidssii" id="nav-tabContent">
                            <div
                              className="tab-pane fade show active"
                              id="nav-bid"
                              role="tabpanel"
                              aria-labelledby="nav-bid-tab"
                            >
                              <div className="newtable">
                                <div className="row">
                                  <div className="col-xl-11 col-12 table-responsive-lg">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="uhvvvv">
                                            <h3 className="headdingrow">Item</h3>
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (ETH)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (USD)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Total Offers
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Details
                                            </h3>
                                          </th>
                                        </tr>
                                      </thead>
                                      {offerRecieved?.length > 0 ?
                                        <tbody>
                                          {offerRecieved?.map((item, index) => {
                                            return (
                                              <tr key={index}>
                                                <td>
                                                  <div className="img-table d-flex">
                                                    <img
                                                      style={{ width: 60 }}
                                                      src={item?.image}
                                                      className="img-fluid"
                                                    />
                                                    <h6 className="">
                                                      {item?.nftName} #{item?.tokenID}
                                                    </h6>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="ethicon">
                                                    <img
                                                      src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                      className="img-fluid"
                                                    />
                                                    <h6 className="">{getMax(item?.bids)}</h6>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="paratable">
                                                    <p className="">{getMaxUsd(item?.bids)}</p>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="paratable">
                                                    <p className="">{item?.bids?.length}</p>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="btn-table">
                                                    <Link to={'/item-details?id=' + item?._id}>
                                                      <button className="viewdetails">
                                                        View Details
                                                      </button>
                                                    </Link>
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          })}
                                        </tbody>
                                        :
                                        <div className="no____nft">
                                          <h3>No Offers Found</h3>
                                        </div>
                                      }
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-swap"
                              role="tabpanel"
                              aria-labelledby="nav-swap-tab"
                            >
                              <div className="newtable">
                                <div className="row">
                                  <div className="col-xl-11 col-12 table-responsive-lg">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="uhvvvv">
                                            <h3 className="headdingrow">Item</h3>
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (ETH)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (USD)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Total Offers
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Details
                                            </h3>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div className="img-table d-flex">
                                              <img
                                                src="\bluemoon-nft\sales\monkey.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">
                                                BoredApe #13963
                                              </h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="ethicon">
                                              <img
                                                src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">0.022 ETH</h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">$72.24</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">20</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="btn-table">
                                              <Link to="/item-details-03">
                                                <button className="viewdetails">
                                                  View Details
                                                </button>
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>

                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="nav-rent"
                              role="tabpanel"
                              aria-labelledby="nav-rent-tab"
                            >
                              <div className="newtable">
                                <div className="row">
                                  <div className="col-xl-11 col-12 table-responsive-lg">
                                    <table className="table">
                                      <thead>
                                        <tr>
                                          <th className="uhvvvv">
                                            <h3 className="headdingrow">Item</h3>
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (ETH)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Highest Bid (USD)
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Total Offers
                                            </h3>{" "}
                                          </th>
                                          <th className="uhvvvv2">
                                            {" "}
                                            <h3 className="headdingrow">
                                              Details
                                            </h3>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div className="img-table d-flex">
                                              <img
                                                src="\bluemoon-nft\sales\monkey.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">
                                                BoredApe #13963
                                              </h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="ethicon">
                                              <img
                                                src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                className="img-fluid"
                                              />
                                              <h6 className="">0.022 ETH</h6>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">$72.24</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="paratable">
                                              <p className="">20</p>
                                            </div>
                                          </td>
                                          <td>
                                            <div className="btn-table">
                                              <Link to="/item-details-03">
                                                <button className="viewdetails">
                                                  View Details
                                                </button>
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-ssent"
                          role="tabpanel"
                          aria-labelledby="pills-ssent-tab"
                        >
                          <div className="newtable">
                            <div className="row">
                              <div className="col-xl-11 col-12 table-responsive-lg">
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th className="uhvvvv">
                                        <h3 className="headdingrow">Item</h3>
                                      </th>
                                      <th className="uhvvvv2">
                                        {" "}
                                        <h3 className="headdingrow">
                                          Highest Bid (ETH)
                                        </h3>{" "}
                                      </th>
                                      <th className="uhvvvv2">
                                        {" "}
                                        <h3 className="headdingrow">
                                          Highest Bid (USD)
                                        </h3>{" "}
                                      </th>
                                      <th className="uhvvvv2">
                                        {" "}
                                        <h3 className="headdingrow">
                                          Total Offers
                                        </h3>{" "}
                                      </th>
                                      <th className="uhvvvv2">
                                        {" "}
                                        <h3 className="headdingrow">
                                          Details
                                        </h3>
                                      </th>
                                    </tr>
                                  </thead>
                                  {offerRecieved?.length > 0 ?
                                    <tbody>
                                      {offerRecieved?.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>
                                              <div className="img-table d-flex">
                                                <img
                                                  style={{ width: 60 }}
                                                  src={item?.image}
                                                  className="img-fluid"
                                                />
                                                <h6 className="">
                                                  {item?.nftName} #{item?.tokenID}
                                                </h6>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="ethicon">
                                                <img
                                                  src="\bluemoon-nft\sales\ETHEREUM.svg"
                                                  className="img-fluid"
                                                />
                                                <h6 className="">{getMax(item?.bids)}</h6>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="paratable">
                                                <p className="">{getMaxUsd(item?.bids)}</p>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="paratable">
                                                <p className="">{item?.bids?.length}</p>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="btn-table">
                                                <Link to={'/item-details?id=' + item?._id}>
                                                  <button className="viewdetails">
                                                    View Details
                                                  </button>
                                                </Link>
                                              </div>
                                            </td>
                                          </tr>
                                        )
                                      })}
                                    </tbody>
                                    :
                                    <div className="no____nft">
                                      <h3>No Offers Found</h3>
                                    </div>
                                  }
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane"
                    id="activity"
                    role="tabpanel"
                    aria-labelledby="collections-tab"
                  >
                    <Activity01 userAcc={data?.resultData?.walletAddress} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Myprofille;