import React, { useEffect } from "react";
import Filter from "../Filter/Filter";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import "./explore.scss";
import ExploreItem from "../../components/layouts/explore-04/ExploreItem";
import { useState } from "react";
import axios from "axios";
import { Api_URL } from "../../utils/ApiURL";
import Loader from "../../hooks/loader";
import { toast } from "react-toastify";

const Explore = () => {

  const [showBtn, setShowBtn] = useState(false);
  const [isBuyNowChecked, setBuyNowChecked] = useState(false);
  const [isBscChecked, setBscChecked] = useState(false);
  const [isEthChecked, setEthChecked] = useState(false);
  const [loader, setLoader] = useState(false);
  const [usd, setUsd] = useState(true);
  const [isOnAction, setOnAction] = useState(false);
  const [recentAddTokens, setRecentAddTokens] = useState(null);
  const [sprice, setSprice] = useState(0);
  const [lprice, setLprice] = useState(99999999999);
  const [categories, setCategories] = useState([]);
  const [forRent, setForRent] = useState(false)
  const [rl, setRl] = useState(false);
  const [rc, setRc] = useState(false);
  const [rs, setRs] = useState(false);
  const [lh, setLh] = useState(false);
  const [hl, setHl] = useState(false);
  const [def, setDef] = useState(false);
  const [auct, setAuct] = useState(false);
  function sortRes() {
    if (rl) {
      return 'Recently Listed'
    } else if (rc) {
      return 'Recently Created'
    } else if (rs) {
      return 'Recently Sold'
    } else if (lh) {
      return 'Low to High'
    } else if (hl) {
      return 'High to Low'
    } else if (def) {
      return 'Default'
    } else {
      return 'Sort By'
    }
  }

  const buyNowHandle = () => {
   if (!isBuyNowChecked && isOnAction){
      window.history.replaceState(null, null, `/explore?state=both`);
     setBuyNowChecked(!isBuyNowChecked);
   } else if (isOnAction && isBuyNowChecked) {
     window.history.replaceState(null, null, `/explore?state=auctionState`);
     setBuyNowChecked(!isBuyNowChecked);
   } else if (!isBuyNowChecked){
      window.history.replaceState(null, null, `/explore?state=fixedPrice`);
     setBuyNowChecked(!isBuyNowChecked);
   } else if (isBuyNowChecked) {
     setBuyNowChecked(!isBuyNowChecked);
   } else {
     window.history.replaceState(null, null, `/explore`);
   }
   
  };

  // Buy Now 

  const onActionHandle = () => {
   if (isBuyNowChecked && !isOnAction) {
      window.history.replaceState(null, null, `/explore?state=both`);
     setOnAction(!isOnAction);
   } else if (isOnAction && isBuyNowChecked) {
     window.history.replaceState(null, null, `/explore?state=fixedPrice`);
     setOnAction(!isOnAction);
   } else if (!isOnAction) {
      window.history.replaceState(null, null, `/explore?state=auctionState`);
     setOnAction(!isOnAction);
   } else if (isOnAction) {
     setOnAction(!isOnAction);
   } else{
     window.history.replaceState(null, null, `/explore`);
    }
   
  }
  //custom routing
  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("state");
    console.log('gash', id)
    if (id) {
      if (id === 'both') {
      setTimeout(() => {
          setBuyNowChecked(true)
          setOnAction(true)
      }, 2000);
      }else if (id === 'fixedPrice') {
        setTimeout(() => {
          setBuyNowChecked(true)
        }, 2000);
      } else if (id === 'auctionState') {

        setTimeout(() => {
          setOnAction(true)
        }, 2000);
      }else if (id === 'forRent') {
        setTimeout(() => {
          setForRent(true)
        }, 2000);
        
      }
    }


  }, [])
  useEffect(async () => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("state");
    console.log('greaat',id)
    if (id === "trending") {
      getLiveAuction();
    } else if (id === "auction" ) {
      let dumArr = [];
      let dumArr2 = null;
      let arra = null;
      // console.log("typeof arra", typeof arra)
      if (typeof arra === Array) {
        dumArr2 = arra;
        setCategories(arra)
      }
      else if (categories.length === 0) {
        dumArr2 = ["Art", "Music", "Domain Names", "Virtual Worlds", "Trading Cards", "Photography", "Collectibles", "Sports", "Utility", "Other"];
      } else {
        dumArr2 = categories;
      }
      setOnAction(true);

      let higho;
      if (lprice !== 99999999999) {
        higho = true
      } else {
        higho = hl;
      }

      try {
        setLoader(true);
        var data = JSON.stringify({
          recentlyListed: rl,
          chainId: "97",
          high: higho,
          low: lh,
          recentlyCreated: rc,
          recentlySold: rs,
          recentlyRented: forRent,
          fromPrice: sprice,
          toPrice: lprice,
          category: dumArr2,
          limit: 1000
        });
        var config = {
          method: 'post',
          url: `${Api_URL}/token/getAllTokensFilter`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        axios(config).then(function (res) {
          // res.data.data = res?.data?.data?.reverse();
          window.history.replaceState(null, "Bluemoon", "/explore")
          if (res) {
            if (res?.data?.data?.length > 0 && res?.data?.data[0]?.tokens?.length > 0) {
              if (isBuyNowChecked && isOnAction) {
                res?.data?.data.filter((i) => {
                  if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "fixed" && i?.onSale === true) {
                    dumArr?.push(i);
                    return 0;
                  } else if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "auction" && i?.onSale === true) {
                    dumArr?.push(i);
                  }
                })
                setLoader(false);
                setRecentAddTokens(dumArr);
              } else if (isBuyNowChecked) {
                res?.data?.data.filter((i) => {
                  if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "fixed" && i?.tokens[0]?.onSale === true) {
                    let date1 = new Date();
                    let date2 = new Date(i?.tokens[0]?.metaData?.endTime)
                    if (date2.getTime() > date1.getTime()) {
                      dumArr?.push(i);
                    }
                    return 0;
                  }
                })
                setLoader(false);
                setRecentAddTokens(dumArr);
              } else if (isOnAction) {
                res?.data?.data.filter((i) => {
                  if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "auction" && i.tokens[0]?.onSale === true) {
                    let date1 = new Date();
                    let date2 = new Date(i?.tokens[0]?.metaData?.endTime)
                    if (date2.getTime() > date1.getTime()) {
                      dumArr?.push(i);
                    }
                    return 0;
                  }
                })
                setRecentAddTokens(dumArr);
                setLoader(false);
              } else {
                setRecentAddTokens(res?.data?.data);
                setLoader(false);
              }
            } else {
              if (isBuyNowChecked && isOnAction) {
                res?.data?.data.filter((i) => {
                  if (i?.metaData && i?.metaData?.method === "fixed" && i?.onSale === true) {
                    dumArr?.push(i);
                    return 0;
                  } else if (i?.metaData && i?.metaData?.method === "auction" && i?.onSale === true) {
                    dumArr?.push(i);
                  }
                })
                setLoader(false);
                setRecentAddTokens(dumArr);
              } else if (isBuyNowChecked) {
                res?.data?.data.filter((i) => {
                  if (i?.metaData && i?.metaData?.method === "fixed" && i?.onSale === true) {
                    let date1 = new Date();
                    let date2 = new Date(i?.metaData?.endTime)
                    if (date2.getTime() > date1.getTime()) {
                      dumArr?.push(i);
                    }
                    return 0;
                  }
                })
                setLoader(false);
                setRecentAddTokens(dumArr);
              } else if (isOnAction) {
                res?.data?.data.filter((i) => {
                  if (i?.metaData && i?.metaData?.method === "auction" && i?.onSale === true) {
                    let date1 = new Date();
                    let date2 = new Date(i?.metaData?.endTime)
                    if (date2.getTime() > date1.getTime()) {
                      dumArr?.push(i);
                    }
                    return 0;
                  }
                })
                setRecentAddTokens(dumArr);
                setLoader(false);
              } else {
                setRecentAddTokens(res?.data?.data);
                setLoader(false);
                setAuct(true);
              }
            }
          }
          setAuct(true);
        }).catch((err) => {
          setLoader(false);
        })
      } catch (error) {
        setLoader(false);
      }
    } else {
      callAPI();
    }
  }, [isOnAction, isBuyNowChecked])

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("state");
    if (forRent) {
      callAPI()
    } else if (id === "auction" && auct === true) {
      callAPI()
    } else if (id !== "auction") {
      callAPI()
    }
  }, [rc, lh, rl, rs, hl, categories, forRent])
  const functionTime = function (createdAt, noOfDays) {
    // Check if createdAt and noOfDays are present and valid
    if (!createdAt || isNaN(parseInt(noOfDays))) {
      // Return false if any of the required properties is missing or invalid
      return false;
    }

    // Convert createdAt to a Date object
    const createdDate = new Date(createdAt);

    // Check if createdDate is a valid date
    if (isNaN(createdDate.getTime())) {
      // Return false if createdAt is not a valid date
      return false;
    }

    // Calculate the end date by adding noOfDays to createdDate
    const endDate = new Date(createdDate);
    endDate.setDate(createdDate.getDate() + parseInt(noOfDays));

    // Get the current date
    const currentDate = new Date();

    // Check if the current date is within the range of createdDate and endDate
    const isAvailable = currentDate >= createdDate && currentDate <= endDate;

    return isAvailable;
  }
  const callAPI = async (arra) => {
    let dumArr = [];
    let dumArr2 = null;
    // console.log("typeof arra", typeof arra)
    if (typeof arra === Array) {
      dumArr2 = arra;
      setCategories(arra)
    }
    else if (categories.length === 0) {
      dumArr2 = ["Art", "Music", "Domain Names", "Virtual Worlds", "Trading Cards", "Photography", "Collectibles", "Sports", "Utility", "Other"];
    } else {
      dumArr2 = categories;
    }


    let higho;
    if (lprice !== 99999999999) {
      higho = true
    } else {
      higho = hl;
    }

    try {
      setLoader(true);
      var data = JSON.stringify({
        recentlyListed: rl,
        chainId: "97",
        high: higho,
        low: lh,
        recentlyCreated: rc,
        recentlySold: rs,
        recentlyRented: forRent,
        fromPrice: sprice,
        toPrice: lprice,
        category: dumArr2,
        limit: 1000

      });
      var config = {
        method: 'post',
        url: `${Api_URL}/token/getAllTokensFilter`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios(config).then(function (res) {
        if(!rc){
          //  res.data.data = res?.data?.data?.reverse();
        }
        if (res) {
          if (res?.data?.data?.length > 0 && res?.data?.data[0]?.tokens?.length > 0) {
            if (isBuyNowChecked && isOnAction) {
              // console.log("1call 0", res?.data?.data)
              res?.data?.data.filter((i) => {
                // console.log("iiiiiii", i)
                if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "fixed" && i?.tokens[0]?.onSale === true) {
                  dumArr?.push(i);
                  return 0;
                } else if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "auction" && i?.tokens[0]?.onSale === true) {
                  dumArr?.push(i);
                  return 0;
                }
              })
              setLoader(false);
              setRecentAddTokens(dumArr);
            } else if (isBuyNowChecked) {
              res?.data?.data.filter((i) => {
               
                if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "fixed" && i?.tokens[0]?.onSale === true) {
                  let date1 = new Date();
                  let date2 = new Date(i?.tokens[0]?.metaData?.endTime)
                  if (date2.getTime() > date1.getTime()) {
                    dumArr?.push(i);
                  }
                  return 0;
                }
              })
              setLoader(false);
              setRecentAddTokens(dumArr);
            } else if (isOnAction) {
              res?.data?.data.filter((i) => {
                if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "auction" && i.tokens[0]?.onSale === true) {
                  let date1 = new Date();
                  let date2 = new Date(i?.tokens[0]?.metaData?.endTime)
                  if (date2.getTime() > date1.getTime()) {
                    dumArr?.push(i);
                  }
                  return 0;
                }
              })
              setRecentAddTokens(dumArr);
              setLoader(false);
            } else {
         
              setRecentAddTokens(res?.data?.data);
              setLoader(false);
            }
          } else {
            if (isBuyNowChecked && isOnAction) {
              // console.log("1call 1")
              res?.data?.data.filter((i) => {
                if (i?.metaData && i?.metaData?.method === "fixed" && i?.onSale === true) {
                  dumArr?.push(i);
                  return 0;
                } else if (i?.metaData && i?.metaData?.method === "auction" && i?.onSale === true) {
                  dumArr?.push(i);
                  return 0;
                }
              })
              setLoader(false);
              setRecentAddTokens(dumArr);
            } else if (isBuyNowChecked) {
              res?.data?.data.filter((i,index) => {
              index===1 && console.log('buynow iitem', i)
                if (i?.metaData && i?.metaData?.method === "fixed" && i?.onSale === true) {
                  console.log('buynow iitem one', true)
                  let date1 = new Date();
                  let date2 = new Date(i?.metaData?.endTime)
                  console.log('buynow iitem two', i?.nftName, date1, date2)
                  // if (date2?.getTime() > date1?.getTime()) {
                    console.log('buynow iitem threee', true)
                    dumArr?.push(i);
                  // }
                  return 0;
                }
              })
              setLoader(false);
              setRecentAddTokens(dumArr);
            } else if (isOnAction) {
              res?.data?.data.filter((i) => {
                if (i?.metaData && i?.metaData?.method === "auction" && i?.onSale === true) {
                  let date1 = new Date();
                  let date2 = new Date(i?.metaData?.endTime)
                  if (date2.getTime() > date1.getTime()) {
                    dumArr?.push(i);
                  }
                  return 0;
                }
              })
                // toast.info("Please")
              setRecentAddTokens(dumArr);
              setLoader(false);
            } else {
              // console.log('daaatttaaa', res?.data?.data);

              const dumArray = res?.data?.data?.reduce((filteredArray, item) => {
                const rentOrder = item?.rentOrder && item.rentOrder[0];

                // Check if rentOrder and its properties are present
                const createdAt = rentOrder?.createdAt;
                const noOfDays = parseInt(rentOrder?.noOfDays);

                // Use the ternary operator to determine availability
                const available = createdAt && noOfDays && functionTime(createdAt, noOfDays);

                if (available) {
                  filteredArray.push(item);
                }
                console.log('daaatttaaa', available, filteredArray);

                return filteredArray;
              }, []);

              console.log('daaatttaaa filter', 34354);

              setRecentAddTokens(forRent ? dumArray : res?.data?.data);
              setLoader(false);
            }

          }
        }
      }).catch((err) => {
        setLoader(false);
      })
    } catch (error) {
      setLoader(false);
    }
  }
  console.log('daaatttaaa FilToks', recentAddTokens)
  const getLiveAuction = async () => {
    await axios.get(`${Api_URL}/token/topItems`)
      .then((res) => {
        window.history.replaceState(null, "Bluemoon", "/explore")
        setRecentAddTokens(res?.data?.data);
      })
      .catch((err) => {

      })
  }

  const clickFiltrerHandl = () => {
    buyNowHandle();
  }
  const clickFiltrerHandlAuc = () => {
    onActionHandle();
  }

  const GetTokensByPrice = async () => {
    if (sprice > 0 && lprice > 0) {
      setLoader(true);
      let tok = localStorage?.getItem("accessToken")
      var data = JSON.stringify({
        "fromPrice": parseFloat(sprice),
        "toPrice": parseFloat(lprice)
      });

      var config = {
        method: 'post',
        url: `${Api_URL}/order/searchTokenByPriceRange`,
        headers: {
          'Authorization': "Bearer " + tok,
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          setLoader(false);
          let toke = [];
          for (let i of response.data.result) {
            toke = [...toke, ...i.tokens]
          }
          for (let i of toke) {
            for (let io of response.data.result) {
              // console.log("ooo", i, io.contractAddress)
              if (i.contractAddress === io.contractAddress) {
                i.creators = io.creator;
              }
            }
          }
          setRecentAddTokens(toke);
        })
        .catch(function (error) {
          // console.log(error);
          setLoader(false);
        });
    }
  }

  // const Filters = async (val) => {
  //   let dumArr = categories;
  //   let dumObj = categories.find((i) => {
  //     return i === val;
  //   });
  //   if (dumObj) {
  //     dumArr = dumArr.filter((i) => {
  //       return i !== val;
  //     });
  //     setCategories(dumArr)
  //   } else {
  //     dumArr.push(val);
  //     setCategories(dumArr);
  //   }
  //   setLoader(true);
  //   const data = {
  //     category: dumArr,
  //   };
  //   setLoader(true);
  //   if (dumArr.length > 0) {
  //     try {
  //       const res = await axios.post(
  //         `${Api_URL}/token/getNftByCategory`,
  //         data,
  //         {
  //           headers: {},
  //         }
  //       );
  //       setLoader(false);
  //       setRecentAddTokens(res?.data?.data?.reverse());
  //     } catch (error) {
  //       setLoader(false);
  //     }
  //   } else {
  //     callAPI();
  //   }
  // };

  const ShowDefault = () => {
    let dumArr2 = null;
    // console.log("typeof arra", typeof arra)
    if (categories.length === 0) {
      dumArr2 = ["Art", "Music", "Domain Names", "Virtual Worlds", "Trading Cards", "Photography", "Collectibles", "Sports", "Utility", "Other"];
    } else {
      dumArr2 = categories;
    }

    try {
      setLoader(true);
      var data = JSON.stringify({
        recentlyListed: false,
        chainId: "97",
        high: false,
        low: false,
        recentlyCreated: false,
        recentlySold: false,
        recentlyRented: false,
        fromPrice: false,
        toPrice: false,
        category: dumArr2,
        limit: 1000
      });
      var config = {
        method: 'post',
        url: `${Api_URL}/token/getAllTokensFilter`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios(config).then(function (res) {
        if(!rc){
          //  res.data.data = res?.data?.data?.reverse();
        }
       
        if (res) {
          let dumArr = [];
          if (res?.data?.data?.length > 0 && res?.data?.data[0]?.tokens?.length > 0) {
            if (isBuyNowChecked && isOnAction) {
              res?.data?.data.filter((i) => {
                if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "fixed" && i?.onSale === true) {
                  dumArr?.push(i);
                  return 0;
                } else if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "auction" && i?.onSale === true) {
                  dumArr?.push(i);
                }
              })
              setLoader(false);
              setRecentAddTokens(dumArr);
            } else if (isBuyNowChecked) {
              res?.data?.data.filter((i) => {
                if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "fixed" && i?.tokens[0]?.onSale === true) {
                  let date1 = new Date();
                  let date2 = new Date(i?.tokens[0]?.metaData?.endTime)
                  if (date2.getTime() > date1.getTime()) {
                    dumArr?.push(i);
                  }
                  return 0;
                }
              })
              setLoader(false);
              setRecentAddTokens(dumArr);
            } else if (isOnAction) {
              res?.data?.data.filter((i) => {
                if (i?.tokens[0]?.metaData && i?.tokens[0]?.metaData?.method === "auction" && i.tokens[0]?.onSale === true) {
                  let date1 = new Date();
                  let date2 = new Date(i?.tokens[0]?.metaData?.endTime)
                  if (date2.getTime() > date1.getTime()) {
                    dumArr?.push(i);
                  }
                  return 0;
                }
              })
              setRecentAddTokens(dumArr);
              setLoader(false);
            } else {
              setRecentAddTokens(res?.data?.data);
              setLoader(false);
            }
          } else {
            if (isBuyNowChecked && isOnAction) {
              res?.data?.data.filter((i) => {
                if (i?.metaData && i?.metaData?.method === "fixed" && i?.onSale === true) {
                  dumArr?.push(i);
                  return 0;
                } else if (i?.metaData && i?.metaData?.method === "auction" && i?.onSale === true) {
                  dumArr?.push(i);
                }
              })
              setLoader(false);
              setRecentAddTokens(dumArr);
            } else if (isBuyNowChecked) {
              res?.data?.data.filter((i) => {
                if (i?.metaData && i?.metaData?.method === "fixed" && i?.onSale === true) {
                  let date1 = new Date();
                  let date2 = new Date(i?.metaData?.endTime)
                  if (date2.getTime() > date1.getTime()) {
                    dumArr?.push(i);
                  }
                  return 0;
                }
              })
              setLoader(false);
              setRecentAddTokens(dumArr);
            } else if (isOnAction) {
              res?.data?.data.filter((i) => {
                if (i?.metaData && i?.metaData?.method === "auction" && i?.onSale === true) {
                  let date1 = new Date();
                  let date2 = new Date(i?.metaData?.endTime)
                  if (date2.getTime() > date1.getTime()) {
                    dumArr?.push(i);
                  }
                  return 0;
                }
              })
              setRecentAddTokens(dumArr);
              setLoader(false);
            } else {
              setRecentAddTokens(res?.data?.data);
              setLoader(false);
            }
          }
        }
      }).catch((err) => {
        setLoader(false);
      })
    } catch (error) {
      setLoader(false);
    }
  }
  const formatTimeAgo = (timeString) => {
    const currentDate = new Date();
    const pastDate = new Date(timeString);

    const timeDifference = currentDate - pastDate;
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const monthsDifference = Math.floor(daysDifference / 30);
    const yearsDifference = Math.floor(daysDifference / 365);

    if (yearsDifference > 0) {
      return yearsDifference === 1 ? 'a year ago' : `${yearsDifference} years ago`;
    } else if (monthsDifference > 0) {
      return monthsDifference === 1 ? 'a month ago' : `${monthsDifference} months ago`;
    } else if (daysDifference > 0) {
      return daysDifference === 1 ? 'a day ago' : `${daysDifference} days ago`;
    } else if (hoursDifference > 0) {
      return hoursDifference === 1 ? 'an hour ago' : `${hoursDifference} hours ago`;
    } else if (minutesDifference > 0) {
      return minutesDifference === 1 ? 'a minute ago' : `${minutesDifference} minutes ago`;
    } else {
      return secondsDifference <= 10 ? 'just now' : `${secondsDifference} seconds ago`;
    }
  };
  const removeCataFunc=(item)=>{
    let res = categories?.filter((obj) => obj !== item)
    console.log('category', categories, res)
    setCategories(res)
  }


  return (
    <>
      {loader && <Loader />}
      <Header />
      <section className="explore explore-inner-card-set">
        <div className="container1920">
        
          <div className="row">
            <div className="col-md-12 ">
              <div className="filter-items">
                <div className="left-filter-icon">
                  <button className="filter-btn pl-0" onClick={() => setShowBtn(!showBtn)}>
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      className="change-my-color"
                    >
                      <path
                        d="M4 9.33301H28"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M8 16H24"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                      <path
                        d="M13.334 22.667H18.6673"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                  <button
                    className="filter-left-btn-mbl d-none"
                    data-toggle="modal"
                    data-target="#staticBackdrop"
                  >
                    {" "}
                    Filters
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="change-my-color"
                    >
                      <path
                        d="M3 7H21"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M6 11.5H18"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8 16.5H16"
                        stroke="white"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
                <div className="right-filter-drop">
                  <div className="dropdown">
                    <button
                      className="btn btn-secondaryss w-245 dropdown-toggle "
                      type="button"
                      id="dropdownMenuButton"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {sortRes()}
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <p className="cPointer" onClick={() => { ShowDefault(); setDef(true); setRl(false); setRc(false); setRs(false); setHl(false); setLh(false); setHl(false) }}>
                        Default
                      </p>
                      <p className="cPointer" onClick={() => { setDef(false); setRl(true); setRc(false); setRs(false); setHl(false); setLh(false); setHl(false) }}>
                        Recently listed
                      </p>
                      <p className="cPointer" onClick={() => { setDef(false); setRl(false); setRc(true); setRs(false); setHl(false); setLh(false); setHl(false) }}>
                        Recently created
                      </p>
                      <p className="cPointer" onClick={() => { setDef(false); setRl(false); setRc(false); setRs(true); setHl(false); setLh(false); setHl(false) }}>
                        Recently sold
                      </p>
                      <p className="cPointer" onClick={() => { setDef(false); setRl(false); setRc(false); setRs(false); setHl(false); setLh(true); setHl(false) }}>
                        Price: Low to High
                      </p>
                      <p className="cPointer" onClick={() => { setDef(false); setRl(false); setRc(false); setRs(false); setHl(false); setLh(false); setHl(true) }}>
                        Price: High to Low
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bdr"></div>
              <div className="row mx-auto">
                <div className="col-12 updated">
                  <h3>{recentAddTokens?.length > 0 && `Updated ${formatTimeAgo(recentAddTokens[0]?.updatedAt)}`}</h3>
                  {categories?.length > 0 ||  <p>{recentAddTokens?.length} items</p>}
                </div>
              </div>
              <div className="row">
                <div className={showBtn ? "col-xl-3 col-lg-3 col-12" : 'none_filters'}>
                  <Filter forRent={forRent} setForRent={setForRent} isBscChecked={isBscChecked} isEthChecked={isEthChecked} setEthChecked={setEthChecked} setBscChecked={setBscChecked} callAPI={callAPI} setLoader={setLoader} setRecentAddTokens={setRecentAddTokens} usd={usd} setUsd={setUsd} setCategories={setCategories} categories={categories} clickFiltrerHandl={clickFiltrerHandl} GetTokensByPrice={GetTokensByPrice} setLprice={setLprice} lprice={lprice} sprice={sprice} setSprice={setSprice} clickFiltrerHandlAuc={clickFiltrerHandlAuc} isBuyNowChecked={isBuyNowChecked} isOnAction={isOnAction} showBtn={showBtn} setBuyNowChecked={setBuyNowChecked} setOnAction={setOnAction}/>
                </div>
                <div className={showBtn ? "col-xl-9 col-lg-9 col-12" : "col-xl-12 m-auto"}>
                  <div className="exploreheadings">
                    <h4 className="mainex"></h4>
                  </div>
                  {isBuyNowChecked ?
                    <button className="filter-delete">
                      Buy Now
                      <svg
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="change-my-color"
                        onClick={() => buyNowHandle()}
                      >
                        <path
                          d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                          fill="white"
                        />
                        <path
                          d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                          fill="url(#paint0_linear_1297_38187)"
                        />
                        <path
                          d="M1 1.5249L9.68233 10.4752"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                          fill="white"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                          fill="url(#paint1_linear_1297_38187)"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1297_38187"
                            x1="5.34117"
                            y1="1.5249"
                            x2="5.34117"
                            y2="10.4752"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0090B4" />
                            <stop offset="1" stop-color="#103F91" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_1297_38187"
                            x1="5.34117"
                            y1="1.5249"
                            x2="5.34117"
                            y2="10.4752"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0090B4" />
                            <stop offset="1" stop-color="#103F91" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </button> :
                    null
                  }
                  {categories?.map((item, index) => {
                    return (
                      <button key={index} className="filter-delete">
                        {item}
                         <svg
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="change-my-color"
                        onClick={() => removeCataFunc(item) }
                      >
                        <path
                          d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                          fill="white"
                        />
                        <path
                          d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                          fill="url(#paint0_linear_1297_38187)"
                        />
                        <path
                          d="M1 1.5249L9.68233 10.4752"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                          fill="white"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                          fill="url(#paint1_linear_1297_38187)"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1297_38187"
                            x1="5.34117"
                            y1="1.5249"
                            x2="5.34117"
                            y2="10.4752"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0090B4" />
                            <stop offset="1" stop-color="#103F91" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_1297_38187"
                            x1="5.34117"
                            y1="1.5249"
                            x2="5.34117"
                            y2="10.4752"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0090B4" />
                            <stop offset="1" stop-color="#103F91" />
                          </linearGradient>
                        </defs>
                      </svg>
                      </button>
                    )
                  })}
                  {isOnAction ?
                    <button className="filter-delete">
                      Auction
                      <svg
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="change-my-color"
                        onClick={() => onActionHandle()}
                      >
                        <path
                          d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                          fill="white"
                        />
                        <path
                          d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                          fill="url(#paint0_linear_1297_38187)"
                        />
                        <path
                          d="M1 1.5249L9.68233 10.4752"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                          fill="white"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                          fill="url(#paint1_linear_1297_38187)"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1297_38187"
                            x1="5.34117"
                            y1="1.5249"
                            x2="5.34117"
                            y2="10.4752"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0090B4" />
                            <stop offset="1" stop-color="#103F91" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_1297_38187"
                            x1="5.34117"
                            y1="1.5249"
                            x2="5.34117"
                            y2="10.4752"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0090B4" />
                            <stop offset="1" stop-color="#103F91" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </button> :
                    null
                  }
                  {isEthChecked ?
                    <button className="filter-delete">
                      Ethereum
                      <svg
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="change-my-color"
                        onClick={() => setEthChecked(!isEthChecked)}
                      >
                        <path
                          d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                          fill="white"
                        />
                        <path
                          d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                          fill="url(#paint0_linear_1297_38187)"
                        />
                        <path
                          d="M1 1.5249L9.68233 10.4752"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                          fill="white"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                          fill="url(#paint1_linear_1297_38187)"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1297_38187"
                            x1="5.34117"
                            y1="1.5249"
                            x2="5.34117"
                            y2="10.4752"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0090B4" />
                            <stop offset="1" stop-color="#103F91" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_1297_38187"
                            x1="5.34117"
                            y1="1.5249"
                            x2="5.34117"
                            y2="10.4752"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0090B4" />
                            <stop offset="1" stop-color="#103F91" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </button> :
                    null
                  }
                  {isBscChecked ?
                    <button className="filter-delete">
                      BSC
                      <svg
                        width="11"
                        height="12"
                        viewBox="0 0 11 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="change-my-color"
                        onClick={() => setBscChecked(!isBscChecked)}
                      >
                        <path
                          d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                          fill="white"
                        />
                        <path
                          d="M1 1.5249L9.68233 10.4752L1 1.5249Z"
                          fill="url(#paint0_linear_1297_38187)"
                        />
                        <path
                          d="M1 1.5249L9.68233 10.4752"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                          fill="white"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752L9.68233 1.5249Z"
                          fill="url(#paint1_linear_1297_38187)"
                        />
                        <path
                          d="M9.68233 1.5249L1 10.4752"
                          stroke="white"
                          strokeWidth="2"
                          strokeLinecap="round"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1297_38187"
                            x1="5.34117"
                            y1="1.5249"
                            x2="5.34117"
                            y2="10.4752"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0090B4" />
                            <stop offset="1" stop-color="#103F91" />
                          </linearGradient>
                          <linearGradient
                            id="paint1_linear_1297_38187"
                            x1="5.34117"
                            y1="1.5249"
                            x2="5.34117"
                            y2="10.4752"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#0090B4" />
                            <stop offset="1" stop-color="#103F91" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </button> :
                    null
                  }
                  <div style={{ marginTop: "23px" }}></div>
                  <ExploreItem forRent={forRent} showBtn={showBtn} recentAddTokens={recentAddTokens} callAPI={callAPI} setLoader={setLoader} loader={loader} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Explore;
