import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
// import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';

const ExploreCollections = props => {
    const data = props?.data;
    const [show, setShow] = useState(9);

    const ToShow = () => {
        let sho = show + 3;
        if (sho > data.length) {
            setShow(data.length);
        } else {
            setShow(sho);
        }
    };

    return (
        <section className="tf-section popular-collection md-pt-0">
            <div className="container1920">
                {/* <div className="row"> */}
                    {/* <div className="col-md-12 md-p-0">
                        <div className="heading-live-auctions">
                            <h2 className="tf-title pb-22 text-left">
                                Popular Collection</h2>
                            <Link to="/collectionfirst" className="exp style2">EXPLORE MORE</Link>
                        </div>
                    </div> */}
                    {
                        data?.length >= 1 ?
                            <div className='row'>
                                {
                                    data?.slice(0, show)?.map((item, index) => (
                                        <PopularCollectionItem key={index} item={item} />
                                    ))
                                }
                                {
                                    data.length > 9 ? <>
                                    <div className="col-md-12 wrap-inner load-more text-center">
                                    <button onClick={() => ToShow()} className="sc-button loadmore fl-button pri-3"><span>{show === data?.length ? "NO MORE TO SHOW" : "LOAD MORE"}</span></button>
                                </div>
                                    </> : ""
                                }
                            </div>
                            :
                            <div className="no_item_display">
                            <img src="\assets\no-display.png" alt="img" className="img-fluid" />
                            <h5 className='text-light'>No Items To Display</h5>
                           </div>
                    }
                </div>
            {/* </div> */}
        </section>
    );
}
ExploreCollections.propTypes = {
    data: PropTypes?.array?.isRequired,
}

const PopularCollectionItem = props => (
    <div className="fl-item col-xl-4 col-lg-6 col-md-6 mobile-p-0">
        <div className="sc-card-collection style-2 home2">
            <div className="card-bottom">
                <div className="author">
                    <div className="sc-author-box style-2">
                        <div className="author-avatar">
                            <img src={props?.item?.logoImage} alt="" className="avatar" />
                            {props?.item?.creator.length > 0 &&
                                <>
                                    {props?.item?.creator[0]?.verified ?
                                        <div className="badge"><i className="ripple"></i></div>
                                        : null
                                    }
                                </>
                            }
                        </div>
                    </div>
                    <div className="content">
                        <h4><Link to={"/collections?id=" + props?.item?._id}>{props?.item?.name}</Link></h4>
                        <div className="infor">
                            <span>Created by</span>
                            <span className="name">
                                <>
                                    <Link to={"/user?id=" + props?.item?.creator[0]?.walletAddress}>
                                        {props?.item?.creator[0]?.userFullName ? props?.item?.creator[0]?.userFullName : props?.item?.creator[0]?.walletAddress?.slice(0, 12)}
                                    </Link>
                                </>
                            </span>
                        </div>
                    </div>
                </div>
                <Link to={"/collections?id=" + props?.item?._id} className="wishlist-button public heart"><span className="number-like"> {props?.item?.likedBy ? props?.item?.likedBy?.length : 0} </span></Link>
            </div>
            <Link to={"/collections?id=" + props?.item?._id}>
                <div className="media-images-collection">
                    <div className="box-left">
                        <img src={props?.item?.BannerImage} alt="axies" />
                    </div>
                    <div className="box-right">
                        <div className="top-img">
                            <img src={props?.item?.featuredImage} alt="axies" />
                            <img src={props?.item?.BannerImage} alt="axies" />
                        </div>
                        <div className="bottom-img">
                            <img src={props?.item?.logoImage} alt="axies" />
                        </div>
                    </div>
                </div>
            </Link>
        </div>
    </div>
)

export default ExploreCollections;
