import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import img1 from "../assets/images/box-item/card-item-10.jpg";
import img2 from "../assets/images/box-item/image-box-10.jpg";
import img3 from "../assets/images/box-item/image-box-11.jpg";
import img4 from "../assets/images/box-item/image-box-21.jpg";
import img5 from "../assets/images/box-item/image-box-6.jpg";
import { Api_URL } from "../utils/ApiURL";
import { useWeb3React } from "@web3-react/core";
import axios from "axios";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Activity01 = ({ userAcc }) => {

  const { account } = useWeb3React();

  const [all, setAll] = useState([]);
  const [sales, setSales] = useState([]);
  const [bids, setBids] = useState([]);
  const [listings, setListings] = useState([]);
  const [swaps, setSwaps] = useState([]);
  const [rents, setRents] = useState([]);
  const [rend, setRend] = useState(false);
  const [filt, setFilt] = useState(false);
  const [loader, setLoader] = useState(false);
  const [dataBox] = useState([
    {
      img: img1,
      title: "Monica Lucas",
      status: "started following",
      author: "Gayle Hicks",
      time: "At 2:30 PM on 19th June, 2021",
      icon: "icon-1",
    },
    {
      img: img2,
      title: "Wow! That Brain Is Floating",
      status: "10 editions listed by",
      author: "Meowbids",
      time: "At 2:30 PM on 19th June, 2021",
      icon: "icon-2",
    },
    {
      img: img3,
      title: "Erotic 35mm And Polaroid Photography",
      status: "started following",
      author: "Gayle Hicks",
      time: "At 2:30 PM on 19th June, 2021",
      icon: "icon-3",
    },
    {
      img: img4,
      title: "Our Journey Start",
      status: "started following",
      author: "Gayle Hicks",
      time: "At 2:30 PM on 19th June, 2021",
      icon: "icon-4",
    },
    {
      img: img5,
      title: "Skrrt Cobain Official",
      status: "started following",
      author: "Gayle Hicks",
      time: "At 2:30 PM on 19th June, 2021",
      icon: "icon-5",
    },
  ]);
  const [filts, setFilts] = useState({
    sales: true,
    listings: true,
    bids: true,
    swaps: true,
    rents: true,
  });
  const [visible, setVisible] = useState(5);
  const showMoreItems = () => {
    setVisible((prevValue) => prevValue + 5);
  };

  useEffect(() => {
    if (account) {
      getActivity();
    }
  }, [account, userAcc]);

  const UpdateFilts = (val) => {
    let dumObj = { ...filts };
    let dumArr = [];
    let dumArr2 = [];
    let dumArr3 = [];
    let dumArr4 = [];
    let dumArr5 = [];

    if (val === "bid") {
      dumObj.bids = !dumObj.bids;
    } else if (val === "sale") {
      dumObj.sales = !dumObj.sales;
    } else if (val === "list") {
      dumObj.listings = !dumObj.listings;
    } else if (val === "swap") {
      dumObj.swaps = !dumObj.swaps;
    } else if (val === "rent") {
      dumObj.rents = !dumObj.rents;
    }

    if (dumObj.sales) {
      dumArr = [...sales];
    }
    if (dumObj.bids) {
      dumArr2 = [...bids];
    }
    if (dumObj.listings) {
      dumArr3 = [...listings];
    }
    if (dumObj.swaps) {
      dumArr4 = [...swaps];
    }
    if (dumObj.rents) {
      dumArr5 = [...rents];
    }

    setAll([...dumArr, ...dumArr2, ...dumArr3, ...dumArr4, ...dumArr5]);
    setFilts(dumObj);
    setRend(!rend);
  };


  const getActivity = async () => {
    let tok = localStorage.getItem("accessToken");
    // setLoader(true);
    var data = JSON.stringify({
      walletAddress: userAcc || account,
    });
    var config = {
      method: "post",
      url: `${Api_URL}/token/Activity`,
      headers: {
        Authorization: "Bearer " + tok,
        "Content-Type": "application/json",
      },
      data: data,
    };
    await axios(config)
      .then(function (response) {
        setSales(response?.data?.sale);
        setBids(response?.data?.bid);
        setListings(response?.data?.listing);
        setSwaps(response?.data?.swap)
        setRents(response?.data?.rent)
        setFilts({
          sales: true,
          listings: true,
          bids: true,
          swaps: true,
          rents: true,
        });
        let arrA = [
          ...response?.data?.bid,
          ...response?.data?.sale,
          ...response?.data?.listing,
          ...response?.data?.swap,
          ...response?.data?.rent,
        ];
        if (arrA.length > 0) {
          setFilt(true)
        }
        setAll(arrA?.reverse());
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  };
  const formatTimeAgo = (timeString) => {
    const currentDate = new Date();
    const pastDate = new Date(timeString);

    const timeDifference = currentDate - pastDate;
    const secondsDifference = Math.floor(timeDifference / 1000);
    const minutesDifference = Math.floor(secondsDifference / 60);
    const hoursDifference = Math.floor(minutesDifference / 60);
    const daysDifference = Math.floor(hoursDifference / 24);
    const monthsDifference = Math.floor(daysDifference / 30);
    const yearsDifference = Math.floor(daysDifference / 365);

    if (yearsDifference > 0) {
      return yearsDifference === 1 ? 'a year ago' : `${yearsDifference} years ago`;
    } else if (monthsDifference > 0) {
      return monthsDifference === 1 ? 'a month ago' : `${monthsDifference} months ago`;
    } else if (daysDifference > 0) {
      return daysDifference === 1 ? 'a day ago' : `${daysDifference} days ago`;
    } else if (hoursDifference > 0) {
      return hoursDifference === 1 ? 'an hour ago' : `${hoursDifference} hours ago`;
    } else if (minutesDifference > 0) {
      return minutesDifference === 1 ? 'a minute ago' : `${minutesDifference} minutes ago`;
    } else {
      return secondsDifference <= 10 ? 'just now' : `${secondsDifference} seconds ago`;
    }
  };
  console.log('filts', filts, swaps,)
  console.log('filts all', all)
  return (
    <div>
      {/* <Header /> */}
      {/* <section className="flat-title-page inner">
                <div className="overlay"></div>
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="page-title-heading mg-bt-12">
                                <h1 className="heading text-center">Activity 1</h1>
                            </div>
                            <div className="breadcrumbs style2">
                                <ul>
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="#">Activity</Link></li>
                                    <li>Activity 1</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>                    
            </section> */}
      <section className="tf-activity s1 tf-section p-0">
        <div className="themesflat-container">
          <>
            {/* {all?.length > 0 ? ( */}
            <div className="row">
              <div className="col-lg-8 col-md-8 col-12 order-sm2">
                {all?.length > 0 ? <>
                  {all?.map((item, index) => (
                    <div key={index}>
                      {item?.tokens?.length > 0 &&
                        <Link to={"/item-details?id=" + item?.tokens[0]?._id}>
                          <div className="sc-card-activity style1">
                            <div className="content">
                              <div className="media">
                                <img
                                  src={
                                    item?.tokens[0]?.bulk === true
                                      ? item?.tokens[0]?.imageUrl
                                      : item?.tokens[0]?.image
                                  }
                                  alt="Axies"
                                  className="img-fluid w-100 h-100"
                                  style={{ objectFit: "cover" }}
                                />
                              </div>
                              <div className="infor">
                                <h3>
                                  {" "}
                                  <Link to={"/item-details?id=" + item?.tokens[0]?._id}>
                                    {item?.tokens[0]?.nftName}
                                  </Link>
                                </h3>
                                {item?.price && <p className="time">     Price {item?.price} BNB</p>}

                                {/* <div className="status">{item.status} <span className="author">{item.author}</span></div> */}
                                {/* <div className="time">{item.time}</div> */}
                                {item?.bidderAddress ? (
                                  <div className="status">
                                    Swap by {item?.bidderAddress?.slice(0, 12)}...
                                  </div>
                                ) : (
                                  <>
                                    {item?.sellerAddress ? (
                                      <div className="status">
                                        transferred from{" "}
                                        {item?.sellerAddress?.slice(0, 12)}... to{" "}
                                        {item?.buyerAddress?.slice(0, 12)}...
                                      </div>
                                    ) : (
                                      <div className="status">
                                        Listed by {item?.ownerAddress?.slice(0, 12)}
                                        ...
                                      </div>
                                    )}
                                  </>
                                )}
                                <p className="time"> {formatTimeAgo(item?.time)} </p>
                              </div>
                            </div>
                            {/* <div className={`button-active icon ${item.icon}`}></div> */}
                          </div>
                        </Link>
                      }
                    </div>
                  ))}
                  :
                </>
                  :
                  <div className="no_item_display">
                    <img
                      src="\assets\display-activity.png"
                      alt="img"
                      className="img-fluid"
                    />
                    <h5>No Activity To Display</h5>
                  </div>
                }
              </div>
              {filt &&
                <div className="col-lg-4 col-md-4 col-12 mt-4">
                  <div id="side-bar" className="side-bar style-2">
                    <div className="widget widget-filter style-2 mgbt-0">
                      <h3 className="title-widget">Filter</h3>
                      <ul className="box-check">
                        <li onClick={() => UpdateFilts("list")}>
                          <Link
                            to="#"
                            className={
                              filts.listings
                                ? "box-widget-filter box__active "
                                : "box-widget-filter"
                            }
                          >
                            <i className={"icon-fl-sort-filled"}></i>Listings
                          </Link>
                        </li>
                        <li onClick={() => UpdateFilts("sale")}>
                          <Link
                            to="#"
                            className={
                              filts.sales
                                ? "box-widget-filter box__active "
                                : "box-widget-filter"
                            }
                          >
                            <i className={"icon-fl-discount"}></i>Sales
                          </Link>
                        </li>
                        <li onClick={() => UpdateFilts("bid")}>
                          <Link
                            to="#"
                            className={
                              filts.bids
                                ? "box-widget-filter box__active "
                                : "box-widget-filter"
                            }
                          >
                            <i className={"icon-fl-credit-card"}></i>Bids
                          </Link>
                        </li>
                        <li onClick={() => UpdateFilts("swap")}>
                          <Link
                            to="#"
                            className={
                              filts.swaps
                                ? "box-widget-filter box__active "
                                : "box-widget-filter"
                            }
                          >
                            <i className={"icon-fl-credit-card"}></i>Swap
                          </Link>
                        </li>
                        <li onClick={() => UpdateFilts("rent")}>
                          <Link
                            to="#"
                            className={
                              filts.rents
                                ? "box-widget-filter box__active "
                                : "box-widget-filter"
                            }
                          >
                            <i className={"icon-fl-credit-card"}></i>Rent
                          </Link>
                        </li>
                      </ul>
                      {/* <Link to="#" className="clear-check btn-filter style-2">
                                        Clear All Filters
                                    </Link> */}
                    </div>
                  </div>
                </div>
              }
            </div>
            {/* // ) : (
            //   <div className="no_item_display">
            //     <img
            //       src="\assets\display-activity.png"
            //       alt="img"
            //       className="img-fluid"
            //     />
            //     <h5>No Activity To Display</h5>
            //   </div>

            // )} */}

          </>
        </div>
      </section>
      {/* <Footer /> */}
    </div>
  );
};

export default Activity01;
