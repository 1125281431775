import { useCallback, useState, useEffect } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

const BuyTransfer = () => {
    const web3 = useWeb3();
    // const contractAddress = environment.MarketContract;
    const [contractAddress, setContractAddress] = useState(null);
    const context = useWeb3React();
    const { chainId } = context;

    useEffect(() => {
        if (chainId === 97) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 4) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 43114) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 80001) {
            setContractAddress(environment.marketPlaceContract);
        }
    }, [chainId])

    const contract = getMarketContract(contractAddress, web3);
    const BuyTrans = useCallback(
        async (account, values, values2, values1, contractAddress, royaltiesAddress, percentageFee, offerTime) => {
            // // console.log("account, values, values2, values1, contractAddress, royaltiesAddress, percentageFee, offerTime", account, values, values2, values1, contractAddress, royaltiesAddress, percentageFee, offerTime);
            if (contractAddress) {
                try {
                    const gas = await contract.methods
                        .BuyAndTransfer(values, values2, values1, contractAddress, royaltiesAddress, percentageFee, offerTime)
                        .estimateGas({ from: account, value: values._amount });
                    let gasPrice = await web3.eth.getGasPrice();
                    const details = await contract.methods
                        .BuyAndTransfer(values, values2, values1, contractAddress, royaltiesAddress, percentageFee, offerTime)
                        .send({
                            from: account,
                            value: values._amount,
                            gas,
                            gasPrice: gasPrice
                        })
                    return details;
                } catch (error) {
                    // console.log(error)
                    throw (error)
                }
            }
        },
        [contract, contractAddress]
    );
    return { BuyTrans: BuyTrans };
};

export default BuyTransfer;