let Environment = {
  CollectionContract: "0xA49c4106C745af2dC483D0DB409C0F871878e9b9", //bluemoon
  fectoryContract: "0x3DfFEc54E630865fD21E68876448C215c6fdE821",
  marketPlaceContract: "0xeaB41Acd98D8Ce961E2239AcAac0Ec55BDB8Ce06",
  weth: "0x7094E2162c9e93345F806426907310cc00CC25ba",
  bulkTransfer: "0x157DC5a1db3B9a96dc81B87a978D839A28b61Afb",
  swap: "0xa4F8b9Fc76e3a5Bf7f4671fa93A261aA4D90C27A", // 0x9Be01d3793e8501408CC077031AA19D97454aEc8
  renting: '0x7B02B30044d16CF3372d31Df093C37406c9f0F96',
  Eth: {
    CollectionContract: "0x697A3A28B7E8cA3E4083a97F69Ae58280285C3E1",
    fectoryContract: "0xFB241C9f9Ba897a68EcA7b4a83c92e0754Bc70c1",
    marketPlaceContract: "0xBdb1324269b4b1dA3552dc93E0f29468b18f03A3",
    weth: "0xB68Dc56a6a04c08f847b12eDfBa2ec617334d36c"
  }
};
export default Environment;
