import { useCallback, useState, useEffect } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

const BuyMint = () => {
    const web3 = useWeb3();
    // const contractAddress = environment.MarketContract;
    const [contractAddress, setContractAddress] = useState(null);
    const context = useWeb3React();
    const { chainId } = context;

    useEffect(() => {
        if (chainId === 97) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 4) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 43114) {
            setContractAddress(environment.marketPlaceContract);
        } else if (chainId === 80001) {
            setContractAddress(environment.marketPlaceContract);
        }
    }, [chainId])

    const contract = getMarketContract(contractAddress, web3);
    const BuyMinte = useCallback(
        async (account, values, image, signS, signB, contractAddress, offTime) => {
            // account, web3.utils.toWei(nft?.metaData?.price.toString()), values, image1, values2, values1, nft?.contractAddress, nft?.metaData?.startTime
            if (contractAddress) {
                try {
                    // // console.log("seller, buyer, amount, uri, tokenId, deadline, v, r, s, contaddress", account, values, image, signS, signB, contractAddress, offTime)
                    const gas = await contract.methods
                        .BuyAndMint(values, image, signS, signB, contractAddress, offTime)
                        .estimateGas({ from: account, value: values._amount });
                    let gasPrice = await web3.eth.getGasPrice();
                    const details = await contract.methods
                        .BuyAndMint(values, image, signS, signB, contractAddress, offTime)
                        .send({
                            from: account,
                            value: values._amount,
                            gas,
                            gasPrice: gasPrice
                        })
                    return details;
                } catch (error) {
                    // console.log(error)
                    throw error;
                }
            }
        },
        [contract]
    );
    return { BuyMinte: BuyMinte };
};

export default BuyMint;