import React, { useState } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import contractAddressIcon from '../../assets/contract-address-copy-icon.svg';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { Api_URL } from "../../utils/ApiURL";
import Loader from "../../hooks/loader";
import { useWeb3React } from "@web3-react/core";

const ImportCollection = () => {
  const [cAddress, setCAddress] = useState()
  let navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const context = useWeb3React();
  const { account } = useWeb3React();
  const { chainId } = context;

  const importCollection = async () => {
    let token = localStorage.getItem("accessToken")
    setLoader(true);
    if (account && chainId && cAddress) {
      var data = {
        contractAddress: cAddress,
        chainId: chainId?.toString(),
        walletAddress: account
      }
      var config = {
        method: "post",
        url: `${Api_URL}/token/importCollectionsByContractAddress`,
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json"
        },
        data: data
      };
      await axios(config)
        .then(function (response) {
          toast.success(response?.data?.message)
          window.$('#exampleModal').modal('hide')
          navigate('/myprofile')
          setLoader(false);
        }).catch((error) => {
          setLoader(false);
          console.log('eeeeeeeeee', error)
          toast.error(error?.response?.data?.err?.message || 'Collection Address Invalid !');
        })
    } else {
      setLoader(false);
      if (!account) {
        toast.info('Connect Your Wallet')
      } else if (!chainId) {
        toast.info('ChainId is missing')
      } else if (!cAddress) {
        toast.info('Contract Address is missing')
      }
    }
  }

  return (
    <>
      <Header />
      {loader && <Loader />}
      <section className="main-Authoronsale ImportCollection-wrpper">
        <div className="container">
          <div className="ImportCollection-inner">
            <div className="ImportCollection-header">
              <h2>Import Collection</h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Laborum obcaecat.
              </p>
            </div>
            <div className="ImportCollection-body">
              <p>Contract Address</p>
              <div className="importCollection-by-contract-address">
                <div className="importCollection-contract-input">
                  <img src={contractAddressIcon} alt="contractAddressIcon" />
                  <input onChange={(e) => setCAddress(e.target.value)} placeholder="Enter contract Address" />
                </div>
                <button onClick={importCollection}>
                  Import Collection <img src="" alt="" />{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ImportCollection;
