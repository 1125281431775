import React, { useEffect, useState } from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { InjectedConnector } from '@web3-react/injected-connector';
import { useWeb3React } from '@web3-react/core';

const POLLING_INTERVAL = 8000;
const RPC_URLS = {
    5: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    97: 'https://data-seed-prebsc-1-s2.binance.org:8545',
    137: 'https://rpc-mainnet.maticvigil.com/'
};

const injected = new InjectedConnector({
    supportedChainIds: [5, 137, 97]
});

const walletconnect = new WalletConnectConnector({
    rpc: { 97: RPC_URLS[97], 5: RPC_URLS[5], 137: RPC_URLS[137] },
    pollingInterval: POLLING_INTERVAL
});

function getLibrary(provider) {
    const library = new Web3Provider(provider);
    library.pollingInterval = POLLING_INTERVAL;
    return library;
}

function App() {
    const { activate, active, account, chainId, library } = useWeb3React();

    const [connecting, setConnecting] = useState(false);

    const connectWallet = async (connector) => {
        try {
            setConnecting(true);
            await activate(connector, undefined, true);
        } catch (error) {
            console.error('Error connecting wallet:', error);
        } finally {
            setConnecting(false);
        }
    };

    useEffect(() => {
        if (!active) {
            // Automatically connect with WalletConnect on app load
            connectWallet(walletconnect);
        }
    }, [active]);

    return (
        <div>
            <h1>WalletConnect v2 React App</h1>
            {connecting && <p>Connecting...</p>}
            {active && (
                <div>
                    <p>Connected to {chainId}</p>
                    <p>Account: {account}</p>
                </div>
            )}
            {!active && (
                <div>
                    {/* <button onClick={() => connectWallet(injected)}>Connect with MetaMask</button> */}
                    <button onClick={() => connectWallet(walletconnect)}>Connect with WalletConnect</button>
                </div>
            )}
        </div>
    );
}

function Web3Wrapper() {
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <App />
        </Web3ReactProvider>
    );
}

export default Web3Wrapper;
