import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getCollectionContract } from "../../utils/contractHelpers";

const AmountApprove = () => {
    const web3 = useWeb3();

    const rentContract = environment.renting
    const UserApprove = useCallback(
        async (tokenId, address, account) => {
            const tokenAddress = address;
            const contract = getCollectionContract(tokenAddress, web3);
            console.log("errorrrr====>", tokenId, rentContract)
            try {
                const detailsSupply = await contract.methods
                    .ownerOf(tokenId)
                    .call()
                console.log("suppley", detailsSupply)
                const gas = await contract.methods
                    .approve(rentContract, tokenId)
                    .estimateGas({ from: account });
                let gasPrice = await web3.eth.getGasPrice();
                const details = await contract.methods
                    .approve(rentContract, tokenId)
                    .send({
                        from: account,
                        gas,
                        gasPrice: gasPrice
                    })

                return details;
            } catch (error) {
                console.log("errorrrr11111111", error, tokenId, rentContract)
                throw (error)
            }
        },
        [web3]
    );
    return { UserApprove: UserApprove };
};
export default AmountApprove;