import React from 'react'
import { Link } from 'react-router-dom'
import "./trendingcard.scss"
// import { useWeb3React } from '@web3-react/core'

const TrendingCard = ({ data }) => {
    // const [leng, setLeng] = useState(6);
    // const context = useWeb3React();
    // const { chainId } = context;

    // const ShowMore = () => {
    //     let val = leng + 3;
    //     if (val > data.length) {
    //         setLeng(data.length);
    //     } else {
    //         setLeng(val);
    //     }
    // }

    return (
        <>
            <section className='trendingcardmobile'>
                <div className="container p-0">
                    <div className="trend-cards">
                        {data?.slice(0, 6)?.map((item, index) => {
                            return (
                                <div key={index} className="card-inner">
                                    <div className="img-main">
                                        <Link to={"/collections?id=" + item?._id}>
                                            <img src={item?.logoImage} alt="img" className='img-fluid' />
                                        </Link>
                                    </div>
                                    <div className="bottom-content">
                                        <div className="profile">
                                            <Link to={"/user?id=" + item?.ownerAddress}>
                                                <img src={item?.creator[0]?.picture} alt="img" className='img-fluid' />
                                            </Link>
                                        </div>
                                        <div className="text">
                                            <Link to={"/user?id=" + item?.ownerAddress}>
                                                <h6><span>Created by</span> {item?.creator[0]?.userFullName ? item?.creator[0]?.userFullName : item?.creator[0]?.walletAddress?.slice(0, 3) + '...' + item?.creator[0]?.walletAddress?.slice(item?.creator[0]?.walletAddress.length - 3, item?.creator[0]?.walletAddress.length)}</h6>
                                            </Link>
                                            <Link to={"/item-details?id=" + item?._id}>
                                                <h5>{item?.nftName}</h5>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {/* <button onClick={() => ShowMore()} className='seemore'>{leng.length === data.leng ? "No more to show" : "See More"}</button> */}
                </div>
            </section>
        </>
    )
}

export default TrendingCard