import React, { useState, useEffect } from 'react';
import "./collections.scss";
import Button from 'react-bootstrap/Button';
// import Offcanvas from 'react-bootstrap/Offcanvas';
import "../MyProfile/myprofile.scss"
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import ExploreItem from "../../components/layouts/explore-04/ExploreItem";
import todayPickData from "../../assets/fake-data/data-today-pick";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { Api_URL } from '../../utils/ApiURL';
import Loader from '../../hooks/loader';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core';
import { Link } from "react-router-dom";
import moment from 'moment'
import { toast, ToastContainer } from 'react-toastify';
import Cofilter from '../Cofilter/Cofilter';
import Treasure from '../donutgraph/donutgraph/Treasure';
import Zoomable from '../Zoomabletimeseries/Zoomable';
import Bubblecharts from '../Bubblecharts/Bubblecharts';
import Columnchart from '../Column with Data Labels/Columnchart ';
import ActivitiesTab from "./activitiesTab";
import Tooltip from "react-bootstrap/Tooltip";
import { OverlayTrigger } from 'react-bootstrap';
import CopyToClipboard from 'react-copy-to-clipboard';

const Collections = () => {
  const [showBtn, setShowBtn] = useState(false);
  const [key, setKey] = useState('home');
  const { account } = useWeb3React();
  const [loader, setLoader] = useState(false);
  const [reveal, setReveal] = useState(false);
  const [colls, setColls] = useState(null);
  const [nfts, setNfts] = useState([]);
  const [onsale, setOnsale] = useState(false);
  const [onauction, setOnauction] = useState(false);
  const [sales, setSales] = useState([]);
  const context = useWeb3React();
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [token, setToken] = useState("");
  const [sprice, setSprice] = useState("0");
  const [lprice, setLprice] = useState("10000000");
  const { chainId } = context;
  const [id, setId] = useState(null);
  const [search, setSearch] = useState("");
  const [contractId, setContractId] = useState(null);
  const [timeFilts, setTimeFilts] = useState("Recently added");
  const [tab, setTab] = useState(0);
  const [queryBy, setQueryBy] = useState("year");
  const [salesCharts, setSalesCharts] = useState(null);
  const [prVoCharts, setPrVoCharts] = useState(null);
  const [priDis, setPriDis] = useState(null);
  const [owners, setOwners] = useState([]);
  const [floorPrice, setFloorPrice] = useState([]);
  const [listings, setListings] = useState([]);
  const [sales0, setSales0] = useState([]);
  const [timeline, setTimeline] = useState("year")
  const [ownDis, setOwnDis] = useState([]);
  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (account) {
      let tok = localStorage.getItem("accessToken")
      setToken(tok);
    }
  }, [account]);

  useEffect(() => {
    var val = window.location.href;
    val = new URL(val);
    var id = val.searchParams.get("id");
    setId(id);
    if (id !== null) {
      setLoader(true);
      var qs = require("qs");
      var data = qs.stringify({
        _id: id,
        "chainId": chainId?.toString()
      });
      var config = {
        method: "post",
        url: `${Api_URL}/collection/getCollectionById`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: data
      };

      axios(config)
        .then(function (response) {
          setContractId(response.data.data[0]?.contractAddress)
          // GetContract(response.data.data[0]?.contractAddress);
          setLoader(false);
          setReveal(response.data.data[0]?.reveal);
          setColls(response.data.data[0]);
          let dumArr = response.data.data[0].likedBy;
          let Find = dumArr.find((e) => {
            return e.address === account?.toLowerCase()?.toString();
          })
          if (Find) {
            setLiked(true);
          }
        })
        .catch(function (error) {
          setLoader(false);
          // console.log(error);
        });
    }
  }, [account]);

  const GetContract = (id) => {
    var qs = require("qs");
    var data = qs.stringify({
      contractAddress: id,
      chainId: chainId?.toString()
    });
    var config = {
      method: "post",
      url: `${Api_URL}/token/getAllTokensOfCollection`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setLoader(false);
        // setNfts(response.data.data);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log(error);
      });
  };

  const GetFilterContract = (val) => {
    // console.log("ASASASAS", sprice, lprice)
    var qs = require("qs");
    let valu = val;
    if (val) {
      setTimeFilts(val);
    } else {
      valu = "Recently added"
    }
    let data = JSON.stringify({
      "contractAddress": contractId,
      "category": ["Art", "Music", "Domain Names", "Virtual Worlds", "Trading Cards", "Photography", "Collectibles", "Sports", "Utility", "Other"],
      "auctionEndingSoon": onauction,
      // "recentlyCreated": valu === "Recently added" ? true : false,
      "recentlyCreated": false,
      "recentlySold": valu === "Recently Sold" ? true : false,
      "recentlyListed": onsale,
      "fromPrice": sprice?.toString(),
      "toPrice": lprice?.toString(),
      "search": search,
      "limit": 500,
    });
    let config = {
      method: 'post',
      url: `${Api_URL}/collection/getCollectionFilter`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        setNfts(response?.data?.data);
        setLoader(false);
      })
      .catch((error) => {
        // console.log(error);
        setLoader(false);
      });
  };

  useEffect(() => {
    if (onsale || onauction) {
      GetFilterContract();
    } else if (contractId) {
      GetFilterContract();
    }
  }, [onsale, onauction])

  useEffect(() => {
    if (contractId) {
      GetFilterContract();
      getActivity("day");

      // chart api calls
      GetSalesChart();
      GetPriceVolumeChart();
      GetPriceDistribution();
      GetFloorPriceChart();

      // listing api calls
      GetOwnerListings();
      GetListings();
      GetTotalSales();
    }
  }, [contractId, timeline])

  const getActivity = async (val) => {
    let tok = localStorage.getItem("accessTok");
    setLoader(true);
    setQueryBy(val);
    var data = JSON.stringify({
      contractAddress: contractId,
      queryBy: val
    });
    var config = {
      method: "post",
      url: `${Api_URL}/collection/totalSales`,
      headers: {
        Authorization: "Bearer " + tok,
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setSales(response?.data)
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  };

  const LikeNft = () => {
    if (account) {
      var data = JSON.stringify({
        "walletAddress": account,
        "_id": colls?._id,
        "contractAddress": colls?.contractAddress
      });
      var config = {
        method: 'post',
        url: `${Api_URL}/collection/likecollection`,
        headers: {
          Authorization: "Bearer " + token,
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios(config)
        .then(function (response) {
          setLiked(true);
          setLikes(1);
        })
        .catch(function (error) {
          setLiked(false);
          // console.log(error);
        });
    } else {
      toast.error("Please connect your wallet")
    }
  }

  const UnLikeNft = () => {
    if (account) {
      var data = JSON.stringify({
        "walletAddress": account,
        "_id": colls?._id,
        "contractAddress": colls?.contractAddress
      });
      setLiked(false);
      if (likes === 1) {
        setLikes(0);
      } else {
        setLikes(-1);
      }
      setLiked(false);
      var config = {
        method: 'post',
        url: `${Api_URL}/collection/unlikecollection`,
        headers: {
          Authorization: "Bearer " + token,
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios(config)
        .then(function (response) {
          setLiked(0)
        })
        .catch(function (error) {
          setLiked(true);
          setLikes(1);
          // console.log(error);
        });
    } else {
      toast.error("Please connect your wallet")
    }
  }

  const GetPriceVolumeChart = async () => {
    setLoader(true);
    var data = JSON.stringify({
      contractAddress: contractId,
      queryBy: timeline
    });
    var config = {
      method: "post",
      url: `${Api_URL}/collection/getVolumeAndPriceChart`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setPrVoCharts(response?.data?.totalSales);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  }

  const GetFloorPriceChart = async () => {
    setLoader(true);
    var data = JSON.stringify({
      contractAddress: contractId,
      queryBy: timeline
    });

    var config = {
      method: "post",
      url: `${Api_URL}/collection/getFloorPriceChart`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setFloorPrice(response?.data?.floorPrices);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  }

  const GetPriceDistribution = async () => {
    setLoader(true);
    var data = JSON.stringify({
      contractAddress: contractId,
      queryBy: timeline
    });
    var config = {
      method: "post",
      url: `${Api_URL}/collection/getPriceDistributionChart`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setPriDis(response?.data);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  }

  const GetSalesChart = async () => {
    setLoader(true);
    var data = JSON.stringify({
      contractAddress: contractId,
      queryBy: timeline
    });

    var config = {
      method: "post",
      url: `${Api_URL}/collection/getSalesChart`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setSalesCharts(response?.data?.totalSales);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  }

  const GetOwnerListings = async () => {
    setLoader(true);
    var data = JSON.stringify({
      contractAddress: contractId,
    });

    var config = {
      method: "post",
      url: `${Api_URL}/collection/getAllOwners`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setOwners(response?.data?.users)
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  }

  const GetListings = async () => {
    setLoader(true);
    var data = JSON.stringify({
      contractAddress: contractId,
      queryBy: timeline
    });

    var config = {
      method: "post",
      url: `${Api_URL}/collection/getAllListings`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setListings(response?.data?.listing);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  }

  const GetTotalSales = async () => {
    setLoader(true);
    var data = JSON.stringify({
      contractAddress: contractId,
      queryBy: timeline
    });

    var config = {
      method: "post",
      url: `${Api_URL}/collection/totalCollectionSales`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        setSales0(response?.data?.totalSales);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  }
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {copied ? "copied" : "copy"}
    </Tooltip>
  );
  useEffect(() => {
    if (contractId) {
      GetOwnerDist();
    }
  }, [contractId])

  const GetOwnerDist = async () => {
    setLoader(true);
    var data = JSON.stringify({
      contractAddress: contractId
    });

    var config = {
      method: "post",
      url: `${Api_URL}/collection/getAllTokenOwnersDistribution`,
      headers: {
        "Content-Type": "application/json"
      },
      data: data
    };
    await axios(config)
      .then(function (response) {
        let val1to10 = 0;
        let val10to20 = 0;
        let val20to50 = 0;
        let val50plus = 0;
        for (let i of response?.data?.tokens) {
          if (i.tokenCount < 10) {
            val1to10 = val1to10 + i?.OwnerCount;
          } else if (i.tokenCount < 20) {
            val10to20 = val10to20 + i?.OwnerCount;
          } else if (i.tokenCount < 50) {
            val20to50 = val20to50 + i?.OwnerCount;
          } else if (i.tokenCount > 49) {
            val50plus = val50plus + i?.OwnerCount;
          }
        }
        let total = 0;
        if (response?.data?.totalOwners?.length > 0) {
          total = response?.data?.totalOwners[0]?.totalOwners;
        }
        setOwnDis({
          secOne: val1to10,
          secTwo: val10to20,
          secThree: val20to50,
          secFour: val50plus,
          totalOwners: total
        })
        // setSales0(response?.data?.totalSales);
        setLoader(false);
      })
      .catch(function (error) {
        setLoader(false);
        // console.log("getApi error", error);
      });
  }
  const copiedHandle = () => {
    setCopied(true);
    setTimeout(
      function () {
        setCopied(false);
      }.bind(this),
      1500
    );
  };
  return (
    <>
      <Header />
      <ToastContainer />
      {/* {loader && <Loader />} */}
      {colls &&
        <section className="landing-account main-Collections">
          <div className="bg-img-author">
            <img src={colls?.BannerImage} alt="img" className="img-fluid" />
          </div>
          <div className="container1920">
            <div className="row">
              <div className="col-sm-12 col-12 ">
                <div className="main_prodiv seccolection ">

                  <div className="">
                    <img src={
                      colls?.logoImage ? colls.logoImage : "./upload.png"
                    } alt="img" className='img-fluid profileavtt' />
                  </div>
                  <div className="icondiv">
                    <ul className="list-inline">
                      {/* let newLink = “”;
                      if (!val?.url.includes(“http”)) {
                        
                        newLink = “https://” + val?.url;
                                    } else {
                        newLink = val?.url;
                                    } */}
                      {colls?.collectionWebLink !== "undefined" && <> {colls?.collectionWebLink?.includes("http") === true ? <li className="list-inline-item iconbtnnnn"><a rel="noopener noreferrer" target="_blank" href={colls.collectionWebLink}><img src="\coicon.svg" alt="img" className='' /></a></li> : <li className="list-inline-item iconbtnnnn"><a target="_blank" href={"https://" + colls.collectionWebLink}><img src="\coicon.svg" alt="img" className='' /></a></li>}</>}
                      {/* <li className="list-inline-item iconbtnnnn"><a href=""><img src="\coiconsec.svg" alt="img" className='' /></a></li> */}
                      {colls?.collectionDiscordLink !== "undefined" && <li className="list-inline-item iconbtnnnn"><a target='_blank' href={colls?.collectionTelegramLink}><img src="\fst.svg" alt="img" className='' /></a></li>}
                      {/* <li className="list-inline-item iconbtnnnn"><a href=""><img src="\sseenndd.svg" alt="img" className='' /></a></li> */}
                      {colls?.collectionDiscordLink !== "undefined" && <li className="list-inline-item iconbtnnnn"><a target='_blank' href={colls.collectionDiscordLink}><img src="\thirdds.svg" alt="img" className='' /></a></li>}
                      {colls?.collectionTwitterLink !== "undefined" && <li className="list-inline-item iconbtnnnn"><a target='_blank' href={colls.collectionTwitterLink}><img src="\forrh.svg" alt="img" className='' /></a></li>}
                      {colls?.collectionInstagramLink !== "undefined" && <li className="list-inline-item iconbtnnnn"><a target='_blank' href={colls?.collectionInstagramLink}><img src="\fifth.svg" alt="img" className='' /></a></li>}
                      {colls?.collectionMediumLink !== "undefined" && <li className="list-inline-item iconbtnnnn"><a target='_blank' href={colls?.collectionMediumLink}><img src="\sixx.svg" alt="img" className='' /></a></li>}
                    </ul>

                  </div>
                </div>
                <div className="avatar__row">
                  <h3 className="jekkee">{colls?.name} <span className="vieweddd cursor-pointer eye mg-r-8 item-viewed">
                    {" "}
                    {liked ? (
                      <img
                        onClick={() => UnLikeNft()}
                        src="\newheart.png"
                        alt="Axies"
                        className="pr-3"
                      />
                    ) : (
                      <img
                        onClick={() => LikeNft()}
                        src="\heart-black.png"
                        alt="Axies"
                        className="pr-3"
                      />
                    )}
                    {colls?.likedBy ? colls?.likedBy?.length + likes : likes}
                  </span></h3>
                  {colls?.creator === account?.toLowerCase() &&
                    <Link to={"/createcollection?id=" + colls?._id}>
                      <button className="mb-4 mt-5">Edit Collection</button>
                    </Link>
                  }
                  {colls?.description && <p className='ttteexxxtt text-justify'>{colls?.description}
                  </p>}
                  <CopyToClipboard

                    text={colls?.contractAddress}
                    onCopy={() => copiedHandle()}
                  >
                    <div className=''>
                      <p className="ttteexxxtt cursor-pointer d-flex align-items-center">
                        {colls?.contractAddress ||
                          colls?.contractAddress}
                        {copied ? <p className='copy-text-abs  ml-2'> Copied</p> :
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-copy ml-2"
                            width="22"
                            height="22"
                            viewBox="0 0 24 24"
                            strokeWidth="1"
                            stroke="#7a798a"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="8" y="8" width="12" height="12" rx="2" />
                            <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2" />
                          </svg>}
                      </p>

                    </div>
                  </CopyToClipboard >
                </div>
                <div className="row NFT_______sell______details">
                  <div className="col-xl-8 col-lg-12 col-md-12 col-sm-12 padd-sm">
                    <div className="inersection">
                      <div className="row">
                        <div className="col-xl-2 col-lg-2 col-md-2 col-6  ">
                          <div className="inerflexsec ">
                            <div className="numbers">
                              <p className="numberpara">Floor price</p>
                              <h5 className="nnnuuumbers">{colls?.florPrice?.length > 0 ? colls?.florPrice[0]?.floorPrice?.toFixed(2) : 0}</h5>
                            </div>
                            <div className="borderline">
                              {" "}
                              <img
                                src="\bluemoon-nft\sales\Linecenter.svg"
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-6  ">
                          <div className="inerflexsec  ">
                            <div className="numbers">
                              <p className="numberpara">Items</p>
                              <h5 className="nnnuuumbers">{colls?.totalSupply ? colls?.totalSupply : 0}</h5>
                            </div>
                            <div className="borderline">
                              {" "}
                              <img
                                src="\bluemoon-nft\sales\Linecenter.svg"
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-6  ">
                          <div className="inerflexsec  ">
                            <div className="numbers">
                              <p className="numberpara">Owners</p>
                              <h5 className="nnnuuumbers">{colls?.tokens?.length > 0 ? colls?.tokens[0]?.distinct : 0}</h5>
                            </div>
                            <div className="borderline">
                              {" "}
                              <img
                                src="\bluemoon-nft\sales\Linecenter.svg"
                                alt="img"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-2 col-lg-2 col-md-2 col-6   ">
                          <div className="inerflexsec ">
                            <div className="numbers">
                              <p className="numberpara">Total Volume</p>
                              <h5 className="nnnuuumbers">{colls?.volume?.length > 0 ? colls?.volume[0]?.amount?.toFixed(4) : 0.00}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="collection___tabs___wrappers">
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="mb-5 collects_______tabs brdrbtm"
                  >
                    <Tab onClick={() => setTab(0)} eventKey="home" title="Items" className='collects_______tabs_____item'>
                      <div className='row'>
                        <div className='col-12'>
                          <div className="main-head">
                            <div className="frameimg d-flex align-items-center justify-between"  >
                              <img src="\assets\Frame5555.svg" onClick={() => setShowBtn(!showBtn)} alt="img" style={{ cursor: 'pointer' }} className="img-fluid" />
                              <p className='result'>{nfts?.length} results</p>
                            </div>
                            <div className="maininput">
                              <input onChange={(e) => setSearch(e.target.value)} type="text" placeholder="Search By Name Or Attribute" />
                              <img onClick={() => GetFilterContract()} src="\assets\search-normal.svg" alt="img" className="img-fluid" style={{ cursor: 'pointer' }} />
                            </div>
                            <div className="dropdownbtn">
                              <button className="btnbtnbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {timeFilts} <img src="\assets\Icon.svg" alt="img" className="img-fluid" />
                              </button>
                              <div className="dropdown-menu fccrrcrcrcrc" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item btnbtnbtnnd" onClick={() => GetFilterContract("Recently added")}>Recently added</a>
                                {/* <a className="dropdown-item btnbtnbtnnd" onClick={() => GetFilterContract("Auction Ending Soon")}>Auction Ending Soon</a>
                                <a className="dropdown-item btnbtnbtnnd" onClick={() => GetFilterContract("Sale Ending Soon")}>Sale Ending Soon</a> */}
                                <a className="dropdown-item btnbtnbtnnd" onClick={() => GetFilterContract("Recently Sold")}>Recently Sold</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12'>
                          <div className='row'>
                            <div className={showBtn ? "col-xl-3 col-lg-3 col-12 hhhhhhh" : 'd-none'}>
                              <Cofilter GetFilterContract={GetFilterContract} search={search} setSearch={setSearch} setSprice={setSprice} setLprice={setLprice} sprice={sprice} lprice={lprice} onsale={onsale} onauction={onauction} setOnsale={setOnsale} setOnauction={setOnauction} GetContract={GetContract} contractId={contractId} />
                            </div>
                            <div className={showBtn ? "col-xl-9 col-lg-9 col-12" : "col-xl-12 col-12"}>
                              <ExploreItem recentAddTokens={nfts} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                    <Tab onClick={() => setTab(1)} style={{ paddingBottom: '100px' }} eventKey="Analytics" title="Analytics" className='collects_______tabs_____item'>
                      <div className='row'>
                        <div className='col-sm-12 m-auto'>
                          <div className="main-head">
                            {/* <div className="frameimg d-flex align-items-center justify-between"  >
                              <img src="\assets\Frame5555.svg" onClick={() => setShowBtn(!showBtn)} alt="img" style={{ cursor: 'pointer' }} className="img-fluid" />
                              <p className='result'>9,627 results</p>
                            </div>
                            <div className="maininput">
                              <input type="text" placeholder="Search items, collections, and accounts" />
                              <img src="\assets\search-normal.svg" alt="img" className="img-fluid" style={{ cursor: 'pointer' }} />
                            </div> */}
                            <div className="dropdownbtn">
                              <button className="btnbtnbtn" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {timeline} <img src="\assets\Icon.svg" alt="img" className="img-fluid" />
                              </button>
                              <div className="dropdown-menu dfsdgsdgsdgsdgsdfg" aria-labelledby="dropdownMenuButton">
                                <a className="dropdown-item btnbtnbtnnddd" onClick={() => setTimeline("day")} href="#">Day</a>
                                <a className="dropdown-item btnbtnbtnnddd" onClick={() => setTimeline("week")} href="#">Week</a>
                                <a className="dropdown-item btnbtnbtnnddd" onClick={() => setTimeline("month")} href="#">Month</a>
                                <a className="dropdown-item btnbtnbtnnddd" onClick={() => setTimeline("year")} href="#">Year</a>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-12 '>
                          <div className='row'>
                            {/* <div className={showBtn ? "col-xl-3 col-lg-3 col-12" : 'd-none'}>
                              <Cofilter />
                            </div> */}
                            <div className={showBtn ? "col-xl-9 col-lg-9 col-12" : "col-xl-12 col-12"}>
                              <div className='row'>
                                <div className='col-sm-12 m-auto'>
                                  <div className='main_fistcard'>
                                    <div className='cardd'>
                                      <div className='card_iner'>
                                        <p className='Volume'>Volume</p>
                                        {sales?.totalVolume?.length > 0 ?
                                          <h4 className='diggits'>{sales?.totalVolume?.length > 0 ? sales?.totalVolume[0]?.amount : "0"} BNB</h4>
                                          :
                                          <h4 className='diggits'>0 BNB</h4>
                                        }
                                        <div className='imgg'>
                                          {sales?.totalVolume?.length === 0 && sales?.totalVolumePrev?.length > 0 &&
                                            <p className='percentagered'>
                                              <img src="\arrow-upd.png" alt="img" className="img-fluid mr-2" />
                                              <span>
                                                {sales?.totalVolumePrev[0]?.amount?.toFixed(6)}
                                              </span>
                                            </p>
                                          }
                                          {sales?.totalVolume?.length > 0 && sales?.totalVolumePrev?.length === 0 &&
                                            <p className='percentage'>
                                              <img src="\arrow-up.svg" alt="img" className="img-fluid mr-2" />
                                              <span>
                                                {sales?.totalVolume[0]?.amount?.toFixed(6)}
                                              </span>
                                            </p>
                                          }
                                          {sales?.totalVolume?.length > 0 && sales?.totalVolumePrev?.length > 0 &&
                                            <p className={' ' + ((sales?.totalVolume?.amount * sales?.totalVolumePrev?.amount) / 100 > 0 ? "percentage" : "percentagered")}>
                                              {(sales?.totalVolume?.amount * sales?.totalVolumePrev?.amount) / 100 > 0 ?
                                                <img src="\arrow-up.svg" alt="img" className="img-fluid mr-2" />
                                                :
                                                <img src="\arrow-upd.png" alt="img" className="img-fluid mr-2" />
                                              }
                                              <span>
                                                {((sales?.totalVolume?.amount * sales?.totalVolumePrev?.amount) / 100)?.toFixed(4)}
                                              </span>
                                            </p>
                                          }
                                          {sales?.totalVolume?.length === 0 && sales?.totalVolumePrev?.length === 0 &&
                                            <p style={{ color: '#7A798A' }} className='percentage'>
                                              {/* <img src="\arrow-up.svg" alt="img" className="img-fluid mr-2" /> */}
                                              <span style={{ color: '#7A798A' }} >
                                                0.00
                                              </span>
                                            </p>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className='cardd'>
                                      <div className='card_iner'>
                                        <p className='Volume'>Sales</p>
                                        {sales?.totalSales?.length > 0 ?
                                          <h4 className='diggits'>{sales?.totalSales?.length > 0 ? sales?.totalSales[0]?.count : "0"}</h4>
                                          :
                                          <h4 className='diggits'>0</h4>
                                        }
                                        <div className='imgg'>
                                          {sales?.totalSales?.length === 0 && sales?.totalSalesPrev?.length > 0 &&
                                            <p className='percentagered'>
                                              <img src="\arrow-upd.png" alt="img" className="img-fluid mr-2" />
                                              <span>
                                                {sales?.totalSalesPrev[0]?.count?.toFixed(2)}
                                              </span>
                                            </p>
                                          }
                                          {sales?.totalSales?.length > 0 && sales?.totalSalesPrev?.length === 0 &&
                                            <p className='percentage'>
                                              <img src="\arrow-up.svg" alt="img" className="img-fluid mr-2" />
                                              <span>
                                                {sales?.totalSales[0]?.count?.toFixed(2)}
                                              </span>
                                            </p>
                                          }
                                          {sales?.totalSales?.length > 0 && sales?.totalSalesPrev?.length > 0 &&
                                            <p className={' ' + ((sales?.totalSales?.count * sales?.totalSalesPrev?.count) / 100 > 0 ? "percentage" : "percentagered")}>
                                              {(sales?.totalSales?.count * sales?.totalSalesPrev?.count) / 100 > 0 ?
                                                <img src="\arrow-up.svg" alt="img" className="img-fluid mr-2" />
                                                :
                                                <img src="\arrow-upd.png" alt="img" className="img-fluid mr-2" />
                                              }
                                              <span>
                                                {((sales?.totalSales?.count * sales?.totalSalesPrev?.count) / 100)?.toFixed(4)}
                                              </span>
                                            </p>
                                          }
                                          {sales?.totalSales?.length === 0 && sales?.totalSalesPrev?.length === 0 &&
                                            <p style={{ color: '#7A798A' }} className='percentage'>
                                              {/* <img src="\arrow-up.svg" alt="img" className="img-fluid mr-2" /> */}
                                              <span style={{ color: '#7A798A' }} >
                                                0.00
                                              </span>
                                            </p>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                    <div className='cardd'>
                                      <div className='card_iner'>
                                        <p className='Volume'>Floor Price</p>
                                        <h4 className='diggits'>{sales?.floorPrice?.length > 0 ? sales?.floorPrice[0]?.min : "0"} BNB</h4>
                                        <div className='imgg'>
                                          {sales?.floorPrice?.length === 0 && sales?.floorPricePrev?.length > 0 &&
                                            <p className='percentagered'>
                                              <img src="\arrow-upd.png" alt="img" className="img-fluid mr-2" />
                                              <span>
                                                {sales?.floorPricePrev[0]?.min?.toFixed(6)}
                                              </span>
                                            </p>
                                          }
                                          {sales?.floorPrice?.length > 0 && sales?.floorPricePrev?.length === 0 &&
                                            <p className='percentage'>
                                              <img src="\arrow-up.svg" alt="img" className="img-fluid mr-2" />
                                              <span>
                                                {sales?.floorPrice[0]?.min?.toFixed(6)}
                                              </span>
                                            </p>
                                          }
                                          {sales?.floorPrice?.length > 0 && sales?.floorPricePrev?.length > 0 &&
                                            <p className={' ' + ((sales?.floorPrice?.min * sales?.floorPricePrev?.min) / 100 > 0 ? "percentage" : "percentagered")}>
                                              {(sales?.floorPrice?.min * sales?.floorPricePrev?.min) / 100 > 0 ?
                                                <img src="\arrow-up.svg" alt="img" className="img-fluid mr-2" />
                                                :
                                                <img src="\arrow-upd.png" alt="img" className="img-fluid mr-2" />
                                              }
                                              <span>
                                                {((sales?.floorPrice?.min * sales?.floorPricePrev?.min) / 100)?.toFixed(4)}
                                              </span>
                                            </p>
                                          }
                                          {sales?.floorPrice?.length === 0 && sales?.floorPricePrev?.length === 0 &&
                                            <p style={{ color: '#7A798A' }} className='percentage'>
                                              {/* <img src="\arrow-up.svg" alt="img" className="img-fluid mr-2" /> */}
                                              <span style={{ color: '#7A798A' }} >
                                                0.00
                                              </span>
                                            </p>
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>


                              </div>

                              <div className='row'>
                                <div className=' col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 '>

                                  {prVoCharts &&
                                    <div className='chart_im'>
                                      <Treasure charts={prVoCharts} />
                                    </div>
                                  }
                                </div>
                                <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 m-auto '>
                                  <div className='chart_im'>
                                    <Zoomable charts={floorPrice} />
                                  </div>
                                </div>
                                <div className=' col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 m-auto '>
                                  <div className='main_scroolinddiv'>
                                    <div className='fst_rowdiv'>
                                      <p className='Listings'>
                                        Listings
                                      </p>
                                      {/* <div className="dropdown">
                                        <button className="btn btnsecondary dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                                          Price
                                        </button>
                                        <div className="dropdown-menu">
                                          <a className="dropdown-item" href="#">Action</a>
                                          <a className="dropdown-item" href="#">Another action</a>
                                          <a className="dropdown-item" href="#">Something else here</a>
                                        </div>
                                      </div> */}
                                    </div>
                                    {listings?.map((it, ind) => {
                                      return (
                                        <div key={ind} className='fst_rowdiv border_buttononly'>
                                          <div className='fstbtnrow'>
                                            <div className='avtar_pro'>
                                              <img style={{ height: 45, width: 45 }} src={it?.tokens[0]?.image} alt="img" className="img-fluid widthhundred" />
                                            </div>
                                            <div className='diggfig'>
                                              <p className='Listingsdd'>
                                                {it?.tokens[0]?.tokenID}
                                              </p>
                                              <p className='Listingsxx'>
                                                {moment(it?.updatedAt).fromNow()}
                                              </p>
                                            </div>
                                          </div>

                                          <div className='fst_scrlbtn'>
                                            <Link to={"/item-details?id=" + it?.tokens[0]?._id}>
                                              <button type="button" className="btn btnoutlineprimary">Buy</button>
                                            </Link>
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                                <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 m-auto '>

                                  <div className='chart_im'>
                                    <div className='fst_rowdiv'>
                                      <p className='Listings'>
                                        Sales
                                      </p>
                                    </div>
                                    {salesCharts &&
                                      <Bubblecharts charts={salesCharts} />
                                    }
                                  </div>
                                </div>
                                <div className=' col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 m-auto '>
                                  <div className='main_scroolinddiv'>
                                    <div className='fst_rowdiv'>
                                      <p className='Listings'>
                                        Sales
                                      </p>
                                    </div>
                                    {sales0?.map((item, index) => {
                                      return (
                                        <div key={index} className='fst_rowdiv border_buttononly'>
                                          <div className='fstbtnrow'>
                                            <div className='avtar_pro'>
                                              <img style={{ width: 40, height: 40 }} src={item?.tokens[0]?.image} alt="img" className="img-fluid" />
                                            </div>
                                            <div className='diggfig'>
                                              <p className='Listingsdd'>
                                                {item?.tokenID}
                                              </p>
                                              <p className='Listingsxx'>
                                                {moment(item?.updatedAt).fromNow()}
                                              </p>
                                            </div>
                                          </div>
                                          <div className='fst_scrlbtn'>
                                            <p className='Listingsdd'>
                                              {item?.price} {item?.chainId === "97" && "Bnb"}
                                            </p>
                                            {/* <p className='Listingsxx'>
                                              -1% floor
                                            </p> */}
                                          </div>
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                                <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 m-auto '>
                                  <div className='chart_im '>
                                    <Columnchart charts={priDis} />
                                  </div>
                                </div>
                                <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 m-auto '>
                                  <div className='progress_maindiv forheight'>
                                    <div className='progress_heading'>
                                      <h5 className='distribution'>
                                        Owner Distribution
                                      </h5>
                                      <p className='progress_numbers'>
                                        Total: {ownDis?.totalOwners}
                                      </p>
                                    </div>
                                    <div className='fst_progressbar'>
                                      <div className='progress_heading'>
                                        <h5 className='progress_persntg'>
                                          {(ownDis?.secOne / ownDis?.totalOwners) * 100 || 0 || 0} %
                                        </h5>
                                        <h5 className='progress_persntg'>
                                          1-10 items
                                        </h5>
                                      </div>
                                      <div className="progress">
                                        <div className="progress-bar" style={{ width: ((ownDis?.secOne / ownDis?.totalOwners) * 100)?.toString() + "%" }} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="55">
                                        </div>
                                      </div>
                                    </div>
                                    <div className='fst_progressbar'>
                                      <div className='progress_heading'>
                                        <h5 className='progress_persntg'>
                                          {(ownDis?.secTwo / ownDis?.totalOwners) * 100 || 0} %
                                        </h5>
                                        <h5 className='progress_persntg'>
                                          10-20  items
                                        </h5>
                                      </div>
                                      <div className="progress ">
                                        <div className="progress-bar" style={{ width: ((ownDis?.secTwo / ownDis?.totalOwners) * 100)?.toString() + "%" }} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                      </div>
                                    </div>
                                    <div className='fst_progressbar'>
                                      <div className='progress_heading'>
                                        <h5 className='progress_persntg'>
                                          {(ownDis?.secThree / ownDis?.totalOwners) * 100 || 0} %
                                        </h5>
                                        <h5 className='progress_persntg'>
                                          20-50  items
                                        </h5>
                                      </div>
                                      <div className="progress ">
                                        <div className="progress-bar" style={{ width: ((ownDis?.secThree / ownDis?.totalOwners) * 100)?.toString() + "%" }} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                      </div>
                                    </div>
                                    <div className='fst_progressbar'>
                                      <div className='progress_heading'>
                                        <h5 className='progress_persntg'>
                                          {(ownDis?.secFour / ownDis?.totalOwners) * 100 || 0} %
                                        </h5>
                                        <h5 className='progress_persntg'>
                                          50+ items
                                        </h5>
                                      </div>
                                      <div className="progress ">
                                        <div className="progress-bar" style={{ width: ((ownDis?.secFour / ownDis?.totalOwners) * 100)?.toString() + "%" }} role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className='col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 m-auto '>
                                  <div className='main_scroolinddiv forheight'>
                                    <div className='fst_rowdiv'>
                                      <p className='Listings'>
                                        Owners
                                      </p>
                                      <div className="dropdownbtnyyy">
                                        <p className='toperhund'>
                                          Top 100
                                        </p>
                                      </div>
                                    </div>
                                    <div className='fst_rowdiv'>
                                      {/* <p className='oowners'>
                                        Owners
                                      </p> */}
                                      <p></p>
                                      <div className="dropdownbtnyyy">
                                        <p className='ooowned'>
                                          Owned
                                        </p>
                                      </div>
                                    </div>
                                    {owners?.map((item, index) => {
                                      return (
                                        <div key={index} className='fst_rowdiv border_buttononly'>
                                          <div className='fstbtnrow'>
                                            <div className='diggfig'>
                                              <p className='Listingsxxsss'>
                                                {index + 1}
                                              </p>
                                            </div>
                                            <div className='avtar_pro'>
                                              <img style={{ width: 45, height: 45 }} src={item?.owners[0]?.picture} alt="img" className="img-fluid" />
                                            </div>
                                            <div className='diggfig'>
                                              <p className='Listinfddhgsdd'>
                                                {item?.owners[0]?.userFullName}
                                              </p>
                                              <p className='Listingsxxvv'>
                                                {item?.owners[0]?.walletAddress?.slice(0, 11) + "..." + item?.owners[0]?.walletAddress?.slice(item?.owners[0]?.walletAddress?.length - 4, item?.walletAddress?.owners[0]?.length)}
                                              </p>
                                            </div>
                                          </div>
                                          {/* <div className='fst_scrlbtn'>
                                            <p className='Listingsdser'>
                                              110
                                            </p>
                                            <p className='Listingsxxdd'>
                                              1.15%
                                            </p>
                                          </div> */}
                                        </div>
                                      )
                                    })}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                    </Tab>
                    <Tab onClick={() => setTab(2)} style={{ paddingBottom: '100px' }} eventKey="profile" title="Activity" className='collects_______tabs_____item'>
                      <ActivitiesTab contractId={contractId} GetContract={GetContract} showBtn={showBtn} setShowBtn={setShowBtn} />
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      <Footer />
    </>
  );
};

export default Collections;
