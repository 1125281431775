import { useCallback, useState, useEffect } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getBulkTansferContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

const BulkTrans = () => {
    const web3 = useWeb3();
    // const contractAddress = environment.MarketContract;
    const [contractAddress, setContractAddress] = useState(null);
    const context = useWeb3React();
    const { chainId } = context;

    useEffect(() => {
        if (chainId === 97) {
            setContractAddress(environment.bulkTransfer);
        }
        //  else if (chainId === 4) {
        //     setContractAddress(environment.Eth.MarketContract);
        // } else if (chainId === 43114) {
        //     setContractAddress(environment.Eth.MarketContract);
        // } else if (chainId === 80001) {
        //     setContractAddress(environment.Polygon.MarketContract);
        // }
    }, [chainId])

    const contract = getBulkTansferContract(contractAddress, web3);

    const BulkTransfer = useCallback(
        async (account, rec, address, tokens, amount) => {
            try {
                // console.log("rec, address, tokens, amount", account, rec, address, tokens, amount)
                // console.log("rec, address, tokens, amount", contract)
                const gas = await contract.methods
                    .bulkTransferMulti(account, rec, address, tokens, amount)
                    .estimateGas({ from: account });
                let gasPrice = await web3.eth.getGasPrice();
                const details = await contract.methods
                    .bulkTransferMulti(account, rec, address, tokens, amount)
                    .send({
                        from: account,
                        gas,
                        gasPrice: gasPrice
                    })
                return details;
            } catch (error) {
                throw (error)
            }
        },
        [contract]
    );
    return { BulkTransfer: BulkTransfer };
};

export default BulkTrans;