import { useCallback, useEffect, useState } from "react";
import useWeb3 from "./useWeb3";
import environment from "../utils/Environment";
import { getFactoryContract } from "../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";
import { env } from "process";

const FactoryContractMethod = () => {
    const web3 = useWeb3();
    const context = useWeb3React();
    const { account } = useWeb3React();
    // const contractAddress = environment.fectoryContract;
    // const contractAddress1 = environment.marketPlaceContract;
    const { chainId } = context;
    const [contractAddress, setContractAddress] = useState(null);
    const [marketAddress, setMarketAddress] = useState(null);
    // const contract = getFactoryContract(contractAddress, web3);

    useEffect(() => {
        if (chainId === 97) {
            setContractAddress(environment.fectoryContract);
            setMarketAddress(environment.marketPlaceContract);
        } else if (chainId === 5) {
            setContractAddress(environment.Eth.fectoryContract);
            setMarketAddress(environment.Eth.marketPlaceContract);
        } else if (chainId === 43114) {
            setContractAddress(environment.Eth.fectoryContract);
            setMarketAddress(environment.Eth.marketPlaceContract);
        } else if (chainId === 80001) {
            setContractAddress(environment.Polygon.fectoryContract);
            setMarketAddress(environment.Polygon.marketPlaceContract);
        }
    }, [chainId])

    const factoryContractMethod = useCallback(
        async (account, name, sym) => {
            let rentingAddress= environment.renting
            try {
                const contract = getFactoryContract(contractAddress, web3);
                const gas = await contract.methods
                    .deployNewCollection(account, name, sym, marketAddress)
                    .estimateGas({ from: account });
                let gasPrice = await web3.eth.getGasPrice();
                const details = await contract.methods
                    .deployNewCollection(account, name, sym, marketAddress)
                    .send({
                        from: account,
                        gas,
                        gasPrice: gasPrice
                    })
                return details;
            } catch (error) {
                // console.log(error)
                throw (error)
            }
        },
        [contractAddress, marketAddress]
    );
    return { factoryContractMethod: factoryContractMethod };
};

export default FactoryContractMethod;