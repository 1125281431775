import React, { useEffect, useState } from 'react';
import "../../pages/Ranking/ranking.scss";
import { Link } from 'react-router-dom'
import { Api_URL } from '../../utils/ApiURL';
import axios from 'axios';
import { useWeb3React } from '@web3-react/core'

const PopularCollection = props => {
    const [data, setAucData] = useState(null);
    const context = useWeb3React();
    const { chainId } = context;


    const getLiveAuction = async () => {
        var data = JSON.stringify({
            "category": ["Art", "Music", "Domain Names", "Virtual Worlds", "Trading Cards", "Photography", "Collectibles", "Sports", "Utility", "Other"],
            "chainId": ["97", "80001", "5", "43114"],
            "queryBy": "day"
        });

        var config = {
            method: 'post',
            url: `${Api_URL}/collection/getTopCollectionByDays`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                setAucData(response?.data);
            })
            .catch(function (error) {
                // console.log(error);
            });
    }

    useEffect(() => {
        getLiveAuction();
    }, [])

    return (
        <>
            <section className='rankingsection landing-sec desktop-padding-manual'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='heading-live-auctions'>
                                <h2 className='tf-title pb-22 text-left'>Top Collections Over The Last 24 Hours</h2>
                                <Link to="/ranking" className='exp style2'>Explore more</Link>
                            </div>
                            <div className='col-sm-12 p-0'>
                                <div className="maintable">
                                    <table className="tbl">
                                        <tr>
                                            <th className="headt pl-0">Collection</th>
                                            <th className='pr-0 '>Floor Price</th>
                                            <th className='pr-0 '>24 Hr Volume</th>
                                            <th className='pr-0 text-right'>24 Hr Change</th>
                                        </tr>
                                        {data?.data?.slice(0, 6).map((item, index) =>
                                            <tr key={index}>
                                                <td className="tda pl-0">
                                                    <span className="clside">{index + 1}</span>
                                                    <figure className="fig1">
                                                        <Link to={"/collections?id=" + item?._id}><img
                                                            src={item?.logoImage}
                                                            className="img-fluid ml-5 img11 rounded-circle"
                                                            alt=""
                                                        /></Link>
                                                        <img
                                                            src="\asssests\tick.svg"
                                                            className="img-fluid img12"
                                                            alt=""
                                                        />
                                                    </figure>
                                                    {item?.name}
                                                </td>
                                                <td className="tdb pr-0 text-right">
                                                    <img
                                                        src="\bnb.svg"
                                                        className="img-fluid img13"
                                                        alt=""
                                                    />
                                                    {item?.florPrice?.length > 0 ? item?.florPrice[0]?.floorPrice?.toFixed(2) : 0} {item?.chainId === "97" && "BNB"} {item?.chainId === "4" && "Eth"} {item?.chainId === "80001" && "Matic"} {item?.chainId === "43114" && "Avax"}
                                                </td>
                                                <td className="tdb pr-0 text-right">
                                                    <img
                                                        src="\bnb.svg"
                                                        className="img-fluid img13"
                                                        alt=""
                                                    />
                                                    {item?.volume[0] ? item?.volume[0]?.amount.toFixed(4) : 0} {item?.chainId === "97" && "BNB"} {item?.chainId === "4" && "Eth"} {item?.chainId === "80001" && "Matic"} {item?.chainId === "43114" && "Avax"}
                                                </td>
                                                {/* Pre data excited */}
                                                <>
                                                    {item?.volume?.length === 0 && data?.prevData[index]?.volume?.length > 0 &&
                                                        <td className='tdd text-danger pr-0 text-right'>
                                                            {parseFloat(data?.prevData[index]?.volume[0]?.amount)?.toFixed(4)}
                                                        </td>
                                                    }
                                                    {item?.volume?.length > 0 && data?.prevData[index]?.volume?.length === 0 &&
                                                        <td className='tdd text-success pr-0 text-right'>
                                                            {parseFloat(item?.volume[0]?.amount)?.toFixed(4)}
                                                        </td>
                                                    }
                                                    {item?.volume?.length > 0 && data?.prevData[index]?.volume?.length > 0 &&
                                                        <td className={'tdd pr-0 text-right ' + ((item?.volume[0]?.amount * data?.prevData[index]?.volume[0]?.amount) / 100 > 0 ? "text-success" : "text-danger")}>
                                                            {((item?.volume[0]?.amount * data?.prevData[index]?.volume[0]?.amount) / 100)?.toFixed(4)}
                                                        </td>
                                                    }
                                                    {item?.volume?.length === 0 && data?.prevData[index]?.volume?.length === 0 &&
                                                        <td style={{ color: '#7A798A' }} className={'tdd pr-0 text-right '}>
                                                            0.00
                                                        </td>
                                                    }
                                                </>
                                            </tr>
                                        )}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className='rankingsection-mbl landing-sec-mbl d-none desktop-padding-manual'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='heading-live-auctions'>
                                <h2 className='tf-title pb-22 text-left'>Top Collections Over The Last 24 Hours</h2>
                                <Link to="/ranking" className='exp style2'>Explore more</Link>
                            </div>
                            <div className='land-headings'>
                                <h5>Collection</h5>
                                <h5>Volume</h5>
                            </div>
                            {data?.data?.slice(0, 6).map((item, index) =>
                                <div key={index} className="box">
                                    <div className="box1">
                                        <p className="head1">{index + 1}</p>
                                        <figure className="fig1">
                                            <img
                                                src={item?.logoImage}
                                                style={{ borderRadius: 50 }}
                                                className="img-fluid ml-3 img11"
                                                alt=""
                                            />
                                            <img
                                                src="\asssests\tick.svg"
                                                className="img-fluid img12"
                                                alt=""
                                            />
                                        </figure>
                                        <div className="link1">
                                            <h4 className="heading1">{item?.name}</h4>
                                            <h4 className="heading2">
                                                <img
                                                    src="\bnb.svg"
                                                    className="img-fluid img13-mbl"
                                                    alt=""
                                                />
                                                {item?.florPrice?.length > 0 ? item?.florPrice[0]?.floorPrice?.toFixed(2) : 0} {chainId === 97 && "BNB"} {chainId === 4 && "Eth"} {chainId === 80001 && "Matic"} {chainId === 43114 && "Avax"}
                                            </h4>
                                        </div>
                                    </div>
                                    <div className="box2">
                                        <h4 className="heading2">
                                            <img
                                                src="\bnb.svg"
                                                className="img-fluid img13-mbl"
                                                alt=""
                                            />
                                            {item?.volume[0] ? item?.volume[0]?.amount.toFixed(4) : 0} {chainId === 97 && "BNB"} {chainId === 4 && "Eth"} {chainId === 80001 && "Matic"} {chainId === 43114 && "Avax"}
                                        </h4>
                                        <>
                                            {item?.volume?.length === 0 && data?.prevData[index]?.volume?.length > 0 &&
                                                <p className='tdd para1 text-danger pr-0 text-right'>
                                                    {parseFloat(data?.prevData[index]?.volume[0]?.amount)?.toFixed(4)}
                                                </p>
                                            }
                                            {item?.volume?.length > 0 && data?.prevData[index]?.volume?.length === 0 &&
                                                <p className='tdd para1 text-success pr-0 text-right'>
                                                    {parseFloat(item?.volume[0]?.amount)?.toFixed(4)}
                                                </p>
                                            }
                                            {item?.volume?.length > 0 && data?.prevData[index]?.volume?.length > 0 &&
                                                <p className={'tdd para1 pr-0 text-right ' + ((item?.volume[0]?.amount * data?.prevData[index]?.volume[0]?.amount) / 100 > 0 ? "text-success" : "text-danger")}>
                                                    {parseFloat((item?.volume[0]?.amount * data?.prevData[index]?.volume[0]?.amount) / 100)?.toFixed(4)}
                                                </p>
                                            }
                                            {item?.volume?.length === 0 && data?.prevData[index]?.volume?.length === 0 &&
                                                <p style={{ color: '#7A798A' }} className={'tdd para1 pr-0 text-right '}>
                                                    0.00
                                                </p>
                                            }
                                        </>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default PopularCollection;
