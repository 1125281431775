import { useCallback, useEffect, useState } from "react";
import useWeb3 from "../useWeb3";
import { getFactContract } from "../../utils/contractHelpers";
import Environment from "../../utils/Environment";
import { useWeb3React } from "@web3-react/core";

const GetApprovedBulk = () => {
    const web3 = useWeb3();
    const [contractAddress, setContractAddress] = useState(null);
    const context = useWeb3React();
    const { chainId } = context;

    useEffect(() => {
        if (chainId === 97) {
            setContractAddress(Environment.bulkTransfer);
        } else if (chainId === 5) {
            setContractAddress(Environment.Eth.bulkTransfer);
        } else if (chainId === 43114) {
            setContractAddress(Environment.Eth.bulkTransfer);
        } else if (chainId === 80001) {
            setContractAddress(Environment.Polygon.bulkTransfer);
        }
    }, [chainId])

    const ApproveMe = useCallback(
        async (account, address) => {
            if (contractAddress) {
                try {
                    var gasFunPrice;
                    web3.eth.getGasPrice().then((result) => {
                        var result2 = parseInt(result) + 5000000000
                        // // console.log("gasfun", typeof result2, result2)
                        gasFunPrice = result2.toString()
                    })
                    const contract = getFactContract(address, web3);
                    const gas = await contract.methods
                        .setApprovalForAll(contractAddress, true)
                        .estimateGas({
                            from: account
                        })
                    const details = await contract.methods
                        .setApprovalForAll(contractAddress, true)
                        .send({
                            gas: gas,
                            gasPrice: gasFunPrice,
                            from: account
                        })
                    return details;
                } catch (error) {
                    // console.log(error)
                    throw (error)
                }
            }
        },
        [web3, contractAddress]
    );
    return { ApproveMeBulk: ApproveMe };
};

export default GetApprovedBulk;