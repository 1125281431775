import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import Loader from "../../hooks/loader";
import Footer from "../../components/footer/Footer";
import "./TransferItems.scss";
import { useWeb3React } from '@web3-react/core'
import { Link } from "react-router-dom";
import warningimg from "../../assets/images/svg/warning.svg";
import rightArrow from "../../assets/images/svg/right-arrow.svg";
import emptyWallet from "../../assets/images/svg/empty-wallet.svg";
import loaderImg from "../../assets/images/svg/loaderImg.svg";
import successimg from "../../assets/images/svg/successimg.svg";
import errorimg from "../../assets/images/svg/warning.svg";
import crossImage from "../../assets/images/svg/crossImage.svg";
import redCrossIcon from "../../assets/images/svg/redCrossIcon.svg";
import useWeb3 from "../../hooks/useWeb3";
import BulkTrans from "../../hooks/dataSenders/bulkTransfer";
import loader0 from "../../assets/loader.svg"
import { toast } from "react-toastify";
import TokenApprBulk from "../../hooks/dataFetchers/isApprovedForAllBulk";
import GetApprovedBulk from "../../hooks/dataSenders/setApprovalBulk";
import { Api_URL } from "../../utils/ApiURL";
import axios from "axios";

const TransferItems = () => {
  const [loader, setLoader] = useState(0);
  const [error, setError] = useState(false);
  const [rend, setRend] = useState(true);
  const [bulkTrans, setBulkTrans] = useState([]);
  const [rec, setRec] = useState("");
  const { BulkTransfer } = BulkTrans();
  const { ApproveTokenBulk } = TokenApprBulk();
  const web3 = useWeb3();
  const { account, chainId } = useWeb3React();
  const { ApproveMeBulk } = GetApprovedBulk();

  useEffect(() => {
    let data = localStorage.getItem("bulkTrans");
    data = JSON.parse(data);
    setBulkTrans(data);
  }, [])

  const OpenModal = async () => {
    if (web3.utils.isAddress(rec)) {
      let dumArr = [];
      let val = false;
      let address = [];
      let tokens = [];
      let amount = [];
      let mintedTokens = [];
      // window.$('#exampleModal').modal('show')
      setLoader(true);
      for (let i of bulkTrans) {
        const res = await ApproveTokenBulk(account, i?.contractAddress);
        if (i.isMinted === true) {
          dumArr.push(i);
          address.push(i?.contractAddress)
          tokens.push(i?.tokenID?.toString());
          amount.push("0");
          if (res === false) {
            try {
              const res0 = await ApproveMeBulk(account, i?.contractAddress);
            } catch (err) {
              toast.error(err?.message);
              val = true;
            }
          }
        } else {
          mintedTokens.push(i);
        }
      }
      if (val === false) {
        let indxx = 0;
        let tok = localStorage.getItem("accessToken");
        for (let i of mintedTokens) {
          indxx = indxx + 1;
          var qs = require("qs");
          var data = JSON.stringify({
            "buyerAddress": rec,
            "sellerAddress": account,
            "contractAddress": i?.contractAddress,
            "tokenID": i?.tokenID,
            // "chainId": chainId?.toString()
          });
          var config = {
            method: "post",
            url: `${Api_URL}/transfer/addTransfer`,
            headers: {
              Authorization: "Bearer " + tok,
              "Content-Type": "application/json"
            },
            data: data
          };
          axios(config)
            .then(function (response) {
              if (dumArr?.length === 0) {
                if (indxx === mintedTokens.length) {
                  setBulkTrans([]);
                  localStorage.setItem("bulkTrans", JSON.stringify([]));
                  window.$('#exampleModal').modal('hide')
                  setLoader(false);
                }
                window.$('#exampleModal').modal('hide')
                setLoader(false);
                toast.success('Transfer Successfull')
              }
            })
            .catch(function (error) {
              if (dumArr?.length === 0) {
                setError(true);
                setBulkTrans([]);
                localStorage.setItem("bulkTrans", JSON.stringify([]));
              }
              setLoader(false);
              window.$('#exampleModal').modal('hide')
            });
        }
        if (dumArr?.length > 0) {
          try {
            const res = await BulkTransfer(account, rec, address, tokens, amount)
            if (res) {
              let inde = 0;
              for (let i of dumArr) {
                inde = inde + 1;
                var qs = require("qs");
                var data = JSON.stringify({
                  "buyerAddress": rec,
                  "sellerAddress": account,
                  "contractAddress": i?.contractAddress,
                  "tokenID": i?.tokenID,
                  // "chainId": chainId?.toString()
                });
                var config = {
                  method: "post",
                  url: `${Api_URL}/transfer/addTransfer`,
                  headers: {
                    Authorization: "Bearer " + tok,
                    "Content-Type": "application/json"
                  },
                  data: data
                };

                axios(config)
                  .then(function (response) {
                    if (inde === dumArr.length) {
                      setLoader(false);
                      window.$('#exampleModal').modal('hide')
                      setBulkTrans([]);
                      localStorage.setItem("bulkTrans", JSON.stringify([]));
                      toast.success('Transfer Successfull')
                    }
                  })
                  .catch(function (error) {
                    setLoader(false);
                    window.$('#exampleModal').modal('hide')
                    setBulkTrans([]);
                    localStorage.setItem("bulkTrans", JSON.stringify([]));
                    // console.log(error);
                  });
              }
            } else {
              setError(true);
            }
          } catch (err) {
            setError(true);
            setLoader(false);
            window.$('#exampleModal').modal('hide')
          }
        }
      }
    } else if (rec === "") {
      toast.info("Wallet address is required")
    } else {
      toast.error("Incorrect wallet address")
    }
    setLoader(false);
  }

  const AddRemoveItem = (item) => {
    let dumArr = bulkTrans;
    let dumObj = dumArr.find((i) => {
      return i._id === item._id;
    })
    if (dumObj) {
      dumArr = dumArr.filter((io) => {
        return io._id !== item._id;
      })
    } else {
      dumArr.push(item);
    }
    setBulkTrans(dumArr);
    localStorage.setItem("bulkTrans", JSON.stringify(dumArr));
    setRend(!rend);
  }

  return (
    <>
      <Header />
      {loader && <Loader />}
      <section className="main-Authoronsale TransferItems">
        <div className="TransferItems-header">
          <h2>Transfer Items</h2>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum
            obcaecat.
          </p>
        </div>
        {bulkTrans?.length > 0 ?
          <>
            <div className="TransferItems-items-wrappers row m-0">
              {bulkTrans?.map((item, index) => {
                return (
                  <div key={index} className="trans-column col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                    <div className="sc-card-product TransferItems-items ">
                      <div className="card-media position-relative">
                        <img onClick={() => AddRemoveItem(item)} src={redCrossIcon} alt="redCrossIcon" className="redCrossIcon cursor-pointer" />
                        <img
                          src={item?.image}
                          alt="axies"
                        />
                      </div>
                      <div className="meta-info">
                        <div className="author">
                          <div className="avatar">
                            <img
                              src={item?.creators[0]?.picture}
                              alt="Axies"
                              className="verified___badge"
                            />
                            {item?.verified ? (
                              <div className="badge">
                                <i className="ripple"></i>
                              </div>
                            ) : null}
                          </div>
                          <div className="info">
                            <h5>{item?.nftName}</h5>
                            <h6>
                              <span>Created by </span>
                              {item?.creators[0]?.userFullName
                                ? item?.creators[0]?.userFullName
                                : item?.creators[0]?.walletAddress?.slice(
                                  0,
                                  12
                                )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
              {/* <div className="trans-column col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
            <div className="sc-card-product TransferItems-items ">
              <div className="card-media position-relative">
                <img src={redCrossIcon} alt="redCrossIcon" className="redCrossIcon" />
                <img
                  src={
                    "https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/in/wp-content/uploads/2022/03/monkey-g412399084_1280.jpg"
                  }
                  alt="axies"
                />
              </div>
              <div className="meta-info">
                <div className="author">
                  <Link to={"/user"}>
                    <div className="avatar">
                      <img
                        src={
                          "https://thumbor.forbes.com/thumbor/fit-in/900x510/https://www.forbes.com/advisor/in/wp-content/uploads/2022/03/monkey-g412399084_1280.jpg"
                        }
                        alt="axies"
                      />
                    </div>
                  </Link>
                  <div className="info">
                    <h5>name</h5>
                    <Link to={"/user"}>
                      <h6>
                        <span>Created by</span> user name
                      </h6>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
            </div>
            <div className="Wallet-ENS-name-wrapper">
              <h3>Wallet Address or ENS name</h3>
              <div className="destination-wrapper">
                <div className="destination-input">
                  <img src={emptyWallet} alt="emptyWallet" />
                  <input onChange={(e) => setRec(e.target.value)} placeholder="e.g. 0x1ead... or destination.eth" />
                </div>
                {/* <button>Send 8 items </button> */}
                {/* <!-- Button trigger modal --> */}
                <button
                  type="button"
                  className="btn send-item-btn"
                  onClick={() => OpenModal()}
                >
                  Send {bulkTrans?.length} items <img src={rightArrow} alt="rightArrow" />
                </button>

                {/* <!-- Modal --> */}
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  role="dialog"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content transfer-modal-innrer">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Transfer Items
                        </h5>
                        <button
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          {/* <span aria-hidden="true">&times;</span> */}
                          <img src={crossImage} alt="crossImage" />
                        </button>
                      </div>
                      <div className="modal-body">
                        {loader ?
                          <>
                            <img src={loader0} alt="loaderImg" />
                            <h4>Transferring items...</h4>
                          </>
                          :
                          <>
                            {error ?
                              <img src={errorimg} alt="loaderImg" />
                              :
                              <img src={successimg} alt="loaderImg" />
                            }
                            <h4>{error ? "Transaction rejected" : "Transaction successfull"}</h4>
                          </>
                        }
                        {/* <img src={successimg} alt="successimg" /> */}
                        <p>Lorem ipsum dolor sit amet, consectetur.</p>
                        {loader ?
                          <></>
                          :
                          <a href={"/myprofile"}>
                            <button className="trans-modalbtn">Back to Profile</button>
                          </a>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="Wallet-ENS-Warning">
                <img src={warningimg} alt="warningimg" />
                <p>Warning: Items sent to wrong address cannot be recovered.</p>
              </div>
            </div>
          </>
          :
          <div className="Wallet-ENS-name-wrapper">
            {/* <h3 className="text-center">Nft is transferred</h3> */}
            <div className="d-flex justify-content-center mt-4">
              <Link to={"/myprofile"}>
                <button className="tans-dark-btn">
                  {" "}
                  Go Back
                </button>
              </Link>
            </div>
          </div>
        }
      </section>
      <Footer />
    </>
  );
};

export default TransferItems;
