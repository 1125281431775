import React from 'react';
import { Link } from 'react-router-dom'
// import { Autoplay } from 'swiper';
// import { Swiper, SwiperSlide } from 'swiper/react';

// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
// import img1 from '../../assets/images/box-item/img_item1.png'
// import img2 from '../../assets/images/box-item/img_item2.png'
// import img3 from '../../assets/images/box-item/img_item3.png'
// import shape1 from '../../assets/images/backgroup-secsion/bg-gradient1.png'
// import shape2 from '../../assets/images/backgroup-secsion/bg-gradient2.png'
// import shape3 from '../../assets/images/backgroup-secsion/bg-gradient3.png'
// import Planet from '../planet/Planet';

const SliderStyle4 = () => {

    return (
        <div>
            <section className="flat-title-page style3 mainslider banner-padding-0" >
                {/* <img className="bgr-gradient gradient1" src={shape1} alt="Axies" />
                <img className="bgr-gradient gradient2" src={shape2} alt="Axies" />
                <img className="bgr-gradient gradient3" src={shape3} alt="Axies" /> */}
                <div className="overlay"></div>
                <div className="container">
                    <div className="wrap-heading">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-12 m-auto">
                                {/* <div className="content  home___page____content">
                                    <h2 className="heading mt-15 white">Buy, Own & Sell NFTs
                                    </h2>
                                    <h1 className="heading mb-style"><span className="tf-text s1">In The Bluemoon Planet</span>
                                    </h1>
                                    <h1 className="heading white">Metaverse Coming Soon!</h1>
                                    <p className="sub-heading mt-19 mb-40">The world’s First NFT Marketplace With An Integrated Metaverse
                                    </p>
                                    <div className="flat-bt-slider flex style2">
                                        <Link to="/explore" className="sc-button header-slider style style-1 rocket fl-button pri-1 specs-hover-btn banner-btn1"><span>Join The Waiting List
                                        </span></Link>
                                        <Link to="/createitem" className="sc-button header-slider style style-1 note fl-button pri-1 specs-hover-btn banner-btn2"><span>Learn More
                                        </span></Link>
                                    </div>
                                </div> */}
                                <div className="main_bannerhead">
                                    <h6 className="main_barnerheading" >⭐ Huge benefits and perks for early adopters + 🏕 Land Giveaway</h6>
                                    <h4 className="main_barnerheadingsec" >Bluemoon Metaverse <br></br> + NFT Marketplace</h4>
                                    <h3 className="main_barnerheadingthrd" >Launching Soon!</h3>
                                    <button className="btn_join_waitlist">Join The waitlist</button>
                                    <a href="http://discord.gg/legionnetwork"
                                        target="_blank"> <button className="btn-discord"><img src="\assets\discord-btn-icon.svg" alt="img" className="img-fluid mr-2" />Join Discord</button></a>
                                    {/* <p className="para">Join the waitlist to get early access!</p> */}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-12 text-center-md">
                                <img src="\banner-globe-combo.png" alt="img" className='img-fluid rotate' />
                                {/* <img src="\banner-globe-combo2.png" alt="img" className='img-fluid rotate' /> */}
                            </div>
                        </div>
                        {/* <Swiper
                                modules={[ Autoplay ]}
                                direction={"vertical"}
                                spaceBetween={25}
                                slidesPerView={5}
                                loop
                                autoplay={{
                                    delay: 1,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                                speed= {2000}
                            >
                                <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>

                            </Swiper>
                            <Swiper
                                modules={[ Autoplay ]}
                                direction={"vertical"}
                                spaceBetween={25}
                                slidesPerView={5}
                                loop
                                autoplay={{
                                    delay: 1,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                                speed= {1800}
                            >
                                <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>

                            </Swiper>
                            <Swiper
                                modules={[ Autoplay ]}
                                direction={"vertical"}
                                spaceBetween={25}
                                slidesPerView={5}
                                loop
                                autoplay={{
                                    delay: 1,
                                    disableOnInteraction: false,
                                    pauseOnMouseEnter: true,
                                }}
                                speed= {2200}
                            >
                                <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img3} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img2} alt="Axies" /></SwiperSlide>
                                <SwiperSlide><img src={img1} alt="Axies" /></SwiperSlide>

                            </Swiper> */}
                    </div>
                </div>

            </section>
        </div>
    );
}


export default SliderStyle4;
