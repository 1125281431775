import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getCollectionContract } from "../../utils/contractHelpers";

const LendNft = () => {
    console.log('ndddd');
    const web3 = useWeb3();
    const lendNfts = useCallback(
        async ( rentContract, tokenId, pricePerDay, endDateUnix,account) => {
            const tokenAddress = rentContract;
            const contract = getCollectionContract(tokenAddress, web3);
            // let nd = 1000000000000000000000;
          let   pricePerDayWei = web3.utils.toWei(pricePerDay.toString(), "ether");
            console.log("nddddd", tokenId, pricePerDayWei, endDateUnix,account)
            try {
                const gas = await contract.methods
                    .putForRent( tokenId, pricePerDayWei, endDateUnix)
                    .estimateGas({ from: account });
                let gasPrice = await web3.eth.getGasPrice();
                const details = await contract.methods
                    .putForRent( tokenId, pricePerDayWei, endDateUnix)
                    .send({
                        from: account,
                        gas,
                        gasPrice: gasPrice
                    })
                return details;
            } catch (error) {
                console.log("nddddd", error)
                throw (error)
            }
        },
        [web3]
    );
    return { lendNfts: lendNfts };
};
export default LendNft;