import React from "react";
import { Link } from "react-router-dom";

const Comp = () => {

    return (
        <div className='mainpills landingpills '>

            <div className="row">
                <div className=" col-sm-12  m-auto ">
                    <ul className="nav nav-tabs " role="tablist">
                        <li className="nav-item">
                            <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#Submitions"
                                role="tab"
                                aria-controls="Submitions"
                                aria-selected="true"
                            >
                                Submitions
                            </a>
                        </li>
                        <li className="nav-item">
                            <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#Winner"
                                role="tab"
                                aria-controls="Winner"
                                aria-selected="false"
                            >
                                Winner
                            </a>
                        </li>

                    </ul>
                    <div className="tab-content mt-3">
                        <div
                            className="tab-pane active"
                            id="Submitions"
                            role="tabpanel"
                            aria-labelledby="Submitions-tab"
                        >
                            <div className="row ">
                                <div className="col-xl-12 col-lg-11 col-md-12 col-12 m-auto ">
                                    <div className="statuss">
                                        <div className="dropdownbutnnss">
                                            <div className="dropdown">
                                                <button
                                                    className="btn  dropdown statusdrop"
                                                    type="button"
                                                    id="123"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Status{" "}
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="change-my-color"
                                                    >
                                                        <path
                                                            d="M4 6L8 10L12 6"
                                                            stroke="#1F1F2C"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </button>
                                                <div
                                                    className="dropdown-menu my-drop-menu2  ccategory"
                                                    aria-labelledby="123"
                                                >
                                                    <a className="dropdown-item" href="/#">
                                                        Action
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Another action
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Something
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="dropdown">
                                                <button
                                                    className="btn  ml-4 dropdown statusdrop my-dropdown"
                                                    type="button"
                                                    id="456"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Price Range{" "}
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="change-my-color"
                                                    >
                                                        <path
                                                            d="M4 6L8 10L12 6"
                                                            stroke="#1F1F2C"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </button>
                                                <div
                                                    className="dropdown-menu my-drop-menu"
                                                    aria-labelledby="456"
                                                >
                                                    <div className="input-inner">
                                                        <input
                                                            type="text"
                                                            className="mr-3"
                                                            placeholder="From"
                                                        />
                                                        <input type="text" placeholder="To" />
                                                    </div>
                                                    <div className="inner-btns">
                                                        <button className="mr-3">Clear</button>
                                                        <button>Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dropdown">
                                                <button
                                                    className="btn ml-4 dropdown statusdrop"
                                                    type="button"
                                                    id="789"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Category{" "}
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="change-my-color"
                                                    >
                                                        <path
                                                            d="M4 6L8 10L12 6"
                                                            stroke="#1F1F2C"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </button>
                                                <div
                                                    className="dropdown-menu statusdrop2"
                                                    aria-labelledby="789"
                                                >
                                                    <a className="dropdown-item" href="/#">
                                                        All
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        PFP
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Art
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Metaverse
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Domain
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="recentlyadded">
                                            <button
                                                className="btn  dropdown statusdrop"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                Recently added{" "}
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="change-my-color"
                                                >
                                                    <path
                                                        d="M4 6L8 10L12 6"
                                                        stroke="#1F1F2C"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                            <div
                                                className="dropdown-menu my-drop-menu2 ccategory"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <a className="dropdown-item" href="/#">
                                                    Recently added
                                                </a>
                                                <a className="dropdown-item" href="/#">
                                                    Price: Low to High
                                                </a>
                                                <a className="dropdown-item" href="/#">
                                                    Price: High to Low
                                                </a>
                                                <a className="dropdown-item" href="/#">
                                                    Auction ending soon
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items d-none">
                                        <div className="left-filter-icon">
                                            <button
                                                className="filter-left-btn-mbl"
                                                data-toggle="modal"
                                                data-target="#staticBackdrop"
                                            >
                                                {" "}
                                                Filters{" "}
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="change-my-color"
                                                >
                                                    <path
                                                        d="M3 7H21"
                                                        stroke="white"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M6 11.5H18"
                                                        stroke="white"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M8 16.5H16"
                                                        stroke="white"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="right-filter-drop">
                                            <div className="dropdown">
                                                <button
                                                    className="btn btn-secondary dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Sort by
                                                </button>
                                                <div
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton"
                                                >
                                                    <a className="dropdown-item" href="/#">
                                                        Price: Low to High
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Price: High to Low
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Auction ending soon
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{" "}
                            </div>
                        </div>
                        <div
                            className="tab-pane"
                            id="Winner"
                            role="tabpanel"
                            aria-labelledby="Winner-tab"
                        >
                            <div className="row ">
                                <div className="col-xl-12 col-lg-11 col-md-12 col-12 m-auto ">
                                    <div className="statuss">
                                        <div className="dropdownbutnnss">
                                            <div className="dropdown">
                                                <button
                                                    className="btn  dropdown statusdrop"
                                                    type="button"
                                                    id="123"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Status{" "}
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="change-my-color"
                                                    >
                                                        <path
                                                            d="M4 6L8 10L12 6"
                                                            stroke="#1F1F2C"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </button>
                                                <div
                                                    className="dropdown-menu my-drop-menu2  ccategory"
                                                    aria-labelledby="123"
                                                >
                                                    <a className="dropdown-item" href="/#">
                                                        Action
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Another action
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Something
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="dropdown">
                                                <button
                                                    className="btn  ml-4 dropdown statusdrop"
                                                    type="button"
                                                    id="456"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Price Range{" "}
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="change-my-color"
                                                    >
                                                        <path
                                                            d="M4 6L8 10L12 6"
                                                            stroke="#1F1F2C"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </button>
                                                <div
                                                    className="dropdown-menu my-drop-menu"
                                                    aria-labelledby="456"
                                                >
                                                    <div className="input-inner">
                                                        <input
                                                            type="text"
                                                            className="mr-3"
                                                            placeholder="From"
                                                        />
                                                        <input type="text" placeholder="To" />
                                                    </div>
                                                    <div className="inner-btns">
                                                        <button className="mr-3">Clear</button>
                                                        <button>Apply</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dropdown">
                                                <button
                                                    className="btn ml-4 dropdown statusdrop"
                                                    type="button"
                                                    id="789"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Category{" "}
                                                    <svg
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 16 16"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="change-my-color"
                                                    >
                                                        <path
                                                            d="M4 6L8 10L12 6"
                                                            stroke="#1F1F2C"
                                                            stroke-width="1.5"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        />
                                                    </svg>
                                                </button>
                                                <div
                                                    className="dropdown-menu statusdrop2"
                                                    aria-labelledby="789"
                                                >
                                                    <a className="dropdown-item" href="/#">
                                                        All
                                                    </a>
                                                    <a className="dropdown-item" href="/">
                                                        PFP
                                                    </a>
                                                    <a className="dropdown-item" href="/">
                                                        Art
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Metaverse
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Domain
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="recentlyadded">
                                            <button
                                                className="btn  dropdown statusdrop"
                                                type="button"
                                                id="dropdownMenuButton"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                Recently added{" "}
                                                <svg
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 16 16"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="change-my-color"
                                                >
                                                    <path
                                                        d="M4 6L8 10L12 6"
                                                        stroke="#1F1F2C"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                            <div
                                                className="dropdown-menu my-drop-menu2 ccategory"
                                                aria-labelledby="dropdownMenuButton"
                                            >
                                                <a className="dropdown-item" href="/#">
                                                    Recently added
                                                </a>
                                                <a className="dropdown-item" href="/#">
                                                    Price: Low to High
                                                </a>
                                                <a className="dropdown-item" href="/#">
                                                    Price: High to Low
                                                </a>
                                                <a className="dropdown-item" href="/#">
                                                    Auction ending soon
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="filter-items d-none">
                                        <div className="left-filter-icon">
                                            <button
                                                className="filter-left-btn-mbl"
                                                data-toggle="modal"
                                                data-target="#staticBackdrop"
                                            >
                                                {" "}
                                                Filters{" "}
                                                <svg
                                                    width="24"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="change-my-color"
                                                >
                                                    <path
                                                        d="M3 7H21"
                                                        stroke="white"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M6 11.5H18"
                                                        stroke="white"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                    <path
                                                        d="M8 16.5H16"
                                                        stroke="white"
                                                        stroke-width="1.5"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="right-filter-drop">
                                            <div className="dropdown">
                                                <button
                                                    className="btn btn-secondary dropdown-toggle"
                                                    type="button"
                                                    id="dropdownMenuButton"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    Sort by
                                                </button>
                                                <div
                                                    className="dropdown-menu"
                                                    aria-labelledby="dropdownMenuButton"
                                                >
                                                    <a className="dropdown-item" href="/#">
                                                        Price: Low to High
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Price: High to Low
                                                    </a>
                                                    <a className="dropdown-item" href="/#">
                                                        Auction ending soon
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>{" "}
                            </div>
                        </div>
                        <div
                            className="tab-pane"
                            id="created"
                            role="tabpanel"
                            aria-labelledby="created-tab"
                        >
                        </div>
                        <div
                            className="tab-pane"
                            id="collections"
                            role="tabpanel"
                            aria-labelledby="collections-tab"
                        >
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Comp;
