import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux'
import { decrement, increment } from '../redux-toolkit/counterSlice'
import loader from "../assets/loader.svg"

function Loader({ text, margin }) {
  return (
    <>
      <div className="">
        <div
          className="position-fixed w-100"
          style={{
            zIndex: 999999,
            marginTop: -100,
            position: 'fixed',
            top: 0,
            height: "122%",
            marginLeft: margin ? -15 : 0,
            background: "rgba(0, 0, 0, 0.9)"
          }}
        >
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="d-flex flex-wrap align-items-center justify-content-center">
              <img
                width={150}
                // style={{
                //   filter:
                //     "invert(99%) sepia(1%) saturate(2%) hue-rotate(168deg) brightness(120%) contrast(100%)",
                // }}
                // src="https://v.fastcdn.co/u/430e104e/57579327-0-Loaders-3.svg"
                src={loader}
                // src="https://lottie.host/aad2bcf0-8373-415a-bc20-42229e767d85/DAoQqMet5k.json"
                alt="loader"
              />
              {/* <lottie-player src="https://lottie.host/aad2bcf0-8373-415a-bc20-42229e767d85/DAoQqMet5k.json" background="transparent" speed="1" style="width: 300px; height: 300px;" loop autoplay></lottie-player> */}
              <h2 className="text-white w-100 text-center mt-5">{text}</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Loader;