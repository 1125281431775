import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import menus from "../../pages/menu";
import DarkMode from "./DarkMode";
import logoheader from "../../assets/images/logo/logo.svg";
import logoheader2x from "../../assets/images/logo/logo@2x.svg";
import logodark from "../../assets/images/logo/logo_dark.svg";
import MetamaskLogo from "../../assets/images/icon/connect-1.png";
import logodark2x from "../../assets/images/logo/logo_dark@2x.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import imgsun from "../../assets/images/icon/sun.png";
import imgmoon from "../../assets/images/icon/moon-2.png";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3React, UnsupportedChainIdError } from "@web3-react/core";
import useAuth from "../../hooks/useAuth";
import { Api_URL } from "../../utils/ApiURL";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import Signature from "../../hooks/dataSenders/userSign";
import { setupNetwork } from "../../utils/wallet";
import useWeb3 from "../../hooks/useWeb3";

import { useEagerConnect, useInactiveListener } from "../../utils/hooks";
import { injected, walletconnect } from "../../utils/connectors";
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from "@web3-react/injected-connector";
import {
  URI_AVAILABLE,
  UserRejectedRequestError as UserRejectedRequestErrorWalletConnect,
} from "@web3-react/walletconnect-connector";
import Web3Wrapper from "./WalletConnect";

const Header = () => {
  // Modal states
  const web3 = useWeb3();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [getUserData, setGetUserData] = useState(null);
  const [checkBlance, setCheckBlance] = useState(null);
  const [changeAccount, setChangeAccount] = useState(null);
  const { userSign } = Signature();
  const [activatingConnector, setActivatingConnector] = useState();
  const context = useWeb3React();
  const { connector, library, chainId, activate, deactivate, active, error } =
    context;

  const connectorsByName = {
    Injected: injected,
    WalletConnect: walletconnect,
  };

  const dispatch = useDispatch();
  const [setWalletError] = useState("");
  function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
      return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
      // setWalletError(toast("You're connected to an unsupported network."));
      toast.error("You're connected to an unsupported network.")
    } else if (
      error instanceof UserRejectedRequestErrorInjected ||
      error instanceof UserRejectedRequestErrorWalletConnect
    ) {
      // setWalletError(
      //   toast("Please authorize this website to access your Ethereum account.")
      // );
      toast.error("You're connected to an unsupported network.")
    } else {
      // setWalletError(
      //   toast("An unknown error occurred. Check the console for more details.")
      // );
      toast.error("You're connected to an unsupported network.")
    }
  }

  // Modal states

  const { login, logout } = useAuth();
  const { account } = useWeb3React();
  const [loggedIn, setLogin] = useState();
  const { logging, doLogging } = useState("");
  // const [walletLoggedIn, setWalletLogin] = useState(false);
  let clickedClass = "clicked";
  const body = document.body;
  const lightTheme = "light";
  const darkTheme = "is_dark";
  const [toggle, settoggle] = useState(false);
  let theme;
  if (localStorage) {
    theme = localStorage.getItem("theme");
  }
  if (theme === lightTheme || theme === darkTheme) {
    body?.classList?.add(theme);
  } else {
    body?.classList?.add(lightTheme);
  }

  const switchTheme = (e) => {
    if (theme === darkTheme) {
      body?.classList?.replace(darkTheme, lightTheme);
      e?.target?.classList?.remove(clickedClass);
      localStorage?.setItem("theme", "light");
      theme = lightTheme;
    } else {
      body?.classList?.replace(lightTheme, lightTheme);
      e.target?.classList?.add(clickedClass);
      localStorage?.setItem("theme", "is_dark");
      theme = lightTheme;
    }
  };
  const { pathname } = useLocation();

  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    const scrollTop = window.scrollY;
    scrollTop >= 300
      ? header.classList.add("is-fixed")
      : header.classList.remove("is-fixed");
    scrollTop >= 400
      ? header.classList.add("is-small")
      : header.classList.remove("is-small");
  };

  const menuLeft = useRef(null);
  const btnToggle = useRef(null);
  const btnSearch = useRef(null);
  const btnNoti = useRef(null);

  const menuToggle = () => {
    menuLeft.current.classList.toggle("active");
    btnToggle.current.classList.toggle("active");
  };

  const searchBtn = () => {
    btnSearch.current.classList.toggle("active");
  };
  // // console.log(
  //   "🚀 ~ file: Header.jsx ~ line 76 ~ searchBtn ~ searchBtn",
  //   searchBtn
  // );

  const notiBtn = () => {
    btnNoti.current.classList.toggle("active");
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleOnClick = (index) => {
    setActiveIndex(index);
  };

  // Notifications
  // const ref = useRef();
  // const [isMenuOpen, setIsMenuOpen] = useState(false);
  // useEffect(() => {
  //   const checkIfClickedOutside = (e) => {
  //     if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
  //       setIsMenuOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", checkIfClickedOutside);

  //   return () => {
  //     document.removeEventListener("mousedown", checkIfClickedOutside);
  //   };
  // }, [isMenuOpen]);

  // Notifications

  // Search bar hide on scroll
  const [scroll, setScroll] = useState(false);

  // // console.log("🚀 ~ file: Header.jsx ~ line 120 ~ Header ~ scroll", scroll);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  }, []);

  const triedEager = useEagerConnect();
  useInactiveListener(!triedEager || !!activatingConnector);

  // Search bar hide on scroll
  const [mainoo, setmainoo] = useState(false);
  const mainbutton = () => {
    // // console.log("ashdgasjdhgasjhgasjhdgasjhdgasjhd");
    if (mainoo) {
      // // console.log("true");
      setmainoo(false);
    } else {
      // // console.log("false");
      setmainoo(true);
    }
  };

  const GetUser = async () => {
    const data = { walletAddress: account };
    let tok = localStorage.getItem("accessToken");
    let wall = localStorage.getItem("wallet");
    if (wall !== account) {
      loginUser();
    } else {
      await axios
        .post(`${Api_URL}/user/getUser`, data)
        .then((res) => {
          setGetUserData(res?.data?.resultData);
        })
        .catch((err) => {
          localStorage.setItem("accessToken", null);
          localStorage.setItem("wallet", null);
          loginUser();
        });
    }
  };

  const loginUser = async () => {
    let tok = localStorage.getItem("accessToken");
    let wall = localStorage.getItem("wallet");
    if (tok === "null" || tok === "undefined" || wall !== account) {
      const res = await userSign(account);
      if (account && res) {
        await axios
          .post(`${Api_URL}/user/login`, {
            walletAddress: account,
            sign: res,
          })
          .then((res) => {
            // console.log("login", res?.data);
            setChangeAccount(res?.data?.token);
            // // console.log("login token", res?.data?.token);
            toast.success("User Logged in Successfully");
            localStorage.setItem("accessToken", res?.data?.token);
            localStorage.setItem("wallet", account);
            GetUser();
          })
          .catch((err) => {
            toast.error(err?.response?.data.msg, {
              position: "top-right",
              autoClose: 2000,
            });
            // // console.log(err?.response?.data.msg)
          });
        // };
      } else {
        localStorage.setItem("flag", "false");
        localStorage.clear()
        setLogin(false);
        logout();
      }
    }
  };

  // const changeAcc = () => {
  //   // let tok = localStorage?.getItem("accessToken");
  //   // if (changeAccount !== tok) {
  //   //   localStorage?.removeItem("accessToken");
  //   //   loginUser();
  //   //   toast.success("Account change Successfully");
  //   //   localStorage?.setItem("accessToken", tok);
  //   // }
  // }
  // useEffect(() => {
  //   changeAcc();
  // }, [account])

  const connectMetamask = async () => {
    const currentConnector = connectorsByName["Injected"];
    // console.log("call, call")
    if (!error) {
      try {
        await activate(currentConnector);
        localStorage.setItem("flag", "true");
        // localStorage.setItem('injected', "injected")
        setActivatingConnector(currentConnector);
        // console.log("in clone");
        handleClose();
        // console.log("out clone");
      } catch (ex) {
        return false;
      }
    } else {
      // console.log("error", error)
      getErrorMessage(error);
    }
  };

  const trustWallet = async () => {
    localStorage.setItem("connectorId", "walletconnect");
    const currentConnector = connectorsByName["WalletConnect"];
    if (!error) {
      try {
        await activate(currentConnector, undefined, true);
        localStorage.setItem("flag", "true");
        localStorage.setItem("walletconnect", "walletconnect");
        setActivatingConnector(currentConnector);
      } catch (ex) {
        return false;
      }
    } else {
      currentConnector.walletConnectProvider = undefined;
      getErrorMessage(error);
    }
  };

  const logoutt = () => {
    localStorage.setItem("flag", "false");
    localStorage.clear()
    setLogin(false);
    logout();
  };

  useEffect(() => {
    if (account) {
      const res = axios
        .post(`${Api_URL}/user/register`, {
          walletAddress: account,
        })
        .then((response) => {
          // // console.log("register", response?.data?.msg)
          // loginUser()
          GetUser();
          toast.success("User Registered Successfully");
        })
        .catch((err) => {
          // // console.log(err?.response?.data.msg)
          GetUser();
          // loginUser()
        });
    }
  }, [account]);

  useEffect(async () => {
    const balance = await web3.eth.getBalance(account);
    const userblance = (balance / 10 ** 18).toFixed(3);
    setCheckBlance(userblance);
  }, [account]);

  const [copied, setCopied] = useState(false);

  const copiedHandle = () => {
    setCopied(true);
    setTimeout(
      function () {
        setCopied(false);
      }.bind(this),
      1500
    );
  };

  // ===========================================  search ========================================= //

  const [search, setSearch] = useState("");

  const [item, setItem] = useState([]);
  const [collection, setCollection] = useState([]);
  const [userAccount, setUserAccount] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleSearch = async (e) => {
    var url = e.target.value;
    var PREFIX = ".";
    for (var i = 0; i < url.length; i++) {
      if (url.startsWith(PREFIX)) {
        url = url.slice(PREFIX.length);
      }
    }
    if (url === "") {
      setSearch("");
      setCollection([]);
      setItem([]);
      // setitemlength(2)
      // setitemlength2(2)
      // setitemlength3(2)
      setUserAccount([]);
      // setToggle(false)
      setLoader(false);
    } else {
      setSearch(url);
      // setToggle(true)
      setLoader(true);
      axios
        .post(`${Api_URL}/collection/search`, { search: url.toLowerCase() })
        .then((res) => {
          // console.log("response we get search", res);
          var data2 = res.data.data;
          setCollection(data2[0].collection);
          setItem(data2[1].token);
          setUserAccount(data2[2].user);
          setLoader(false);
        })
        .catch((err) => {
          return err;
        });
    }
  };

  // dropdown menu code ................................................

  // const [isShown, setIsShown] = useState(false);

  // const handleClick = event => {
  //   setIsShown(current => !current);
  // };

  // const refdrop = useRef();

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (!refdrop.current.contains(event.target)) {
  //       setIsShown(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [refdrop]);

  const refdropmenu = useRef();
  const [isMenuOpendrop, setIsMenuOpendrop] = useState(false);

  async function ChangeNetwork(chain) {
    setLoader(true);
    const res = await setupNetwork(chain, setLoader);
    await setLoader(false);
  }

  useEffect(() => {
    const checkIfClickedOutsidemenu = (e) => {
      if (
        isMenuOpendrop &&
        refdropmenu.current &&
        !refdropmenu.current.contains(e.target)
      ) {
        setIsMenuOpendrop(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutsidemenu);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutsidemenu);
    };
  }, [isMenuOpendrop]);

  const getStatus = () => {
    if (account) {
      setIsMenuOpendrop((oldState) => !oldState);
    } else {
      handleShow();
    }
  }

  return (
    <header
      id="header_main"
      className="header_1 js-header header-final-changes"
      ref={headerRef}
    >
      <div className="container1920">
        <div className={mainoo ? "input-field-btn-search" : "d-none gyvgvdccd"}>
          <span onClick={mainbutton} className="input-cross-ico d-none">
            <img src="\assets\arrow-back.png" alt="img" className="img-fluid" />
          </span>
          <img
            src="\search-normal.svg"
            alt="img"
            className="img-fluid search-icon"
          />
          <input
            type="search"
            placeholder="Search items, collections, and accounts"
            // className="cttyvtycvt"
            className="cttyvtycvt  dropdown-toggle"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            onChange={(e) => handleSearch(e)}
            value={search}
            autocomplete="off"
          />

          {
            search != "" ? <span className="clear-icon" onClick={() => setSearch("")}> <img src="\assets\close-icon.svg" alt="img" className="img-fluid" /> </span> : ""
          }

          {/* {collection || userAccount || item ? */}

          <div
            className={search ? "dropdown-menu searchbar-menu text-primary" : 'dropdown-menu searchbar-menu invisible'}
            aria-labelledby="dropdownMenuButton"
          >
            <div className="parent-main">
              <div className="main">
                <h5 className="main-heading">Collections</h5>
                <a className="dropdown-item" href="#">
                  {collection?.length >= 1 ? (
                    <>
                      {collection?.map((data) => (
                        <a href={"/collections?id=" + data?._id}>
                          <div className="parent">
                            <div className="left">
                              <img
                                alt="img"
                                src={data?.logoImage}
                                className="img-fluid mr-3"
                                style={{
                                  borderRadius: "100%",
                                  height: "40px",
                                  width: "40px",
                                }}
                              />
                            </div>
                            <div className="right">
                              <h6>{data?.name}</h6>
                              {/* <p>{collection?.length} items</p> */}
                            </div>
                          </div>
                        </a>
                      ))}
                    </>
                  ) : (
                    <p>No collection found</p>
                  )}
                </a>
              </div>
              <div className="main">
                <h5 className="main-heading">Accounts</h5>
                <a className="dropdown-item" href="#">
                  {userAccount?.length >= 1 ? (
                    <>
                      {userAccount?.map((data) => (
                        <a href={"/user?id=" + data?.walletAddress}>
                          {/* {// console.log("data", data)} */}
                          <div className="parent">
                            <div className="left">
                              <img
                                src={data?.picture}
                                alt="img"
                                className="img-fluid mr-3"
                                style={{
                                  borderRadius: "100%",
                                  height: "40px",
                                  width: "40px",
                                }}
                              />
                            </div>
                            <div className="right">
                              <h6>{data?.userFullName}</h6>
                            </div>
                          </div>
                        </a>
                      ))}
                    </>
                  ) : (
                    <p>No user Found</p>
                  )}
                </a>
              </div>
              <div className="main">
                <h5 className="main-heading">Items</h5>
                <a className="dropdown-item">
                  {item?.length >= 1 ? (
                    <>
                      {item?.map((data) => (
                        <a href={"/item-details?id=" + data?._id}>
                          <div className="parent">
                            <div className="left">
                              <img
                                src={data?.image}
                                alt="img"
                                className="img-fluid mr-3"
                                style={{
                                  borderRadius: "100%",
                                  height: "40px",
                                  width: "40px",
                                }}
                              />
                            </div>
                            <div className="right">
                              <h6>{data?.nftName}</h6>
                              {/* <p>azukkiglobal#3</p> */}
                            </div>
                          </div>
                        </a>
                      ))}
                    </>
                  ) : (
                    <p>No item found</p>
                  )}
                </a>
              </div>
            </div>
            {/* <div className="input-field">
            <input type="text" placeholder="Press Enter to search all items" />
          </div> */}
          </div>
          {/* :
          <>....</>
        } */}
        </div>
        <div className="row">
          <div className="col-xl-12 m-auto">
            <div id="site-header-inner">
              <div className="wrap-box flex justify-content-space-between position-relative">
                <div id="site-logo" className="clearfix">
                  <div id="site-logo-inner">
                    <Link to="/" rel="home" className="main-logo">
                      <img
                        className="logo-dark"
                        id="logo_header"
                        src={logodark}
                        srcSet={`${logodark2x}`}
                        alt="nft-gaming"
                      />
                      <img
                        className="logo-light"
                        id="logo_header"
                        src={logoheader}
                        srcSet={`${logoheader2x}`}
                        alt="nft-gaming"
                      />
                    </Link>
                  </div>
                </div>
                <div
                  className="mobile-button"
                  ref={btnToggle}
                  onClick={menuToggle}
                >
                  <span></span>
                </div>

                <div className="flat-search-btn111 flex">
                  <nav id="main-nav" className="main-nav " ref={menuLeft}>
                    <ul id="menu-primary-menu" className="menu">
                      {/* <li className="menu-item">
                      <Link to="/explore">Explore</Link>
                    </li> */}
                      <li className="menu-item pl-0">
                        <div className="dropdown">
                          <Link
                            to=""
                            className="feature-drop explore_____drop___down dropdown-toggle-animate"
                            type="button"
                            id="dropdownMenuButtonExplore"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Explore
                          </Link>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButtonExplore"
                          >
                            <Link
                              to="/explore"
                              className="dropdown-item"
                              href=""
                            >
                              All NFT's
                            </Link>
                            <Link
                              to="/explore-collections"
                              className="dropdown-item mt-3 border-0"
                              href=""
                            >
                              All Collections
                            </Link>
                          </div>
                        </div>
                      </li>
                      <li className="menu-item">
                        <Link to="/createitem">Create</Link>
                      </li>
                      {account &&
                        <li className="menu-item">
                          <div className="dropdown">
                            <Link
                              to=""
                              className=" feature-drop dropdown-toggle-animate"
                              type="button"
                              id="dropdownMenuButton1"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              {chainId === 5 && "Eth"}
                              {chainId === 97 && "Bsc"}
                              {chainId === 137 && "Polygon"}
                            </Link>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <a
                                onClick={() => ChangeNetwork(5)}
                                className="dropdown-item"
                              >
                                Eth
                              </a>
                              <a
                                onClick={() => ChangeNetwork(97)}
                                className="dropdown-item"
                              >
                                Bsc
                              </a>
                              <a
                                onClick={() => ChangeNetwork(137)}
                                className="dropdown-item border-0"
                              >
                                Polygon
                              </a>
                            </div>
                          </div>
                        </li>
                      }
                      {/* <li className="menu-item">
                        <div className="dropdown">
                          <Link
                            to=""
                            className=" feature-drop dropdown-toggle-animate"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Features
                          </Link>
                          <div
                            className="dropdown-menu"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <Link
                              to="/createnft"
                              className="dropdown-item create_____nft____dropdown"
                              href=""
                            >
                              Create NFT
                            </Link> */}
                      {/* <Link
                              to="/compettion"
                              className="dropdown-item"
                              href=""
                            >
                              Competions
                            </Link> */}
                      {/* <Link
                              to="/createcollection"
                              className="dropdown-item border-0"
                              href=""
                            >
                              Create Collection
                            </Link> */}
                      {/* <Link
                              to="/rentnft"
                              className="dropdown-item"
                              href=""
                            >
                              Rent NFT
                            </Link>
                            <Link to="/swap" className="dropdown-item" href="">
                              Swap NFT
                            </Link>
                            <a
                              className="dropdown-item border-0 Compare________NFT______dropdown"
                              href=""
                            >
                              Compare NFT
                            </a> */}
                      {/* </div>
                        </div>
                      </li> */}
                      {/* <li className="menu-item">
                      <Link to="/author">Authors</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/collectionfirst">Collections</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="/ranking">Rankings</Link>
                    </li>
                    <li className="menu-item">
                      <Link to="">Create Competition</Link>
                    </li> */}

                      <li className="mode-switcher-li d-none">
                        <div className="mode_switcher">
                          <Link
                            to="#"
                            onClick={(e) => switchTheme(e)}
                          // onClick={() => settoggle(!toggle)}
                          >
                            {theme === darkTheme ? (
                              <img src={imgsun} alt="" />
                            ) : (
                              <img src={imgmoon} alt="" />
                            )}
                          </Link>
                        </div>
                      </li>
                      <li className="flash-btn-li d-none">
                        <div className="flash-btn">
                          <div
                            className="header-search flat-show-search gcccvg"
                            id="s2"
                          // ref={ref}
                          >
                            <Link
                              to="#"
                              className="show-search header-search-trigger"
                            // onClick={() => {
                            //   setIsMenuOpen((oldState) => !oldState);
                            // }}
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="change-my-color"
                              >
                                <path
                                  d="M17.91 10.7209H14.82V3.52087C14.82 1.84087 13.91 1.50087 12.8 2.76087L12 3.67087L5.23001 11.3709C4.30001 12.4209 4.69001 13.2809 6.09001 13.2809H9.18001V20.4809C9.18001 22.1609 10.09 22.5009 11.2 21.2409L12 20.3309L18.77 12.6309C19.7 11.5809 19.31 10.7209 17.91 10.7209Z"
                                  fill="white"
                                />
                              </svg>
                            </Link>

                            {/* Notifactions Box*/}
                            {/* {isMenuOpen && (
                            <div className="top-search Notifications__box dropdown_menus notification-bar">
                              <div className="heading d-flex">
                                <h3 className="heading1">Notifications</h3>
                                <div className="seeall">See All</div>
                              </div>
                              <div className="content-search">
                                <Link to="/searchcollection">
                                  <ul className="list-inline iiiiiii">
                                    <li className="list-inline-item uuuuuuuuu">
                                      <div className="inner-img">
                                        <img
                                          src="\bluemoon-nft\Rectangle 1.svg"
                                          className="img-fluid w100 mr-4"
                                          alt=""
                                        />
                                      </div>
                                      <div className="inner-img mb-2">
                                        <h6 className="grey">
                                          Tyler Covington started <br></br>
                                          following you.
                                        </h6>
                                        <p className="">1 hour ago</p>
                                      </div>
                                    </li>
                                  </ul>
                                </Link>
                                <Link to="/searchcollection">
                                  <ul className="list-inline iiiiiii">
                                    <li className="list-inline-item uuuuuuuuu">
                                      <div className="inner-img">
                                        <img
                                          src="\bluemoon-nft\Rectangle 1.svg"
                                          className="img-fluid w100 mr-4"
                                          alt=""
                                        />
                                      </div>
                                      <div className="inner-img mb-2">
                                        <h6 className="grey">
                                          Tyler Covington started <br></br>
                                          following you.
                                        </h6>
                                        <p className="">1 hour ago</p>
                                      </div>
                                    </li>
                                  </ul>
                                </Link>
                                <Link to="/searchcollection">
                                  <ul className="list-inline iiiiiii">
                                    <li className="list-inline-item uuuuuuuuu">
                                      <div className="inner-img">
                                        <img
                                          src="\bluemoon-nft\Rectangle 1.svg"
                                          className="img-fluid w100 mr-4"
                                          alt=""
                                        />
                                      </div>
                                      <div className="inner-img mb-2">
                                        <h6 className="grey">
                                          Tyler Covington started <br></br>
                                          following you.
                                        </h6>
                                        <p className="">1 hour ago</p>
                                      </div>
                                    </li>
                                  </ul>
                                </Link>
                                <Link to="/searchcollection">
                                  <ul className="list-inline iiiiiii">
                                    <li className="list-inline-item uuuuuuuuu">
                                      <div className="inner-img">
                                        <img
                                          src="\bluemoon-nft\Rectangle 1.svg"
                                          className="img-fluid w100 mr-4"
                                          alt=""
                                        />
                                      </div>
                                      <div className="inner-img mb-2">
                                        <h6 className="grey">
                                          Tyler Covington started <br></br>
                                          following you.
                                        </h6>
                                        <p className="">1 hour ago</p>
                                      </div>
                                    </li>
                                  </ul>
                                </Link>
                              </div>
                            </div>
                          )} */}
                            {/* Notifactions */}
                          </div>
                        </div>
                      </li>
                    </ul>
                  </nav>
                  <div className="flat-search-btn">
                    <div className="header-search flat-show-search " id="s1">
                      <Link
                        to="#"
                        className="show-search header-search-trigger d-none"
                        onClick={mainbutton}
                      >
                        <i className="far fa-search"></i>
                      </Link>

                      <div
                        id="navbar"
                        className={
                          "top-search p-0 New__CLass_search hgctcty d-none"
                        }
                        ref={btnSearch}
                      >
                        <span
                          onClick={searchBtn}
                          className="input-cross-ico d-none"
                        >
                          x
                        </span>
                        <input
                          className="cttyvtycvt"
                          type="search"
                          placeholder="Search..."
                        />
                      </div>
                    </div>
                    <div className="sc-btn-top mg-r-12 d-none" id="site-header">
                      <Link
                        to=""
                        className="sc-button header-slider style style-1 wallet fl-button pri-1 specs-hover-btn"
                      >
                        <span>Wallet connect</span>
                      </Link>
                    </div>
                    <div
                      className="nav-drop position-relative dropdown"
                      ref={refdropmenu}
                    >
                      <button
                        className={
                          account
                            ? "buttonsco ml-2 mr-5 profile-btn"
                            : "button-connect buttonsco ml-2 mr-5 profile-btn"
                        }
                        type="button"
                        onClick={() => getStatus()}
                      >
                        <div className="user-profile-img">
                          {account ? (
                            <>
                              {getUserData?.picture ?
                                <img
                                  src={getUserData?.picture}
                                  className="img-fluid"
                                />
                                :
                                <img
                                  src={"https://bluemoon-s3.s3.us-west-2.amazonaws.com/pictures/placeholder.png"}
                                  className="img-fluid mr-3"
                                />
                              }
                            </>
                          ) : (
                            <img
                              src="/assets/buttonwallet.png"
                              className="img-fluid mr-3"
                            />
                          )}
                        </div>

                        <span className="user-title-btn">
                          {account ? account.slice(0, 12) : "Connect Wallet"}
                        </span>
                        {account ?
                          <img
                            src="\bluemoon-nft\Property 33.png"
                            className={
                              isMenuOpendrop
                                ? "img-fluid arrow-down-pro mr-3 dropdown-toggle-animate-spec"
                                : "img-fluid arrow-down-pro mr-3"
                            }
                          />
                          :
                          <div></div>
                        }
                      </button>

                      {isMenuOpendrop && (
                        <div
                          className="dropdown-menu head22 d-block"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div className="row">
                            <div className="col-sm-12 p-0">
                              <div className="inner-drop">
                                {account ? (
                                  <>
                                    <div className="boxy"></div>
                                    <div className="withprofile">
                                      <div className="left">
                                        <div className="user-profile-img-inner">
                                          {account ? (
                                            <img
                                              src={getUserData?.picture}
                                              className="img-fluid"
                                            />
                                          ) : (
                                            <img
                                              src="/assets/buttonwallet.png"
                                              className="img-fluid"
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="right">
                                        <h3 className="headingofdrop">
                                          {getUserData?.userFullName}
                                        </h3>
                                        <div className="">
                                          <div className="known">
                                            {getUserData?.walletAddress ? (
                                              <>
                                                <p className="text-truncate">
                                                  {getUserData?.walletAddress.slice(
                                                    0,
                                                    18
                                                  )}
                                                </p>
                                                <CopyToClipboard
                                                  text={account}
                                                  onCopy={() => copiedHandle()}
                                                >
                                                  <img
                                                    src="\instagramnft-assets\detail-page\copy-fav.png"
                                                    className="img-fluid ml-2 cursor-pointer "
                                                    alt=""
                                                  />
                                                </CopyToClipboard>
                                              </>
                                            ) : null}
                                          </div>
                                          <div className="copied__alert text-dark mt-2 text-left">
                                            {copied ? "copied" : ""}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <div className="mb-4">
                                    <p className="text-truncate">
                                      Connect Wallet
                                    </p>
                                  </div>
                                )}
                                {account ? (
                                  <ul className="list-inline unknown">
                                    <li className="list-inline-item">
                                      <img
                                        src="\asssests\ETHEREUM 2.svg"
                                        className="img-fluid mr-2"
                                        alt=""
                                      />
                                    </li>

                                    <li className="list-inline-item">
                                      <div className="inner-blnc">
                                        <h6 className="headofdown">Balance</h6>
                                        <h4 className="">
                                          <span className="common-text">
                                            {checkBlance ? checkBlance : 0}{" "}
                                            <span>BNB</span>{" "}
                                          </span>
                                        </h4>
                                      </div>
                                    </li>
                                  </ul>
                                ) : null}
                                <div
                                  className="bottom-text"
                                  style={{ marginTop: "32px" }}
                                >
                                  {account ? (
                                    <>
                                      <Link
                                        className="my-profile-btn"
                                        to="/myprofile/sale"
                                      >
                                        <div className="mainofdrop">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="change-my-color mr-3"
                                          >
                                            <path
                                              d="M12 2C9.38 2 7.25 4.13 7.25 6.75C7.25 9.32 9.26 11.4 11.88 11.49C11.96 11.48 12.04 11.48 12.1 11.49C12.12 11.49 12.13 11.49 12.15 11.49C12.16 11.49 12.16 11.49 12.17 11.49C14.73 11.4 16.74 9.32 16.75 6.75C16.75 4.13 14.62 2 12 2Z"
                                              fill="white"
                                            />
                                            <path
                                              d="M17.0809 14.15C14.2909 12.29 9.74094 12.29 6.93094 14.15C5.66094 15 4.96094 16.15 4.96094 17.38C4.96094 18.61 5.66094 19.75 6.92094 20.59C8.32094 21.53 10.1609 22 12.0009 22C13.8409 22 15.6809 21.53 17.0809 20.59C18.3409 19.74 19.0409 18.6 19.0409 17.36C19.0309 16.13 18.3409 14.99 17.0809 14.15Z"
                                              fill="white"
                                            />
                                          </svg>

                                          <p className="headofdone">
                                            My Profile
                                          </p>
                                        </div>
                                      </Link>
                                      <Link
                                        className="my-profile-btn"
                                        to="/EditProfile"
                                      >
                                        <div className="mainofdrop">
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="change-my-color mr-3"
                                          >
                                            <path
                                              d="M21 22H3C2.59 22 2.25 21.66 2.25 21.25C2.25 20.84 2.59 20.5 3 20.5H21C21.41 20.5 21.75 20.84 21.75 21.25C21.75 21.66 21.41 22 21 22Z"
                                              fill="white"
                                            />
                                            <path
                                              d="M19.0206 3.47967C17.0806 1.53967 15.1806 1.48967 13.1906 3.47967L11.9806 4.68967C11.8806 4.78967 11.8406 4.94967 11.8806 5.08967C12.6406 7.73967 14.7606 9.85967 17.4106 10.6197C17.4506 10.6297 17.4906 10.6397 17.5306 10.6397C17.6406 10.6397 17.7406 10.5997 17.8206 10.5197L19.0206 9.30967C20.0106 8.32967 20.4906 7.37967 20.4906 6.41967C20.5006 5.42967 20.0206 4.46967 19.0206 3.47967Z"
                                              fill="white"
                                            />
                                            <path
                                              d="M15.6103 11.53C15.3203 11.39 15.0403 11.25 14.7703 11.09C14.5503 10.96 14.3403 10.82 14.1303 10.67C13.9603 10.56 13.7603 10.4 13.5703 10.24C13.5503 10.23 13.4803 10.17 13.4003 10.09C13.0703 9.81005 12.7003 9.45005 12.3703 9.05005C12.3403 9.03005 12.2903 8.96005 12.2203 8.87005C12.1203 8.75005 11.9503 8.55005 11.8003 8.32005C11.6803 8.17005 11.5403 7.95005 11.4103 7.73005C11.2503 7.46005 11.1103 7.19005 10.9703 6.91005C10.9491 6.86465 10.9286 6.81949 10.9088 6.77458C10.7612 6.44127 10.3265 6.34382 10.0688 6.60158L4.34032 12.33C4.21032 12.46 4.09032 12.71 4.06032 12.88L3.52032 16.71C3.42032 17.39 3.61032 18.03 4.03032 18.46C4.39032 18.81 4.89032 19 5.43032 19C5.55032 19 5.67032 18.99 5.79032 18.97L9.63032 18.43C9.81032 18.4 10.0603 18.28 10.1803 18.15L15.9016 12.4287C16.1612 12.1692 16.0633 11.7237 15.7257 11.5797C15.6877 11.5634 15.6492 11.5469 15.6103 11.53Z"
                                              fill="white"
                                            />
                                          </svg>

                                          <p className="headofdone">
                                            Edit Profile
                                          </p>
                                        </div>
                                      </Link>
                                    </>
                                  ) : null}
                                  <Link className="my-profile-btn" to="">
                                    <div className="mainofdrop">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="change-my-color mr-3"
                                      >
                                        <path
                                          d="M16.8 2H14.2C11 2 9 4 9 7.2V11.25H15.25C15.66 11.25 16 11.59 16 12C16 12.41 15.66 12.75 15.25 12.75H9V16.8C9 20 11 22 14.2 22H16.79C19.99 22 21.99 20 21.99 16.8V7.2C22 4 20 2 16.8 2Z"
                                          fill="white"
                                        />
                                        <path
                                          d="M4.55945 11.25L6.62945 9.17997C6.77945 9.02997 6.84945 8.83997 6.84945 8.64997C6.84945 8.45997 6.77945 8.25997 6.62945 8.11997C6.33945 7.82997 5.85945 7.82997 5.56945 8.11997L2.21945 11.47C1.92945 11.76 1.92945 12.24 2.21945 12.53L5.56945 15.88C5.85945 16.17 6.33945 16.17 6.62945 15.88C6.91945 15.59 6.91945 15.11 6.62945 14.82L4.55945 12.75H8.99945V11.25H4.55945Z"
                                          fill="white"
                                        />
                                      </svg>
                                      {account ? (
                                        <p
                                          className="headofdone"
                                          onClick={logoutt}
                                        >
                                          {" "}
                                          Disconnect
                                        </p>
                                      ) : (
                                        <p
                                          className="headofdone"
                                          onClick={handleShow}
                                        >
                                          {" "}
                                          Connect
                                        </p>
                                      )}
                                      <br />
                                    </div>
                                  </Link>
                                  {/* <Link className="my-profile-btn" to="">
                                    <div className="mainofdrop">
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="change-my-color mr-3"
                                      >
                                        <path
                                          d="M21.5307 15.9299C21.3707 15.6599 20.9207 15.2399 19.8007 15.4399C19.1807 15.5499 18.5507 15.5999 17.9207 15.5699C15.5907 15.4699 13.4807 14.3999 12.0107 12.7499C10.7107 11.2999 9.91068 9.40987 9.90068 7.36987C9.90068 6.22987 10.1207 5.12987 10.5707 4.08987C11.0107 3.07987 10.7007 2.54987 10.4807 2.32987C10.2507 2.09987 9.71068 1.77987 8.65068 2.21987C4.56068 3.93987 2.03068 8.03987 2.33068 12.4299C2.63068 16.5599 5.53068 20.0899 9.37068 21.4199C10.2907 21.7399 11.2607 21.9299 12.2607 21.9699C12.4207 21.9799 12.5807 21.9899 12.7407 21.9899C16.0907 21.9899 19.2307 20.4099 21.2107 17.7199C21.8807 16.7899 21.7007 16.1999 21.5307 15.9299Z"
                                          fill="#7A798A"
                                        />
                                      </svg>

                                      <p className="headofdone"> Dark Mode</p>
                                      <div className="mode_switcher">
                                        <Link
                                          to="#"
                                          onClick={(e) => switchTheme(e)}
                                        >
                                          {theme === darkTheme ? (
                                            <img src={imgsun} alt="" />
                                          ) : (
                                            <img src={imgmoon} alt="" />
                                          )}
                                        </Link>
                                      </div>
                                    </div>
                                  </Link> */}
                                </div>
                                <div className="bottom-text"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <Modal
                        className="connect_wallet_modal_wrapper"
                        show={show}
                        onHide={handleClose}
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title className="connect-wallet-header">
                            Connect Wallet
                          </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <div onClick={connectMetamask} className="connect_wallet_type_btn">
                            <div
                              className="connect_wallet_type_img"
                            >
                              <img src={MetamaskLogo} />
                            </div>

                            <h4>Metamask</h4>
                          </div>
                          {/* <Web3Wrapper/> */}
                          <div
                            className="connect_wallet_type_btn"
                            onClick={trustWallet}
                          >
                            <div className="connect_wallet_type_img">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="22"
                                height="22"
                                viewBox="0 0 36 22"
                                fill="none"
                              >
                                <path
                                  d="M7.37376 4.29932C13.2428 -1.43306 22.7562 -1.43306 28.6329 4.29932L29.339 4.98841C29.6351 5.27617 29.6351 5.73809 29.339 6.02585L26.9245 8.38846C26.7803 8.53234 26.5373 8.53234 26.3931 8.38846L25.4212 7.4419C21.3288 3.44362 14.6854 3.44362 10.593 7.4419L9.55282 8.45662C9.40856 8.6005 9.1656 8.6005 9.02134 8.45662L6.59932 6.094C6.30321 5.80624 6.30321 5.34432 6.59932 5.05657L7.37376 4.29932ZM33.6287 9.176L35.7774 11.2736C36.0735 11.5613 36.0735 12.0233 35.7774 12.311L26.0818 21.7842C25.7857 22.072 25.3149 22.072 25.0188 21.7842L18.1324 15.0598C18.0565 14.9917 17.9426 14.9917 17.8666 15.0598L10.9878 21.7842C10.6917 22.072 10.221 22.072 9.92485 21.7842L0.221593 12.3186C-0.0745155 12.0308 -0.0745155 11.5689 0.221593 11.2812L2.37028 9.18358C2.66639 8.89582 3.13713 8.89582 3.43323 9.18358L10.3197 15.908C10.3956 15.9761 10.5095 15.9761 10.5854 15.908L17.4642 9.18358C17.7603 8.89582 18.2311 8.89582 18.5272 9.18358L25.4136 15.908C25.4895 15.9761 25.6034 15.9761 25.6794 15.908L32.5658 9.18358C32.8543 8.88825 33.3326 8.88825 33.6287 9.176Z"
                                  fill="white"
                                />
                              </svg>
                            </div>
                            <h4>WalletConnect</h4>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DarkMode />
    </header>
  );
};

export default Header;
