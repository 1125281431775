import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Scrollbar, A11y } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import axios from 'axios';
import { Api_URL } from '../../utils/ApiURL';
// import 'swiper/swiper-bundle.min.css'
// import 'swiper/swiper.min.css'

const TopSeller = props => {
    // const data = props.data;



    const [data, setTopSellerData] = useState(null);

    const getTopSellers = async () => {

        await axios.get(`${Api_URL}/sale/getPopularSellers`)

            .then((res) => {
                setTopSellerData(res?.data?.data);
            })
            .catch((err) => {

            })
    }

    useEffect(() => {
        getTopSellers();
    }, [])




    return (
        <section className="Top_____Seller top-seller desktop-padding-manual">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="heading-live-auctions">
                            <h2 className="tf-title">
                                Top Sellers</h2>
                        </div>
                    </div>
                    <div className="col-md-12 padd-right-0">
                        <Swiper
                            modules={[Navigation, Scrollbar, A11y]}
                            spaceBetween={15}
                            navigation
                            breakpoints={{
                                0: {
                                    slidesPerView: 2.7,
                                    spaceBetween: 33,
                                },
                                767: {
                                    slidesPerView: 5,
                                },
                                991: {
                                    slidesPerView: 7,
                                },
                                1200: {
                                    slidesPerView: 8,
                                },
                                1350: {
                                    slidesPerView: 8,
                                },
                            }}
                            scrollbar={{ draggable: true }}
                        >
                            {
                                data?.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <TopSellerItem item={item} />
                                    </SwiperSlide>
                                ))
                            }
                        </Swiper>
                    </div>
                </div>
            </div>
        </section>
    );
}

const TopSellerItem = data => (
    <div className="swiper-container seller style2 seller-slider2 button-arow-style">
        <div className="swiper-wrapper">
            <div className="swiper-slide">
                <div className="slider-item">
                    <div className="sc-author-box style-2">
                        <div className="author-avatar">
                            <Link to={"/user?id=" + data?.item?.user?.walletAddress}><img src={data?.item?.user?.picture} alt="" className="avatar" /></Link>
                            {data?.item?.verified ? <div className="badge"></div> : null}
                        </div>
                        <div className="author-infor">
                            <h5><Link to={"/user?id=" + data?.item?.user?.walletAddress}>{data?.item?.user?.userFullName && data?.item?.user?.userFullName !== "undefined" ? data?.item?.user?.userFullName : data?.item?.user?.walletAddress?.slice(0, 3) + "..." + data?.item?.user?.walletAddress?.slice(data?.item?.user?.walletAddress.length - 3, data?.item?.user?.walletAddress.length)}</Link></h5>
                            <span className="price">{data?.item?.totalAmount?.toFixed(4)}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
)

export default TopSeller;
