import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";
import { Api_URL } from "../../utils/ApiURL";
import "./ranking.scss";
import { useWeb3React } from '@web3-react/core'
import Loader from '../../hooks/loader'

const Ranking = () => {

  const [collByCat, setCollByCat] = useState('Category');
  const [time, setTime] = useState("day");
  const [colls, setColls] = useState([]);
  const [category, setCategory] = useState(["Art", "Music", "Domain Names", "Virtual Worlds", "Trading Cards", "Collectibles", "Sports", "Photography"]);
  const [chain, setChain] = useState(["97", "80001", "4", "43114"])
  const [prev, setPrev] = useState([]);
  const context = useWeb3React();
  const [loader, setLoader] = useState(null);
  const { chainId } = context;

  // const [isShown, setIsShown] = useState(false);
  // const handleClick = (event) => {
  //   setIsShown((current) => !current);
  // };

  // const getCollByCat = () => {

  //   var data = { category: collByCat };

  //   axios.post(`${Api_URL}/collection/getCollectionsByCategory`, data)
  //     .then((res) => {
  //       setColls(res?.data?.data);
  //       // console.log(res?.data?.data, "setCollByCatData")
  //     })
  //     .catch((err) => {

  //     })
  // }

  // useEffect(() => {
  //   getCollByCat();
  // }, [collByCat])



  useEffect(() => {
    setLoader(true);
    var data = JSON.stringify({
      "category": category,
      "chainId": chain,
      "queryBy": time
    });

    var config = {
      method: 'post',
      url: `${Api_URL}/collection/getTopCollectionByDays`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(config)
      .then(function (response) {
        setColls(response.data.data);
        setPrev(response?.data?.prevData)
        setLoader(false);
        // setPrev(response.data.prevData);
      })
      .catch(function (error) {
        // console.log(error);
        setLoader(false);
      });
  }, [time, chain, category])

  const List = ({ data, index }) => {

    const [show, setShow] = useState(false);

    return (
      <>
        <div className="box">
          <div className="box1">
            <p className="head1">{index + 1}</p>
            <figure className="fig1">
              <Link to={"/collections?id=" + data?._id}>
                <img
                  // src="\asssests\boreape.svg"
                  src={data?.logoImage}
                  style={{ borderRadius: 50 }}
                  className="img-fluid ml-3 img11"
                  alt=""
                />
              </Link>
              <img
                src="\asssests\tick.svg"
                className="img-fluid img12"
                alt=""
              />
            </figure>
            <div className="link1">
              <h4 className="heading1">{data?.name}</h4>
              {
                show ? <a className="link-btn" onClick={() => setShow(false)}>
                  -Less
                </a> : <a className="link-btn more-btn" onClick={() => setShow(true)}>
                  +More
                </a>
              }
            </div>
          </div>
          <div className="box2">
            <h4 className="heading2">
              <img
                src="\bnb.svg"
                className="img-fluid img13-mbl"
                alt=""
              />
              {data?.volume[0] ? data?.volume[0]?.amount.toFixed(2) : 0} {chainId === 97 && "BNB"} {chainId === 4 && "Eth"} {chainId === 80001 && "Matic"} {chainId === 43114 && "Avax"}
            </h4>
            {/* <p className="para1">-1.60%</p> */}
            {data?.volume?.length === 0 && prev[index]?.volume?.length > 0 &&
              <p className='tdc para1 text-danger'>
                {(prev[index]?.volume[0]?.amount).toFixed(2)}
              </p>
            }
            {data?.volume?.length > 0 && prev[index]?.volume?.length === 0 &&
              <p className='tdc para1 text-success'>
                {(data?.volume[0]?.amount).toFixed(2)}
              </p>
            }
            {data?.volume?.length > 0 && prev[index]?.volume?.length > 0 &&
              <p className={'tdc para1 ' + ((data?.volume[0]?.amount * prev[index]?.volume[0]?.amount) / 100 > 0 ? "text-success" : "text-danger")}>
                {((data?.volume[0]?.amount * prev[index]?.volume[0]?.amount) / 100).toFixed(2)}
              </p>
            }
            {data?.volume?.length === 0 && prev[index]?.volume?.length === 0 &&
              <p style={{ color: '#7A798A' }} className={'tdc para1 '}>
                0.00
              </p>
            }
          </div>
        </div>
        {
          show ? <>
            <div className="main1">
              <div className="small1">
                <h4 className="paraa1">Floor Price</h4>
                <div className="set1">
                  <h4 className="para2">
                    <img
                      src="\bnb.svg"
                      className="img-fluid img13-mbl"
                      alt=""
                    />
                    {data?.florPrice?.length > 0 ? data?.florPrice[0]?.floorPrice?.toFixed(2) : 0} {data?.chainId === "97" && "BNB"} {data?.chainId === "4" && "Eth"} {data?.chainId === "80001" && "Matic"} {data?.chainId === "43114" && "Avax"}
                  </h4>
                </div>
              </div>
              <div className="small1">
                <h4 className="paraa1">Owners</h4>
                <h4 className="para2">{data?.creators?.length}</h4>
              </div>
              <div className="small1">
                <h4 className="paraa1">Items</h4>
                <h4 className="para2">{data?.items?.length}</h4>
              </div>
            </div>
          </> : ""
        }
      </>
    )
  }

  return (
    <>
      {loader && <Loader />}
      <Header />
      <section className="rankingsection">
        <div className="headings">
          <div>
            <h4 className="mainheading">Top Collection</h4>
          </div>
          <div>
            <p className="mainparagraph">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum
              obcaecati <br />
              dignissimos quae quo ad iste ipsum officiis deleniti asperiores
              sit.
            </p>
          </div>
        </div>
        <div>
          <div className="btns-drop">
            <div className="dropdown">
              <button
                className="btn1"
                type=""
                id=""
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {"1 " + time}
                <figure>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="change-my-color"
                  >
                    <path
                      d="M4 6L8 10L12 6"
                      stroke="#1F1F2C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </figure>
              </button>
              <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <button className="dropdown-item" onClick={() => setTime("day")}>
                  1 Day
                </button>
                <button className="dropdown-item" onClick={() => setTime("week")}>
                  1 Week
                </button>
                <button className="dropdown-item" onClick={() => setTime("month")}>
                  1 Month
                </button>
                <button className="dropdown-item" onClick={() => setTime("year")}>
                  All
                </button>
              </div>
            </div>
            <div className="dropdown">
              <button
                className="btn2"
                type="button"
                id=""
                data-toggle="dropdown"
                aria-expanded="false"
              >
                {collByCat}
                <figure>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="change-my-color"
                  >
                    <path
                      d="M4 6L8 10L12 6"
                      stroke="#1F1F2C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </figure>
              </button>
              <div className="dropdown-menu statusdrop2" aria-labelledby="789">
                <button className="dropdown-item" onClick={() => setCategory(["Art", "Music", "Domain Names", "Virtual Worlds", "Trading Cards", "Photography", "Collectibles", "Sports", "Utility", "Other"])}>
                  All
                </button>
                {/* <button className="dropdown-item" onClick={() => setCategory(["Trending"])}>
                  Trending
                </button>
                <button className="dropdown-item" onClick={() => setCategory(["Top"])}>
                  Top
                </button> */}
                <button className="dropdown-item" onClick={() => setCategory(["Art"])}>
                  Art
                </button>
                <button className="dropdown-item" onClick={() => setCategory(["Collectibles"])}>
                  Collectibles
                </button>
                <button className="dropdown-item" onClick={() => setCategory(["Photography"])}>
                  Photography
                </button>
                <button className="dropdown-item" onClick={() => setCategory(["Domain Names"])}>
                  Domain Names
                </button>
                <button className="dropdown-item" onClick={() => setCategory(["Music"])}>
                  Music
                </button>
                <button className="dropdown-item" onClick={() => setCategory(["Sports"])}>
                  Sports
                </button>
                <button className="dropdown-item" onClick={() => setCategory(["Trading Cards"])}>
                  Trading Cards
                </button>
                <button className="dropdown-item" onClick={() => setCategory(["Virtual Worlds"])}>
                  Virtual Worlds
                </button>
              </div>
            </div>
            <div className="dropdown">
              <button
                className="btn2"
                type="button"
                id=""
                data-toggle="dropdown"
                aria-expanded="false"
              >
                All Chains{" "}
                <figure>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="change-my-color"
                  >
                    <path
                      d="M4 6L8 10L12 6"
                      stroke="#1F1F2C"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </figure>
              </button>
              <div className="dropdown-menu statusdrop2" aria-labelledby="789">
                <button className="dropdown-item pl-0" onClick={() => setChain(["97", "80001", "4", "43114"])}>
                  All
                </button>
                <button className="dropdown-item pl-0" onClick={() => setChain(["97"])}>
                  Bsc
                </button>
                {/* <button className="dropdown-item pl-0">
                  Solana
                </button> */}
                {/* <button className="dropdown-item pl-0" onClick={() => setChain(["80001"])}>
                  Polygon
                </button> */}
                {/* <div className="inner-btns">
                  <button className="mr-3">Clear</button>
                  <button>Apply</button>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
          <div className="maintable">
          {colls?.length > 0 && prev?.length > 0 ?
            <table className="tbl">
           <tr>
                <th className="headt forpadd">Collection</th>
                <th className="forpadd">Volume</th>
                <th className="forpadd">24h %</th>
                <th className="forpadd">Floor Price</th>
                <th className="forpadd">Owners</th>
                <th className="forpadd">Items</th>
              </tr>
              {colls?.map((data, index) =>
                <tr key={index}>
                  <td className="tda">
                    <span className="clside">{index + 1}</span>
                    <figure className="fig1">
                      <Link to={"/collections?id=" + data?._id}><img
                        src={data?.logoImage}
                        className="img-fluid ml-5 img11 rounded-circle"
                        alt=""
                      /></Link>
                      <img
                        src="\asssests\tick.svg"
                        className="img-fluid img12"
                        alt=""
                      />
                    </figure>
                    {data?.name}
                  </td>
                  <td className="tdb">
                    <img
                      src="\bnb.svg"
                      className="img-fluid img13"
                      alt=""
                    />
                    {data?.volume[0] ? data?.volume[0]?.amount.toFixed(4) : 0} {data?.chainId === "97" && "BNB"} {data?.chainId === "4" && "Eth"} {data?.chainId === "80001" && "Matic"} {data?.chainId === "43114" && "Avax"}
                  </td>
                  {/* <td className="tdc">-1.60%</td> */}
                  {data?.volume?.length === 0 && prev[index]?.volume?.length > 0 &&
                    <td className='tdc text-danger'>
                      {prev[index]?.volume[0]?.amount?.toFixed(6)}
                    </td>
                  }
                  {data?.volume?.length > 0 && prev[index]?.volume?.length === 0 &&
                    <td className='tdc text-success'>
                      {data?.volume[0]?.amount?.toFixed(6)}
                    </td>
                  }
                  {data?.volume?.length > 0 && prev[index]?.volume?.length > 0 &&
                    <td className={'tdc ' + ((data?.volume[0]?.amount * prev[index]?.volume[0]?.amount) / 100 > 0 ? "text-success" : "text-danger")}>
                      {((data?.volume[0]?.amount * prev[index]?.volume[0]?.amount) / 100)?.toFixed(4)}
                    </td>
                  }
                  {data?.volume?.length === 0 && prev[index]?.volume?.length === 0 &&
                    <td style={{ color: '#7A798A' }} className={'tdc '}>
                      0.00
                    </td>
                  }
                  <td className="tde">
                    <img
                      src="\bnb.svg"
                      className="img-fluid img13"
                      alt=""
                    />
                    {data?.florPrice?.length > 0 ? data?.florPrice[0]?.floorPrice?.toFixed(4) : 0} {data?.chainId === "97" && "BNB"} {data?.chainId === "4" && "Eth"} {data?.chainId === "80001" && "Matic"} {data?.chainId === "43114" && "Avax"}
                  </td>
                  <td className="tdf">{data?.creators?.length}</td>
                  <td className="tdg">{data?.items?.length}</td>
                </tr>
              )}

            </table>
            :
            <div className='py-5 my-5 d-flex align-items-center justify-content-center w-100'>
              <h2 className='py-5 my-5'>No Collection found</h2>
            </div>

          }
        </div>
          </div>
        </div>
    
        

        {/* <div className="butn mt-5 mb-5">
          <button className="btn5">Load More</button>
        </div> */}
      </section>
      <section className="rankingsection-mbl d-none">
        <div className="headings">
          <div>
            <h4 className="mainheading">Top Collections</h4>
          </div>
          <div className="sparagraph">
            <p className="mainparagraph">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum
              obcaecati <br />
              dignissimos quae quo ad iste ipsum officiis deleniti asperiores
              sit.
            </p>
          </div>
        </div>
        <div className="btns-drop">
          <div className="dropdown">
            <button
              className="btn1"
              type="button"
              id=""
              data-toggle="dropdown"
              aria-expanded="false"
            >
              {"1 " + time}
              <figure>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="change-my-color"
                >
                  <path
                    d="M4 6L8 10L12 6"
                    stroke="#1F1F2C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </figure>
            </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
              <button className="dropdown-item" onClick={() => setTime("day")}>
                1 Day
              </button>
              <button className="dropdown-item" onClick={() => setTime("week")}>
                1 Week
              </button>
              <button className="dropdown-item" onClick={() => setTime("month")}>
                1 Month
              </button>
              <button className="dropdown-item" onClick={() => setTime("year")}>
                All
              </button>
            </div>
          </div>
          <div className="dropdown">
            <button
              className="btn2"
              type="button"
              id=""
              data-toggle="dropdown"
              aria-expanded="false"
            >
              {collByCat}
              <figure>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="change-my-color"
                >
                  <path
                    d="M4 6L8 10L12 6"
                    stroke="#1F1F2C"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </figure>
            </button>
            <div className="dropdown-menu statusdrop2" aria-labelledby="789">
              <button className="dropdown-item" onClick={() => setCategory(["Art", "Music", "Domain Names", "Virtual Worlds", "Trading Cards", "Collectibles", "Sports", "Photography"])}>
                All
              </button>
              {/* <button className="dropdown-item" onClick={() => setCategory(["Trending"])}>
                Trending
              </button>
              <button className="dropdown-item" onClick={() => setCategory(["Top"])}>
                Top
              </button> */}
              <button className="dropdown-item" onClick={() => setCategory(["Photography"])}>
                Photography
              </button>
              <button className="dropdown-item" onClick={() => setCategory(["Art"])}>
                Art
              </button>
              <button className="dropdown-item" onClick={() => setCategory(["Collectibles"])}>
                Collectibles
              </button>
              <button className="dropdown-item" onClick={() => setCategory(["Domain Names"])}>
                Domain Names
              </button>
              <button className="dropdown-item" onClick={() => setCategory(["Music"])}>
                Music
              </button>
              <button className="dropdown-item" onClick={() => setCategory(["Sports"])}>
                Sports
              </button>
              <button className="dropdown-item" onClick={() => setCategory(["Trading Cards"])}>
                Trading Cards
              </button>
              <button className="dropdown-item" onClick={() => setCategory(["Virtual Worlds"])}>
                Virtual Worlds
              </button>
            </div>
          </div>
        </div>
        {colls?.map((data, index) =>
          <List data={data} index={index} />
        )}
        {/* <div className="butn mt-5 mb-5">
          <button className="btn5">Load More</button>
        </div> */}
      </section>
      <Footer />
    </>
  );
};

export default Ranking;
