import React, { useState } from "react";
import { Link } from "react-router-dom";
import imgsun from "../../assets/images/icon/sun.png";
import imgmoon from "../../assets/images/icon/moon-2.png";

const DarkMode = () => {
  let clickedClass = "clicked";
  const body = document.body;
  const lightTheme = "light";
  const darkTheme = "is_dark";
  const [toggle, settoggle] = useState(false);
  let theme;
  if (localStorage) {
    theme = localStorage.getItem("theme");
  }
  // if (theme === lightTheme || theme === darkTheme) {
  //   body.classList.add(theme);
  // } else {
  body.classList.add(lightTheme);
  // }

  const switchTheme = (e) => {
    // if (theme === darkTheme) {
    //   body.classList.replace(darkTheme, lightTheme);
    //   e.target.classList.remove(clickedClass);
    //   localStorage.setItem("theme", "light");
    //   theme = lightTheme;
    // } else {
    body.classList.replace(lightTheme, lightTheme);
    e.target.classList.add(clickedClass);
    localStorage.setItem("theme", "light");
    theme = lightTheme;
    // }
  };
  return (
    <div className="mode_switcher d-none">
      <Link
        to="#"
        onClick={e => switchTheme(e)}
      // onClick={() => settoggle(!toggle)}
      >
        {theme === darkTheme ? <img src={imgsun} alt="" /> : <img src={imgmoon} alt="" />}
      </Link>
    </div>
  );
};

export default DarkMode;
