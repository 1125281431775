import React, { useEffect, useState } from "react";
const AddProperties = ({ properties, setProperties }) => {
  const [nArr, setNArr] = useState(1);
  const [rend, setRend] = useState(false);

  useEffect(() => {
    let arr = Array.from(Array(nArr).keys());
    let dumArr = properties;
    let dumObj = {
      name: "",
      value: ""
    };
    arr.forEach((number, index) => {
      if (index + 1 > properties.length) {
        dumArr.push(dumObj);
      }
    })
    setProperties(dumArr);
    setRend(!rend);
  }, [nArr])

  const UpdateName = (val, index) => {
    let dumObj = properties;
    dumObj[index].name = val;
    // console.log("call", dumObj)
    setProperties(dumObj);
    setRend(!rend)
  }

  const UpdateVals = (val, index) => {
    let dumObj = properties;
    dumObj[index].value = val;
    // console.log("call", dumObj)
    setProperties(dumObj);
    setRend(!rend)
  }

  const RemoveRow = (index) => {
    let dumArr = properties;
    dumArr.splice(index, 1); 
    setProperties(dumArr);
    setNArr(dumArr.length);
    setRend(!rend);
  }

  return (
    // <div className="modal-dialog modal-dialog-centered" role="document">
    //     <div className="modal-content">
    //         <div className="modal-header">
    //             <h5 className="modal-title" id="exampleModalLongTitle">
    //                 Add Properties
    //             </h5>
    //             <button
    //                 type="button"
    //                 className="close close-pos-minting"
    //                 data-dismiss="modal"
    //                 aria-label="Close"
    //             >
    //                 <span aria-hidden="true"><img src=".\create\close.png" alt="img" className="img-fluid" /></span>
    //             </button>
    //         </div>
    //         <div className="modal-body">
    //             <div className="modal-minting">
    //                 <div className="center">
    //                     <p className="para-prop">Properties show up underneath your item, are clickable and can be filtered in your collection sidebar</p>
    //                     {properties.map((item, index) => {
    //                         return <div key={index} className="input-items my-2">
    //                             <div className="input-field">
    //                                 <label>Name</label>
    //                                 <input onChange={(e) => UpdateName(e.target.value, index)} value={item.name} type="text" placeholder="Character" />
    //                             </div>
    //                             <div className="input-field">
    //                                 <label>Values</label>
    //                                 <input onChange={(e) => UpdateVals(e.target.value, index)} value={item.character} type="text" placeholder="Male" />
    //                             </div>
    //                         </div>
    //                     })}
    //                 </div>
    //                 <div className="bottom-text">
    //                     <button onClick={() => setNArr(nArr + 1)} className="add-more-btn">Add More</button>
    //                     <div className="btn-save">
    //                         <button data-dismiss="modal"
    //                             aria-label="Close" className="save-btn">Save</button>
    //                     </div>
    //                 </div>
    //             </div>
    //         </div>
    //     </div>
    // </div>

    <div className="modal-dialog modal-dialog-centered" role="document">
      <div className="modal-content">
        <div className="modal-header mainheading">
          <h5 className="text-center">Add Properties</h5>
          <button
            type="button"
            className="close btn-close-header"
            data-dismiss="modal"
            aria-label="Close"
          >
            <img
              src="\bluemoon-nft\create\layer1.svg"
              className="img-fluid cross"
            />
          </button>
        </div>
        <div className="modal-body addproprty">
          <div className="paramodal">
            <p className="">
              Properties show up underneath your item, are clickable, and can be
              filtered in your collection's sidebar.
            </p>
          </div>

          {properties.map((item, index) => {
            return (
              <>
                <div className="modal-fieldssss" key={index}>
                  <div className="set-fields">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Type</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Character"
                        onChange={(e) => UpdateName(e.target.value, index)}
                        value={item.name}
                      />
                    </div>
                  </div>
                  <div className="set-fields">
                    <div className="form-group">
                      <label for="exampleInputEmail1">Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="exampleInputEmail1"
                        aria-describedby="emailHelp"
                        placeholder="Male"
                        onChange={(e) => UpdateVals(e.target.value, index)} 
                        value={item.character}
                      />
                    </div>
                  </div>
                  <div className="set-field-icon">
                    <div className="closecross">
                      <img
                        onClick={()=>RemoveRow(index)}
                        src="\asssests\close-circle.svg"
                        className="img-fluid crosimg"
                      />
                    </div>
                  </div>
                </div>
              </>
            );
          })}
          <div className="addmre">
            <button onClick={() => setNArr(nArr + 1)} className="heelo-btn">
              {/* <button className="heelo-btn" onClick={() => setNArr(nArr + 1)}> */}
              Add More
            </button>
          </div>
          <div className="savebutton">
            <button data-dismiss="modal" aria-label="Close" className="">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddProperties;
