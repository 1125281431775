import { useCallback, useState, useEffect } from "react";
import useWeb3 from "../useWeb3";
import environment from "../../utils/Environment";
import { getMarketContract } from "../../utils/contractHelpers";
import { useWeb3React } from "@web3-react/core";

const AcceptBid = () => {
    const web3 = useWeb3();
    // const contractAddress = environment.MarketContract;
    const [contractAddress, setContractAddress] = useState(null);
    const context = useWeb3React();
    const { chainId } = context;

    useEffect(() => {
        if (chainId === 97) {
            setContractAddress(environment.marketPlaceContract);
        }
        //  else if (chainId === 4) {
        //     setContractAddress(environment.Eth.MarketContract);
        // } else if (chainId === 43114) {
        //     setContractAddress(environment.Eth.MarketContract);
        // } else if (chainId === 80001) {
        //     setContractAddress(environment.Polygon.MarketContract);
        // }
    }, [chainId])

    const contract = getMarketContract(contractAddress, web3);

    const BidAccept = useCallback(
        async (account, values, uri, unix_seconds, values1, values2, contractAddress) => {
            console.log("accept bid", values, uri, unix_seconds, values1, values2, contractAddress)
            // account, values, image1, unix_seconds, values1, values2, nft?.contractAddress
            if (contractAddress) {
                try {
                    const gas = await contract.methods
                        .AcceptBidAndMint(values, uri, unix_seconds, values1, values2, contractAddress)
                        .estimateGas({ from: account });
                    let gasPrice = await web3.eth.getGasPrice();
                    const details = await contract.methods
                        .AcceptBidAndMint(values, uri, unix_seconds, values1, values2, contractAddress)
                        .send({
                            from: account,
                            gas,
                            gasPrice: gasPrice
                        })
                    return details;
                } catch (error) {
                    throw (error)
                }
            }
        },
        [contract]
    );
    return { BidAccept: BidAccept };
};

export default AcceptBid;